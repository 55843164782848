import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { isEmpty } from "lodash";
// import SearchIcon from '@material-ui/icons/Search';
import BurgerIcon from "@material-ui/icons/Menu";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";

import CircularProgress from "@material-ui/core/CircularProgress";
import userIcon from "../../../../assets/img/user.jpg";
import Image from "../../../ImageTag/Image";
import { CONFIG } from "../../../../constants";
import { userLogout } from "../../../../redux/actions/user.actions";
import sitemap from "../../../../routing/siteMap";
import store from "../../../../redux";
import Logo from "../../../Logo/Logo";
import QuickSearch from "../../../QuickSearch";
import Notifications from "../DesktopHeader/Notifications";
import Messages from "../DesktopHeader/Messages";
import SocketContext from "../../../../socket";
import "./MobileHeader.scss";
import SocialMedia from "../../Footer/SocialMedia";

const mapStateToProps = (state) => ({
  user: state.user,
});

class MobileHeader extends Component {
  state = {
    drawerOpen: false,
    isLanguageOpen: false,
  };

  handleShowDrawer = () => {
    this.setState({
      drawerOpen: true,
    });
  };

  handleHideDrawer = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  handleShowLanguage = () => {
    this.setState((prevState) => ({
      isLanguageOpen: !prevState.isLanguageOpen,
    }));
  };

  handleHideLanguage = () => {
    this.setState({
      isLanguageOpen: false,
    });
  };

  logOut = () => {
    const { history, socket } = this.props;
    store.dispatch(userLogout());
    socket.disconnect();
    history.push("/");
  };

  render() {
    const { user, isUserRefreshInProgress } = this.props;
    const { drawerOpen } = this.state;

    const isUserAvailable = !isEmpty(user);
    return (
      <header id="headerWrapper">
        <div className="headerContainer">
          <div className="toggleContainer">
            <BurgerIcon onClick={this.handleShowDrawer} />
          </div>

          <div className="logoContainer">
            <Logo />
          </div>

          <div className="searchContainer">
            <QuickSearch mobile />
          </div>
        </div>
        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={this.handleHideDrawer}
          onOpen={this.handleShowDrawer}
          PaperProps={{ style: { backgroundColor: "#212121" } }}
        >
          <div className="mobileNavContainer">
            <div className="navButtons">
              {user ? (
                <div className="linkBlock">
                  <span
                    onClick={this.handleHideDrawer}
                    role="button"
                    tabIndex="-1"
                  >
                    <Messages />
                  </span>
                  <span
                    onClick={this.handleHideDrawer}
                    role="button"
                    tabIndex="-1"
                  >
                    <Notifications />
                  </span>
                </div>
              ) : (
                <div className="buttonBlock">
                  <ul>
                    <li>
                      <NavLink
                        to={sitemap.login}
                        activeClassName="active"
                        onClick={this.handleHideDrawer}
                      >
                        Register / Login
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="nav">
              <ul>
                <li>
                  <NavLink to="/" onClick={this.handleHideDrawer}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/exhibitions" onClick={this.handleHideDrawer}>
                    Exhibition
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/gallery/all" onClick={this.handleHideDrawer}>
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/store/all" onClick={this.handleHideDrawer}>
                    Store
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/studios" onClick={this.handleHideDrawer}>
                    Studios
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/feed" onClick={this.handleHideDrawer}>
                    Feed
                  </NavLink>
                </li>

                {isUserAvailable && (
                  <>
                    {isUserRefreshInProgress ? (
                      <CircularProgress style={{ width: 24, height: 24 }} />
                    ) : (
                      <>
                        <li className="userProfile menu-showing">
                          <span>
                            <i>
                              {user?.picture && user?.picture !== undefined ? (
                                <Image src={`users/${user?.picture}`} alt="" />
                              ) : (
                                <img
                                  src="https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png"
                                  alt=""
                                />
                              )}
                            </i>
                            <i>{user?.name}</i>
                          </span>
                          <ul>
                            <li>
                              {user.role > 10 && user.role < 21 ? (
                                <NavLink
                                  to={generatePath(sitemap.yourWork, {
                                    slug: user?.slug,
                                  })}
                                  activeClassName="active"
                                  onClick={this.handleHideDrawer}
                                >
                                  Profile
                                </NavLink>
                              ) : (
                                <NavLink
                                  to={generatePath(sitemap.yourWork, {
                                    slug: user?.slug,
                                  })}
                                  activeClassName="active"
                                  onClick={this.handleHideDrawer}
                                >
                                  Profile
                                </NavLink>
                              )}
                            </li>
                            {user.role > 20 && (
                              <li>
                                <NavLink
                                  to={generatePath(sitemap.getStudio, {
                                    slug: user.slug,
                                  })}
                                  activeClassName="active"
                                  onClick={this.handleHideDrawer}
                                >
                                  Get A Studio
                                </NavLink>
                              </li>
                            )}

                            <li>
                              <NavLink
                                to={sitemap.uploadArt}
                                activeClassName="active"
                                onClick={this.handleHideDrawer}
                              >
                                Upload Art
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to={sitemap.profilePrivacy}
                                activeClassName="active"
                                onClick={this.handleHideDrawer}
                              >
                                Privacy
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to={sitemap.editInformation}
                                activeClassName="active"
                                onClick={this.handleHideDrawer}
                              >
                                {user.updated === "false"
                                  ? "Update Profile"
                                  : "Edit Information"}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to={sitemap.order}
                                activeClassName="active"
                                onClick={this.handleHideDrawer}
                              >
                                My Orders
                              </NavLink>
                            </li>
                            <li style={{ marginBottom: "20px" }}>
                              <button onClick={this.logOut}>Log Out</button>
                            </li>
                          </ul>
                        </li>
                      </>
                    )}
                  </>
                )}
                {/* <li className={languageClass}>

                  <span onClick={this.handleShowLanguage}>English<DownIcon/></span>
                  <ul>
                    <li><span>Nepali</span></li>
                    <li><span>Chineese</span></li>
                  </ul>
                </li> */}
                <li>
                  <NavLink to={sitemap.terms}>Terms of Use</NavLink>
                </li>
                <li>
                  <NavLink to={sitemap.privacy}>Privacy Policy</NavLink>
                </li>
                <li style={{ marginLeft: 10 }}>
                  <SocialMedia />
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Logo />
                </li>
                <li
                  style={{
                    fontSize: "12px",
                    marginTop: "2px",
                    color: "white",
                    padding: "0px 30px",
                    marginBottom: "10px",
                  }}
                >
                  © {new Date().getFullYear()} Tilicho Kala
                </li>
              </ul>
              {/* </>
              )} */}
            </div>
          </div>
        </SwipeableDrawer>
      </header>
    );
  }
}

MobileHeader.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  isUserRefreshInProgress: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

MobileHeader.defaultProps = {
  user: null,
};
const Socketed = (props) => (
  <SocketContext.Consumer>
    {(socket) => <MobileHeader {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default withRouter(connect(mapStateToProps)(Socketed));
