import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import cn from "classnames";

import Button from "../Button";
import css from "./exhibitionSlider.module.scss";


const EmailModal = ({ onClose, onSubmit, loading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  return (
    <div className={css.imageModal}>
      <div
        className={css.backdropDetails}
        onClick={onClose}
        role="button"
        tabIndex="-1"
      />
      <div className={css.imageContainer}>
        <div className={cn(css.detailsModal, css.excerptModal)}>
          <div className={css._title}>Send us a message</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit({ name, email, phone, message });
            }}
          >
            <div className="customForm">
              <div className="fields">
                <div className="formGroup">
                  <input
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="formGroup">
                  <input
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="formGroup">
                  <input
                    type="tel"
                    placeholder="Phone no."
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="formGroup">
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>
                <div className="formAction">
                  <Button
                    loading={loading}
                    size="full"
                    mode="specialBlack"
                    type="submit"
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <CloseIcon onClick={onClose} className={css.close} />
      </div>
    </div>
  );
};

export default EmailModal;
