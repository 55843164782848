import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BottomScrollListener from "react-bottom-scroll-listener";

import Loading from "../../Loading";
import FeedItem from "../../FeedItem";

const FeedCollection = ({
    posts,
    refresh,
    loadMore,
    loadingMore,
    total,
    showOption,
    user = "",
}) => {
    useEffect(() => {
        const data = posts.filter((post) => {
            return !post?.hideFrom?.find((listId) => listId === user?.id);
        });

        // if (data.length <= 0 && user) {
        //     loadMore();
        // }
    }, [posts]);
    return (
        <>
            <div className="commonFeedContainer">
                {posts.map((post, i) => (
                    <>

                        {!post?.hideFrom?.find(
                            (listId) => listId === user?.id
                        ) &&
                            post?.author != null && (
                                <FeedItem
                                    key={`feed-item-${i}`}
                                    post={post}
                                    refresh={refresh}
                                    showOption={showOption}
                                />
                            )}
                    </>
                ))}
            </div>
            {loadingMore ? (
                <Loading color="red" />
            ) : (
                <>
                    {posts.length < total ? (
                        <BottomScrollListener
                            onBottom={loadMore}
                            offset={500}
                        />
                    ) : (
                        <div style={{ textAlign: "center", padding: "15px 0" }}>
                            No posts to show
                        </div>
                    )}
                </>
            )}
        </>
    );
};

FeedCollection.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object).isRequired,
    refresh: PropTypes.func,
    loadMore: PropTypes.func.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
};

export default FeedCollection;
