import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import Image, { OverlapImage, Image1 } from "../../../ImageTag/Image";
import sitemap from "../../../../routing/siteMap";
import { postAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import calculateTime from "./calculateTime";
import SocketContext from "../../../../socket";
import { connect } from "react-redux";

const Template = ({
  // index,
  // calculateTime,
  // name,
  // picture,
  // to,
  // isSeen,
  // lastMessage,
  // messageCheck,
  // onClick,
  i,
  // conversation,
  user,
  data,
  socket,
  refreshallConversation,
  seenId,
  activeParticipants,
  messageSeen,
}) => {
  const makeConversationSeen = (id) => {
    // socket.emit('read-message', { room: data._id, userId: user.id });
    // socket.emit("read-message", { room: data.room._id, userId: user.id });
    socket.on("message-seen", (data) => {
      messageSeen && messageSeen(data.seen);
    });
    // //
    //  console.log(activeParticipants, "activeParticipants");

    refreshallConversation();
  };

  const [sender, setSender] = useState();
  const [isSeen, setIsSeen] = useState();

  const getSenderInfo = (conversation) => {
    const sender = conversation?.participants?.filter(
      (info) => info._id !== user.id
    );
    // const isSeen = data._id===seenId?true:false;
    const isSeen = data.seen.includes(user?.id);
    let isSeenData;
    if (data?._id === seenId) {
      isSeenData = activeParticipants?.some((info) => info._id === seenId);
    }
    // console.log(data._id,"seen",seenId)
    // console.log(seenId,"message seen",data)

    setIsSeen(isSeen || isSeenData);

    if (sender?.length) setSender(sender[0]);
  };

  useEffect(() => {
    getSenderInfo(data);
  }, [data, seenId]);

  return (
    <>
      {data?.type === "normal" ? (
        data?.lastMessage?.message && (
          <div className="messages-container" key={`active-conversation-${i}`}>
            <div className="innerItem">
              {data?.type === "group" ? (
                <OverlapImage
                  items={data.participants.filter(
                    (ptpnt) => ptpnt._id !== user.id
                  )}
                />
              ) : (
                <Image1
                  src={
                    sender?.picture && sender?.picture != "undefined"
                      ? `users/${sender?.picture}`
                      : "assets/profile-dummy.png"
                  }
                  styleClass={{
                    width: 100,
                    justifyContent: "center",
                    display: "flex",
                  }}
                />
              )}
            </div>
            <div
              className="innerItem"
              onClick={() => makeConversationSeen(data?._id)}
            >
              <Link
                to={generatePath(sitemap.messages, {
                  to: data?._id,
                })}
                role="button"
                params={{ conversation: data }}
                // onClick={onClick}
              >
                <ul>
                  <li>
                    <span className="inbox-name">
                      {data.type === "normal" ? sender?.name : data.name}
                    </span>
                  </li>
                  <li className={`inbox-message ${isSeen ? "read" : "unread"}`}>
                    <p className="msg-inbox">
                      <span>
                        {data?.lastMessage?.from === user && "You: "}
                        {data?.lastMessage?.messageType === "text"
                          ? data?.lastMessage?.message
                          : data?.lastMessage?.messageType === "image"
                          ? "Image"
                          : ""}
                      </span>
                    </p>
                    <span className="msg-time">
                      {data && calculateTime(data?.updatedAt)}
                    </span>
                  </li>
                </ul>
              </Link>
            </div>
          </div>
        )
      ) : (
        <div className="messages-container" key={`active-conversation-${i}`}>
          <div className="innerItem">
            {data?.type === "group" ? (
              <OverlapImage
                items={data.participants.filter(
                  (ptpnt) => ptpnt._id !== user.id
                )}
              />
            ) : (
              <Image1
                src={
                  sender?.picture && sender?.picture != "undefined"
                    ? `users/${sender?.picture}`
                    : "assets/profile-dummy.png"
                }
                styleClass={{
                  width: 100,
                  justifyContent: "center",
                  display: "flex",
                }}
              />
            )}
          </div>
          <div
            className="innerItem"
            onClick={() => makeConversationSeen(data?._id)}
          >
            <Link
              to={generatePath(sitemap.messages, {
                to: data?._id,
              })}
              role="button"
              params={{ conversation: data }}
              // onClick={onClick}
            >
              <ul>
                <li>
                  <span className="inbox-name">
                    {data.type === "normal" ? sender?.name : data.name}
                  </span>
                </li>
                <li className={`inbox-message ${isSeen ? "read" : "unread"}`}>
                  <p className="msg-inbox">
                    <span>
                      {data?.lastMessage?.from === user && "You: "}
                      {["text", "left", "join"].includes(
                        data?.lastMessage?.messageType
                      )
                        ? data?.lastMessage?.message
                        : data?.lastMessage?.messageType === "image"
                        ? "Image"
                        : ""}
                    </span>
                  </p>
                  <span className="msg-time">
                    {data && calculateTime(data?.updatedAt)}
                  </span>
                </li>
              </ul>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
const SocketedConversation = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Template {...props} socket={socket} />}
  </SocketContext.Consumer>
);
const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps)(SocketedConversation);
