import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import sitemap from "../../routing/siteMap";
import FollowArtist from "../FollowArtist";
import Avatar from "../Avatar";
import CommonButton from "../Button";
import VerifiedIcon from "../../assets/img/Verified.png";

import "./advanceAvatarInlineStudio.scss";

/**
 * Display Avatar with more info
 * @visibleName Advanced Avatar
 */
const AdvancedAvatarInlineStudio = ({
    artist,
    name,
    imgPath,
    followers,
    works,
    category,
    verified,
    intl,
    setFollow
}) => {
    // const [readMore, setReadMore] = useState(true);

    const detailsArray = [];
    console.log({followers})
    if (followers)
        detailsArray.push(
            intl.formatMessage(
                { id: "ui.artist.followers" },
                { count: followers }
            )
        );
    if (works)
        detailsArray.push(
            intl.formatMessage({ id: "ui.artist.works" }, { count: works })
        );
    const detailsString = detailsArray.join(" / ");

    // const toggleReadMore = () => {
    //     setReadMore(!readMore);
    // };
    return (
        <div className="advanceAvatarInline">
            {imgPath && <Avatar imgPath={imgPath} alt={name} />}
            <div className="infoContainer">
                <div className="name">
                    <Link
                        to={generatePath(sitemap.artist, {
                            slug: artist?.slug,
                        })}
                    >
                        <h4>
                            {name}{" "}
                            {verified && (
                                <img
                                    src={VerifiedIcon}
                                    style={{
                                        marginBottom: "3px",
                                        height: "12px",
                                    }}
                                />
                            )}
                        </h4>
                    </Link>
                </div>
                <div className="category">
                    <h6>{category}</h6>
                </div>
                <div className="details">
                    <h6>{detailsString}</h6>
                </div>

                <div className="action">
                    <ul>
                        <li>
                            <FollowArtist artist={artist} setFollow={setFollow} />
                        </li>
                        <li>
                            <Link
                                to={generatePath(sitemap.artist, {
                                    slug: artist.slug,
                                })}
                            >
                                <CommonButton size="medium" mode="profile">
                                    Profile
                                </CommonButton>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="artistDesc">
                    {artist?.info && artist?.info?.biography !== "undefined" && (
                        <>
                            <p className="bio">
                                {artist?.info?.biography?.slice(0, 140)}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

AdvancedAvatarInlineStudio.propTypes = {
    artist: PropTypes.objectOf(PropTypes.any).isRequired,
    imgPath: PropTypes.string,
    followers: PropTypes.number,
    works: PropTypes.number,
    name: PropTypes.string.isRequired,
    category: PropTypes.string,
    verified: PropTypes.bool,

    intl: intlShape.isRequired,
};

AdvancedAvatarInlineStudio.defaultProps = {
    imgPath: null,
    followers: null,
    works: null,
};

export default injectIntl(AdvancedAvatarInlineStudio);
