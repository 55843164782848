import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import Cookie from 'js-cookie';
import axios from 'axios';
import sound from '../../../../../assets/sounds/beep.mp3';

import Avatar from '../../../../Avatar';
import sitemap from '../../../../../routing/siteMap';
import {
    getWithAuth,
    postAuth
} from '../../../../../services/generalApiServices';
import Loading from '../../../../Loading';
import SocketContext from '../../../../../socket';
import { CONFIG } from '../../../../../constants';
import NotificationSVG from '../../../../../assets/icons/Notification.svg';
import CommonButton from '../../../../Button';
import './Notifications.scss';
import { showGlobalSnack } from '../../../../../redux/actions/snack.actions';

import NotificationOutline from '../../../../../assets/icons/Notification.Stroke.png';
import NotificationFill from '../../../../../assets/icons/Notification.Fill.png';

const mapStateToProps = ({ user }) => ({
    user
});

class Notifications extends Component {
    state = {
        loading: true,
        notifications: [],
        newNotification: false,
        following: []
    };
    ping = new Audio(sound);
    componentDidMount() {
        this.connect();
        this.checkUnreadNotification();
        this.getFollowing();
    }

    componentDidUpdate() {
        this.connect();
    }

    connect = () => {
        const { socket } = this.props;
        if (socket) {
            socket.on('newNotification', () => {
                this.setState({ newNotification: true });
                this.getNotifications();
                // this.checkUnreadNotification();
                this.playSound(this.ping);
            });
        }
    };
    playSound = (audioFile) => {
        audioFile.play();
    };
    checkUnreadNotification = () => {
        const { user } = this.props;
        let unseenNotifications = false;
        if (user) {
            _.each(user.notifications, (notification) => {
                if (notification.read === 'no') {
                    unseenNotifications = true;
                }
            });
        }
        if (unseenNotifications) {
            this.setState({ newNotification: true });
        }
    };

    getNotifications = () => {
        getWithAuth(
            CONFIG.GET_NOTIFICATIONS,
            (notifications) => {
                const sortedNotifications = notifications.sort(
                    (notificationA, notificationB) => {
                        return moment(notificationA.created).unix() <
                            moment(notificationB.created).unix()
                            ? 1
                            : -1;
                    }
                );
                sortedNotifications.splice(CONFIG.NOTIFICATION_LIMIT);
                this.setState({
                    loading: false,
                    notifications: sortedNotifications
                });
            },
            (error) => {
                this.setState({
                    loading: false
                });
                console.error(error);
            }
        );
    };

    makeNotiificationsSeen = () => {
        const { notifications } = this.state;
        let needToUpdate = false;
        _.each(notifications, (notification) => {
            if (notification.read === 'no') needToUpdate = true;
        });
        if (needToUpdate) {
            postAuth(
                CONFIG.MAKE_NOTIFICATIONS_SEEN,
                () => {
                    this.setState({ newNotification: false });
                },
                (e) => {
                    console.error(e);
                }
            );
        }
    };

    makeNotificationViewed = (id) => {
        postAuth(
            CONFIG.MAKE_NOTIFICATION_VIEWED,
            () => {
                this.getNotifications();
            },
            (e) => {
                console.error(e);
            },
            {
                notificationID: id
            }
        );
    };
    getFollowing = () => {
        getWithAuth(
            CONFIG.GET_FOLLOWING,
            (following) => this.setState({ following }),
            (e) => console.log(e)
        );
    };
    approveFollowRequest = (notification) => {
        postAuth(
            CONFIG.APPROVE_FOLLOW,
            () => {
                this.setState({ notifications: [], loading: true });
                this.getNotifications();
            },
            (error) => console.log(error),
            {
                notification
            }
        );
    };
    followArtist = (id) => {
        const token = Cookie.get(CONFIG.AUTH_TOKEN);
        if (token) {
            // this.setState({ loading: true });
            axios({
                url: CONFIG.FOLLOW_ARTIST,
                method: 'POST',
                headers: {
                    Authorization: `JWT ${token}`
                },
                data: {
                    artist: id
                }
            })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.getFollowing();
                    } else if (response.data.status === 'followed') {
                        showGlobalSnack(
                            'normal',
                            'You already follow this artist. Please refresh the page again.',
                            2000
                        );
                    } else if (response.data.status === 'not-found') {
                        showGlobalSnack(
                            'normal',
                            'An error occurred. Please refresh the page and try again.',
                            2000
                        );
                    } else {
                        showGlobalSnack(
                            'normal',
                            'An unexpected error occurred. Please refresh the page and try again.',
                            2000
                        );
                    }
                    this.setState({ loading: false });
                })
                .catch((e) => {
                    showGlobalSnack(
                        'normal',
                        'An unexpected error occurred. Please refresh the page and try again.',
                        2000
                    );
                    this.setState({ loading: false });
                    console.log(e.message || e);
                });
        }
    };
    unFollowArtist = (id) => {
        const token = Cookie.get(CONFIG.AUTH_TOKEN);
        if (token) {
            // this.setState({ loading: true });
            axios({
                url: `${CONFIG.API_URL}/secure/unfollowartist`,
                method: 'POST',
                headers: {
                    Authorization: `JWT ${token}`
                },
                data: {
                    artist: id
                }
            })
                .then((res) => {
                    if (res.data.status === 'success') {
                        this.getFollowing();
                    } else {
                        showGlobalSnack(
                            'normal',
                            'An unexpected error occurred. Please refresh the page and try again.',
                            2000
                        );
                    }
                    this.setState({ loading: false });
                })
                .catch((e) => {
                    showGlobalSnack(
                        'normal',
                        'An unexpected error occurred. Please refresh the page and try again.',
                        2000
                    );
                    console.log(e.message || e);
                    this.setState({ loading: false });
                });
        }
    };
    render() {
        const {
            loading,
            notifications,
            newNotification,
            following
        } = this.state;
        const { footer, fill } = this.props;

        let notificationList = [];
        if (!_.isEmpty(notifications)) {
            notificationList = notifications.map((notification) => {
                let isFollowing = false;
                _.each(following, (list) => {
                    if (list?._id === notification?.sender?._id)
                        isFollowing = true;
                });
                if (notification?.type === 'post') {
                    return (
                        <div
                            key={`notification-key-${notification._id}`}
                            className={`notification-status dropDownItem -${notification?.read}`}
                        >
                            <Link
                                to={generatePath(sitemap.feedDetails, {
                                    id: notification?.link
                                })}
                                onClick={() =>
                                    this.makeNotificationViewed(
                                        notification?._id
                                    )
                                }
                            >
                                <div className="notification-wrapper">
                                    <Avatar
                                        className="notification"
                                        imgPath={
                                            notification?.sender?.picture &&
                                            `users/${notification?.sender?.picture}`
                                        }
                                    />{' '}
                                    <div className="noti-detail">
                                        {`${notification?.sender &&
                                            notification?.sender?.name} ${
                                            notification?.message
                                        }`}
                                        <p>
                                            {moment(
                                                notification?.created
                                            ).fromNow()}
                                        </p>
                                    </div>
                                    {notification?.image && (
                                        <div className="img-wrapper">
                                            <img src={notification?.image} />
                                        </div>
                                    )}
                                </div>
                            </Link>
                        </div>
                    );
                } else if (notification?.type === 'follow') {
                    return (
                        <div
                            key={`notification-key-${notification._id}`}
                            className={`notification-status dropDownItem -${notification?.read} follow-notification`}
                        >
                            <div
                                className="notification-wrapper"
                                onClick={() =>
                                    this.makeNotificationViewed(
                                        notification?._id
                                    )
                                }
                            >
                                {/* <Link
                  to={notification?.link}
                  onClick={() => this.makeNotificationViewed(notification?._id)}
                > */}
                                <Avatar
                                    className="notification"
                                    imgPath={
                                        notification?.sender?.picture &&
                                        `users/${notification?.sender?.picture}`
                                    }
                                />
                                {/* </Link> */}

                                <div className="noti-detail">
                                    {`${notification?.sender &&
                                        notification?.sender?.name} ${
                                        notification?.message
                                    }`}
                                    <p>
                                        {moment(
                                            notification?.created
                                        ).fromNow()}
                                    </p>
                                </div>
                                {isFollowing ? (
                                    <button
                                        className=" btn following-btn"
                                        onClick={() =>
                                            this.unFollowArtist(
                                                notification?.sender?._id
                                            )
                                        }
                                    >
                                        Following
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            this.followArtist(
                                                notification?.sender?._id
                                            )
                                        }
                                        className="btn follow-btn"
                                    >
                                        Follow
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                }
                return (
                    <div
                        key={`notification-key-${notification._id}`}
                        className={`notification-status dropDownItem -${notification?.read}`}
                    >
                        <Link
                            to={notification.link}
                            onClick={() =>
                                this.makeNotificationViewed(notification?._id)
                            }
                        >
                            <div className="notification-wrapper">
                                <Avatar
                                    className="notification"
                                    imgPath={
                                        notification?.sender?.picture &&
                                        `users/${notification?.sender?.picture}`
                                    }
                                />

                                <div className="noti-detail">
                                    {`${notification?.sender &&
                                        notification?.sender?.name} ${
                                        notification?.message
                                    }`}
                                    <p style={{ display: 'block' }}>
                                        {moment(
                                            notification?.created
                                        ).fromNow()}
                                    </p>
                                </div>
                                {notification?.image && (
                                    <div className="img-wrapper">
                                        <img src={notification?.image} />
                                    </div>
                                )}
                            </div>
                        </Link>
                    </div>
                );
            });
        }

        if (footer) {
            return (
                <div className="nav-icon">
                    <NavLink
                        to={sitemap.notifications}
                        onClick={this.makeNotiificationsSeen}
                    >
                        <Badge
                            color="primary"
                            invisible={!newNotification}
                            variant="dot"
                            style={{}}
                        >
                            <img
                                src={
                                    fill === 5
                                        ? NotificationFill
                                        : NotificationSVG
                                }
                                alt=""
                                style={{ height: 24 }}
                            />
                        </Badge>
                    </NavLink>
                </div>
            );
        }
        return (
            <>
                <NavLink
                    to={sitemap.notifications}
                    activeClassName="active"
                    onMouseOver={this.getNotifications}
                    onClick={this.makeNotiificationsSeen}
                >
                    {newNotification ? (
                        <NotificationIcon style={{ fill: '#984B48' }} />
                    ) : (
                        <NotificationIcon />
                    )}
                </NavLink>

                <div className="dropDownContainer notification-list">
                    <div className="inner">
                        {loading ? (
                            <div
                                className="dropDownItem typeLoading"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Loading color="red" />
                            </div>
                        ) : (
                            <>
                                {notifications.length > 0 ? (
                                    <>{notificationList}</>
                                ) : (
                                    <div className="dropDownItem">
                                        <span>
                                            {"You don't have any notifications"}
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="seeMore">
                        <Link to={sitemap.notifications}>See More</Link>
                    </div>
                </div>
            </>
        );
    }
}

const SocketedNotifications = (props) => (
    <SocketContext.Consumer>
        {(socket) => <Notifications {...props} socket={socket} />}
    </SocketContext.Consumer>
);

Notifications.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    socket: PropTypes.objectOf(PropTypes.any).isRequired,
    footer: PropTypes.bool
};

Notifications.defaultProps = {
    footer: false
};

export default connect(mapStateToProps)(SocketedNotifications);
