import React, { Component } from "react";
import { string, func } from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

import css from "./exhibitionSlider.module.scss";

class ImageModal extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.listenToKeyboard);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listenToKeyboard);
  }

  listenToKeyboard = (e) => {
    const { onClose } = this.props;
    if (e.keyCode === 27) onClose();
  };

  render() {
    const { image, onClose } = this.props;
    return (
      <div className={css.imageModal}>
        <div
          className={css.backdrop}
          onClick={onClose}
          role="button"
          tabIndex="-1"
        />
        <div className={css.fullimage}>
          {image && <img src={image} alt="" />}
          <CloseIcon onClick={onClose} className={css.close} />
        </div>
      </div>
    );
  }
}

ImageModal.propTypes = {
  image: string.isRequired,
  onClose: func.isRequired,
};

export default ImageModal;
