import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { string, func } from "prop-types";
import cn from "classnames";

import { nl2br } from "../../utils/stringUtils";
import css from "./exhibitionSlider.module.scss";

class ExcerptModal extends Component {
  componentDidMount() {}

  render() {
    const { artist, content, onClose } = this.props;
    return (
      <div className={css.imageModal}>
        <div
          className={css.backdropDetails}
          onClick={onClose}
          role="button"
          tabIndex="-1"
        />
        <div className={css.imageContainer}>
          <div className={cn(css.detailsModal, css.excerptModal)}>
            <div className={css._title}>{artist && artist}</div>
            <div>{content && nl2br(content)}</div>
          </div>
          <CloseIcon onClick={onClose} className={css.close} />
        </div>
      </div>
    );
  }
}

ExcerptModal.propTypes = {
  artist: string.isRequired,
  content: string.isRequired,
  onClose: func.isRequired,
};

export default ExcerptModal;
