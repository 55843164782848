import React, { useState, useRef, useEffect, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@material-ui/icons/Image';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Cookie from 'js-cookie';
import axios from 'axios';
import CancelIcon from '@material-ui/icons/Cancel';
import ScrollToBottom from 'react-scroll-to-bottom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LazyLoad from 'react-lazy-load';
import store from '../../../../redux';
import User from '../../../../assets/img/user.jpg';
import {
  getWithAuth,
  postAuth,
  get,
  getAuth,
  postData
} from '../../../../services/generalApiServices';
import { CONFIG } from '../../../../constants';
import { showGlobalSnack } from '../../../../redux/actions/snack.actions';
import SocketContext from '../../../../socket';
import Button from '../../../Button';
import Image from '../../../ImageTag/Image';
import Loading from '../../../Loading';
import Lightbox from 'react-modal-image';
import sitemap from '../../../../routing/siteMap';
import './Message.scss';
import DropdownMenu from '../../../DropdownMenu';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import defaultImg from '../../../../assets/img/skeleton-img.jpg';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { isUserArtist } from '../../../../utils/userUtils';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #984b48;
  height: 30px;
  width: 30px;
`;

const mapStateToProps = ({ dialog }) => ({
  dialog
});
const dialogClasses = {
  paper: {
    width: '100%',
    maxWidth: 280,
    padding: 20
  }
};
const MessageBox = (props) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const [show, setShow] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showBlock, setShowBlock] = useState(false);
  const [showUnblock, setShowUnblock] = useState(false);
  const chatContainer = useRef(null);
  const [reportOpt, setReportOpt] = useState([]);
  const [reportValue, setReportValue] = useState('');
  const [sending, setSending] = useState(false);
  const [messageTxt, setMessageTxt] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [loadingTo, setLoadingTo] = useState(true);
  const [senderData, setSenderData] = useState();
  const [activeConversation, setActiveConversation] = useState({
    allMessage: []
  });
  const [items, setItems] = Array.from({ length: 20 });
  

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);

  const {
    reciever,
    sender,
    // activeConversation,
    handler,
    participantHandler,
    socket,
    refreshallConversation,
    match,
    user,
    classes,
    refreshMessages,
    getMessageSeen
  } = props;
  const room = props.match?.params?.to;

  useEffect(() => {
    if (currentPage === 1) {
      chatContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeConversation]);

  useEffect(() => {
    getConversation();
  }, [currentPage]);

    useEffect(() => {
        socket.on('receive-message', (data) => {
            if (data?.room === room) {
                setActiveConversation((prevState) => {
                    return {
                        // ...prevState,
                        // messages: [...prevState?.allMessage?.messages, data],
                        ...prevState,
                        allMessage: {
                            ...prevState.allMessage,
                            messages: [...prevState.allMessage?.messages, data]
                        }
                    };
                });
              
                props.recieveMessages()
            }
            // refreshallConversation();
        });
    }, [socket]);

  useEffect(() => {
    getReportOptions();
  }, []);

  const refreshConversation = () => {
    if (sender) {
      getWithAuth(
        generatePath(CONFIG.GET_MESSAGES, {
          roomId: match?.params?.to
        }),
        (activeConversation) => {
          handler(activeConversation);
          if (
            !_.isEmpty(activeConversation) &&
            activeConversation.seen.includes(sender?.id)
          ) {
            makeConversationSeen(activeConversation?._id);
          }
        },
        (e) => {
          console.error(e);
          store.dispatch(
            showGlobalSnack(
              'error',
              'Something went wrong. Please reload and try again.',
              3000
            )
          );
        }
      );
    }
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    // setTimeout(() => {
    //     this.setState({
    //         items: this.state.items.concat(Array.from({ length: 20 })),
    //     });
    // }, 1500);
  };

  const getConversation = () => {
    setLoadingTo(false);
    if (user) {
      getAuth(
        generatePath(
          `${CONFIG.GET_MESSAGES}/?per_page=${20}&page=${currentPage}}`,
          {
            roomId: match?.params?.to
          }
        ),
        (data) => {
          
          if (currentPage > 1)
            setActiveConversation((prevState) => {
              return {
                ...prevState,
                allMessage: {
                  ...prevState.allMessage,
                  messages: [
                    ...data?.allMessage?.messages,
                    ...prevState?.allMessage?.messages
                  ]
                }
              };
            });
          else setActiveConversation(data);


       
          socket.emit("read-message", { room: data.room._id, userId: user.id });

          
          getMessageSeen({roomId:data.room._id});
        },
        (e) => {
          console.error(e);
          // store.dispatch(showGlobalSnack('error', 'Something went wrong. Please reload and try again.', 3000));
        }
      );
    }
    getAuth(
      `${CONFIG.GET_CONVERSATIONS}?user=${user?.id}`,
      (conversations) => {
        const data = conversations?.find(
          (conversations) => conversations._id === room
        );

        const sender = data?.participants.find(
          (sender) => sender._id !== user?.id
        );

        get(
          generatePath(CONFIG.GET_USER_BY_ID, { id: sender.id }),
          (res) => {
            setSenderData({
              name: res.name,
              slug: res.slug,
              role: res.role
            });
          },
          (e) => console.log(e)
        );
      },

      (e) => console.log(e)
    );
  };

  const getOldMessage = () => {
    setCurrentPage(currentPage + 1);
  };
  const refreshParticipants = () => {
    if (sender) {
      getWithAuth(
        CONFIG.GET_CONVERSATIONS,
        (conversations) => participantHandler(conversations),
        (e) => console.log(e)
      );
    }
  };
  const getReportOptions = () => {
    setLoadingTo(true);
    get(
      CONFIG.GET_REPORT_OPTIONS,
      (options) => {
        setReportOpt(options);
        setLoadingTo(false);
      },
      (err) => {
        setLoadingTo(false);
      }
    );
  };
  const blockMessages = (id) => {
    const apiUrl = generatePath(CONFIG.BLOCK_MESSAGES, { chatRoomId: id });
    if (token) {
      axios({
        url: apiUrl,
        method: 'PATCH',
        headers: {
          Authorization: `JWT ${token}`
        },
        data: { blockBy: user.id }
      })
        .then((response) => {
          if (response.data) {
            setShowBlock(false);
            store.dispatch(
              showGlobalSnack('success', 'Messages blocked successfully', 3000)
            );
            refreshMessages();
          } else throw new Error(response.data.message);
        })
        .catch((e) => {
          setShowBlock(false);
          store.dispatch(
            showGlobalSnack(
              'error',
              'Something went wrong while blocking messages',
              3000
            )
          );
        });
    }
  };

    const unblockMessages = (id) => {
        const apiUrl = generatePath(CONFIG.UNBLOCK_MESSAGES, {
            chatRoomId: id
        });
        if (token) {
            axios({
                url: apiUrl,
                method: 'PATCH',
                headers: {
                    Authorization: `JWT ${token}`
                },
                data: { blockBy: user.id }
            })
                .then((response) => {
                    if (response.data) {
                        setShowUnblock(false);
                        store.dispatch(
                            showGlobalSnack(
                                'success',
                                'Messages unblocked successfully',
                                3000
                            )
                        );
                        refreshMessages();
                    } else throw new Error(response.data.message);
                })
                .catch((e) => {
                    setShowUnblock(false);
                    store.dispatch(
                        showGlobalSnack(
                            'error',
                            'Something went wrong while unblocking messages',
                            3000
                        )
                    );
                });
        }
    };

    const deleteConversation = (conversation_id) => {
        // getWithAuth(
        //     generatePath(CONFIG.DELETE_CONVERSATION, { id: conversation_id }),
        //     (conversation) => {
        //         setShow(false);
        //         refreshConversation();
        //         refreshParticipants();
        //         getConversation();
        //     },
        //     (e) => {
        //         console.error(e);
        //         setShow(false);
        //         store.dispatch(
        //             showGlobalSnack(
        //                 "error",
        //                 "Something went wrong while deleting conversation. Please reload and try again.",
        //                 3000
        //             )
        //         );
        //     }
        // );
        const apiUrl = generatePath(CONFIG.LEAVE_GROUP, {
            chatRoomId: conversation_id
        });
        if (token) {
            axios({
                url: apiUrl,
                method: 'PATCH',
                headers: {
                    Authorization: `JWT ${token}`
                },
                data: { participant: user.id }
            })
                .then((response) => {
                    if (response.data) {
                        setShow(false);
                        store.dispatch(
                            showGlobalSnack(
                                'success',
                                'Group Leave successfully',
                                3000
                            )
                        );
                        refreshMessages();

                        refreshallConversation();

                        history.push('/inbox');
                    } else throw new Error(response.data.message);
                })
                .catch((e) => {
                    console.log(e, 'error');
                    setShow(false);
                    store.dispatch(
                        showGlobalSnack(
                            'error',
                            'Something went wrong while leaving group',
                            3000
                        )
                    );
                });
        }
    };

  const closeDialog = () => {
    setShow(false);
    setShowReport(false);
    setShowBlock(false);
    setShowUnblock(false);
  };
  const cancelImg = () => {
    setImage(null);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = Cookie.get(CONFIG.AUTH_TOKEN);

        if (activeConversation?.room?.isBlocked) {
            if (activeConversation?.room?.blockBy == user?.id) {
                store.dispatch(
                    showGlobalSnack(
                        'error',
                        'Please unblock to send the message',
                        3000
                    )
                );
            } else {
                store.dispatch(
                    showGlobalSnack(
                        'error',
                        'You cannot send message to this user',
                        3000
                    )
                );
            }
        }
        if (token && !activeConversation?.room?.isBlocked) {
            if (imageFile) {
                if (imageFile?.size <= 3e6) {
                    setSending(true);
                    const data = new FormData();
                    data.append('picture', imageFile);
                    axios({
                        url: `${CONFIG.API_URL}/message/picture`,
                        method: 'POST',
                        data,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `JWT ${token}`
                        }
                    })
                        .then((response) => {
                            if (response?.data?.status === 'success') {
                                const data = {
                                    // from: sender?.id,
                                    // to: reciever?._id,
                                    // message: response?.data?.message,
                                    // type: "image",
                                    postedBy: sender?.id,
                                    room: room,
                                    message: response?.data?.message,
                                    messageType: 'image',
                                    seen:[user?.id]

                                };
                                setActiveConversation((prevState) => {
                                    return {
                                        // ...prevState,
                                        // messages: [...prevState?.allMessage?.messages, data],
                                        ...activeConversation,
                                        allMessage: {
                                            ...activeConversation.allMessage,
                                            messages: [
                                                ...activeConversation.allMessage
                                                    ?.messages,

                                                data
                                            ]
                                        }
                                    };
                                });
                                if (socket) {
                                    socket.emit('send-message', {
                                        ...data
                                    });
                                    setSending(false);
                                    setMessageTxt('');
                                    setImageFile(null);
                                    setImage(null);
                                     props.refreshConversation()
                                    // refreshallConversation();
                                }
                            } else {
                                throw new Error(response.data.message);
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            if (e.message === 'File too large') {
                                const message = `Attached image must be less than ${CONFIG.MESSAGE_ATTACHMENT_MB} MB`;
                                store.dispatch(
                                    showGlobalSnack('error', message, 3000)
                                );
                            } else {
                                store.dispatch(
                                    showGlobalSnack(
                                        'error',
                                        e.message.toString(),
                                        3000
                                    )
                                );
                            }
                            setSending(false);
                            setImageFile(null);
                            setImage(null);
                        });
                } else {
                    store.dispatch(
                        showGlobalSnack(
                            'error',
                            'Attached Image must be less than 3MB',
                            3000
                        )
                    );
                    setSending(false);
                    setImageFile(null);
                    setImage(null);
                    
                }
            }
            if (messageTxt) {
                // if (!activeConversation?.allMessage) {
                //     postData(
                //         CONFIG.CREATE_ROOM,
                //         (res) => {
                //             console.log(res, "single convo", {
                //                 sender: user.id,
                //                 receiver: room,
                //             });
                //         },
                //         (e) => console.log(e),
                //         { sender: room, receiver: user.id }
                //     );
                // }
                const data = {
                    postedBy: sender?.id,
                    room: room,
                    message: messageTxt,
                    messageType: 'text',
                    seen:[user?.id]
                };
                setActiveConversation((prevState) => {
                    return {
                        // ...prevState,
                        // messages: [...prevState?.allMessage?.messages, data],
                        ...activeConversation,
                        allMessage: {
                            ...activeConversation.allMessage,
                            messages: [
                                ...activeConversation.allMessage?.messages,
                                data
                            ]
                        }
                    };
                });
                if (socket) {
                    socket.emit('send-message', {
                        ...data
                    });
                    setSending(false);
                    setMessageTxt('');
                    setImageFile(null);
                    setImage(null);
                    props.refreshConversation()
                    // refreshallConversation();
                }
            }
        }
    };

    const makeConversationSeen = (id) => {
        postAuth(
            generatePath(CONFIG.MAKE_CONVERSATION_SEEN, { id }),
            () => {},
            (e) => {
                console.error(e);
            }
        );
    };

    const closeLightbox = () => {
        setIsOpen(true);
    };
    const showDeleteDrop = () => {
        setShow(true);
    };
    const showReportDrop = () => {
        setShowReport(true);
    };
    const showBlockDrop = () => {
        setShowBlock(true);
    };
    const showUnblockDrop = () => {
        setShowUnblock(true);
    };
    const dropDownActions = [
        {
            id: `delete-conversation-${activeConversation?._id}`,
            name: 'Delete',
            onClick: showDeleteDrop
        },

        {
            id: `block-user-${activeConversation?._id}`,
            name: 'Unblock',
            onClick: showUnblockDrop
        }
    ];
    const dropDownActions1 = [
        {
            id: `delete-conversation-${activeConversation?._id}`,
            name: 'Leave',
            onClick: showDeleteDrop
        }

        // {
        //     id: `block-user-${activeConversation?.room?._id}`,
        //     name: "Block",
        //     onClick: showBlockDrop,
        // },
    ];
    const dropDownActions2 = [
        {
            id: `block-user-${activeConversation?.room?._id}`,
            name: 'Block',
            onClick: showBlockDrop
        }
    ];
    const dropDownActions3 = [
        {
            id: `block-user-${activeConversation?.room?._id}`,
            name: 'Unblock',
            onClick: showUnblockDrop
        }
    ];

  const handleChange = (e) => {
    setReportValue(e.target.value);
  };

    let messages = [];
    // if (!_.isEmpty(activeConversation)) {
    //     let recieverImagePath = User;

    //     let isUnseenRendered = false;
    //     let newMsg = false;
    //     let printed = false

    //     for (
    //         let index = 0;
    //         index < activeConversation?.allMessage?.messages?.length;
    //         index++
    //     ) {
    //         let message = activeConversation?.allMessage?.messages[index];

    //         let messageClass;
    //         if (message.postedBy?._id)
    //             messageClass =
    //                 message.postedBy?._id === sender?.id ? 'sent' : 'received';
    //         else
    //             messageClass =
    //                 message.postedBy === sender?.id ? 'sent' : 'received';
    //         const messageCheck = message.messageType;

    //         const isUnseen = !!user?.id && !message.seen?.includes(user.id);

    //         messages.push(
    //             messageCheck === 'text' || messageCheck === 'image' ? (
    //                 <div key={index}>
    //                     {!isUnseenRendered && isUnseen && (
    //                         <div className={`message-user-status`}>
    //                             <span>UNREAD MESSAGES</span>
    //                         </div>
    //                     )}

    //                     {messageClass === 'received' &&
    //                         activeConversation?.room?.type == 'group' && (
    //                             <div
    //                                 style={{
    //                                     marginLeft: 60,
    //                                     fontSize: 10,
    //                                     color: '#9a9a9a'
    //                                 }}
    //                             >
    //                                 {message.postedBy?.name}
    //                             </div>
    //                         )}
    //                     <div
    //                         className={`message-contents ${messageClass}`}
    //                         key={index}
    //                     >
    //                         {messageClass === 'received' && (
    //                             <>
    //                                 {isUserArtist(message.postedBy?.role) &&
    //                                 message.postedBy?.slug ? (
    //                                     <Link
    //                                         to={generatePath(sitemap.artist, {
    //                                             slug: message.postedBy?.slug
    //                                         })}
    //                                         title={`Visit Profile`}
    //                                     >
    //                                         <Image
    //                                             src={
    //                                                 message.postedBy.picture &&
    //                                                 message.postedBy.picture !=
    //                                                     'undefined'
    //                                                     ? `users/${message.postedBy.picture}`
    //                                                     : 'assets/profile-dummy.png'
    //                                             }
    //                                             className="receiver-img"
    //                                         />
    //                                     </Link>
    //                                 ) : (
    //                                     <>
    //                                         {message.postedBy?.slug && (
    //                                             <Link
    //                                                 to={generatePath(
    //                                                     sitemap.normalUser,
    //                                                     {
    //                                                         slug:
    //                                                             message.postedBy
    //                                                                 ?.slug
    //                                                     }
    //                                                 )}
    //                                                 title={`Visit Profile`}
    //                                             >
    //                                                 <Image
    //                                                     src={
    //                                                         message.postedBy
    //                                                             .picture &&
    //                                                         message.postedBy
    //                                                             .picture !=
    //                                                             'undefined'
    //                                                             ? `users/${message.postedBy.picture}`
    //                                                             : 'assets/profile-dummy.png'
    //                                                     }
    //                                                     className="receiver-img"
    //                                                 />
    //                                             </Link>
    //                                         )}
    //                                     </>
    //                                 )}
    //                             </>
    //                         )}

    //                         <div
    //                             className={`msg ${messageClass} ${messageCheck}`}
    //                             // style={{ width: "100%" }}
    //                         >
    //                             {messageCheck === 'text' ? (
    //                                 <p>{message?.message}</p>
    //                             ) : (
    //                                 <>
    //                                     <div className="lazy-placeholder-message">
    //                                         <LazyLoad>
    //                                             {isOpen && (
    //                                                 <Lightbox
    //                                                     large={message.message}
    //                                                     small={message.message}
    //                                                     alt=""
    //                                                     onClose={closeLightbox}
    //                                                     className="img-msg"
    //                                                     imageBackgroundColor="white"
    //                                                     showRotate={true}
    //                                                 />
    //                                             )}
    //                                         </LazyLoad>
    //                                     </div>
    //                                 </>
    //                             )}
    //                         </div>
    //                     </div>
    //                 </div>
    //             ) : messageCheck === 'left' || messageCheck === 'join' ? (
    //                 <div className={`message-user-status`} key={index}>
    //                     <span>{message?.message}</span>
    //                 </div>
    //             ) : (
    //                 <></>
    //             )
    //         );

    //         if (isUnseen) {
    //             isUnseenRendered = true;
    //         }
    //     }
    // }
    if (!_.isEmpty(activeConversation)) {
        let recieverImagePath = User;
        let newMsg = false;
        let printed = false;
    
        messages = activeConversation?.allMessage?.messages?.map(
          (message, index) => {
            let found;
    
            if (!newMsg) {
              found = message?.seen?.find(
                (u) => u.toString() == user?.id?.toString()
              );
              if (!found) {
                newMsg = true;
                //   printed = true;
              }
            }
            let messageClass;
            if (message.postedBy?._id)
              messageClass =
                message.postedBy._id === sender?.id ? "sent" : "received";
            else
              messageClass = message.postedBy === sender?.id ? "sent" : "received";
    
            let messageType = message.messageType;
            const messageCheck =
              messageType === "text"
                ? "text"
                : messageType == "image"
                ? "image"
                : messageType == "left"
                ? "left"
                : "join";

            return (
              <>
                 {!found && !printed && <p className="new-message">New Message</p>}
                {!found && !printed ? (printed = true) : ""}
                {messageCheck != "left" && messageCheck != "join" && messageClass == "received" &&
                  activeConversation?.room?.type == "group" && (
     
                    <div
                      style={{
                        marginLeft: 60,
                        fontSize: 10,
                        color: "#9a9a9a",
                      }}
                    >
                      {message?.postedBy?.name}
                    </div>)
          }
               
    
                {messageCheck != "left" && messageCheck != "join" ? (
                  
                  <div className={`message-contents ${messageClass}`} key={index}>
                    {messageClass === "received" && (
                      <>
                        {isUserArtist(message.postedBy?.role) &&
                        message.postedBy?.slug ? (
                          <Link
                            to={generatePath(sitemap.artist, {
                              slug: message.postedBy?.slug,
                            })}
                            title={`Visit Profile`}
                          >
                            <Image
                              src={
                                message.postedBy.picture &&
                                message.postedBy.picture != "undefined"
                                  ? `users/${message.postedBy.picture}`
                                  : "assets/profile-dummy.png"
                              }
                              className="receiver-img"
                            />
                          </Link>
                        ) : (
                          <>
                            {message.postedBy?.slug && (
                              <Link
                                to={generatePath(sitemap.normalUser, {
                                  slug: message.postedBy?.slug,
                                })}
                                title={`Visit Profile`}
                              >
                                <Image
                                  src={
                                    message.postedBy.picture &&
                                    message.postedBy.picture != "undefined"
                                      ? `users/${message.postedBy.picture}`
                                      : "assets/profile-dummy.png"
                                  }
                                  className="receiver-img"
                                />
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
    
                    <div
                      className={`msg ${messageClass} ${messageCheck}`}
                   
                    >
                      {messageCheck === "text" ? (
                        <p style={{overflowWrap:"break-word",maxWidth:300}}>{message?.message}</p>
                      ) : (
                        <>
                          {/* {loading ? (
                      <img src={defaultImg} />
                    ) : (
                      <> */}
                          <div className="lazy-placeholder-message">
                            <LazyLoad>
                              {isOpen && (
                                <Lightbox
                                  large={message.message}
                                  small={message.message}
                                  alt=""
                                  onClose={closeLightbox}
                                  className="img-msg"
                                  imageBackgroundColor="white"
                                  showRotate={true}
                                />
                              )}
                            </LazyLoad>
                          </div>
                          {/* </>
                    )} */}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <p className={`message-${messageCheck}`}>{message.message}</p>
                )}
              </>
            );
          }
        );
      }

    return (
        <div>
            <div id="message-box">
                <Dialog
                    open={show}
                    onClose={closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={classes}
                >
                    <div id="responsive-dialog-title">Leave Conversation</div>
                    <div
                        id="responsive-dialog-actions"
                        style={{ marginTop: '14px' }}
                    >
                        <span
                            role="button"
                            tabIndex="-1"
                            onClick={() =>
                                deleteConversation(
                                    activeConversation?.room?._id
                                )
                            }
                            mode="special"
                        >
                            Confirm
                        </span>
                        <Button
                            size="medium"
                            onClick={closeDialog}
                            mode="specialBlack"
                        >
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <Dialog
                    open={showReport}
                    onClose={closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={classes}
                >
                    <div id="responsive-dialog-title">Report Conversation</div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="report"
                            name="report"
                            disabled="true"
                            value={reportValue}
                            onChange={handleChange}
                        >
                            {reportOpt &&
                                reportOpt.map((opt) => (
                                    <FormControlLabel
                                        value={opt?._id}
                                        control={<Radio />}
                                        label={<div>{opt?.name}</div>}
                                    />
                                ))}
                        </RadioGroup>
                    </FormControl>
                    <div className="report-btns">
                        <Button mode="reportSubmit">Submit</Button>
                        <Button
                            mode="special"
                            onClick={() => {
                                setShowReport(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <Dialog
                    open={showBlock}
                    onClose={closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={classes}
                >
                    <div id="responsive-dialog-title">Block Messages</div>
                    <div
                        id="responsive-dialog-actions"
                        style={{ marginTop: '14px' }}
                    >
                        <span
                            role="button"
                            tabIndex="-1"
                            onClick={() =>
                                blockMessages(activeConversation?.room?._id)
                            }
                            mode="special"
                        >
                            Confirm
                        </span>
                        <Button
                            size="medium"
                            onClick={closeDialog}
                            mode="specialBlack"
                        >
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <Dialog
                    open={showUnblock}
                    onClose={closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={classes}
                >
                    <div id="responsive-dialog-title">Unblock Messages</div>
                    <div
                        id="responsive-dialog-actions"
                        style={{ marginTop: '14px' }}
                    >
                        <span
                            role="button"
                            tabIndex="-1"
                            onClick={() =>
                                unblockMessages(activeConversation?.room?._id)
                            }
                            mode="special"
                        >
                            Confirm
                        </span>
                        <Button
                            size="medium"
                            onClick={closeDialog}
                            mode="specialBlack"
                        >
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <div className="inbox-open">
                    <div className="title">
                        <div className="backIcon">
                            <Link to="/inbox">
                                <ArrowLeftIcon />
                            </Link>
                        </div>
                        
                        {activeConversation?.room?.type == 'normal' ? (
                            isUserArtist(senderData?.role) &&
                            senderData?.slug ? (
                                <Link
                                    to={generatePath(sitemap.artist, {
                                        slug: senderData?.slug
                                    })}
                                    title={`Visit ${senderData?.name}'s Profile`}
                                >
                                    <h5>{senderData?.name}</h5>
                                </Link>
                            ) : (
                                <>
                                    {senderData?.slug && (
                                        <Link
                                            to={generatePath(
                                                sitemap.normalUser,
                                                {
                                                    slug: senderData?.slug
                                                }
                                            )}
                                            title={`Visit ${senderData?.name}'s Profile`}
                                        >
                                            <h5>{senderData?.name}</h5>
                                        </Link>
                                    )}
                                </>
                            )
                        ) : (
                            <h5>{activeConversation?.room?.name}</h5>
                        )}

                        <div className="deleteIcon">
                            <>
                                {activeConversation?.room?.type == 'normal' ? (
                                    activeConversation?.room?.isBlocked ? (
                                        activeConversation?.room?.blockBy ==
                                        user?.id ? (
                                            <DropdownMenu
                                                actions={dropDownActions3}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <DropdownMenu
                                            actions={dropDownActions2}
                                        />
                                    )
                                ) : (
                                    <DropdownMenu actions={dropDownActions1} />
                                )}
                            </>
                        </div>
                    </div>

                    {/* {currentPage !=
                        activeConversation?.allMessage?.total_page && (
                        <div
                            className="load-more"
                            onClick={getOldMessage}
                            // style={{ textAlign: "center", marginTop: 20 }}
                        >
                            Load More
                        </div>
                    )} */}
                    <div className="content">
                             {currentPage !=
                        activeConversation?.allMessage?.total_page && (
                        <div
                            className="load-more"
                            onClick={getOldMessage}
                            // style={{ textAlign: "center", marginTop: 60 }}
                        >
                            Load More
                        </div>
                    )}
                        <div className="messageThread">
                            <div className="messages">
                                <div
                                    className="messages"
                                    // style={{overflowY: 'scroll'}}
                                    // scrollViewClassName="scroll-view"
                                >
                                    {messages}
                                </div>
                            </div>
                        </div>
                        {image && (
                            <div id="post-picture">
                                <img
                                    src={image}
                                    alt=""
                                    style={{
                                        height: '70px',
                                        marginLeft: '16px'
                                    }}
                                />
                                <CancelIcon
                                    className="cancel-icon"
                                    onClick={cancelImg}
                                />
                            </div>
                        )}
                        <div className="newmessages">
                            <form onSubmit={handleSubmit}>
                                {!image && (
                                    <label
                                        className="add-image"
                                        htmlFor="post-picture"
                                    >
                                        <input
                                            type="file"
                                            id="post-picture"
                                            className="custom-input-file"
                                            multiple={false}
                                            onChange={onImageChange}
                                            accept="image/*"
                                        />
                                        <span>
                                            {' '}
                                            <ImageIcon className="img-btn" />
                                        </span>
                                    </label>
                                )}

                                <input
                                    type="text"
                                    disabled={sending}
                                    placeholder="Write a message"
                                    value={messageTxt}
                                    onChange={(e) =>
                                        setMessageTxt(e.target.value)
                                    }
                                />
                                <button
                                    disabled={sending}
                                    mode="primary"
                                    className="send-btn"
                                    type="submit"
                                >
                                    {sending ? (
                                        <ClipLoader
                                            color="black"
                                            loading={sending}
                                            css={override}
                                            size={60}
                                        />
                                    ) : (
                                        <SendIcon />
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={chatContainer} />
        </div>
    );
};

const SocketedConversation = (props) => (
  <SocketContext.Consumer>
    {(socket) => <MessageBox {...props} socket={socket} />}
  </SocketContext.Consumer>
);

MessageBox.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    reciever: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    activeConversation: PropTypes.any,
    handler: PropTypes.func.isRequired,
    // participantHandler: PropTypes.func.isRequired,
    socket: PropTypes.objectOf(PropTypes.any).isRequired,
    refreshallConversation: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    refreshMessages: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(
  withStyles(dialogClasses)(SocketedConversation)
);
