import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { generatePath } from "react-router";

import {
  showDialog,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import { getWithAuth, postAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import Loading from "../../../Loading";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import Image from "../../../ImageTag/Image";

class Following extends Component {
  state = {
    loading: true,
    deleting: false,
  };

  handleDelete = (id, e) => {
    const { refresh } = this.props;
    e.preventDefault();
    store.dispatch(
      showDialog(
        "Unfollow User",
        "Are you sure you want to unfollow this user?",
        () => {
          this.setState({ deleting: true });
          postAuth(
            generatePath(CONFIG.UNFOLLOW_ARTIST),
            () => {
              store.dispatch(
                showGlobalSnack("Normal", " Unfollowed successfully", 3000)
              );
              store.dispatch(hideDialog());
              refresh();
            },
            () => {
              console.log("Error unfollowing.");
              store.dispatch(hideDialog());
            },
            { artist: id }
          );
        }
      )
    );
  };

  render() {
    const { loading } = this.state;
    const { following } = this.props;
    const dummyImage = "assets/profile-dummy.png";

    return (
      <section className="followersSection">
        {following.length > 0 ? (
          <>
            <table>
              {following.map((f) => {
                return (
                  <tbody key={f?.id}>
                    <tr>
                      <td>
                        <Image
                          className="pp-img"
                          src={
                            f?.picture && f?.picture !== "undefined"
                              ? `users/${f?.picture}`
                              : dummyImage
                          }
                        />
                        {f?.name}
                      </td>
                      <td>
                        <IconButton>
                          <DeleteIcon
                            className="dlt-icon"
                            onClick={(e) => this.handleDelete(f?.id, e)}
                          />
                        </IconButton>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            No Followings
          </div>
        )}
      </section>
    );
  }
}

Following.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  following: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Following;
