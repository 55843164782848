import { SHOW_GLOBAL_SNACK, HIDE_GLOBAL_SNACK } from '../constants/action-types';

const initState = {
  type: 'normal',
  message: '',
  duration: 3000
};

export default (state = initState, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_SNACK:
      return {
        ...action.payload
      };
    case HIDE_GLOBAL_SNACK:
      return {
        type: 'normal',
        message: '',
        duration: 3000
      };
    default:
      return state;
  }
};
