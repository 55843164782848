import { find } from "lodash";
import dummyArt from "../assets/img/dummy.png";

import { CONFIG } from "../constants";

export const getThumbnail = (imageObj, sizename) => {
  if (imageObj) {
    const thumbnail = find(
      imageObj?.sizes,
      (size) => size?.sizeName === sizename
    );

    return {
      ...thumbnail,
      url: `${imageObj?.set?.slug}/${thumbnail?.fileName}`,
    };
  } else {
    return {
      url: { dummyArt },
    };
  }
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }

  // separate out the mime component
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

// from http://stackoverflow.com/a/32490603
// export const getOrientation = (file, callback) => {
//   const reader = new FileReader();

//   reader.onload = (event) => {
//     const view = new DataView(event.target.result);

//     if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);

//     const length = view.byteLength;
//     let offset = 2;

//     while (offset < length) {
//       const marker = view.getUint16(offset, false);
//       offset += 2;

//       if (marker === 0xFFE1) {
//         if (view.getUint32(offset += 2, false) !== 0x45786966) { // eslint-disable-line
//           return callback(-1);
//         }
//         const little = view.getUint16(offset += 6, false) === 0x4949;
//         offset += view.getUint32(offset + 4, little);
//         const tags = view.getUint16(offset, little);
//         offset += 2;

//         for (let i = 0; i < tags; i++) {
//           if (view.getUint16(offset + (i * 12), little) === 0x0112) {
//             return callback(view.getUint16(offset + (i * 12) + 8, little));
//           }

//           if ((marker & 0xFF00) !== 0xFF00) { // eslint-disable-line
//             break;
//           } else offset += view.getUint16(offset, false);
//         }
//       }
//     }
//     return callback(-1);
//   };

//   reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
// };

// from http://stackoverflow.com/a/32490603
export const getOrientation = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    const view = new DataView(event.target.result);

    if (view.getUint16(0, false) !== 0xffd8) return callback(-2);

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      const marker = view.getUint16(offset, false);
      offset += 2;

      if (marker === 0xffe1) {
        if (view.getUint32((offset += 2), false) !== 0x45786966) {
          // eslint-disable-line
          return callback(-1);
        }
        const little = view.getUint16((offset += 6), false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;

        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
      } else if ((marker & 0xff00) != 0xff00) break;
      // eslint-disable-line
      else offset += view.getUint16(offset, false);
    }
    return callback(-1);
  };

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
};
