import React, { Component } from 'react';
import { number } from 'prop-types';
import withSizes from 'react-sizes';

import { ThreeDotsRed } from '../../ThreeDots';
import { CONFIG } from '../../../constants';
import { get } from '../../../services/generalApiServices';
import ReactAnimeSlider from '../../ReactAnimeSlider';
import MobileBanner from './MobileBanner';
import './Home.scss';

const mapSizesToProps = (sizes => (
  {
    winWidth: sizes.width
  }
));

class Banner extends Component {
  state = {
    slides: [],
    slidesLoaded: false
  }

  sliderRef = React.createRef();

  bannerRatio = (1920 / 600);

  componentDidMount() {
    get(
      `${CONFIG.API_URL}/slider/home-slider`,
      (response) => {
        this.setState({ slides: response.slides, slidesLoaded: true });
      },
      (error) => {
        this.setState({ slidesLoaded: true });
        console.log(error);
      }
    );
  }

  render() {
    const { slidesLoaded, slides } = this.state;
    const { winWidth } = this.props;

    const slideList = slidesLoaded && slides.map((slide, index) => (
      <div
        key={`home-slide-${index}`}
        className="slide"
        style={{ background: `url("${slide.imageMain}") 0px center` }}
      />
    ));
    return (
      <section className="bannerSection">
        {
          slidesLoaded
            ? (
              <div className="customContainer fullWidthLayout noPaddingTop">
                <div className="bannerSlider">
                  {
                    winWidth > 999
                      ? (
                        <ReactAnimeSlider
                          autoplay
                          nav
                          arrows={false}
                          interval={3000}
                          ratio={this.bannerRatio}
                          slideList={slides}
                        >
                          {slideList}
                        </ReactAnimeSlider>
                      )
                      : (
                        <MobileBanner
                          slides={slides}
                          ratio={426 / 230}
                          winWidth={winWidth}
                        />
                      )
                  }
                </div>
              </div>
            )
            : (
              <div className="bannerLoading">
                <ThreeDotsRed variant="flashing"/>
              </div>
            )
        }
      </section>
    );
  }
}

Banner.propTypes = {
  winWidth: number.isRequired
};

export default withSizes(mapSizesToProps)(Banner);
