import React, { Component } from "react";
import { func, arrayOf, shape, string } from "prop-types";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";

import "./DropdownMenu.scss";

class DropdownMenu extends Component {
  state = {
    isActive: false,
  };

  dropdownRef = React.createRef();

  handleClickOutside = (e) => {
    if (
      this.dropdownRef.current &&
      this.dropdownRef.current.contains(e.target)
    ) {
      // do nothing
    } else {
      window.removeEventListener("click", this.handleClickOutside);
      this.setState({ isActive: false });
    }
  };

  toggleDropdown = () => {
    const { isActive } = this.state;
    if (isActive) {
      this.setState({ isActive: false });
    } else {
      this.setState({ isActive: true });
      window.addEventListener("click", this.handleClickOutside);
    }
  };

  render() {
    const { actions } = this.props;
    const { isActive } = this.state;
    const dropdownClasses = classnames("dropdown", {
      active: isActive,
    });
    return (
      <div className={dropdownClasses} ref={this.dropdownRef}>
        <IconButton onClick={this.toggleDropdown}>
          <MoreVertSharpIcon />
        </IconButton>
        <div className="dropdown-menu">
          <ul>
            {actions.map((action) => (
              <li
                key={action.id}
                role="button"
                tabIndex="-1"
                onClick={action.onClick}
              >
                {action.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  actions: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      onClick: func,
    })
  ).isRequired,
};

export default DropdownMenu;
