import React, { useEffect } from "react";
import StaticContentCard from "../StaticContentCard/StaticContentCard";
import "./static.scss";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="static-page privacy-policy">
      <StaticContentCard title="Privacy Policy">
        <h3>Friday, August 13th, 2021 </h3>
        <p>
          This Privacy Policy governs the manner in which Tulikaa (A product of
          Tulikaa Kala Pvt. Ltd) collects, uses, maintains and discloses
          information collected from users (each, a “User”) of the tulikaa.com
          website (“Site”). This privacy policy applies to the Site and all
          products and services offered by Tulikaa.
        </p>{" "}
        <h3>Personal identification information</h3>
        <p>
          We may collect personal identification information from Users in a
          variety of ways in connection with activities, services, features or
          resources we make available on our Site. Users may visit our Site
          anonymously. We will collect personal identification information from
          Users only if they voluntarily submit such information to us. Users
          can always refuse to supply personal identification information,
          except that it may prevent them from engaging in certain Site related
          activities and features.
        </p>{" "}
        <h3> Non-personal identification information</h3>
        <p>
          {" "}
          We may collect non-personal identification information about Users
          whenever they interact with our Site. Non-personal identification
          information may include the browser name, the type of computer and
          technical information about Users' means of connection to our Site
          such as the operating system and the Internet service providers
          utilized and other similar information. Site also will collect
          information about the user usage and interaction with artworks,
          artists, artwork and artist categories and other website data for
          better user experience.{" "}
        </p>
        <h3>Web browser cookies</h3>{" "}
        <p>
          {" "}
          Our Site may use “cookies” to enhance User experience. User’s web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. Users may choose to set
          their web browser to refuse cookies, or to alert you when cookies are
          being sent. If they do so, note that some parts of the Site may not
          function properly.{" "}
        </p>
        <h3>How we protect your information</h3>
        <p>
          {" "}
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information and data stored on our
          Site.
        </p>{" "}
        <h3> Sharing your personal information </h3>
        <p>
          We do not sell, trade, or rent Users personal identification
          information to others. We may share generic aggregated demographic
          information not linked to any personal identification information
          regarding visitors and users with our business partners, trusted
          affiliates and advertisers for the purposes outlined above.
        </p>{" "}
        <h3> Changes to this privacy policy </h3>
        <p>
          Tulikaa Kala discretion to update this privacy policy at any time. Due
          to government rules and regulations changing time and again privacy
          policy might change accordingly. When we do, we will revise the
          updated date at the bottom of this page. We encourage Users to
          frequently check this page for any changes to stay informed about how
          we are helping to protect the personal information we collect. You
          acknowledge and agree that it is your responsibility to review this
          privacy policy periodically and become aware of modifications.
        </p>{" "}
        <h3>Your acceptance of these terms</h3>
        <p>
          {" "}
          By using this Site, you signify your acceptance of this policy. If you
          do not agree to this policy, please do not use our Site. Your
          continued use of the Site following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </p>
      </StaticContentCard>
    </div>
  );
};

export default Privacy;
