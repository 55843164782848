import React, { useState, useEffect } from "react";
import { Route, Redirect, NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import Cookie from "js-cookie";

import sitemap from "../../../../routing/siteMap";
import { isUserArtist } from "../../../../utils/userUtils";
import { CONFIG } from "../../../../constants";
import PageHead from "../../../PageHead";
import { getUserData, getYourInfo } from "../../../../services/userServices";
import "./Profile.scss";
import ProfileSidebar from "./ProfileSidebar";
import Loading from "../../../Loading";
import YourWork from "./YourWork";
import YourFeed from "./YourFeed";

const Profile = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { match, history, user } = props;
  const token = Cookie.get(CONFIG.AUTH_TOKEN);

  useEffect(() => {
    if (token) {
      getUserInfo();
    }
  }, [match?.params?.slug]);

  const getUserInfo = () => {
    getYourInfo(
      (response) => {
        setUserInfo(response);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  return (
    <div id="profilePage">
      {loading ? (
        <Loading color="red" />
      ) : (
        <div className="commonGidContainer typeHeaderUp">
          <PageHead imgPath={userInfo?.cover && `users/${userInfo?.cover}`} />
          <ProfileSidebar userInfo={userInfo} history={history} />
          <div className="gridItem mainContent">
            <div className="profile-body">
              {!isUserArtist(userInfo?.role) && user?.id === userInfo?._id && (
                <div className="customContainer2 smallWidthLayout2">
                  <div className="sectionContent">
                    <Link
                      to={generatePath(sitemap.getStudio, {
                        slug: user?.slug,
                      })}
                    >
                      <div className="getFeedTitle">
                        <h3>Get Into Studio</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              <div>
                <div className="user-bar">
                  <div className="sectionContent">
                    <ul>
                      <li>
                        <NavLink
                          to={generatePath(sitemap.yourWork, {
                            slug: match.params?.slug,
                          })}
                        >
                          Artworks
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={generatePath(sitemap.yourFeed, {
                            slug: match.params?.slug,
                          })}
                        >
                          Feed
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <Route
                  exact
                  path={sitemap.yourWork}
                  render={(props) => (
                    <YourWork {...props} userInfo={userInfo} />
                  )}
                />
                <Route
                  exact
                  path={sitemap.yourFeed}
                  render={(props) => (
                    <YourFeed {...props} userInfo={userInfo} />
                  )}
                />
                <Redirect
                  to={generatePath(sitemap.yourWork, {
                    slug: match.params.slug,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  readOnly: PropTypes.bool,
};
Profile.defaultProps = {
  readOnly: true,
};

export default Profile;
