import React from 'react';
import PropTypes from 'prop-types';

import CommonButton from '../Button';
import './UserPageHeader.scss';

const UserPageHeader = ({ title, subTitle, cancel, publish, loading }) => (
  <div id="page-top-bar">
    <div className="customContainer">
      <div className="inner">
        <div className="title">
          <h3>{title}</h3>
          <span>{subTitle}</span>
        </div>
        <div className="actions">
          <span id="cancel" role="button" tabIndex="-1" onClick={cancel}>Cancel</span>
          <CommonButton loading={loading} onClick={publish}>Publish</CommonButton>
        </div>
      </div>
    </div>
  </div>
);

UserPageHeader.propTypes = {
  cancel: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  subTitle: PropTypes.string
};

UserPageHeader.defaultProps = {
  loading: false,
  subTitle: ''
};

export default UserPageHeader;
