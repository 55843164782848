import React, { Component } from 'react';
import axios from 'axios';

import { CONFIG } from '../../../constants';
import store from '../../../redux';
import { showGlobalSnack } from '../../../redux/actions/snack.actions';
import Button from '../../Button';
import './Login.scss';

class ResetPassword extends Component {
  state = {
    email: '',
    loading: false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    if (email === '') return;

    this.setState({ loading: true });
    axios.post(CONFIG.RESET, {
      email
    })
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            loading: false
          });
          store.dispatch(showGlobalSnack('normal', 'Please check your email', 3000));
        } else if (response.data.status === 'not-found') {
          this.setState({
            loading: false
          });
          store.dispatch(showGlobalSnack('error', 'Email not found', 3000));
        } else {
          this.setState({ loading: false });
          store.dispatch(showGlobalSnack('error', 'Unexpected error', 3000));
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
          email: ''
        });
      });
  }

  render() {
    const { email, loading } = this.state;

    return (
      <div id="login-page">
        <section className="contentSection">
          <div className="customContainer headerLayout ">
            <div className="sectionContent addFullHeight">
              <div className="customTabContent">
                <div className="title">
                  <h2>Reset Password</h2>
                  <p>Enter your email and we‘ll send a reset code if the email is associated with a user account.</p>
                </div>

                <div className="content">
                  <div className="customForm">
                    <form onSubmit={this.handleSubmit}>
                      <div className="fields">
                        <div className="formGroup">
                          <input
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            value={email}
                            onChange={e => this.setState({ email: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="formAction">
                        <Button loading={loading} type="submit" size="full" mode="specialBlack">Submit</Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    );
  }
}


export default ResetPassword;
