import { SHOW_DIALOG, HIDE_DIALOG } from '../constants/action-types';

const initState = {
  title: '',
  message: '',
  confirm: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...action.payload
      };
    case HIDE_DIALOG:
      return {
        confirm: null
      };
    default:
      return state;
  }
};
