import React from 'react';

const Success = () => {
  return (
    <div>
      SUCCESS
    </div>
  );
};

export default Success;
