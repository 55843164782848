import React, { Component } from "react";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import Breakpoint from "react-socks";
import BottomScrollListener from "react-bottom-scroll-listener";

import ArtShowcase, { ArtShowcaseItem } from "../../ArtShowcase";
import { getThumbnail } from "../../../utils/mediaUtils";
import { nl2br } from "../../../utils/stringUtils";
import Loading from "../../Loading";
import { get, post } from "../../../services/generalApiServices";
import ArtBanner from "./ArtBanner";
import { CONFIG } from "../../../constants";
import ArtWorkBreakdwon from "./ArtWorkBreakdown";
import ArtistShowcase from "../../ArtistShowcase";
import CommentOnArt from "../../Comment/CommentOnArt";
import "./ArtWork.scss";
import LazyLoad from "react-lazy-load";
import { getSimilarProducts } from "../../../services/productServices";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

class ArtWork extends Component {
  constructor() {
    super();
    this.state = {
      art: {},
      similarArts: [],
      artist: null,
      isLoading: true,
      readMore: false,
      loadingMore: false,
    };
  }
  skip = 0;
  limit = 15;
  total = 999999;
  limit = CONFIG.GALLERY_PAGE_LIMIT;

  componentDidMount() {
    this.loadArt();
    if (this?.state?.art) {
      this.getSimilarArts();
    }
    this.UpdateViewCount();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match?.params?.slug !== match?.params?.slug) {
      this.setState(
        {
          // eslint-disable-line
          art: {},
          similarArts: [],
          artist: null,
          isLoading: true,
          readMore: false,
        },
        () => {
          this.loadArt();
          if (this.state?.art) {
            this.getSimilarArts();
          }
          this.UpdateViewCount();
        }
      );
    }
  }

  getSimilarArts = () => {
    const { similarArts } = this.state;
    const { match } = this.props;
    const slug = match?.params?.slug;
    getSimilarProducts(
      slug,
      {
        skip: this.skip,
        limit: this.limit,
      },
      (response) => {
        const newProducts = response.products;
        this.total = response.total;
        this.setState({
          similarArts: [...similarArts, ...newProducts],
          loadingMore: false,
        });
      },
      (error) => {
        console.error(error);
        this.setState({
          loadingMore: false,
        });
      }
    );
  };

  loadMoreSimilar = () => {
    this.skip += this.limit;
    this.setState({ loadingMore: true });
    this.getSimilarArts();
  };

  loadArt() {
    const { match } = this.props;
    post(
      generatePath(CONFIG.GET_ARTWORK_BY_SLUG, { slug: match?.params?.slug }),
      (art) => {
        get(
          generatePath(CONFIG.GET_ARTIST_BY_SLUG, { slug: art?.author?.slug }),
          (artist) => {
            this.setState({
              artist,
              art,
              isLoading: false,
            });
          }
        );
      },
      (error) => {
        console.log(error);
        // this.setState({ isLoading: false });
      }
    );
  }

  toggleReadMore = () => {
    this.setState({ readMore: !this.state.readMore });
  };

  UpdateViewCount = () => {
    const { match } = this.props;
    post(
      generatePath(CONFIG.PRODUCT_VIEW, { slug: match?.params?.slug }),
      () => {},
      (e) => {
        console.log("error updating view", e);
      }
    );
  };
  render() {
    const { art, similarArts, isLoading, artist, loadingMore } = this.state;
    const { winWidth } = this.props;
    const productsList = similarArts?.map((product) => {
      const thumbnail = getThumbnail(product?.image, "gallery");
      return (
        <ArtShowcaseItem
          key={`artist-work-${product?._id}`}
          artist={product?.author?.name}
          artistId={product?.author?.id}
          artName={product?.name}
          thumbnail={thumbnail}
          price={product?.priceField}
          slug={product?.slug}
          art={product}
        />
      );
    });

    let columnCount = 3;
    let gutterWidth = 14;

    if (winWidth < 991) {
      columnCount = 2;
      gutterWidth = 10;
    }

    return (
      <div id="artWorkPage">
        {isLoading ? (
          <Loading color="red" />
        ) : (
          <>
            {art ? (
              <>
                <div className="mobilePriceContainer">
                  <Breakpoint s>
                    <section className="mobilePriceSection">
                      <div className="priceContainer">
                        <h2>{art?.name}</h2>
                        <h2>{art?.priceField?.toUpperCase()}</h2>
                      </div>
                    </section>
                  </Breakpoint>
                </div>

                {art && (
                  <section className="artGallerySection">
                    <ArtBanner art={art} />
                  </section>
                )}

                <section className="detialSection">
                  <div className="customContainer mediumWidthLayout">
                    <div className="sectionContent">
                      <article className="arDetailArticle">
                        <div className="artDetailContainer">
                          <div className="artDetailItem">
                            <Breakpoint m>
                              <article
                                className="artNameArticle"
                                style={{
                                  paddingBottom: "20px",
                                  paddingTop: "0",
                                  paddingLeft: "0",
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <h2>{art.name}</h2>
                                <p style={{ marginTop: "5px" }}>
                                  {art?.priceField?.toUpperCase()}
                                  {/* {art?.price && art?.price !== 0
                                ? "NPR. " + art.price
                                : "NFS"} */}
                                </p>
                                {art.attributes &&
                                (art.attributes.height ||
                                  art.attributes.width) ? (
                                  <div className="dimension">
                                    <span>
                                      <b>H:</b>
                                      <span>
                                        {" "}
                                        {art?.attributes?.height
                                          ? art?.attributes?.height
                                          : ""}
                                        in
                                      </span>
                                    </span>
                                    <span>
                                      <b>W:</b>
                                      <span>
                                        {" "}
                                        {art?.attributes?.width
                                          ? art?.attributes?.width
                                          : ""}
                                        in
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </article>
                            </Breakpoint>
                            {art?.description && (
                              <div className="descriptionWrapper">
                                <h6>Description</h6>

                                {art?.description &&
                                  art?.description !== undefined && (
                                    <>
                                      <p>
                                        {!this.state.readMore ? (
                                          <>{art?.description?.slice(0, 150)}</>
                                        ) : (
                                          nl2br(art?.description)
                                        )}
                                        <label
                                          style={{ fontWeight: "bolder" }}
                                          onClick={this.toggleReadMore}
                                          htmlFor="expanded"
                                          role="button"
                                        >
                                          {art?.description?.length > 150 && (
                                            <>
                                              {!this.state.readMore
                                                ? " .... Read More"
                                                : "Show Less"}
                                            </>
                                          )}
                                        </label>
                                      </p>
                                    </>
                                  )}
                              </div>
                            )}

                            {art && (
                              <div className="commentWrapper">
                                <CommentOnArt item={art} />
                              </div>
                            )}
                          </div>
                          {art && (
                            <div className="artDetailItem">
                              <ArtWorkBreakdwon art={art} />
                            </div>
                          )}
                        </div>
                      </article>

                      {artist && (
                        <article className="artistInfoArticle">
                          <ArtistShowcase fullWidthGallery artist={artist} />
                        </article>
                      )}

                      <article className="similarArtArticle">
                        <div className="sectionTitle addTypeCenter coAddMargin coMarginSmall">
                          <h4>Similar Artworks</h4>
                        </div>
                        {isLoading ? (
                          <Loading color="red" />
                        ) : (
                          <ArtShowcase
                            columnCount={columnCount}
                            gutter={gutterWidth}
                          >
                            {productsList}
                          </ArtShowcase>
                        )}

                        {loadingMore ? (
                          <Loading color="red" />
                        ) : (
                          <>
                            {similarArts?.length < this.total && (
                              <BottomScrollListener
                                onBottom={this.loadMoreSimilar}
                                offset={300}
                              />
                            )}
                          </>
                        )}
                      </article>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <div style={{ textAlign: "center", paddingTop: "10%" }}>
                Sorry, the art is unavailable!
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

ArtWork.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
};

export default withSizes(mapSizesToProps)(ArtWork);
