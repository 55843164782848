import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import AddImageIcon from "@material-ui/icons/AddPhotoAlternate";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { isEqual } from "lodash";

import Image from "../ImageTag/Image";
import { getOrientation } from "../../utils/mediaUtils";
import { GLOBALS } from "../../constants";
import "./ImageUploader.scss";

const styles = {
  iconStyles: {
    color: "#984B48",
  },
  closeIconStyles: {
    color: "#fff",
  },
};

class ImageUploader extends Component {
  state = {
    imagesPreview: [],
    loading: true,
  };

  inputFileRef = React.createRef();

  scrollerRef = React.createRef();

  componentDidMount() {
    this.generatePreviewImages();
  }

  componentDidUpdate(prevProps) {
    const { images, coverImageIndex } = this.props;
    if (
      !isEqual(images, prevProps.images) ||
      coverImageIndex !== prevProps.coverImageIndex
    ) {
      this.generatePreviewImages();
    }
  }

  handleUploadClick = () => {
    if (this.inputFileRef) {
      this.inputFileRef.current.click();
    }
  };

  onImageChange = (event) => {
    event.persist();
    const { addImage } = this.props;
    if (event.target.files) {
      const { files } = event.target;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          addImage(event.target.files[i], e.target.result, uuid.v4());
          const scrollerDiv = document.querySelector(".scrollbar-container");
          if (scrollerDiv && this.scrollerRef.current) {
            scrollerDiv.scrollLeft = 999999;
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  generatePreviewPromise = (image, i) => {
    const {
      classes,
      coverImageIndex,
      setCoverImage,
      removeImage,
      coverImage,
    } = this.props;

    return new Promise((resolve) => {
      if (image.file) {
        getOrientation(image.file, (orientation) => {
          const transformImage =
            orientation && GLOBALS.EXIF_ORIENTATION_TO_TRANSFORM[orientation];
          const previewDivClasses = classnames("preview", {
            "no-cover": !coverImage,
          });
          resolve(
            <div
              className={previewDivClasses}
              key={`upload-image-preview-${i}`}
            >
              {i === coverImageIndex && (
                <div className="cover-image">Cover</div>
              )}
              <img
                src={image.dataUrl}
                alt=""
                style={{ transform: transformImage }}
              />
              {i !== coverImageIndex && (
                <div
                  className="cover-overlay"
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    setCoverImage(i);
                  }}
                >
                  <span>Make Cover</span>
                </div>
              )}
              <span
                role="button"
                tabIndex="-1"
                onClick={() => {
                  removeImage(image.id);
                }}
                className="remove-image"
              >
                <CloseIcon className={classes.closeIconStyles} />
              </span>
            </div>
          );
        });
      } else {
        const previewDivClasses = classnames("preview", {
          "no-cover": !coverImage,
        });
        resolve(
          <div className={previewDivClasses} key={`upload-image-preview-${i}`}>
            {i === coverImageIndex && <div className="cover-image">Cover</div>}
            <Image src={image.dataUrl} alt="" />
            {i !== coverImageIndex && (
              <div
                className="cover-overlay"
                role="button"
                tabIndex="-1"
                onClick={() => {
                  setCoverImage(i);
                }}
              >
                <span>Make Cover</span>
              </div>
            )}
            <span
              role="button"
              tabIndex="-1"
              onClick={() => {
                removeImage(image.id);
              }}
              className="remove-image"
            >
              <CloseIcon className={classes.closeIconStyles} />
            </span>
          </div>
        );
      }
    });
  };

  generatePreviewImages = () => {
    const { images } = this.props;
    const allPromises = images.map((image, i) => {
      return this.generatePreviewPromise(image, i);
    });
    Promise.all(allPromises).then((list) => {
      this.setState({
        loading: false,
        imagesPreview: list,
      });
    });
  };

  render() {
    const { images, classes } = this.props;
    const { loading, imagesPreview } = this.state;
    return (
      <div className="image-uploader-wrap">
        <input
          multiple
          type="file"
          style={{ display: "none" }}
          ref={this.inputFileRef}
          onChange={this.onImageChange}
          accept="image/*"
        />
        {!loading && images.length > 0 ? (
          <PerfectScrollbar ref={this.scrollerRef}>
            <div className="images-preview">
              {imagesPreview}
              <div
                className="add-image-single"
                role="button"
                tabIndex="-1"
                onClick={this.handleUploadClick}
              >
                <AddIcon className={classes.iconStyles} />
              </div>
            </div>
          </PerfectScrollbar>
        ) : (
          <div
            role="button"
            tabIndex="-1"
            className="image-uploader"
            onClick={this.handleUploadClick}
          >
            <AddImageIcon
              className={classes.iconStyles}
              style={{ height: "24px", marginRight: "6px" }}
            />
            <span className="click-to-add-img">Click to add images</span>
          </div>
        )}
      </div>
    );
  }
}

ImageUploader.propTypes = {
  addImage: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  coverImageIndex: PropTypes.number.isRequired,
  setCoverImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  coverImage: PropTypes.bool,
};

ImageUploader.defaultProps = {
  coverImage: true,
};

export default withStyles(styles)(ImageUploader);
