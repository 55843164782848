import React from "react";
import PropTypes from "prop-types";

import "./Avatar.scss";
import Image from "../ImageTag/Image";

/**
 * Display a rounded avatar
 * @visibleName Avatar
 */

const Avatar = ({ className, imgPath }) => (
  <div className={`commonAvatar ${className}`}>
    <div className="imageContainer">
      {imgPath ? <Image src={imgPath} alt="" /> : ""}
    </div>
  </div>
);

Avatar.propTypes = {
  className: PropTypes.string,
  imgPath: PropTypes.string,
};

Avatar.defaultProps = {
  className: "",
  imgPath: null,
};

export default Avatar;
