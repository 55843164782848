import React, { Component } from "react";
import PropTypes from "prop-types";
import AvatarEditor from "react-avatar-editor";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";

import Button from "../Button";
import "./ImageUploader.scss";

class ProfileUploader extends Component {
  state = {
    zoom: 1.5,
  };

  inputFileRef = React.createRef();

  handleUploadClick = () => {
    if (this.inputFileRef) {
      this.inputFileRef.current.click();
    }
  };

  zoomIn = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom + 0.1,
    }));
  };

  zoomOut = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom - 0.1,
    }));
  };

  cropImage = () => {
    const { setCroppedImage } = this.props;
    if (this.editor) {
      const canvas = this?.editor?.getImage();
      setCroppedImage(canvas?.toDataURL());
    }
  };

  render() {
    const { imageFile, width, height, onImageChange, remove, url } = this.props;
    const { zoom } = this.state;
    return (
      <div className="pp-uploader-wrap">
        <input
          type="file"
          style={{ display: "none" }}
          ref={this.inputFileRef}
          onChange={onImageChange}
          accept="image/*"
        />

        <AvatarEditor
          ref={(c) => {
            this.editor = c;
          }}
          // onMouseUp={this.cropImage}
          onLoadSuccess={this.cropImage}
          onPositionChange={this.cropImage}
          image={imageFile != null ? imageFile : url}
          width={width}
          height={height}
          color={[0, 0, 0, 0.7]} // RGBA
          scale={zoom}
          border={3}
          borderRadius={500}
        />
        <div className="scale">
          <ZoomInIcon onClick={this.zoomIn} style={{ margin: "0 9" }} />
          <ZoomOutIcon onClick={this.zoomOut} style={{ margin: "0 9" }} />
        </div>

        <Button mode="secondary" size="auto" onClick={this.handleUploadClick}>
          Change Image
        </Button>
      </div>
    );
  }
}

ProfileUploader.propTypes = {
  setCroppedImage: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  imageFile: PropTypes.instanceOf(new FileReader()),
};

ProfileUploader.defaultProps = {
  imageFile: null,
};

export default ProfileUploader;
