import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Cookie from "js-cookie";
import moment from "moment";
import Axios from "axios";

import Image from "../../../ImageTag/Image";
import sitemap from "../../../../routing/siteMap";
import { postAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import {
  showDialog,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import Loading from "../../../Loading/Loading";
import { getThumbnail } from "../../../../utils/mediaUtils";
import "./Order.scss";

const mapStateToProps = ({ user, cart }) => ({ user, cart });

class Order extends Component {
  state = {
    orders: {},
    isLoading: true,
  };
  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      const res = await Axios.get(CONFIG.PLACE_ORDER, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      this.setState({ orders: res.data.result, isLoading: false });
    }
  };

  cancelOrder = (id, i_id) => {
    store.dispatch(
      showDialog(
        "Cancel Order",
        "Are you sure you want to cancel this order?",
        () => {
          postAuth(
            CONFIG.CANCEL_ORDER_ITEM,
            (res) => {
              store.dispatch(
                showGlobalSnack("normal", "Order has been Cancelled", 2000)
              );
              store.dispatch(hideDialog());
              this.getOrders();
            },
            (error) => {
              console.log("error", error);
              store.dispatch(
                showGlobalSnack("error", "Something went wrong", 3000)
              );
            },
            {
              order_id: id,
              item_id: i_id,
            }
          );
        }
      )
    );
  };

  render() {
    const { orders, isLoading } = this.state;
    return (
      <div className="order-page">
        <div className="order-header">
          <div className="left">Order</div>
          <div className="right">
            <Link to={sitemap.cart}>
              <button className="go-to-cart-btn">Go To Cart</button>
            </Link>
          </div>
        </div>
        <div className="orders">
          {isLoading ? (
            <Loading color="red" />
          ) : (
            <>
              {orders.length > 0 ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td>Product</td>
                        <td>Price</td>
                        <td>Placed on</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    {orders.map((order) => {
                      return order.orderItems.map((orderItem) => {
                        const thumbnail = getThumbnail(
                          orderItem.image,
                          "thumbnail"
                        );
                        return (
                          <>
                            <tbody key={orderItem.product}>
                              <tr>
                                <td className="art-desc">
                                  <Image src={thumbnail.url} alt="order-item" />
                                  <div className="desc">
                                    <span className="art-name">
                                      {orderItem.name}
                                    </span>{" "}
                                    <br />
                                    <span className="artist">
                                      by {orderItem?.product?.author?.name}
                                    </span>
                                  </div>
                                </td>
                                <td>NPR. {orderItem?.price}</td>
                                <td>
                                  {moment(order?.createdAt).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`order-status ${order?.orderStatus.toLowerCase()}`}
                                  >
                                    {order?.orderStatus}
                                  </span>
                                  <br />
                                  <br />

                                  {order?.orderStatus === "PENDING" && (
                                    <>
                                      <button
                                        className="cancel-btn"
                                        onClick={() => {
                                          this.cancelOrder(
                                            order._id,
                                            orderItem._id
                                          );
                                        }}
                                      >
                                        CANCEL
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      });
                    })}
                  </table>
                </>
              ) : (
                <div className="no-items">You don't have any orders!</div>
              )}
            </>
          )}
        </div>
        <div className="responsive-orders">
          {isLoading ? (
            <Loading color="red" />
          ) : (
            <>
              {orders?.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td>Product Details</td>
                      <td> Status/Date</td>
                    </tr>
                  </thead>
                  {orders.map((order) => {
                    return order.orderItems.map((orderItem) => {
                      const thumbnail = getThumbnail(
                        orderItem.image,
                        "thumbnail"
                      );
                      return (
                        <>
                          <tbody key={orderItem.product}>
                            <tr>
                              <td className="art-desc">
                                <Image src={thumbnail.url} />
                                <div className="desc">
                                  <span className="art-name">
                                    {orderItem?.name}
                                  </span>{" "}
                                  <br />
                                  <span className="artist">
                                    by {orderItem?.product?.author?.name}
                                  </span>
                                  <br />
                                  <span className="price">
                                    NPR. {orderItem?.price}
                                  </span>
                                </div>
                              </td>
                              <td className="status">
                                <span
                                  className={`order-status ${order.orderStatus.toLowerCase()}`}
                                >
                                  {order?.orderStatus}
                                </span>
                                <br />

                                <span className="date">
                                  {moment(order?.createdAt).format(
                                    "YYYY-MM-DD"
                                  )}
                                </span>
                                <br />
                                {order?.orderStatus === "PENDING" && (
                                  <>
                                    <button
                                      className="cancel-btn"
                                      onClick={() => {
                                        this.cancelOrder(
                                          order._id,
                                          orderItem._id
                                        );
                                      }}
                                    >
                                      CANCEL
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      );
                    });
                  })}
                </table>
              ) : (
                <div className="no-items">You don't have any orders!</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

Order.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  cart: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Order);
