import React from 'react';
import PropTypes from 'prop-types';

import './sass/three-dots.scss';

/**
 * CSS Loading Animation
 */
const ThreeDots = ({ variant }) => (
  <div className={`dot-${variant}`}/>
);

ThreeDots.propTypes = {
  variant: PropTypes.oneOf([
    'elastic',
    'pulse',
    'flashing',
    'collision',
    'revolution',
    'carousel',
    'typing',
    'windmill',
    'bricks',
    'floating',
    'fire',
    'spin',
    'falling',
    'stretching'
  ])
};

ThreeDots.defaultProps = {
  variant: 'floating'
};

export default ThreeDots;
