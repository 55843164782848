import React from 'react';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import './Header.scss';

const Header = ({ isUserRefreshInProgress,messageSeen }) => (
   <>
    <Breakpoint m>
      <DesktopHeader isUserRefreshInProgress={isUserRefreshInProgress} messageSeen={messageSeen}  />
    </Breakpoint>

    <Breakpoint s>
      <MobileHeader isUserRefreshInProgress={isUserRefreshInProgress} />
    </Breakpoint>
  </>
);

Header.propTypes = {
  isUserRefreshInProgress: PropTypes.bool.isRequired
};

export default Header;
