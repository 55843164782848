import React, { Component } from "react";
import { generatePath, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import { getThumbnail } from "../../../utils/mediaUtils";
import sitemap from "../../../routing/siteMap";
import ContentCard from "../../ContentCard";
import ArtistSideBar from "./ArtistSideBar";
import "../User/LikedArtwork/LikedArtwork.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getAllLikedArtworks } from "../../../services/artworkServices";
import { get } from "../../../services/generalApiServices";
import { CONFIG } from "../../../constants";
import PageHead from "../../PageHead";
import Image from "../../ImageTag/Image";
import { getYourInfo } from "../../../services/userServices";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

class ArtistLikedArtwork extends Component {
  state = {
    artist: {},
    loading: true,
    liked_artworks: [],
  };

  componentDidMount() {
    getAllLikedArtworks(
      (response) => {
        this.setState({
          liked_artworks: response.liked_artworks,
          loading: false,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
    getYourInfo(
      (response) => {
        this.setState({ artist: response, loading: false });
      },
      (err) => {
        this.setState({ loading: false });
        console.log(err);
        // history.push("/404");
      }
    );
  }
  setFollow=()=>{
    getAllLikedArtworks(
      (response) => {
        this.setState({
          liked_artworks: response.liked_artworks,
          loading: false,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
    getYourInfo(
      (response) => {
        this.setState({ artist: response, loading: false });
      },
      (err) => {
        this.setState({ loading: false });
        console.log(err);
        // history.push("/404");
      }
    );
  }

  render() {
    const { liked_artworks, artist } = this.state;
    const images = [];
    liked_artworks.map((artwork) => {
      const thumbnail = getThumbnail(artwork?.image, "gallery");
      images.push({ image: thumbnail?.url, slug: artwork?.slug });
    });

    return (
      <div id="likedartwork-page">
        <div className="commonGidContainer typeBox">
          <PageHead imgPath={artist?.cover && `users/${artist?.cover}`} />
          {Object.keys(this.state.artist).length > 0 && (
            <ArtistSideBar artist={artist} setFollow={this.setFollow} />
          )}

          <div className="artworks">
            <ContentCard title="Liked Artworks">
              {images?.length > 0 ? (
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry columnsCount={3} gutter="5px">
                    {images.map((image, i) => (
                      <NavLink
                        to={generatePath(sitemap.artWork, {
                          slug: image?.slug,
                        })}
                      >
                        <Image
                          className="imageStyle"
                          key={i}
                          src={image?.image}
                          alt={image?.slug}
                          style={{ width: "100%", display: "block" }}
                        />
                      </NavLink>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              ) : (
                <div style={{ textAlign: "center" }}>
                  You haven't liked any artworks yet!
                </div>
              )}
            </ContentCard>
          </div>
        </div>
      </div>
    );
  }
}

ArtistLikedArtwork.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
};

export default withSizes(mapSizesToProps)(ArtistLikedArtwork);
