import React, { Component } from "react";
import anime from "animejs";
import PropTypes from "prop-types";
import classnames from "classnames";
import Slider from "react-slick";
import WithSizes from "react-sizes";

import ButtonBase from "@material-ui/core/ButtonBase";
import NextArrow from "@material-ui/icons/ChevronRightRounded";
import Prevarrow from "@material-ui/icons/ChevronLeftRounded";
import { ThreeDotsRed } from "../ThreeDots";

import styles from "./ContentScroller.module.scss";
import "./ContentScroller.scss";

/**
 * Display scrollable content section with/without tabs
 * @visibleName Content Scroller
 */

const arrowButtonStyles = {
  width: "100%",
  height: "100%",
  color: "#BBBBBB",
};

const slickSliderSettings = {
  autoplay: false,
  dots: false,
  arrows: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

class ContentScroller extends Component {
  constructor() {
    super();
    this.state = {
      slideIndex: 0,
      activeNext: true,
      activePrev: false,
    };
    this.sliderTrackRef = React.createRef();
    this.sliderContainerRef = React.createRef();
    this.sliderItems = [];
    this.sliderPosition = 0;
  }

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;
    if (prevProps.activeTab !== activeTab) {
      this.setState({ slideIndex: 0 }); // eslint-disable-line
      this.sliderPosition = 0;
    }
  }

  slidePrev = () => {
    const { slideIndex, activeNext } = this.state;
    if (slideIndex !== 0) {
      this.setState({ activeNext: true });
      // calc x translate3d
      let newSliderPosition = 0;
      for (let i = 0; i < slideIndex - 1; i++) {
        newSliderPosition += this.sliderItems[i].clientWidth;
      }
      this.sliderPosition = newSliderPosition * -1;
      anime({
        targets: this.sliderTrackRef.current,
        translateX: this.sliderPosition,
        translateY: 0,
        translateZ: 0,
        easing: "easeOutQuint",
        duration: 500,
        complete: () => {
          this.setState((prevState) => ({
            slideIndex: prevState.slideIndex - 1,
          }));
        },
      });
    } else {
      this.setState({ activePrev: false });
    }
  };

  slideNext = () => {
    const { slideIndex } = this.state;
    this.setState({ activePrev: true });
    const visibleSlider =
      this.sliderTrackRef.current.clientWidth + this.sliderPosition;
    if (visibleSlider <= this.sliderContainerRef.current.clientWidth) {
      return;
    }
    // calc x translate3d
    let newSliderPosition = 0;
    for (let i = 0; i <= slideIndex; i++) {
      newSliderPosition += this.sliderItems[i].clientWidth;
    }
    newSliderPosition *= -1;
    // check last slider
    const newVisibleSlider =
      this.sliderTrackRef.current.clientWidth + newSliderPosition;
    if (newVisibleSlider < this.sliderContainerRef.current.clientWidth) {
      this.setState({ activeNext: false });
      newSliderPosition =
        (this.sliderTrackRef.current.clientWidth -
          this.sliderContainerRef.current.clientWidth) *
        -1;
    }
    anime({
      targets: this.sliderTrackRef.current,
      translateX: newSliderPosition,
      translateY: 0,
      translateZ: 0,
      easing: "easeOutQuint",
      duration: 500,
      complete: () => {
        this.setState((prevState) => ({
          slideIndex: prevState.slideIndex + 1,
        }));
        this.sliderPosition = newSliderPosition;
      },
    });
  };

  render() {
    // this.sliderItems = [];
    // this.sliderItems.length = 0;
    const {
      title,
      tabs,
      setActive,
      activeTab,
      children,
      loading,
      arrowPosition,
      winWidth,
      studiosToFollow,
    } = this.props;
    const { activeNext, activePrev } = this.state;
    if (winWidth < 480) {
      const slickSlides = children.map((child, index) => {
        const classes = classnames("content-scroller-slick", {
          "studios-to-follow": studiosToFollow,
        });
        return (
          <div className={classes} key={`content-scroller-slick-${index}`}>
            <div className="slide-inner" style={{ maxWidth: winWidth - 30 }}>
              {child}
            </div>
          </div>
        );
      });
      return (
        <div className="content-slider-slick-wrapper">
          <Slider {...slickSliderSettings}>{slickSlides}</Slider>
        </div>
      );
    }

    const tabList =
      tabs &&
      tabs.map((tab) => {
        const tabClass = classnames({
          [styles.tab]: true,
          active: tab._id === activeTab,
        });
        return (
          <span
            key={`content-scroller-tab-${tab._id}`}
            className={tabClass}
            onClick={() => {
              setActive(tab._id);
            }}
            role="button"
            tabIndex="-1"
          >
            {tab.name}
          </span>
        );
      });
    const itemList =
      !loading &&
      children.map((child, index) => {
        return (
          <div
            key={`slider-item-${index}`}
            className={classnames(styles.item, "item")}
            ref={(e) => {
              this.sliderItems[index] = e;
            }}
          >
            {child}
          </div>
        );
      });
    const wrapperClasses = classnames(
      "content-scroller",
      styles.contentScroller,
      {
        [styles.noTab]: tabs === null || tabs.length < 1,
        [styles.typeAround]: arrowPosition === "around",
      }
    );
    return (
      <div className={wrapperClasses}>
        {arrowPosition === "normal" && (
          <div className={styles.top}>
            <div className={styles.title}>{title}</div>
            {tabs && tabs.length > 0 && (
              <div className={styles.tabs}>
                <span
                  className={classnames({
                    [styles.tab]: true,
                    active: activeTab === "all",
                  })}
                  onClick={() => {
                    setActive("all");
                  }}
                  role="button"
                  tabIndex="-1"
                >
                  All
                </span>
                {tabList}
              </div>
            )}
            {
              <div className={styles.arrows}>
                <div className={styles.arrow}>
                  <ButtonBase
                    focusRipple
                    style={arrowButtonStyles}
                    onClick={this.slidePrev}
                  >
                    <Prevarrow />
                  </ButtonBase>
                </div>
                <div className={styles.arrow}>
                  <ButtonBase
                    focusRipple
                    style={arrowButtonStyles}
                    onClick={this.slideNext}
                  >
                    <NextArrow />
                  </ButtonBase>
                </div>
              </div>
            }
          </div>
        )}

        <div className={classnames(styles.main, "main")}>
          {children.length > 0 ? (
            arrowPosition === "around" && (
              <div
                className={classnames(
                  styles.sideArrow,
                  styles.arrowPrev,
                  "_arrow_prev"
                )}
              >
                {activePrev ? (
                  <ButtonBase
                    style={{ color: "black" }}
                    onClick={this.slidePrev}
                  >
                    <Prevarrow />
                  </ButtonBase>
                ) : (
                  <ButtonBase
                  // onClick={this.slidePrev}
                  >
                    <Prevarrow />
                  </ButtonBase>
                )}
              </div>
            )
          ) : (
            <ButtonBase
              style={{ color: "#BBBBBB" }}
              // onClick={this.slidePrev}
            >
              <Prevarrow />
            </ButtonBase>
          )}
          {loading ? (
            <div className={styles.loading}>
              <ThreeDotsRed variant="flashing" />
            </div>
          ) : (
            <>
              {children.length > 0 ? (
                <div
                  className={styles.scrollerWrap}
                  ref={this.sliderContainerRef}
                >
                  <div className={styles.track} ref={this.sliderTrackRef}>
                    {itemList}
                  </div>
                </div>
              ) : (
                <div className={styles.nothing}>
                  <span>Nothing to show</span>
                </div>
              )}
            </>
          )}
          {children.length > 0 ? (
            arrowPosition === "around" && (
              <div
                className={classnames(
                  styles.sideArrow,
                  styles.arrowNext,
                  "_arrow_next"
                )}
              >
                {activeNext ? (
                  <ButtonBase
                    focusRipple
                    style={{ color: "black" }}
                    onClick={this.slideNext}
                  >
                    <NextArrow />
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    focusRipple
                    // onClick={this.slideNext}
                  >
                    <NextArrow />
                  </ButtonBase>
                )}
              </div>
            )
          ) : (
            <ButtonBase
              style={{ color: "#BBBBBB" }}
              focusRipple
              // onClick={this.slideNext}
            >
              <NextArrow />
            </ButtonBase>
          )}
        </div>
      </div>
    );
  }
}

ContentScroller.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  setActive: PropTypes.func,
  activeTab: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  arrowPosition: PropTypes.oneOf(["normal", "around"]),
  winWidth: PropTypes.number.isRequired,
  studiosToFollow: PropTypes.bool,
};

ContentScroller.defaultProps = {
  title: "",
  tabs: null,
  setActive: () => {},
  activeTab: null,
  loading: false,
  arrowPosition: "normal",
  studiosToFollow: false,
};

export default WithSizes(mapSizesToProps)(ContentScroller);
