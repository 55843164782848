import React, { Component, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  Link,
  BrowserRouter as Router,
} from "react-router-dom";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { connect } from "react-redux";

import { isUserArtist } from "../../../../utils/userUtils";
import sitemap from "../../../../routing/siteMap";
import ContentCard from "../../../ContentCard";
import { CONFIG } from "../../../../constants";
import ProfileSidebar from "../Profile/ProfileSidebar";
import ArtistSideBar from "../../Artist/ArtistSideBar";
import { getWithAuth } from "../../../../services/generalApiServices";
import { getArtistData } from "../../../../services/artistServices";
import { getUserData, getYourInfo } from "../../../../services/userServices";
import PageHead from "../../../PageHead";
import Following from "./Following";
import Followers from "./Followers";
import "./FollowingFollowers.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});

class FollowingFollowers extends Component {
  state = {
    loading: true,
    followers: {},
    following: {},
    artist: {},
    userInfo: {},
  };

  componentDidMount() {
    this.getFollowers();
    this.getFollowing();
    this.getUserData();
  }

  getFollowers = () => {
    getWithAuth(
      CONFIG.GET_FOLLOWERS,
      (followers) => this.setState({ followers, loading: false }),
      (e) => {
        console.log(e);
        this.setState({ loading: false });
      }
    );
  };

  getFollowing = () => {
    getWithAuth(
      CONFIG.GET_FOLLOWING,
      (following) => this.setState({ following, loading: false }),
      (e) => {
        console.log(e);
        this.setState({ loading: false });
      }
    );
  };

  getUserData = () => {
    getYourInfo(
      (response) => {
        this.setState({ userInfo: response });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  render() {
    const { user, match } = this.props;
    const { followers, following, userInfo } = this.state;
    return (
      <div id="followingFollowers-page">
        <Router>
          <div className="commonGidContainer typeHeaderUp">
            <PageHead imgPath={userInfo?.cover && `users/${userInfo?.cover}`} />
            {Object.keys(userInfo).length > 0 && (
              <ProfileSidebar userInfo={userInfo} />
            )}

            <div className="followers-following">
              <ContentCard>
                <ul>
                  <li>
                    <NavLink
                      to={generatePath(sitemap.followers, {
                        slug: match.params.slug,
                      })}
                    >
                      Followers ({followers?.length}){" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={generatePath(sitemap.following, {
                        slug: match.params.slug,
                      })}
                    >
                      Following ({following?.length}){" "}
                    </NavLink>
                  </li>
                </ul>
                <div className="followers">
                  <Switch>
                    <Route
                      path={sitemap.followers}
                      render={(props) => (
                        <Followers
                          {...props}
                          followers={followers}
                          refresh={this.getFollowers}
                        />
                      )}
                    />
                    <Route
                      path={sitemap.following}
                      render={(props) => (
                        <Following
                          {...props}
                          following={following}
                          refresh={this.getFollowing}
                        />
                      )}
                    />
                    <Redirect
                      to={generatePath(sitemap.followers, {
                        slug: match?.params?.slug,
                      })}
                    />
                  </Switch>
                </div>
              </ContentCard>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

FollowingFollowers.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

FollowingFollowers.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(FollowingFollowers);
