import { GET_ALL_USER_DETAILS } from "../constants/action-types";
const initialState = {
  userDetails: [],
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_USER_DETAILS:
      return {
        userDetails: payload,
      };
    default:
      return state;
  }
};
