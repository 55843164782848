import { SHOW_GLOBAL_SNACK, HIDE_GLOBAL_SNACK } from '../constants/action-types';

export const showGlobalSnack = (type, message, duration) => ({
  type: SHOW_GLOBAL_SNACK,
  payload: {
    type,
    message,
    duration
  }
});

export const hideGlobalSnack = () => (
  {
    type: HIDE_GLOBAL_SNACK
  }
);
