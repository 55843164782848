import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import ArtShowcase, { ArtShowcaseItem } from "../../ArtShowcase";
import { getThumbnail } from "../../../utils/mediaUtils";
import Loading from "../../Loading";
import BottomScrollListener from "react-bottom-scroll-listener";
import { getProfileProducts } from "../../../services/productServices";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

const ArtistWork = ({ artist, winWidth }) => {
  const [products, setProducts] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(999999);

  useEffect(() => {
    getProducts();
  }, [artist?._id, page]);

  const getProducts = () => {
    const id = artist?._id;
    getProfileProducts(
      id,
      { page: page },
      (response) => {
        const newProducts = response?.products;
        setTotal(response?.total);
        setProducts([...products, ...newProducts]);
        setLoadingMore(false);
      },
      (err) => {
        console.log(err);
        setLoadingMore(false);
      }
    );
  };
  const loadMore = () => {
    setPage((page) => page + 1);
    setLoadingMore(true);
  };

  const artShowcaseItemList = products?.map((product) => {
    const thumbnail = getThumbnail(product?.image, "gallery");
    return (
      <ArtShowcaseItem
        readOnly={false}
        key={`artist-work-${product?._id}`}
        artist={artist?.name}
        artistId={artist?._id}
        artName={product?.name}
        thumbnail={thumbnail}
        price={product?.priceField}
        slug={product?.slug}
        art={product}
      />
    );
  });

  let columnCount = 4;
  if (winWidth < 1440) {
    columnCount = 4;
  }
  if (winWidth < 1199) {
    columnCount = 3;
  }
  // if (winWidth < 991) {
  //   columnCount = 3;
  // }
  if (winWidth < 481) {
    columnCount = 2;
  }

  return (
    <section className="artistWorkSection">
      <div className="customContainer">
        <div className="sectionContent">
          {products?.length > 0 ? (
            <>
              <ArtShowcase columnCount={columnCount} gutter={17}>
                {artShowcaseItemList}
              </ArtShowcase>
              {loadingMore ? (
                <Loading color="red" />
              ) : (
                <>
                  {products?.length < total && (
                    <>
                      <BottomScrollListener onBottom={loadMore} offset={300} />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ textAlign: "center", paddingBottom: 20 }}>
              No Artworks to show
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

ArtistWork.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
};

export default withSizes(mapSizesToProps)(ArtistWork);
