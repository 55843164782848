import ImageUploader from './ImageUploader';
import ImageUploaderSingle from './ImageUploderSingle';
import CoverUploader from './CoverUploader';
import ProfilePictureUploader from './ProfilePictureUploader';

export default ImageUploader;

export {
  ImageUploaderSingle,
  CoverUploader,
  ProfilePictureUploader
};
