import React from 'react';
import PropTypes from 'prop-types';

import './ContentCard.scss';

const ContentCard = ({ title, subtitle, optional, heading, children,handleEdit, editBtn }) => (
  <div className="contentCardItem">
    <div className="title">
      <h5>{title} {optional && <small style={{ color: '#C0C0C0', fontWeight: 100, fontStyle: 'normal' }}>{optional}</small>}</h5>
      {
        subtitle && <small>{subtitle}</small>
      }
      {
        heading && (
          <p style={{ marginTop: '10px' }}>{heading}</p>
        )
      }
      {editBtn ==  true ?(<button className='btns'  onClick={handleEdit}>Edit</button>):('')}
      
    </div>
    <div className="main">
      {children}
    </div>
  </div>
);

ContentCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.string,
  optional: PropTypes.string,
  heading: PropTypes.string,
};

ContentCard.defaultProps = {
  title: '',
  children: '',
  subtitle: null,
  optional: null,
  heading: null,
};

export default ContentCard;
