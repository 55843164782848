import React from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Brush";

import LikeArt from "../LikeArt";
import sitemap from "../../routing/siteMap";
import Image from "../ImageTag/Image";

const mapStateToProps = ({ user }) => ({ user });

const ArtShowcaseItem = ({
  artist,
  thumbnail,
  artName,
  price,
  slug,
  readOnly,
  user,
  artistId,
  artistSlug,
  art
}) => {
  return (
    <div className="artItem">
      <div className="inner">
        {!readOnly && user && user?.id === artistId && (
          <Link to={generatePath(sitemap.artWorkEdit, { slug })}>
            <button type="button" className="edit-art">
              <EditIcon style={{ fontSize: 10 }} />
              <span>Edit</span>
            </button>
          </Link>
        )}
        <div className="imageContainer">
          <Link to={generatePath(sitemap.artWork, { slug })}>
            <div>
              <Image src={thumbnail?.url} alt="" skeletonHeight={200} />
            </div>
          </Link>
          <LikeArt art={art} out={true} />
        </div>

        <div className="textContainer">
          <div className="textItem">
            <h5>{artName}</h5>
            <h6>{price.toUpperCase()}</h6>
            {artistSlug ? (
              <span style={{ color: "#A8A8A8" }}>
                by{" "}
                <Link
                  to={generatePath(sitemap.artist, {
                    slug: artistSlug
                  })}
                >
                  {artist}
                </Link>
              </span>
            ) : (
              <span style={{ color: "#A8A8A8" }}>by {artist}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ArtShowcaseItem.propTypes = {
  artist: PropTypes.string.isRequired,
  artName: PropTypes.string.isRequired,
  thumbnail: PropTypes.objectOf(PropTypes.any).isRequired,
  price: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  artistId: PropTypes.string,
  readOnly: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
  artistSlug: PropTypes.string
};

ArtShowcaseItem.defaultProps = {
  readOnly: true,
  user: null,
  artistSlug: null
};

export default connect(mapStateToProps)(ArtShowcaseItem);
