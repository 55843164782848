import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { objectOf, any } from "prop-types";

import { get } from "../../../services/generalApiServices";
import Banner from "./Banner";
import ArtCategory from "./ArtCategory";
import ArtFromFollowed from "../../ArtFromFollowed";
import StudiosToFollow from "../../StudiosToFollow";
import FeaturedArtworks from "../../FeaturedArtworks";
import RecommendedProducts from "../../RecommendedProducts";
import ArtList from "../../ArtList";
import BhittaBanner from "./BhittaBanner";
import ScrollTop from "../../ScrollToTop";
import Image from "../../ImageTag/Image";
import { CONFIG } from "../../../constants";

const mapStateToProps = ({ user }) => ({ user });

const Home = ({ user }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    get(
      CONFIG.GET_CATEGORIES,
      (categories) => {
        setCategories(categories);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <div id="homePage">
      {/* <Banner/> */}
      <ScrollTop />
      <BhittaBanner />
      <ArtCategory />
      {user && <ArtFromFollowed />}
      {user && <RecommendedProducts />}
      {user && <StudiosToFollow user={user} />}
      <FeaturedArtworks user={user} />
      {categories.map((cat) => {
        return <ArtList categoryId={cat?._id} name={cat?.name} user={user} />;
      })}
    </div>
  );
};

Home.propTypes = {
  user: objectOf(any),
};

Home.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(Home);
