import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Cookie from "js-cookie";

import store from "../../../redux";
import { showGlobalSnack } from "../../../redux/actions/snack.actions";
import CommonButton from "../../Button";
import { CONFIG } from "../../../constants";
import { FaImage, FaYoutube } from "react-icons/fa";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import YouTube from "react-youtube";
import CancelIcon from "@material-ui/icons/Cancel";

const NewFeed = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [hasYoutubeLink, setHasYoutubeLink] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [youtubeURL, setYoutubeURL] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [post, setPost] = useState("");
  const { refresh, history } = props;

  const clear = () => {
    setPost("");
    setVideoId("");
    setYoutubeURL("");
    setImage(null);
    setImageFile(null);
    setSubmitting(false);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setHasYoutubeLink(true);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  //************************ */
  //**Youtube link copy paste feature */
  //** Added by Rojan Dhimal */

  const onYoutubeLinkPaste = (event) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    let url = (event.clipboardData || window.clipboardData).getData("text");
    var match = url.match(regExp);
    if (match && match[7].length == 11) {
      setVideoId(match[7]);
    } else {
      const message = `Invalid Youtube Url`;
      store.dispatch(showGlobalSnack("error", message, 3000));
    }
  };

  const onYoutubeLinkChange = (event) => {
    setYoutubeURL(event.target.value);
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = youtubeURL.match(regExp);
    if (match && match[7].length == 11) {
      setVideoId(match[7]);
    }
  };

  const handleYoutubeBtn = () => {
    let val = !hasYoutubeLink;
    setHasYoutubeLink(val);
  };
  const cancelImage = (e) => {
    setImage("");
    setHasYoutubeLink(false);
  };

  const cancelYoutubeVideo = () => {
    setYoutubeURL("");
    setVideoId(null);
    setHasYoutubeLink(false);
  };

  const onPostChange = (e) => {
    setPost(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      setSubmitting(true);
      const data = new FormData();
      data.append("post", post);
      if (imageFile) data.append("picture", imageFile);
      if (youtubeURL) data.append("youtube_url", videoId);
      axios({
        url: `${CONFIG.API_URL}/post/new`,
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            const message = `Post created successfully`;
            store.dispatch(showGlobalSnack("success", message, 3000));
            setHasYoutubeLink(false);
            clear();
            refresh();
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.message === "File too large") {
            const message = `Attached image must be less than ${CONFIG.POST_ATTACHMENT_MB} MB`;
            store.dispatch(showGlobalSnack("error", message, 3000));
          } else {
            store.dispatch(
              showGlobalSnack("error", e.message.toString(), 3000)
            );
          }
          clear();
        });
    }
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const opts = {
    height: "300",
    width: "530",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="commonFeedContainer typeNewFeed articleBoxContainer">
      <form onSubmit={handleSubmit}>
        <div className="content">
          {image && (
            <div id="post-picture">
              <img
                src={image}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "cover",
                  maxHeight: "240px",
                  borderRadius: "5px",
                }}
              />
              <CancelIcon className="cancel-icon" onClick={cancelImage} />
            </div>
          )}
          {videoId && (
            <div id="post-video">
              <YouTube videoId={videoId} opts={opts} onReady={_onReady} />
              <CancelIcon
                className="cancel-icon"
                onClick={cancelYoutubeVideo}
              />
            </div>
          )}
          <textarea
            required
            placeholder="Write an Article"
            value={post}
            onChange={onPostChange}
          />
          {!image ? (
            <input
              type="text"
              name="youtubelink"
              value={youtubeURL}
              placeholder="Paste youtube link here"
              style={{
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #E8E8E8",
                padding: "5px",
              }}
              className={!hasYoutubeLink ? "hideYoutube" : "showYoutube"}
              disabled={hasYoutubeLink ? false : true}
              onPaste={onYoutubeLinkPaste}
              onChange={onYoutubeLinkChange}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="action">
          <label className="add-image" htmlFor="post-picture">
            {!youtubeURL ? (
              <>
                <input
                  type="file"
                  id="post-picture"
                  className="custom-input-file"
                  onChange={onImageChange}
                  accept="image/*"
                />
                {!image && !youtubeURL ? (
                  <span>
                    <InsertPhotoIcon
                      style={{
                        position: "relative",
                        top: "-1px",
                        right: "3px",
                        fontSize: "16px",
                      }}
                    />
                    Add a picture
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {!image && !youtubeURL ? (
                  <>
                    <input
                      type="text"
                      id="post-picture"
                      className="custom-input-file"
                    />
                    <span>
                      <InsertPhotoIcon
                        style={{
                          position: "relative",
                          top: "-1px",
                          right: "3px",
                          fontSize: "16px",
                        }}
                      />
                      Add a picture
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </label>
          <label className="add-image" htmlFor="post-youtubeUrl">
            {!image ? (
              <>
                {!youtubeURL && !image ? (
                  <span onClick={handleYoutubeBtn}>
                    <FaYoutube
                      style={{
                        position: "relative",
                        top: "-1px",
                        right: "3px",
                        fontSize: "16px",
                      }}
                    />
                    Youtube URL
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {!youtubeURL && !image ? (
                  <span>
                    <FaYoutube
                      style={{
                        position: "relative",
                        top: "-1px",
                        right: "3px",
                        fontSize: "16px",
                      }}
                    />
                    Youtube URL
                  </span>
                ) : (
                  ""
                )}
              </>
            )}
          </label>

          <CommonButton
            loading={submitting}
            size="medium"
            mode="specialBlack"
            type="submit"
          >
            Post
          </CommonButton>
        </div>
      </form>
    </div>
  );
};

NewFeed.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default NewFeed;
