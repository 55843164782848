import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import { FaRegComment, FaHeart } from "react-icons/fa";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Button from "../Button";
import { CONFIG } from "../../constants";
import Avatar from "../Avatar";
import Comment from "../Comment";
import ButtonLike from "../ButtonLike";
import DropdownMenu from "../DropdownMenu";
import EditFeed from "./EditFeed";
import sitemap from "../../routing/siteMap";
import store from "../../redux";
import { showDialog, hideDialog } from "../../redux/actions/dialog.actions";
import { showGlobalSnack } from "../../redux/actions/snack.actions";
import {
  postAuthMultipart,
  postAuth,
  patchAuth
} from "../../services/generalApiServices";
import { nl2br } from "../../utils/stringUtils";
import { get } from "../../services/generalApiServices";
import YouTube from "react-youtube";
import Image from "../ImageTag/Image";
import Lightbox from "react-modal-image";
import VerifiedIcon from "../../assets/img/Verified.png";
import "./FeedItemNew.scss";
import { report } from "../../services/reportServices";
import { isUserArtist } from "../../utils/userUtils";
import Axios from "axios";
import CommonButton from "../Button";

const mapStateToProps = ({ user }) => ({ user });
const dialogClasses = {
  paper: {
    width: "100%",
    maxWidth: 280,
    padding: 20
  }
};
class FeedItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComments: false,
      editDialogVisible: false,
      comments: [],
      isOpen: true,
      total: CONFIG.FEED_COMMENTS_LIMIT,
      post: props?.post,
      commentInput: "",
      allComments: props?.post?.comments,
      showReport: false,
      reportOpt: [],
      reportValue: "",
      reportPostId: null,
      showInput: false,
      otherText: "",
      showAllText: false,
      hidePost: false
    };
  }

  closeLightbox = () => {
    this.setState({ isOpen: true });
  };
  closeDialog = () => {
    this.setState({ showReport: false });
  };
  getReportOptions = () => {
    get(
      CONFIG.GET_REPORT_OPTIONS,
      options => {
        this.setState({ reportOpt: options });
      },
      err => {}
    );
  };
  deletePost = () => {
    const { post, refresh } = this.props;
    store.dispatch(hideDialog());
    store.dispatch(showGlobalSnack("normal", "Deleting post...", 10000));
    postAuthMultipart(
      generatePath(CONFIG.DELETE_POST, { id: post?._id }),
      () => {
        store.dispatch(showGlobalSnack("normal", "", 3000));
        store.dispatch(
          showGlobalSnack("normal", "Post has been deleted.", 2000)
        );

        refresh();
      },
      e => {
        console.log(e);
        store.dispatch(
          showGlobalSnack("error", "An error occurred. Please try again", 2000)
        );
      }
    );
  };

  hideUnhidePost = () => {
    const { post, refresh } = this.props;

    patchAuth(
      generatePath(CONFIG.HIDE_POST, { postId: post?._id }),
      res => {
        store.dispatch(showGlobalSnack("normal", "", 3000));
        store.dispatch(showGlobalSnack("normal", res.data.message, 2000));
      },
      e => {
        console.log(e);
        store.dispatch(
          showGlobalSnack("error", "An error occurred. Please try again", 2000)
        );
      }
    );
  };

  toggleComments = (flag = false) => {
    this.setState({ showComments: flag });
  };

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true
    });
  };

  hideEditDialog = () => {
    this.setState({
      editDialogVisible: false
    });
  };

  handleDelete = () => {
    store.dispatch(
      showDialog(
        "Delete Post",
        "Are you sure you want to delete this post?",
        this.deletePost,
        "Delete",
        "Cancel"
      )
    );
  };

  componentDidMount() {
    this.getComments();
    this.getReportOptions();
  }

  getComments = () => {
    const { total } = this.state;
    const { refresh } = this.props;
    get(
      generatePath(CONFIG.GET_POST_COMMENTS, {
        postId: this.props.post?._id,
        total
      }),
      post => {
        this.setState({ comments: post?.comments });
        refresh();
      }
    );
  };
  handleCommentOnchange = e => {
    this.setState({ commentInput: e.target.value });
  };
  handleCommentIncrement = allComments => {
    this.setState({ allComments: allComments });
  };
  handleShowReport = () => {
    this.setState({ showReport: true });
  };
  handleHidePost = () => {
    this.setState({ hidePost: !this.state.hidePost });
    this.hideUnhidePost();
  };
  handleReportChange = e => {
    e.preventDefault();
    this.setState({ reportValue: e.target.value });
    if (e.target.value === "61cd60b2d9cf90805f719a2c") {
      this.setState({ showInput: true });
    }
  };
  handleReportSubmit = () => {
    const { reportValue, post, otherText } = this.state;
    if (reportValue) {
      report(
        generatePath(CONFIG.REPORT_FEED, { id: post?._id }),
        res => {
          store.dispatch(
            showGlobalSnack("success", "Reported Successfully", 3000)
          );
          this.setState({ showReport: false });
        },
        error => {
          store.dispatch(showGlobalSnack("error", error, 3000));
          this.setState({ showReport: false });
        },
        { category: reportValue, content: otherText }
      );
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please Select option to continue", 3000)
      );
    }
  };
  render() {
    const opts = {
      height: "200",
      width: "300",
      playerVars: {
        autoplay: 0
      }
    };
    const { refresh, user, preview, showOption } = this.props;
    const {
      showComments,
      editDialogVisible,
      comments,
      post,
      allComments,
      isOpen,
      reportOpt,
      reportValue,
      showInput,
      hidePost
    } = this.state;
    const dropdownActions = [
      {
        id: `post-edit-action-${post?._id}`,
        name: "Edit",
        onClick: this.showEditDialog
      },
      {
        id: `post-delete-action-${post?._id}`,
        name: "Delete",
        onClick: this.handleDelete
      }
    ];
    const dropDownActions2 = [
      {
        id: `post-report-action-${post?._id}`,
        name: "Report",
        onClick: this.handleShowReport
      },
      {
        id: `post-hide-action-${post?._id}`,
        name: "Hide",
        onClick: this.handleHidePost
      }
    ];
    return (
      <div className="feedItem">
        <Dialog
          open={this.state.showReport}
          onClose={this.closeDialog}
          aria-labelledby="responsive-dialog-title"
          classes={this.props.classes}
        >
          <div id="responsive-dialog-title">Report Post</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="report"
              name="report"
              disabled="true"
              value={reportValue}
              onChange={this.handleReportChange}
            >
              {reportOpt &&
                reportOpt.map(opt => (
                  <FormControlLabel
                    value={opt?._id}
                    control={<Radio />}
                    label={<div>{opt?.name}</div>}
                  />
                ))}
            </RadioGroup>
            {showInput && (
              <input
                placeholder="write reason"
                className="report-input"
                onChange={e => this.setState({ otherText: e.target.value })}
              />
            )}
          </FormControl>
          <div className="report-btns">
            <Button mode="reportSubmit" onClick={this.handleReportSubmit}>
              Submit
            </Button>
            <Button
              mode="special"
              onClick={() => {
                this.setState({ showReport: false });
              }}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
        {editDialogVisible && (
          <EditFeed
            post={post}
            handleClose={this.hideEditDialog}
            refresh={this.props.refresh}
          />
        )}

        {!hidePost ? (
          <>
            <div className="feedTitle">
              {user && user?.id === post?.author?.id ? (
                <Link
                  to={generatePath(sitemap.profile, {
                    slug: post?.author?.slug
                  })}
                >
                  <Avatar
                    imgPath={
                      post?.author?.picture &&
                      post?.author?.picture != "undefined" &&
                      `users/${post?.author?.picture}`
                    }
                  />
                </Link>
              ) : (
                <>
                  {" "}
                  {isUserArtist(post?.author?.role) && !!post?.author?.slug ? (
                    <Link
                      to={generatePath(sitemap.artist, {
                        slug: post?.author?.slug
                      })}
                    >
                      <Avatar
                        imgPath={
                          post?.author?.picture &&
                          post?.author?.picture != "undefined" &&
                          `users/${post?.author?.picture}`
                        }
                      />
                    </Link>
                  ) : (
                    <Link
                      to={generatePath(sitemap.normalUser, {
                        slug: post?.author?.slug
                      })}
                    >
                      <Avatar
                        imgPath={
                          post?.author?.picture &&
                          post?.author?.picture != "undefined" &&
                          `users/${post?.author?.picture}`
                        }
                      />
                    </Link>
                  )}
                </>
              )}

              <div className="authorName">
                {user && user?.id === post?.author?.id ? (
                  <h4>
                    <Link
                      to={generatePath(sitemap.profile, {
                        slug: post?.author?.slug
                      })}
                    >
                      {post?.author?.name}{" "}
                    </Link>
                  </h4>
                ) : (
                  <>
                    {isUserArtist(post?.author?.role) &&
                    !!post?.author?.slug ? (
                      <h4>
                        <Link
                          to={generatePath(sitemap.artist, {
                            slug: post?.author?.slug
                          })}
                        >
                          {post?.author?.name}{" "}
                        </Link>
                      </h4>
                    ) : (
                      <Link
                        to={generatePath(sitemap.normalUser, {
                          slug: post?.author?.slug
                        })}
                      >
                        <h4>{post?.author?.name}</h4>
                      </Link>
                    )}
                  </>
                )}

                <p className="coSmallText">{moment(post?.created).fromNow()}</p>
              </div>
              {user && user?.id === post?.author?._id && showOption && (
                <DropdownMenu actions={dropdownActions} />
              )}
              {user && user?.id !== post?.author?._id && (
                <DropdownMenu actions={dropDownActions2} />
              )}
            </div>
            <div className="feedContent">
              <Link
                to={
                  user
                    ? generatePath(sitemap.feedDetails, {
                        id: post?._id
                      })
                    : sitemap.login
                }
              >
                {post?.picture && (
                  <>
                    <div className="imageContainer">
                      <Image
                        src={`posts/${post?.picture}`}
                        alt=""
                        skeletonWidth={400}
                        skeletonHeight={400}
                      />
                    </div>
                    {/* )} */}
                  </>
                )}

                {post?.youtube_url && (
                  <div className="youtube-container">
                    <YouTube
                      videoId={post?.youtube_url}
                      opts={opts}
                      onReady={this._onReady}
                    />
                  </div>
                )}

                <div className="textContainer">
                  {/* <p>{post?.post}</p> */}
                  <p className={`bio ${this.state.showAllText && "view-all"}`}>
                    {post?.post.slice(0, 300)}
                    {this.state.showAllText && post?.post.slice(300)}
                    {post?.post.length > 300 && (
                      <>
                        <span
                          onClick={() =>
                            this.setState({
                              showAllText: !this.state.showAllText
                            })
                          }
                          style={{
                            fontWeight: "600"
                          }}
                        >
                          {!this.state.showAllText
                            ? " .... Read More"
                            : " Read Less"}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </Link>
              <div className="commonLike">
                <div className="inner">
                  <ButtonLike item={post} refresh={refresh} />
                  <div className="likeBtn">
                    <FaRegComment className="commentIcon" refresh={refresh} />
                    <span
                      style={{
                        padding: "0 5px",
                        position: "relative",
                        top: "1px"
                      }}
                    >
                      {allComments?.length}
                    </span>
                  </div>
                </div>
              </div>
              <div className="viewAllComments">
                {showComments && allComments?.length > 0 ? (
                  <a
                    className="view-comments"
                    onClick={() => this.toggleComments()}
                    style={{ color: "#9A9A9A" }}
                  >
                    Hide comments
                  </a>
                ) : (
                  <a
                    className="view-comments"
                    onClick={() => this.toggleComments(true)}
                    style={{ color: "#9A9A9A" }}
                  >
                    {allComments &&
                      allComments?.length > 0 &&
                      `View all comments (${allComments.length})`}
                  </a>
                )}

                {!showComments && allComments?.length > 0 && (
                  <p style={{ marginTop: "10px" }}>
                    <span style={{ marginRight: "5px" }}>
                      <b>
                        {allComments[allComments?.length - 1]?.author?.name}
                      </b>
                      {allComments[allComments?.length - 1]?.author
                        ?.verifiedArtist && (
                        <img
                          src={VerifiedIcon}
                          style={{
                            marginLeft: "4px",
                            marginBottom: "3px",
                            height: "12px"
                          }}
                        />
                      )}
                    </span>
                    <span>{allComments[allComments?.length - 1]?.comment}</span>
                    {"     "}
                    <span
                      style={{
                        color: "#BBBBBB",
                        fontSize: "12px",
                        marginLeft: "7px",
                        fontWeight: "bold"
                      }}
                    >
                      {moment(
                        allComments[allComments?.length - 1]?.created
                      ).fromNow()}
                    </span>
                  </p>
                )}

                {
                  <Comment
                    item={post}
                    showComments={showComments}
                    handler={() => this.toggleComments(false)}
                    refresh={refresh}
                    onChange={this.handleCommentOnchange}
                    comment={this.state.commentInput}
                    clearComment={() => {
                      this.setState({ commentInput: "" });
                    }}
                    getComments={this.getComments}
                    commentCount={this.handleCommentIncrement}
                  />
                }
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: 15
            }}
            // className="feedTitle"
          >
            <div
              style={{
                paddingBottom: 20
              }}
            >
              The post has been hidden
            </div>
            {/* <div onClick={() => this.handleHidePost()}>Undo</div> */}
            <CommonButton
              //  loading={submitting}
              size="auto"
              mode="follow"
              type="submit"
              onClick={() => this.handleHidePost()}
            >
              Undo
            </CommonButton>
          </div>
        )}
      </div>
    );
  }
}

FeedItem.propTypes = {
  post: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  showOption: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func
};

export default connect(mapStateToProps)(withStyles(dialogClasses)(FeedItem));
