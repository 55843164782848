import React, { Component } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import Slider from "react-slick";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";
import Breakpoint from "react-socks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { showDialog, hideDialog } from "../../../redux/actions/dialog.actions";
import { nl2br } from "../../../utils/stringUtils";
import { list } from "../../../services/exhibition.services";
import sitemap from "../../../routing/siteMap";
import Loading from "../../Loading";
import css from "./exhibitions.module.scss";
import { CONFIG } from "../../../constants";
import { get, getWithAuth } from "../../../services/generalApiServices";
import exhiTitle from "../../../assets/img/Exhibition.png";
import banner from "../../../assets/img/Exhibition_web.jpg";

import "react-tabs/style/react-tabs.scss";
import "./Tab.scss";

const Carousel = require("react-responsive-carousel").Carousel;

const mapStateToProps = ({ user }) => ({ user });

class Exhibitions extends Component {
  state = {
    loading: true,
    exhibitions: [],
    slideIndex: 0,
    currentExhi: [],
    previousExhi: [],
    upcomingExhi: [],
    index: 0,
  };

  slickRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    list()
      .then((exhibitions) => {
        this.setState({ exhibitions, loading: false });
      })
      .catch((e) => console.log(e));
    this.getCurrentExhi();
    this.getPreviousExhi();
    this.getUpcomingExhi();
  }

  confirmExplicit = (exhibition) => {
    const { dispatch, history } = this.props;
    dispatch(
      showDialog(
        "Explicit Content",
        "This exhibition contains explicit material. By continuing you confirm that you are over 18 years of age.",
        () => {
          history.push(
            generatePath(sitemap.exhibition, { slug: exhibition._id })
          );
          dispatch(hideDialog());
        },
        "Continue",
        "Cancel"
      )
    );
  };

  onClickThumb = (index) => {
    this.setState({ index: index });
  };

  getCurrentExhi = () => {
    const { user } = this.props;
    if (user) {
      getWithAuth(
        CONFIG.GET_CURRENT_EXHIBITION,
        (res) => {
          this.setState({ currentExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    } else {
      get(
        CONFIG.GET_CURRENT_EXHIBITION,
        (res) => {
          this.setState({ currentExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    }
  };
  getPreviousExhi = () => {
    const { user } = this.props;
    if (user) {
      getWithAuth(
        CONFIG.GET_PREVIOUS_EXHIBITION,
        (res) => {
          this.setState({ previousExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    } else {
      get(
        CONFIG.GET_PREVIOUS_EXHIBITION,
        (res) => {
          this.setState({ previousExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    }
  };
  getUpcomingExhi = () => {
    const { user } = this.props;
    if (user) {
      getWithAuth(
        CONFIG.GET_UPCOMING_EXHIBITION,
        (res) => {
          this.setState({ upcomingExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    } else {
      get(
        CONFIG.GET_UPCOMING_EXHIBITION,
        (res) => {
          this.setState({ upcomingExhi: res, loading: false });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
    }
  };

  render() {
    const {
      loading,
      exhibitions,
      slideIndex,
      currentExhi,
      previousExhi,
      upcomingExhi,
      index,
    } = this.state;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      rows: 2,
      arrows: false,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            rows: 4,
          },
        },
      ],
    };

    const previousExhibitionSlides = previousExhi?.map((exhibition) => {
      if (exhibition._id === "5eeb9d4a0b1ead4398462058") {
        return (
          <div
            key={`exhibition-${exhibition?._id}`}
            className={css.slide}
            onClick={() => this.confirmExplicit(exhibition)}
            role="button"
            tabIndex="-1"
          >
            <img src={exhibition?.listingThumb} alt="" />
            <div
              className={cn(css.details, {
                [css._dark]: exhibition.darkListing,
              })}
            >
              <div className={css.artistName}>{exhibition?.artist?.name}</div>
              <div className={css.subtitle}>{nl2br(exhibition?.subtitle)}</div>
            </div>
          </div>
        );
      }

      return (
        <div key={`exhibition-${exhibition?._id}`} className={css.slide}>
          <Link
            to={generatePath(sitemap.exhibition, { slug: exhibition?._id })}
          >
            <img src={exhibition.listingThumb} alt="" />
            <div
              className={cn(css.details, {
                [css._dark]: exhibition.darkListing,
              })}
            >
              <div className={css.artistName}>{exhibition?.artist?.name}</div>
              <div className={css.subtitle}>{nl2br(exhibition?.subtitle)}</div>
            </div>
          </Link>
        </div>
      );
    });

    const CurrentExhibitionSlide = currentExhi?.map((exhibition) => {
      if (exhibition._id === "5eeb9d4a0b1ead4398462058") {
        return (
          <div
            key={`exhibition-${exhibition?._id}`}
            className="banner"
            onClick={() => this.confirmExplicit(exhibition)}
            role="button"
            tabIndex="-1"
          >
            <img
              src={exhibition?.banner ? exhibition?.banner : banner}
              alt=""
            />
          </div>
        );
      }

      return (
        <Link to={generatePath(sitemap.exhibition, { slug: exhibition?._id })}>
          <div className="banner">
            <img
              src={exhibition?.banner ? exhibition?.banner : banner}
              alt=""
            />
          </div>
        </Link>
      );
    });

    const upcomingExhibitionSlides = upcomingExhi?.map((exhibition) => {
      if (exhibition._id === "5eeb9d4a0b1ead4398462058") {
        return (
          <div
            key={`exhibition-${exhibition?._id}`}
            className={css.slide}
            onClick={() => this.confirmExplicit(exhibition)}
            role="button"
            tabIndex="-1"
          >
            <img src={exhibition?.listingThumb} alt="" />
            <div
              className={cn(css.details, {
                [css._dark]: exhibition.darkListing,
              })}
            >
              <div className={css.artistName}>{exhibition?.artist?.name}</div>
              <div className={css.subtitle}>{nl2br(exhibition?.subtitle)}</div>
            </div>
          </div>
        );
      }

      return (
        <div key={`exhibition-${exhibition?._id}`} className={css.slide}>
          <Link
            to={generatePath(sitemap.exhibition, { slug: exhibition?._id })}
          >
            <img src={exhibition?.listingThumb} alt="" />
            <div
              className={cn(css.details, {
                [css._dark]: exhibition.darkListing,
              })}
            >
              <div className={css.artistName}>{exhibition?.artist?.name}</div>
              <div className={css.subtitle}>{nl2br(exhibition?.subtitle)}</div>
            </div>
          </Link>
        </div>
      );
    });

    return (
      <div id="exhibitions" className={css.wrapper}>
        <div className={css.inner}>
          {loading ? (
            <div className={css.loader}>
              <Loading color="red" />
            </div>
          ) : (
            <>
              <div className="exhi_title">
                <div className={css.exhibitionTitle}>
                  <img src={exhiTitle} style={{ height: "44px" }} />
                </div>
                <div className={css.exhibitionBanner}>
                  {CurrentExhibitionSlide?.length > 0 && CurrentExhibitionSlide}
                </div>
              </div>
              <div className={css.sliderWrap}>
                <Tabs>
                  <div className={css.tabHeader}>
                    <TabList className={css.reactTabsTabList}>
                      {previousExhi?.length > 0 && <Tab>Previous</Tab>}
                      {upcomingExhi?.length > 0 && <Tab>Upcoming</Tab>}
                    </TabList>
                  </div>
                  {previousExhi.length > 0 && (
                    <TabPanel>
                      <Breakpoint m>
                        <Slider
                          {...settings}
                          ref={this.slickRef}
                          afterChange={(si) => {
                            this.setState({ slideIndex: si });
                          }}
                        >
                          {previousExhi?.length > 0 ? (
                            previousExhibitionSlides
                          ) : (
                            <div>There is no any previous exhibitions</div>
                          )}
                        </Slider>
                      </Breakpoint>

                      <Breakpoint s>
                        {previousExhi?.length > 0 ? (
                          previousExhibitionSlides
                        ) : (
                          <div>There is no previous exhibitions</div>
                        )}
                      </Breakpoint>
                      {previousExhi?.length > 0 && (
                        <Breakpoint m>
                          <div className={css.sliderBottom}>
                            <div className={css.arrows}>
                              <div
                                className={css.left}
                                role="button"
                                tabIndex="-1"
                                onClick={() => {
                                  this.slickRef.current.slickPrev();
                                }}
                              />
                              <div
                                className={css.right}
                                role="button"
                                tabIndex="-1"
                                onClick={() => {
                                  this.slickRef.current.slickNext();
                                }}
                              />
                            </div>
                            <div className={css.counter}>
                              <span>{Math.ceil((slideIndex + 1) / 2)}</span>/
                              {Math.ceil(previousExhi?.length / 4)}
                            </div>
                          </div>
                        </Breakpoint>
                      )}
                    </TabPanel>
                  )}
                  {upcomingExhi?.length > 0 && (
                    <TabPanel>
                      <Breakpoint m>
                        <Slider
                          {...settings}
                          ref={this.slickRef}
                          afterChange={(si) => {
                            this.setState({ slideIndex: si });
                          }}
                        >
                          {upcomingExhi?.length > 0 ? (
                            upcomingExhibitionSlides
                          ) : (
                            <div>There is no any upcoming exhibitions</div>
                          )}
                        </Slider>
                      </Breakpoint>

                      <Breakpoint s>
                        {upcomingExhi?.length > 0 ? (
                          upcomingExhibitionSlides
                        ) : (
                          <div>There is no any upcoming exhibitions</div>
                        )}
                      </Breakpoint>
                      {upcomingExhi.length > 0 && (
                        <Breakpoint m>
                          <div className={css.sliderBottom}>
                            <div className={css.arrows}>
                              <div
                                className={css.left}
                                role="button"
                                tabIndex="-1"
                                onClick={() => {
                                  this.slickRef.current.slickPrev();
                                }}
                              />
                              <div
                                className={css.right}
                                role="button"
                                tabIndex="-1"
                                onClick={() => {
                                  this.slickRef.current.slickNext();
                                }}
                              />
                            </div>
                            <div className={css.counter}>
                              <span>{Math.ceil((slideIndex + 1) / 2)}</span>/
                              {Math.ceil(upcomingExhi.length / 4)}
                            </div>
                          </div>
                        </Breakpoint>
                      )}
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Exhibitions));
