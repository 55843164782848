import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Breakpoint from "react-socks";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";

import CategorySelectDialog from "./CategorySelectDialog";
import { CONFIG } from "../../../../constants";
import { get, postAuth } from "../../../../services/generalApiServices";
import sitemap from "../../../../routing/siteMap";
import Loading from "../../../Loading";
import AdvancedAvatar from "../../../AdvancedAvatar/AdvancedAvatar";
import Button from "../../../Button";
import store from "../../../../redux";
import { getArtistsByCat } from "../../../../services/artistServices";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import "./InitiateFollows.scss";

class InitiateFollows extends Component {
  state = {
    isLoading: true,
    artists: [],
    categories: [],
    selectedArtists: [],
    updating: false,
    categorySelectDialogOpen: false,
    category_id: "all",
  };

  componentDidMount() {
    // make body unscrollable
    document.body.classList.add("no-scroll");
    this.getCategories();
    this.getArtists();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.category_id !== this.state.category_id) {
      this.getArtists();
    }
  }

  componentWillUnmount() {
    // make body unscrollable
    document.body.classList.remove("no-scroll");
  }

  getCategories = () => {
    get(
      CONFIG.GET_CATEGORIES,
      (categories) => {
        this.setState({
          categories,
        });
      },
      (e) => {
        console.log(e);
      }
    );
  };
  getArtists = () => {
    const { category_id } = this.state;
    getArtistsByCat(
      category_id,
      (response) => {
        const filteredArtists = response.filter((artist) => {
          if (artist.settings) {
            if (artist.settings.private) {
              return false;
            }

            return true;
          }

          return true;
        });
        this.setState({
          artists: filteredArtists,
          isLoading: false,
        });
      },
      (error) => {
        console.error(error);
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleArtistClick = (artistId) => {
    const { selectedArtists } = this.state;
    const newSelection = [...selectedArtists];
    const artistIndex = newSelection.findIndex((id) => id === artistId);
    if (artistIndex < 0) {
      newSelection.push(artistId);
    } else {
      newSelection.splice(artistIndex, 1);
    }
    this.setState({
      selectedArtists: newSelection,
    });
  };

  handleConfirm = () => {
    const { selectedArtists } = this.state;
    if (selectedArtists.length < 1) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please select at least one artist to follow",
          3000
        )
      );
    } else {
      const data = new FormData();
      for (let i = 0; i < selectedArtists.length; i++) {
        data.append("follows[]", selectedArtists[i]);
      }
      this.setState({ updating: true });
      postAuth(
        CONFIG.INITIATE_FOLLOWS,
        () => {
          window.location.reload();
        },
        (e) => {
          console.error(e);
          store.dispatch(
            "error",
            "Something went wrong. Please try again",
            3000
          );
        },
        data
      );
    }
  };

  handleSkip = () => {
    this.setState({ updating: true });
    postAuth(
      CONFIG.INITIATE_FOLLOWS,
      () => {
        window.location.reload();
      },
      (e) => {
        console.error(e);
        store.dispatch("error", "Something went wrong. Please try again", 3000);
      }
    );
  };
  selectCategory = (_id) => {
    this.setState({ category_id: _id });
  };

  render() {
    const {
      isLoading,
      artists,
      categories,
      selectedArtists,
      updating,
      categorySelectDialogOpen,
      category_id,
    } = this.state;
    let activeCategoryName = null;
    if (category_id === "all") {
      activeCategoryName = "All";
    } else {
      activeCategoryName = categories.find((cat) => cat._id === category_id);
      activeCategoryName = activeCategoryName && activeCategoryName.name;
    }

    const artistsList =
      artists.length > 0 &&
      artists.map((item, index) => {
        const artistItemClasses = classnames("artistItem", {
          selected: selectedArtists.includes(item._id),
        });
        return (
          <div
            key={`nav-item-${index}`}
            className={artistItemClasses}
            role="button"
            tabIndex="-1"
            onClick={() => this.handleArtistClick(item._id)}
          >
            <div className="artistInner">
              <AdvancedAvatar
                imgPath={
                  item?.picture &&
                  item?.picture != undefined &&
                  `users/${item?.picture}`
                }
                followers={item?.follows ? item?.follows?.length : null}
                works={item?.products?.length}
                name={item?.name}
                category={item?.info?.categories[0]?.name}
                verified={item?.verifiedArtist}
              />
            </div>
          </div>
        );
      });

    return (
      <div id="InitiateFollowsPage">
        <CategorySelectDialog
          isOpen={categorySelectDialogOpen}
          onClose={() => {
            this.setState({ categorySelectDialogOpen: false });
          }}
          categories={categories}
          selectCategory={this.selectCategory}
          activeCat={activeCategoryName}
        />

        <Breakpoint m>
          <section className="commonSubNavSection addHalfMargin typeCategories">
            <div className="customContainer">
              <div className="sectionContent">
                <div className="subNavItem">
                  <ul>
                    <li>
                      <Link
                        exact
                        to="/"
                        onClick={() => this.selectCategory("all")}
                        className={activeCategoryName === "All" ? "active" : ""}
                      >
                        <span>All</span>
                      </Link>
                    </li>
                    {categories.map((category) => {
                      return (
                        <li key={`follow-cat-${category?._id}`}>
                          <Link
                            exact
                            to={"/"}
                            onClick={() => this.selectCategory(category?._id)}
                            className={
                              category?.name === activeCategoryName
                                ? "active"
                                : ""
                            }
                          >
                            <span>{category?.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </Breakpoint>

        <Breakpoint s>
          <section className="mobileSelectSection">
            <div className="filterContainer">
              <div
                role="button"
                tabIndex="-1"
                className="filterItem"
                onClick={() => {
                  this.setState({ categorySelectDialogOpen: true });
                }}
              >
                <ul>
                  <li>Categories</li>
                  <li>{activeCategoryName}</li>
                </ul>
                <DownIcon />
              </div>
            </div>
          </section>
        </Breakpoint>
        <section className="artistCollectionSection">
          <div className="customContainer">
            <div className="sectionContent">
              <div className="artistCollectionContainer">
                {isLoading ? <Loading color="red" /> : artistsList}
              </div>
            </div>
          </div>
        </section>
        <div className="confirmation-bar">
          <span>Select artists you want to follow</span>
          <Button loading={updating} onClick={this.handleConfirm}>
            Confirm
          </Button>
          <span role="button" tabIndex="-1" onClick={this.handleSkip}>
            Skip
          </span>
        </div>
      </div>
    );
  }
}
InitiateFollows.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any).isRequired
};
export default InitiateFollows;
