import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Image.css";

const Image = ({ src, className, alt, skeletonHeight, skeletonWidth }) => {
  const [loaded, setLoaded] = useState(false);
  const [bucketUrl, SetBucketUrl] = useState(
    "https://storage.googleapis.com/tulikaabucketdev/"
  );

  return (
    <>
      {!loaded && skeletonHeight && !skeletonWidth && (
        <Skeleton
          height={skeletonHeight || 300}
          containerClassName="margin-auto"
        />
      )}
      {!loaded && !skeletonHeight && skeletonWidth && (
        <Skeleton
          width={skeletonWidth || 300}
          containerClassName="margin-auto"
        />
      )}
      {!loaded && skeletonHeight && skeletonWidth && (
        <Skeleton
          height={skeletonHeight || 300}
          width={skeletonWidth || 300}
          containerClassName="margin-auto"
        />
      )}
      <img
        className={className || ""}
        src={`${bucketUrl}${src}`}
        style={{ display: loaded ? "block" : "none" }}
        alt={alt || ""}
        onError={() => {
          SetBucketUrl("https://storage.googleapis.com/tilichobucket/");
        }}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

export default Image;

export const Image1 = ({
  src,
  className,
  alt,
  styleClass,
  lazyLoad = false
}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loaded, setLoaded] = useState(false);
  const [bucketUrl, SetBucketUrl] = useState(
    "https://storage.googleapis.com/tulikaabucketdev/"
  );

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <div style={styleClass ? styleClass : {}}>
      <img
        className={className || ""}
        src={`${bucketUrl}${src}`}
        alt={alt || ""}
        onError={() => {
          SetBucketUrl("https://storage.googleapis.com/tilichobucket/");
        }}
        onLoad={handleImageLoaded.bind(this)}
      />
    </div>
  );
};

export const OverlapImage = ({ items }) => {
  return (
    <div className="overlapImages">
      {items.slice(0, 5).map((item, i) => (
        <div className="overlapImage">
          <Image
            src={
              item?.picture && item?.picture != "undefined"
                ? `users/${item?.picture}`
                : "assets/profile-dummy.png"
            }
            className="overlap-img"
          />
        </div>
      ))}
    </div>
  );
};
