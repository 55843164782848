import {
     ADD_TO_CART,
     UPDATE_CART_ITEM,
     REMOVE_CART_ITEM,
     CLEAR_CART
} from '../constants/action-types';



export const addToCart = (data) => ({

     type: ADD_TO_CART,
     payload: {
          product: data.product_id,
          qty: data.quantity,
          // price: parseInt(data.price.split(' ')[1]),
          price: parseInt(data.price),
          name: data.name,
          image: data.image,
          author: data.author


     },

});

export const removeCartItem = productId => ({
     type: REMOVE_CART_ITEM,
     payload: {
          product: productId
     }
});

export const clearCartItems = () => ({
     type: CLEAR_CART,

});

// export const updateCartItem = (productId, quantity) => ({
//      type: UPDATE_CART_ITEM,
//      payload: {
//           productId,
//           quantity
//      }
// });


