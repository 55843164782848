import React, { useState, useEffect } from "react";
import { Route, Redirect, NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import PropTypes from "prop-types";

import sitemap from "../../../routing/siteMap";
import { isUserArtist } from "../../../utils/userUtils";

import PageHead from "../../PageHead";
import { getUserData } from "../../../services/userServices";
import UserWork from "./UserWork";
import UserFeed from "./UserFeed";
import "./NormalUser.scss";
import ProfileSidebar from "./ProfileSidebar";
import Loading from "../../Loading";

const NormalUser = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { match, history, user } = props;

  useEffect(() => {
    getUserInfo();
  }, [match?.params?.slug]);

 const setFollow=()=>{
  getUserInfo();
  }

  const getUserInfo = () => {
    const slug = match?.params?.slug;
    getUserData(
      slug,
      (response) => {
        setUserInfo(response);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  return (
    <div id="userProfilePage">
      {loading ? (
        <Loading color="red" />
      ) : (
        <div className="commonGidContainer typeHeaderUp">
          <PageHead imgPath={userInfo?.cover && `users/${userInfo?.cover}`} />
          <ProfileSidebar userInfo={userInfo} history={history} setFollow={setFollow} />
          <div className="gridItem mainContent">
            <div className="feedSection">
              {/* <div className="customContainer2 smallWidthLayout2">
                <div className="sectionContent">
                  {!isUserArtist(userInfo?.role) && user?.id === userInfo?._id && (
                    <Link
                      to={generatePath(sitemap.getStudio, {
                        slug: user?.slug,
                      })}
                    >
                      <div className="getFeedTitle">
                        <h3>Get Into Studio</h3>
                      </div>
                    </Link>
                  )}
                </div>
              </div> */}
              <div>
                <div className="user-bar">
                  <div className="sectionContent">
                    <ul>
                      <li>
                        <NavLink
                          to={generatePath(sitemap.userWork, {
                            slug: match.params?.slug,
                          })}
                        >
                          Artworks
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={generatePath(sitemap.userFeed, {
                            slug: match.params?.slug,
                          })}
                        >
                          Feed
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <Route
                  exact
                  path={sitemap.userWork}
                  render={(props) => (
                    <UserWork {...props} userInfo={userInfo} />
                  )}
                />
                <Route
                  exact
                  path={sitemap.userFeed}
                  render={(props) => (
                    <UserFeed {...props} userInfo={userInfo} />
                  )}
                />
                <Redirect
                  to={generatePath(sitemap.userWork, {
                    slug: match.params.slug,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NormalUser;
