import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Cookie from "js-cookie";

import store from "../../redux";
import { showGlobalSnack } from "../../redux/actions/snack.actions";
import { CONFIG } from "../../constants";
import CommonButton from "../Button";
import { postAuth } from "../../services/generalApiServices";

const mapStateToProps = (state) => ({
  user: state.user,
});

class FollowArtist extends Component {
  state = {
    loading: false,
    isFollowing: false,
    requested: false,
  };

  componentDidMount() {
    const { user, artist } = this.props;
    if (user !== null) {
      this.checkRequested();
      const isFollowing =
        user && artist.follows.find((userId) => userId === user.id);
      if (isFollowing) {
        this.setFollowing();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user, artist } = this.props;
    if (prevProps.user !== user) {
      if (user !== null) {
        this.checkRequested();
        const isFollowing =
          user && artist.follows.find((userId) => userId === user.id);
        if (isFollowing) {
          this.setFollowing();
        }
      }
    }
  }

  checkRequested = () => {
    const { artist } = this.props;
    this.setState({ loading: true });
    postAuth(
      CONFIG.CHECK_FOLLOW_REQUEST,
      (message) => {
        if (message === "requested") {
          this.setRequested();
        }
        this.setState({ loading: false });
      },
      () => {
        this.setState({ loading: false });
      },
      {
        artist: artist._id,
      }
    );
  };

  cancelRequest = () => {
    const { artist } = this.props;
    this.setState({ loading: true });
    postAuth(
      CONFIG.CANCEL_FOLLOW_REQUEST,
      () => {
        this.setState({ loading: false, requested: false });
      },
      () => {},
      {
        artist: artist._id,
      }
    );
  };

  setFollowing = (flag = true) => {
    this.setState({ isFollowing: flag });
  };

  setRequested = () => {
    this.setState({ requested: true });
  };

  proceedToLogin = () => {
    const { history } = this.props;
    store.dispatch(showGlobalSnack("normal", "Please Login to follow", 3000));
    history.push("/login");
  };

  followArtist = () => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    const { artist } = this.props;
    if (token) {
      this.setState({ loading: true });
      axios({
        url: CONFIG.FOLLOW_ARTIST,
        method: "POST",
        headers: {
          Authorization: `JWT ${token}`,
        },
        data: {
          artist: artist._id,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            this.setFollowing();
          } else if (response.data.status === "requested") {
            this.setRequested();
          } else if (response.data.status === "followed") {
            showGlobalSnack(
              "normal",
              "You already follow this artist. Please refresh the page again.",
              2000
            );
          } else if (response.data.status === "not-found") {
            showGlobalSnack(
              "normal",
              "An error occurred. Please refresh the page and try again.",
              2000
            );
          } else {
            showGlobalSnack(
              "normal",
              "An unexpected error occurred. Please refresh the page and try again.",
              2000
            );
          }
          this.setState({ loading: false });
        })
        .catch((e) => {
          showGlobalSnack(
            "normal",
            "An unexpected error occurred. Please refresh the page and try again.",
            2000
          );
          this.setState({ loading: false });
          console.log(e.message || e);
        });
    }
  };

  unFollowArtist = () => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    const { artist } = this.props;
    if (token) {
      this.setState({ loading: true });
      axios({
        url: `${CONFIG.API_URL}/secure/unfollowartist`,
        method: "POST",
        headers: {
          Authorization: `JWT ${token}`,
        },
        data: {
          artist: artist._id,
        },
      })
        .then((res) => {
          if (res.data.status === "success") {
            this.setFollowing(false);
          } else {
            showGlobalSnack(
              "normal",
              "An unexpected error occurred. Please refresh the page and try again.",
              2000
            );
          }
          this.setState({ loading: false });
        })
        .catch((e) => {
          showGlobalSnack(
            "normal",
            "An unexpected error occurred. Please refresh the page and try again.",
            2000
          );
          console.log(e.message || e);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { user } = this.props;
    const { isFollowing, loading, requested } = this.state;
    if (!user) {
      return (
        <CommonButton
          size="medium"
          mode="primary"
          onClick={this.proceedToLogin}
        >
          Follow
        </CommonButton>
      );
    }
    if (isFollowing) {
      return (
        <CommonButton
          size="extraLarge"
          mode="following"
          loading={loading}
          onClick={this.unFollowArtist}
        >
          Following
        </CommonButton>
      );
    }
    if (requested) {
      return (
        <CommonButton
          size="extraLarge"
          mode="follow"
          loading={loading}
          onClick={this.cancelRequest}
        >
          Requested
        </CommonButton>
      );
    }
    return (
      <CommonButton
        size="extraLarge"
        mode="follow"
        onClick={this.followArtist}
        loading={loading}
      >
        Follow
      </CommonButton>
    );
  }
}

FollowArtist.propTypes = {
  artist: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

FollowArtist.defaultProps = {
  user: null,
};

export default withRouter(connect(mapStateToProps)(FollowArtist));
