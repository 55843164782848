import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CONFIG } from "../../constants";
import AdvanceAvatarInlineUser from "../AdvancedAvatarInline/AdvanceAvatarInlineUser";
import UserGalleryInline from "../ArtistGalleryInline/UserGalleryInline";
import "./artistShowcase.scss";

const UserShowcase = ({ userInfo, fullWidthGallery,setFollow }) => {
  const image = "assets/profile-dummy.png";
  const showcaseItemClasses = classnames("artistShowcaseItem", {
    "full-width-gallery": fullWidthGallery,
  });

  return (
    <div className={showcaseItemClasses}>
      <AdvanceAvatarInlineUser
        imgPath={
          userInfo?.picture && userInfo?.picture !== "undefined"
            ? `users/${userInfo?.picture}`
            : image
        }
        followers={userInfo?.follows?.length}
        works={userInfo?.totalArtworks}
        name={userInfo?.name}
        category={userInfo?.info?.categories[0]?.name}
        userInfo={userInfo}
        setFollow={setFollow}
      />
      <UserGalleryInline artistId={userInfo._id} />
    </div>
  );
};

UserShowcase.propTypes = {
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidthGallery: PropTypes.bool,
};

UserShowcase.defaultProps = {
  fullWidthGallery: false,
};

export default UserShowcase;
