import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import "./CategorySelectDialog.scss";

const dialogClasses = {
  paper: {
    width: "100%",
    padding: 0,
    borderRadius: 0,
    maxWidth: 300,
  },
};

const CategorySelectDialog = ({
  isOpen,
  onClose,
  classes,
  categories,
  selectCategory,
  activeCat,
}) => {
  const categoryList = categories.map((category) => (
    <li
      key={`gallery-cat-${category?._id}`}
      className={activeCat === category?.name ? "active" : ""}
    >
      <span onClick={() => selectCategory(category?._id)}>{category.name}</span>
    </li>
  ));
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={classes}
    >
      <div id="category-select-dialog-title">
        <span>Categories</span>
        <span className="_close" onClick={onClose} role="button" tabIndex="-1">
          <CloseIcon />
        </span>
      </div>
      <div id="category-select-dialog-message">
        <ul>
          <li className={activeCat === "All" ? "active" : ""}>
            <span onClick={() => selectCategory("all")}>All</span>
          </li>
          {categoryList}
        </ul>
      </div>
    </Dialog>
  );
};

CategorySelectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(dialogClasses)(CategorySelectDialog);
