import React, { Component } from "react";
import { generatePath } from "react-router";
import { Link, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import classnames from "classnames";
import Avatar from "../../../Avatar";
import MessageBox from "./MessageBox";
import Loading from "../../../Loading";
import sitemap from "../../../../routing/siteMap";
import { CONFIG } from "../../../../constants";
import store from "../../../../redux";
import Image from "../../../ImageTag/Image";
import {
  get,
  getAuth,
  getWithAuth,
  postAuth
} from "../../../../services/generalApiServices";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import "./Message.scss";
import { calculateTime } from "../../../../constants/calculateTime";
import Template from "./Template";
import Inbox from "./Inbox";

const mapStateToProps = ({ user, messageSeen }) => ({ user });

class Message extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.participantHandler = this.participantHandler.bind(this);
  }
  state = {
    conversations: [],
    activeConversation: [],
    reciever: null,
    loadingTo: true,
    count: 0,
    roomId: ""
  };

  handler(activeConversation) {
    this.setState({
      activeConversation
    });
  }

  participantHandler(conversations) {
    this.setState({
      conversations
    });
  }

  getMessageSeen = seen => {
    this.setState({
      roomId: seen?.roomId
    });
    // if(seen) this.getConversation()
  };

  componentDidMount() {
    const { match } = this.props;

    if (match.params.to) {
      this.getAllConversationOfCurrentUser();
      // this.getRecieverUser();
      this.getConversation();
    }
  }
  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps?.match?.params?.to !== match?.params?.to) {
      this.getAllConversationOfCurrentUser();
      // this.getRecieverUser();
      this.getConversation();
    }
    if (prevProps.isSeen != this.state.isSeen) {
      this.getAllConversationOfCurrentUser();
      // this.getRecieverUser();
      this.getConversation();
    }
  }

  getAllConversationOfCurrentUser = () => {
    const { match, user } = this.props;

    getAuth(
      `${CONFIG.GET_CONVERSATIONS}?user=${user?.id}`,
      conversations => {
        // console.log("conversations",conversations[0].lastMessage)

        this.setState({
          conversations: conversations,
          loadingTo: false
        });
      },

      e => {
        this.setState({ loadingTo: false });
        console.log(e);
      }
    );
  };

  getRecieverUser = () => {
    const { match, history } = this.props;
    this.setState({
      loadingTo: true
    });
    get(
      generatePath(CONFIG.GET_USER_BY_SLUG, { slug: match?.params?.to }),
      to => {
        this.setState({ reciever: to, loadingTo: false });
      },
      e => {
        console.error(e);
        store.dispatch(showGlobalSnack("error", "Something went wrong", 3000));
        history.push(sitemap.inbox);
      }
    );
  };

  getConversation = () => {
    const { match, user } = this.props;
    this.setState({
      loadingTo: true
    });
    if (user) {
      getAuth(
        generatePath(CONFIG.GET_MESSAGES, {
          roomId: match?.params?.to
        }),
        activeConversation => {
          this.setState({
            activeConversation,
            loadingTo: false
          });
        },
        e => {
          console.error(e);
          this.setState({ loadingTo: false });
          // store.dispatch(showGlobalSnack('error', 'Something went wrong. Please reload and try again.', 3000));
        }
      );
    }
  };

  handleShowConversation = () => {
    this.setState({
      isShowingConversation: true
    });
  };

  handleHideConversation = () => {
    this.setState({
      isShowingConversation: false
    });
  };
  // makeConversationSeen = (id) => {
  //   postAuth(
  //     generatePath(CONFIG.MAKE_CONVERSATION_SEEN, { id }),
  //     () => {},
  //     (e) => {
  //       console.error(e);
  //     }
  //   );
  // };
  makeConversationSeen = seen => {
    // postAuth(
    //   generatePath(CONFIG.MAKE_CONVERSATION_SEEN, { id }),
    //   () => {},
    //   (e) => {
    //     console.error(e);
    //   }
    // );

    this.props.messageSeen(seen);
  };
  refresh = data => {};
  recieveMessages = () => {
    this.getAllConversationOfCurrentUser();
  };
  refreshConversation = () => {
    //  console.log("refresh")

    this.getAllConversationOfCurrentUser();
  };
  render() {
    const {
      conversations,
      activeConversation,
      isShowingConversation,
      reciever,
      loadingTo
    } = this.state;

    const { user } = this.props;
    let lastMessage = {};
    var temp = conversations;
    const activeParticipants = temp?.sort((a, b) => {
      return new Date(b?.updated) - new Date(a?.updated);
    });
    // _.each(
    //     conversations.sort(
    //         (a, b) =>
    //             new Date(b.updated).getTime() -
    //             new Date(a.updated).getTime()
    //     ),
    //     (conversation, i) => {
    //         let unseen = false;
    //         if (!conversation?.seen?.includes(user?.id)) {
    //             unseen = true;
    //         }

    //         const activeParticipant = _.filter(
    //             conversation?.participants,
    //             (participant, index) => {
    //                 if (participant?._id !== user?.id) {
    //                     const allMessages = conversation?.messages;
    //                     if (allMessages.length > 0) {
    //                         lastMessage = allMessages[0];
    //                     } else {
    //                         lastMessage.message = "";
    //                     }
    //                     const messageCheck =
    //                         lastMessage.type === "text" ? "text" : "image";
    //                     const EmptyMessage =
    //                         lastMessage.message === ""
    //                             ? "Empty"
    //                             : "notEmpty";

    //                     activeParticipants.push({
    //                         i,
    //                         index,
    //                         calculateTime: calculateTime(
    //                             conversation?.updated
    //                         ),
    //                         name: participant?.name,
    //                         picture: participant?.picture,
    //                         to: participant?.slug,
    //                         isSeen: unseen,
    //                         isEmpty: EmptyMessage,
    //                         lastMessage,
    //                         messageCheck,
    //                         receiver: reciever?._id,
    //                         conversation,
    //                     });
    //                 }
    //                 return false;
    //             }
    //         );
    //         activeParticipants.push(...activeParticipant);
    //     }
    // );

    const messageBoxClasses = classnames("messageGridItem", "messageBox", {
      "box-showing": isShowingConversation
    });
    return (
      <div id="messagePage">
        <section className="messageSection">
          <div className="customContainer mediumWidthLayout">
            <div className="sectionContent">
              <div className="messageGridItem messageListBox2">
                <div className="title">
                  <h5>Messages</h5>
                </div>

                <div className="content">
                  {activeParticipants.map((data, i) => {
                    return (
                      <>
                        {/* <Template
                                                    index={data?.index}
                                                    calculateTime={
                                                        data?.calculateTime
                                                    }
                                                    name={data?.name}
                                                    picture={data?.picture}
                                                    to={data?.to}
                                                    isSeen={data?.isSeen}
                                                    isEmpty={data?.isEmpty}
                                                    lastMessage={
                                                        data?.lastMessage
                                                    }
                                                    messageCheck={
                                                        data?.messageCheck
                                                    }
                                                    receiver={data?.receiver}
                                                    i={data?.i}
                                                    conversation={
                                                        data?.conversation
                                                    }
                                                    onClick={
                                                        this
                                                            .handleShowConversation
                                                    }
                                                    user={user?.id}
                                                /> */}

                        <Template
                          data={data}
                          user={user}
                          i={i}
                          refreshallConversation={this.getConversation}
                          seenId={this.state.roomId && this.state.roomId}
                          activeParticipants={activeParticipants}
                          messageSeen={this.makeConversationSeen}
                        />
                      </>
                    );
                  })}
                </div>
              </div>

              <div className={messageBoxClasses}>
                {loadingTo ? (
                  <Loading color="red" />
                ) : (
                  <MessageBox
                    {...this.props}
                    // reciever={reciever}
                    sender={user}
                    activeConversation={activeConversation}
                    handler={this.handler}
                    participantHandler={this.participantHandler}
                    refreshallConversation={this.getConversation}
                    // messageSeen={this.state.messageSeen}
                    // messageSeen={messageSeen}
                    getMessageSeen={this.getMessageSeen}
                    refreshMessages={this.getConversation}
                    recieveMessages={this.recieveMessages}
                    refreshConversation={this.refreshConversation}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Message.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps)(Message);
