import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "../Button";
import store from "../../redux";
import { hideDialog } from "../../redux/actions/dialog.actions";
import "./GlobalDialog.scss";

const mapStateToProps = ({ dialog }) => ({
  dialog,
});

const dialogClasses = {
  paper: {
    width: "100%",
    maxWidth:280,
    padding: 20,
  },
};

class GlobalDialog extends Component {
  closeDialog = () => {
    store.dispatch(hideDialog());
  };

  render() {
    const { dialog, classes } = this.props;
    return (
      <Dialog
        open={dialog.confirm !== null}
        onClose={this.closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
      >
        <div id="responsive-dialog-title">{dialog.title}</div>
        <div id="responsive-dialog-message">{dialog.message}</div>
        <div id="responsive-dialog-actions">
          <span
            role="button"
            tabIndex="-1"
            onClick={dialog.confirm}
            mode="special"
          >
            {dialog.yesLabel}
          </span>
          <Button size="auto" onClick={this.closeDialog} mode="specialBlack">
            {dialog.noLabel}
          </Button>
        </div>
      </Dialog>
    );
  }
}

GlobalDialog.propTypes = {
  dialog: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(
  withStyles(dialogClasses)(GlobalDialog)
);
