import axios from "axios";
import { generatePath } from "react-router";
import { CONFIG } from "../constants";
import Cookie from "js-cookie";
import store from "../redux";
import { showGlobalSnack } from "../redux/actions/snack.actions";

export const report = (api, success, fail, data = {}) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  if (token) {
    axios({
      url: api,
      method: "PATCH",
      data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          success(response.data.message);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((e) => {
        fail(e.message);
      });
  } else {
    store.dispatch(showGlobalSnack("error", "Please login first", 3000));
  }
};
