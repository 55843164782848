import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath } from "react-router";

import sitemap from "../../../../routing/siteMap";
import CommonButton from "../../../Button";
import { Input } from "../../../Form";
import { postAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import { getUserData, getYourInfo } from "../../../../services/userServices";
import ProfileSidebar from "../Profile/ProfileSidebar";

import "./ArtistCode.scss";

class ArtistCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      loading: false,
      userInfo: [],
    };
  }
  componentDidMount() {
    getYourInfo(
      (response) => {
        this.setState({ userInfo: response, loading: false });
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  }

  handleSubmit = () => {
    const { history, match } = this.props;
    const { code, userInfo } = this.state;
    const slug = match?.params?.slug;
    this.setState({ loading: true });

    postAuth(
      CONFIG.VALIDATE_CODE,
      () => {
        store.dispatch(
          showGlobalSnack(
            "normal",
            "Artist code verified successfully. You have a studio now.",
            3000
          )
        );
        history.push(generatePath(sitemap.profile, { slug: slug }));
        window.location.reload();
      },
      (e) => {
        console.log(e);
        store.dispatch(
          showGlobalSnack(
            "error",
            "The code you provided could not be verified.",
            3000
          )
        );
        this.setState({ code: "", loading: false });
      },
      {
        code,
      }
    );
  };

  render() {
    const { code, loading, userInfo } = this.state;

    return (
      <div id="artistCodePage">
        <div className="commonGidContainer typeBox">
          {Object.keys(userInfo).length > 0 && (
            <ProfileSidebar userInfo={userInfo} />
          )}
          <div className="gridItem mainContent">
            <section className="artistCodeSection">
              <div className="customContainer smallWidthLayout">
                <div className="sectionContent addFullHeight">
                  <div className="sectionTitle">
                    <h2>Request for a Studio</h2>
                    <p>
                      Enter Artist Code you have received below. You will become
                      artist directly without sending request. If you are having
                      problem with your code send us email at
                      support@tilichokala.com.
                    </p>
                  </div>
                  <div className="formContainer">
                    <div className="fields">
                      <Input
                        required
                        type="text"
                        name="code"
                        placeholder="Enter Code Here"
                        value={code}
                        onChange={(e) =>
                          this.setState({ code: e.target.value })
                        }
                      />
                    </div>
                    <div className="formAction">
                      <CommonButton
                        loading={loading}
                        onClick={this.handleSubmit}
                        size="medium"
                      >
                        Submit
                      </CommonButton>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

ArtistCode.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArtistCode;
