import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import "./crop.scss";

class CropCoverImage extends Component {
  cropImage = () => {
    const { setCroppedImage } = this.props;
    if (this.editor) {
      const canvas = this?.editor?.getImage();
      setCroppedImage(canvas?.toDataURL());
    }
  };

  render() {
    const { image, width, height, zoomValue, zoomIn, zoomOut } = this.props;
    return (
      <>
        <AvatarEditor
          ref={(c) => {
            this.editor = c;
          }}
          //   onMouseUp={this.cropImage}
          onLoadSuccess={this.cropImage}
          onPositionChange={this.cropImage}
          image={image}
          width={500}
          //   height={120}
          border={0}
          color={[0, 0, 0, 0.8]} // RGBA
          scale={zoomValue}
        />
        <div className="scale">
          <ZoomInIcon onClick={zoomIn} style={{ margin: "0 9" }} />
          <ZoomOutIcon onClick={zoomOut} style={{ margin: "0 9" }} />
        </div>
      </>
    );
  }
}

CropCoverImage.propTypes = {
  setCroppedImage: PropTypes.func.isRequired,
  image: PropTypes.instanceOf(new FileReader()).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default CropCoverImage;
