import { styles } from "material-ui-pickers/wrappers/InlineWrapper";
import React from "react";
import { NavLink } from "react-router-dom";
import { Breakpoint } from "react-socks";
import siteMap from "../../../routing/siteMap";

import Logo from "../../Logo/Logo";
import Styles from "./Footer.module.scss";
import SocialMedia from "./SocialMedia";

const Footer = () => (
  <Breakpoint m>
    <footer id={Styles.footerWrapper} className="footerWrapper">
      <div className="customContainer headerLayout">
        <div className={Styles.footerBlock}>
          <div className={Styles.footerItem}>
            <Logo />
            <SocialMedia />
          </div>
          <div className={Styles.footerItem}>
            <ul>
              <li>
                <NavLink to={siteMap.terms}>Terms of Use</NavLink>
              </li>
              <li>
                <NavLink to={siteMap.privacy}>Privacy Policy</NavLink>
              </li>
              {/* <li>Security</li>
              <li>Conditions of Sale</li> */}
              {/* </ul>
            <ul> */}
              <li style={{ fontSize: "12px", marginTop: "2px" }}>
                © {new Date().getFullYear()} Tilicho Kala
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </Breakpoint>
);

export default Footer;
