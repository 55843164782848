//const BACKEND = 'https://anmaritamang.com';
// const BACKEND = "http://localhost:5000";
const BACKEND = "https://tulikaa-api.asterdio.xyz";
// const BACKEND = "https://api.tulikaa.com";

const API_URL = `${BACKEND}/api`;
const AUTH_URL = `${BACKEND}/auth`;
const CHAT_API_BASE_URL = "https://chat.tulikaa.com";
// const CHAT_API_BASE_URL = "https://chat-tulikaa.asterdio.xyz";

export default {
  DEFAULT_SITE_LANGUAGE: "en",
  AUTH_URL: `${BACKEND}/auth`,
  API_URL: `${BACKEND}/api`,
  SOCKET_URL: CHAT_API_BASE_URL,
  // SOCKET_URL: 'http://localhost',
  BUCKET_URL: "https://storage.googleapis.com/tulikaabucketdev",
  GALLERY_PAGE_LIMIT: 15,
  ARTISTS_PAGE_LIMIT: 10,
  ARTISTS_WORK_LIMIT: 12,
  FEED_LIMIT: 10,
  FEED_COMMENTS_LIMIT: 5,
  HOMEPAGE: "https://tulikaa.com",
  CURRENCY: "NPR ",
  AUTH_TOKEN: "tulitoken",
  POST_ATTACHMENT_MB: 5,
  MESSAGE_ATTACHMENT_MB: 2,
  NOTIFICATION_LIMIT: 15,
  FEED_TOP_STUDIOS_LIMIT: 5,
  UPLOAD_ART_IMAGES_LIMIT: 6,
  LANG_COOKIE_NAME: "__tulika__lang__",
  CART_COOKIE_NAME: "__tulika__cart__",
  //Social links
  YOUTUBE_LINK:
    "https://www.youtube.com/channel/UC3tC5PDSuQUGodjc-w81ZFQ/featured",
  FACEBOOK_LINK: "https://www.facebook.com/TulikaaKala",
  INSTAGRAM_LINK: "https://www.instagram.com/tulikaa_kala/",
  //
  // API ENDPOINTS
  GET_TOP_ARTISTS: `${API_URL}/artists/top/all`,
  GET_ALL_ARTISTS: `${API_URL}/users/artists`,
  GET_POSTS_ALL: `${API_URL}/feed/allPosts`,
  GET_POSTS_BY_FOLLOWED: `${API_URL}/feed/posts`,
  GET_ARTIST_BY_CATEGORY: `${API_URL}/artists/category/:cat`,
  GET_USER_BY_SLUG: `${API_URL}/user/getbyslug/:slug`,
  GET_USER_BY_ID: `${API_URL}/user/get/:id`,
  GET_ARTIST_BY_SLUG: `${API_URL}/artist/:slug`,
  GET_USER_SLUG: `${API_URL}/user/:slug`,
  GET_POSTS_BY_ARTIST: `${API_URL}/post/getbyartist/:id/:total`,
  GET_ARTISTS_SORTED: `${API_URL}/artists/sortable`,
  GET_ARTWORK_BY_SLUG: `${API_URL}/product/:slug`,
  GET_ARTWORK_BY_SIMILAR: `${API_URL}/products/similar/:slug`,
  GET_PRODUCTS_BY_USERID: `${API_URL}/user/gallery/:id`,
  GET_PRODUCTS_WITH_IMAGES: `${API_URL}/artist/:artistId/productswithimages`,
  GET_PRODUCTS_BY_CAT_AUTH: `${API_URL}/v2/products/category/:cat`,
  GET_PRODUCTS_BY_CAT: `${API_URL}/products/category/:catID`,
  GET_PRODUCTS_BY_FOLLOWED: `${API_URL}/products/followed`,
  GET_CATEGORIES: `${API_URL}/categories`,
  GET_CATEGORIES_SORTED: `${API_URL}/categories/sorted`,
  GET_NOTIFICATIONS: `${API_URL}/notifications/all`, // auth
  MAKE_NOTIFICATIONS_SEEN: `${API_URL}/notifications/makeseen`, // post (auth),
  GET_POST_COMMENTS: `${API_URL}/v2/post/comments/:postId/:total`,
  MAKE_POST_COMMENT: `${API_URL}/v2/post/comments/new`,
  CLEAR_NOTIFICATIONS: `${API_URL}/notifications/clear`, // post auth
  UPLOAD_ART: `${API_URL}/user/upload`, // post auth //by artist
  UPLOAD_ART_BY_USER: `${API_URL}/artwork/upload`, // post auth //by normal user
  UPDATE_ART: `${API_URL}/user/edit/:id`, // post auth artist
  UPDATE_USER_ART: `${API_URL}/artwork/edit/:id`, //post auth normal user
  DELETE_POST: `${API_URL}/post/delete/:id`, // post auth
  UPDATE_POST: `${API_URL}/post/update/:id`, // post auth multipart
  UPDATE_USER_INFO: `${API_URL}/user/update`, // post auth multipart
  GET_STUDIO: `${API_URL}/users/become-artist`, // post auth multipart
  VALIDATE_CODE: `${API_URL}/users/validatecode`, // post auth
  LIKE_PRODUCT: `${API_URL}/like/product/:productId`,
  FOLLOW_ARTIST: `${API_URL}/secure/followartist`,
  GET_PRODUCTS_BY_IDS: `${API_URL}/products/getbyids`,
  GET_FEATURED_PRODUCTS: `${API_URL}/products/getfeatured`,
  GET_FEATURED_STUDIOS: `${API_URL}/studios/featured`,
  GET_MEDIA_BY_ID: `${API_URL}/mediafile/:id`,
  DELETE_ART: `${API_URL}/user/del/:id`,
  DELETE_USER_ART: `${API_URL}/artwork/delete/:id`,
  GET_STORE: `${API_URL}/store`,
  GET_STORE_BY_CAT: `${API_URL}/store/:catID`,
  GET_STORE_BY_CAT_AUTH: `${API_URL}/user/store/:catID`,
  GET_FOLLOWERS: `${API_URL}/user/follower`,
  GET_FOLLOWING: `${API_URL}/user/following`,
  REMOVE_FOLLOWER: `${API_URL}/user/removeFollower/:id`, //get auth
  UNFOLLOW_ARTIST: `${API_URL}/secure/unfollowartist`,
  GET_USER_ARTWORKS: `${API_URL}/user/artworks/:id`,
  GET_SINGLE_POST: `${API_URL}/feed/post/:id`,
  MAKE_NOTIFICATION_VIEWED: `${API_URL}/notifications/makeviewed`,
  MAKE_ALL_NOTIFICATIONS_VIEWED: `${API_URL}/notifications/makeallviewed`,
  GET_BASIC_DETAILS: `${API_URL}/user/shippingInfo`,
  GET_YOUR_INFO: `${API_URL}/user/profile`,
  GET_YOUR_ARTWORK: `${API_URL}/user/gallery`,

  //like post
  LIKE_POST: `${API_URL}/post/like`,
  // STUDIOS
  GET_STUDIOS_TO_FOLLOW: `${API_URL}/studios/tofollow`,
  GET_RECOMMENDED_STUDIOS: `${API_URL}/studios/recommended`,

  //RECOMMENDED PRODUCTS
  GET_RECOMMENDED: `${API_URL}/products/recommended`,

  //Recommended Post
  GET_RECOMMENDED_POST: `${API_URL}/posts/recommended`,

  //Product View
  PRODUCT_VIEW: `${API_URL}/product/:slug`,

  // MESSAGES
  GET_MESSAGES: `${CHAT_API_BASE_URL}/chat/rooms/:roomId/messages`,
  GET_ACTIVE_MESSAGES: `${API_URL}/messages/active`,
  GET_CONVERSATIONS: `${CHAT_API_BASE_URL}/chat/rooms`,
  CREATE_MESSAGE: `${API_URL}/messages/new`, // post auth
  MAKE_CONVERSATION_SEEN: `${API_URL}/messages/makeseen/:id`, // post auth
  DELETE_CONVERSATION: `${API_URL}/conversation/delete/:id`,
  CREATE_ROOM: `${CHAT_API_BASE_URL}/chat/rooms/init`,
  BLOCK_MESSAGES: `${CHAT_API_BASE_URL}/chat/rooms/:chatRoomId/block`,
  UNBLOCK_MESSAGES: `${CHAT_API_BASE_URL}/chat/rooms/:chatRoomId/unblock`,
  LEAVE_GROUP: `${CHAT_API_BASE_URL}/chat/rooms/:chatRoomId/leave`,

  INITIATE_FOLLOWS: `${API_URL}/user/initiatefollows`, // post auth
  CHECK_FOLLOW_REQUEST: `${API_URL}/user/checkfollow`, // post auth
  APPROVE_FOLLOW: `${API_URL}/user/approvefollow`,
  CANCEL_FOLLOW_REQUEST: `${API_URL}/user/cancelrequest`,

  // VERIFICATION
  VERIFY_CODE: `${AUTH_URL}/verifyemail/:code`, // post
  RESEND_CODE: `${AUTH_URL}/resendverificationemail`, // post

  RESET: `${AUTH_URL}/resetpassword`,

  // SEARCH
  SEARCH: `${API_URL}/search/:term`,

  // ESEWA
  // ESEWA_URL: 'https://uat.esewa.com.np/',
  // PLACE_ORDER: `${API_URL}/users/payment/order` // POST AUTH

  // FB LOGIN
  FB_VERIFY_TOKEN: `${AUTH_URL}/social/fb/check/token`,

  // GOOGLE LOGIN
  GOOGLE_VERIFY_TOKEN: `${AUTH_URL}/social/google/checktoken`,

  // EXHIBITION
  LIST_EXHIBITIONS: `${API_URL}/exhibitions`,
  GET_EXHIBITION: `${API_URL}/exhibitions/:id`,
  GET_CURRENT_EXHIBITION: `${API_URL}/current/exhibitions`,
  GET_PREVIOUS_EXHIBITION: `${API_URL}/previous/exhibitions`,
  GET_UPCOMING_EXHIBITION: `${API_URL}/upcomming/exhibitions`,
  BUY_MESSAGE: `${API_URL}/exhibition/message`,

  // SEND EMAIL AUTH
  SEND_EMAIL: `${API_URL}/sendemail`,
  //SLIDERS
  GET_SLIDERS: `${API_URL}/get-sliders`,
  GET_ART_COMMENTS: `${API_URL}/v2/artwork/comments/:productId/:total`,
  MAKE_ART_COMMENT: `${API_URL}/v2/artwork/comment/new`,
  MAKE_USERART_COMMENT: `${API_URL}/v2/artwork/comment/new`,

  //Cart
  // ADD_TO_CART: `${API_URL}/cart`,
  CART_URL: `${API_URL}/cart`,

  //Place order
  PLACE_ORDER: `${API_URL}/user/order`,

  //Cancel Order
  CANCEL_ORDER: `${API_URL}/user/order/cancel`,
  CANCEL_ORDER_ITEM: `${API_URL}/user/order/itemcancel`,

  //Get liked artwork
  GET_LIKED_ARTWORS: `${API_URL}/artworks/liked`,

  //GET liked artworksofartist
  GEL_ARTIST_LIKED_ARTWORKS: `${API_URL}/artist/:slug`,

  //GET report options
  GET_REPORT_OPTIONS: `${API_URL}/report/categories`,

  //Report
  REPORT_PRODUCT: `${API_URL}/report/product/:id`,
  REPORT_FEED: `${API_URL}/report/feed/:id`,
  REPORT_USER: `${API_URL}/report/user/:id`,

  //Block Messages
  // BLOCK_MESSAGES: `${API_URL}/conversation/block/:id`,

  //Block User
  BLOCK_USER: `${API_URL}/block/user/:id `,

  //Unblock Messages
  // UNBLOCK_MESSAGES: `${API_URL}/conversation/unblock/:id`,

  //Unblock User
  UNBLOCK_USER: `${API_URL}/unblock/user/:id`,

  //BlockList
  GET_BLOCK_LIST: `${API_URL}/blocklist`,

  //HidePost
  HIDE_POST: `${API_URL}/post/hide/:postId`
};
