import React, { Component } from "react";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { post } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import sitemap from "../../../../routing/siteMap";
import Loading from "../../../Loading";
import EditArtworkForm from "./EditArtworkForm";

const mapStateToProps = ({ user }) => ({ user });

class EditArtwork extends Component {
  state = {
    art: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getArt();
  }

  getArt = () => {
    const { match, history, user } = this.props;
    if (user) {
      post(
        generatePath(CONFIG.GET_ARTWORK_BY_SLUG, { slug: match?.params?.slug }),
        (art) => {
          if (art?.author?.id === user?.id) {
            this.setState({ art });
          } else {
            throw new Error("Not owner");
          }
        },
        (e) => {
          console.log(e);
          history.push(
            generatePath(sitemap.artWork, { slug: match.params.slug })
          );
        }
      );
    }
  };

  render() {
    const { art } = this.state;
    const { isUserRefreshInProgress, history, user, match } = this.props;
    if (isUserRefreshInProgress === false && user === null) {
      history.push(
        generatePath(sitemap.artWork, { slug: match?.params?.slug })
      );
    }
    return (
      <>
        {art === null ? <Loading color="red" /> : <EditArtworkForm art={art} />}
      </>
    );
  }
}

EditArtwork.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  isUserRefreshInProgress: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(EditArtwork);
