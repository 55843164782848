import React, { Component } from "react";
import { string } from "prop-types";
import classnames from "classnames";
import anime from "animejs";
import { CSSTransition } from "react-transition-group";
import ReactPlayer from "react-player";
import VolumeOnIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { connect } from "react-redux";
import withSizes from "react-sizes";

import { showGlobalSnack } from "../../redux/actions/snack.actions";
import { CONFIG } from "../../constants";
import { postPromise } from "../../services/generalApiServices";
import EmailModal from "./EmailModal";
import ExcerptModal from "./ExcerptModal";
import ImageModal from "./ImageModal";
import DetailModal from "./DetailModal";
import css from "./exhibitionSlider.module.scss";

const mapSizesToProps = ({ width, height }) => ({ winWidth: width, height });

class ExhibitionSlider extends Component {
  state = {
    loading: true,
    ready: false,
    position: 0,
    imageModalVisible: -1,
    detailsModalVisible: -1,
    mediaPlaying: true,
    infoModalVisible: false,
    emailModalVisible: -1,
    sendingEmail: false,
  };

  wrapperRef = React.createRef();

  trackRef = React.createRef();

  bgRef = React.createRef();

  backgroundRef = React.createRef();

  itemsRef = [];

  wrapperWidth = null;

  animating = false;

  trackOffset = 0;

  componentDidMount() {
    this.wrapperWidth = this.wrapperRef.current.getBoundingClientRect().width;
    this.trackOffset = this.wrapperWidth;
    document.addEventListener("keydown", this.listenToKeyboard);
    this.animateSlider(0);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listenToKeyboard);
  }

  listenToKeyboard = (e) => {
    const { position, imageModalVisible, detailsModalVisible } = this.state;

    if (detailsModalVisible || imageModalVisible !== -1) return false;
    if (e.keyCode === 39) this.animateSlider(position + 1);
    if (e.keyCode === 37) this.animateSlider(position - 1);
  };

  animateSlider = (pos) => {
    const { exhibition, winWidth } = this.props;
    if (this.animating || pos < 0 || pos > exhibition.items.length - 1)
      return false;

    let itemOffsetFromTrack = 0;

    for (let i = 0; i <= pos; i++) {
      if (i === 0) {
        itemOffsetFromTrack += Number(exhibition.items[i].margins.left);
      } else {
        itemOffsetFromTrack += this.itemsRef[i - 1].getBoundingClientRect()
          .width;
        itemOffsetFromTrack += Number(exhibition.items[i - 1].margins.right);
        itemOffsetFromTrack += Number(exhibition.items[i].margins.left);
      }
    }

    const itemWidth = this.itemsRef[pos].getBoundingClientRect().width;

    const offset = this.wrapperWidth / 2 - itemOffsetFromTrack - itemWidth / 2;

    if (exhibition.parallaxBackground) {
      anime({
        targets: this.bgRef.current,
        left: `${offset}px`,
        easing: "easeInOutSine",
        duration: 1000,
        begin: () => {},
        complete: () => {},
      });
    }

    anime({
      targets: this.trackRef.current,
      translateX: {
        value: [`${this.trackOffset}px`, `${offset}px`],
        duration: 1000,
      },
      translateY: 0,
      translateZ: 0,
      easing: "easeInOutSine",
      duration: 1000,
      begin: () => {
        this.resetDistance();
        this.animating = true;
        this.setState({ ready: false });
      },
      complete: () => {
        this.animating = false;
        this.trackOffset = offset;
        this.setState({ ready: true, position: pos }, () => {
          // this.distance();
        });
      },
    });

    return true;
  };

  distance = () => {
    const { position } = this.state;

    const itemsClone = [...this.itemsRef];
    const itemsToTheRight = itemsClone.splice(position + 1);
    itemsClone.pop();
    const itemsToTheLeft = itemsClone; // remaining items

    anime({
      targets: itemsToTheRight,
      translateX: "+15px",
      translateY: 0,
      translateZ: 0,
      easing: "easeInOutSine",
      duration: 750,
      begin: () => {},
      complete: () => {},
    });

    anime({
      targets: itemsToTheLeft,
      translateX: "-15px",
      translateY: 0,
      translateZ: 0,
      easing: "easeInOutSine",
      duration: 750,
      begin: () => {},
      complete: () => {},
    });
  };

  resetDistance = () => {
    anime({
      targets: this.itemsRef,
      translateX: 0,
      translateY: 0,
      translateZ: 0,
      easing: "easeInOutSine",
      duration: 1000,
      begin: () => {},
      complete: () => {},
    });
  };

  sendEmail = (details, itemIndex) => {
    const { dispatch, exhibition } = this.props;
    details.art = exhibition?.items[itemIndex]?.title;
    details.artist = exhibition?.artist?.name;
    details.artImage = exhibition?.items[itemIndex]?.imageMain;
    this.setState({ sendingEmail: true });
    postPromise(CONFIG.SEND_EMAIL, details)
      .then(() => {
        this.setState({ emailModalVisible: -1, sendingEmail: false });
        dispatch(
          showGlobalSnack(
            "normal",
            "Message Received Successfully! We will get back to you as soon as possible",
            3000
          )
        );
      })
      .catch((e) => {
        console.log(e);
        this.setState({ emailModalVisible: -1, sendingEmail: false });
        dispatch(
          showGlobalSnack(
            "error",
            "An error has occurred, please try again",
            3000
          )
        );
      });
  };

  render() {
    const {
      loading,
      ready,
      position,
      imageModalVisible,
      detailsModalVisible,
      mediaPlaying,
      infoModalVisible,
      emailModalVisible,
      sendingEmail,
    } = this.state;
    const { background, exhibition, winWidth, height } = this.props;

    // mobile 100 vh fix
    document
      .querySelector(":root")
      .style.setProperty("--vh", window.innerHeight / 100 + "px");

    const trackOffset = this.itemsRef[0]
      ? this.wrapperWidth / 2 -
        Number(exhibition.items[0].margins.left) -
        this.itemsRef[0].getBoundingClientRect().width / 2
      : 0;

    const items = exhibition.items.map((item, index) => {
      const styles = {
        marginLeft: Number(item.margins.left),
        marginRight: Number(item.margins.right),
      };

      return (
        <div
          className={css.slideWrap}
          style={styles}
          key={`exh-slider-item-${index}`}
          ref={(r) => {
            this.itemsRef[index] = r;
            return true;
          }}
        >
          <img
            src={item.thumb}
            alt=""
            role="button"
            tabIndex="-1"
            onClick={() => this.setState({ detailsModalVisible: index })}
          />
        </div>
      );
    });

    const imageModalItems = exhibition?.items?.map((item, index) => (
      <CSSTransition
        key={`image-modal-${index}`}
        in={imageModalVisible === index}
        unmountOnExit
        timeout={200}
        classNames="global-fade"
      >
        <div>
          <ImageModal
            image={item.imageMain}
            onClose={() => this.setState({ imageModalVisible: -1 })}
          />
        </div>
      </CSSTransition>
    ));

    const detailModalItems = exhibition?.items?.map((item, index) => (
      <>
        <CSSTransition
          key={`detail-modal-${index}`}
          in={detailsModalVisible === index}
          unmountOnExit
          timeout={200}
          classNames="global-fade"
        >
          <div>
            <DetailModal
              onImageOpen={() => {
                this.setState({
                  detailsModalVisible: -1,
                  imageModalVisible: index,
                });
              }}
              item={item}
              onClose={() => this.setState({ detailsModalVisible: -1 })}
              onBuy={() =>
                this.setState({
                  emailModalVisible: index,
                  detailsModalVisible: -1,
                })
              }
            />
          </div>
        </CSSTransition>
        <CSSTransition
          in={emailModalVisible === index}
          unmountOnExit
          timeout={200}
          classNames="global-fade"
        >
          <div>
            <EmailModal
              loading={sendingEmail}
              onClose={() => this.setState({ emailModalVisible: -1 })}
              onSubmit={(details) => this.sendEmail(details, index)}
            />
          </div>
        </CSSTransition>
      </>
    ));

    return (
      <div className={css.view} ref={this.wrapperRef}>
        {imageModalItems}
        {detailModalItems}
        <CSSTransition
          in={infoModalVisible}
          unmountOnExit
          timeout={200}
          classNames="global-fade"
        >
          <div>
            <ExcerptModal
              artist={exhibition?.artist?.name}
              content={exhibition?.excerpt}
              onClose={() => this.setState({ infoModalVisible: false })}
            />
          </div>
        </CSSTransition>

        {/* <div className={css.offseter} style={{ width: trackOffset, backgroundImage: `url(${background})` }}/> */}
        <div className={css.sliderView}>
          <div className={css.buttons}>
            {/* <span
              role="button"
              tabIndex="-1"
              onClick={() => this.setState({ emailModalVisible: true })}
              style={{ fontSize: 35 }}
            >
              <ChatIcon/>
            </span> */}
            {/* <span
              role="button"
              tabIndex="-1"
              onClick={() => this.setState({ infoModalVisible: true })}
              style={{ fontSize: 35 }}
            >
              <AiOutlineInfo/>
            </span> */}
            {!!exhibition.mediaUrl && (
              <>
                <span
                  className={css.volumeButton}
                  role="button"
                  tabIndex="-1"
                  onClick={() => this.setState({ mediaPlaying: !mediaPlaying })}
                >
                  {mediaPlaying ? <VolumeOnIcon /> : <VolumeOffIcon />}
                </span>
                <div style={{ display: "none" }}>
                  <ReactPlayer
                    url={exhibition?.mediaUrl}
                    playing={mediaPlaying}
                    loop
                  />
                </div>
              </>
            )}
          </div>
          <div
            className={classnames(css.wrapper, { [css.ready]: ready })}
            style={{ backgroundColor: exhibition.backgroundColor }}
          >
            <div
              className={classnames(css.bg, {
                _parallax: exhibition.parallaxBackground,
              })}
              style={{
                backgroundImage: `url("${background}")`,
              }}
              ref={this.bgRef}
            />
            {/* <div className={css.bgImageDiv} ref={this.bgRef}>
              <img src={background} alt=""/>
              <img src={background} alt="" className={css.bgImageAfter}/>
            </div> */}
            <div
              className={css.track}
              ref={this.trackRef}
              id={`ex-slide-${exhibition?._id}`}
            >
              {items}
            </div>
          </div>
          <div className={css.details}>
            <div className={css.controls}>
              <div className={css.container}>
                <div
                  className={css.left}
                  role="button"
                  tabIndex="-1"
                  onClick={() => this.animateSlider(position - 1)}
                />
                <div className={css.title}>
                  <div className={css.name}>
                    {exhibition?.items[position]?.title}
                  </div>
                  <span className={css.clickInfo}>
                    click on the image for more info
                  </span>
                </div>
                <div
                  className={css.right}
                  role="button"
                  tabIndex="-1"
                  onClick={() => this.animateSlider(position + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ExhibitionSlider.propTypes = {
  background: string.isRequired,
};

export default connect()(withSizes(mapSizesToProps)(ExhibitionSlider));
