import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";

import sitemap from "../../routing/siteMap";
import Avatar from "../Avatar";
import Loading from "../Loading";
import { get, postAuth } from "../../services/generalApiServices";
import { CONFIG } from "../../constants";
import "./CommentOnArt.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});

class CommentOnUserArt extends Component {
  state = {
    comment: "",
    comments: [],
    commenting: false,
    total: 2,
    loadingComments: true,
    isCommmentsTruncated: true,
  };

  componentDidMount() {
    this.getComments();
  }

  getComments = () => {
    const { item } = this.props;
    const { total } = this.state;
    get(
      generatePath(CONFIG.GET_ART_COMMENTS, { productId: item._id, total }),
      (product) => {
        this.setState({ comments: product.comments, loadingComments: false });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handleNewComment = (e) => {
    e.preventDefault();
    const { comment } = this.state;
    const { item } = this.props;
    this.setState({ commenting: true });
    postAuth(
      CONFIG.MAKE_USERART_COMMENT,
      () => {
        this.setState({ commenting: false, comment: "" });
        this.getComments();
      },
      (err) => {
        console.error(err);
        this.setState({ commenting: false, comment: "" });
      },
      {
        comment,
        product: item._id,
      }
    );
  };

  showAllComments = () => {
    this.setState({
      total: 0,
    });
  };

  hideComments = () => {
    this.setState({ total: 2 });
  };
  render() {
    const { user } = this.props;

    const {
      comments,
      comment,
      commenting,
      loadingComments,
      total,
      isCommmentsTruncated,
    } = this.state;
    const commentsNewFirst = comments.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });

    const truncatedComments = [];
    commentsNewFirst.forEach((commentItem, i) => {
      if (total === 0 || i < total) {
        truncatedComments.push(commentItem);
      }
    });

    const defaultImage = "assets/profile-dummy.png";

    const commentsList = truncatedComments.map((commentItem) => {
      const avatarPath =
        commentItem?.author?.picture &&
        commentItem?.author?.picture !== undefined
          ? `users/${commentItem.author && commentItem?.author?.picture}`
          : defaultImage;
      return (
        <div
          className="commonCommentItem"
          key={`art-comment-${commentItem._id}`}
        >
          <Avatar imgPath={avatarPath} />
          <div className="commentField">
            <h5>{commentItem?.author?.name}</h5>
            <div className="inner" style={{ marginLeft: "10px" }}>
              {commentItem?.comment}
              <span
                style={{
                  marginLeft: "7px",
                  fontSize: "12px",
                  color: "#BBBBBB",
                }}
              >
                {moment(commentItem?.created).fromNow()}
              </span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="comments-wrap">
        {
          <div className="comments">
            <div className="commonCommentContainer">
              {loadingComments ? <Loading color="red" /> : commentsList}
            </div>
            {isCommmentsTruncated &&
            comments?.length > truncatedComments.length ? (
              <div
                className="commentsViewMore"
                onClick={this.showAllComments}
                role="button"
                tabIndex="-1"
              >
                {/* <p className="coSmallText">View More Comments <DownArrow /></p> */}
                <p className="coSmallText">
                  View All Comments ({comments.length})
                </p>
              </div>
            ) : (
              ""
            )}
            {this.state.total === 0 && (
              <div
                className="commentsViewMore"
                onClick={this.hideComments}
                role="button"
                tabIndex="-1"
              >
                Show Less
              </div>
            )}
          </div>
        }

        {user ? (
          <div className="comments newComments">
            <div className="commonCommentContainer">
              <div className="commonCommentItem">
                <div className="commentField">
                  <div className="inner">
                    <form
                      onSubmit={(e) => {
                        this.handleNewComment(e);
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Add a comment..."
                        value={comment}
                        disabled={commenting}
                        onChange={(e) => {
                          this.setState({ comment: e.target.value });
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="not-logged-in-comment">
            <Link to={sitemap.login}>Write a comment</Link>
          </div>
        )}
      </div>
    );
  }
}

CommentOnUserArt.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

CommentOnUserArt.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(CommentOnUserArt);
