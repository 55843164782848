import React, { Component } from 'react';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import store from '../../redux';
import { setLang } from '../../redux/actions/locale.actions';

const mapStateToProps = ({ locale }) => ({ locale });

class LanguageSwitcher extends Component {
  handleLanguageChange = (lang) => {
    store.dispatch(setLang(lang));
  }

  render() {
    const { locale } = this.props;

    return (
      <div className="languageContainer">
        <ul>
          <li>
            <span>
              {
                locale.lang === 'en' && 'EN'
              }
              {
                locale.lang === 'ne' && 'NP'
              }
              {
                locale.lang === 'zh' && 'ZH'
              }
              <DownIcon/>
            </span>
            <ul>
              <li role="button" onClick={() => this.handleLanguageChange('ne')}>Nepali</li>
              <li role="button" onClick={() => this.handleLanguageChange('en')}>English</li>
              <li role="button" onClick={() => this.handleLanguageChange('zh')}>Chinese</li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

LanguageSwitcher.propTypes = {
  locale: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps)(LanguageSwitcher);
