import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import sitemap from "../../routing/siteMap";
import { get, getWithParamsRaw } from "../../services/generalApiServices";
import { getThumbnail } from "../../utils/mediaUtils";
import ContentScroller from "../ContentScroller";
import Loading from "../Loading";
import { CONFIG } from "../../constants";
import "./ArtistGalleryInline.scss";
import Image from "../ImageTag/Image";

class UserGalleryInline extends Component {
  state = {
    products: [],
    loading: true
  };

  componentDidMount() {
    const { artistId } = this.props;

    // getWithParamsRaw(
    //   CONFIG.GET_PRODUCTS_BY_USERID,
    //   (products) => {
    //     this.setState({ products, loading: false });
    //   },
    //   (error) => {
    //     console.error(error);
    //     this.setState({ loading: false });
    //   }, { id: artistId }
    // );

    get(
      generatePath(CONFIG.GET_USER_ARTWORKS, { id: artistId }),
      products => {
        this.setState({ products, loading: false });
      },
      error => {
        console.error(error);
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { loading, products } = this.state;
    const artistGalleryImages = products.map(product => {
      const thumbnail = getThumbnail(product.image, "productImage");
      return (
        <div className="artist-gallery-inline-image" key={product.slug}>
          <p>hi</p>
          <Link to={generatePath(sitemap.userArt, { slug: product.slug })}>
            <Image
              src={thumbnail.url}
              width={thumbnail.width}
              height={thumbnail.height}
              alt={product.name}
            />
          </Link>
        </div>
      );
    });
    return (
      <div className="artist-gallery-inline">
        {loading ? (
          <Loading color="red" />
        ) : (
          <ContentScroller arrowPosition="around">
            {artistGalleryImages}
          </ContentScroller>
        )}
      </div>
    );
  }
}

UserGalleryInline.propTypes = {
  artistId: PropTypes.string.isRequired
};

export default UserGalleryInline;
