import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CONFIG } from "../../constants";
import AdvancedAvatarInline from "../AdvancedAvatarInline";
import ArtistGalleryInline from "../ArtistGalleryInline";
import "./artistShowcase.scss";

const ArtistShowcase = ({ artist, fullWidthGallery }) => {
  const image = "assets/profile-dummy.png";
  const showcaseItemClasses = classnames("artistShowcaseItem", {
    "full-width-gallery": fullWidthGallery,
  });
  return (
    <div className={showcaseItemClasses}>
      <AdvancedAvatarInline
        imgPath={
          artist?.picture && artist?.picture != "undefined"
            ? `users/${artist.picture}`
            : image
        }
        followers={artist?.follows?.length}
        works={artist?.products?.length}
        name={artist?.name}
        category={artist?.info?.categories[0]?.name}
        verified={artist?.verifiedArtist}
        artist={artist}
      />
      <ArtistGalleryInline artistId={artist._id} />
    </div>
  );
};

ArtistShowcase.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidthGallery: PropTypes.bool,
};

ArtistShowcase.defaultProps = {
  fullWidthGallery: false,
};

export default ArtistShowcase;
