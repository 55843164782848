import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import ThreeDots from "../ThreeDots";

/**
 * Reusable Button Component
 * @visibleName Common Button
 */

const buttonStyles = {
  button: {
    borderRadius: 30,
    fontFamily: "Roboto",
    fontSize: 12,
    height: 34,
    lineHeight: 1,
    textTransform: "none",
  },
  buttonAuto: {
    width: "auto",
  },
  buttonMedium: {
    width: 130,
  },
  buttonLarge: {
    width: 160,
    height: 36,
  },
  buttonExtraLarge: {
    width: 300,
    padding: "20px 0",
    fontSize: "14px",
    fontFamily: "roboto",
    fontWeight: "bold",
  },
  buttonFull: {
    borderRadius: 6,
    width: "100%",
    height: 50,
    fontSize: 16,
  },
  buttonChat: {
    width: 100,
    height: 38,
  },

  buttonPlain: {
    background: "#fff",
    color: "#BBBBBB",
    border: 0,
  },

  profileButton: {
    background: "#fff",
    color: "#BBBBBB",
    border: "1px solid #BBBBBB",
    borderRadius: "5px",
    outline: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  followButton: {
    background: "#984B48;",
    color: "#FFFFFF",
    borderRadius: "5px",
    outline: "none",
    "&:hover": {
      backgroundColor: "rgb(106, 52, 50)",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  followingButton: {
    background: "#9A9A9A",
    color: "#FFFFFF",
    borderRadius: "5px",
    outline: "none",
    "&:hover": {
      backgroundColor: "rgb(106, 52, 50)",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  reportSubmit: {
    background: "#984B48;",
    color: "#FFFFFF",
  },
  buttonWhite: {
    background: "#fff",
    color: "#212121",
    border: "1px solid #E8E8E8",
  },
  buttonBlack: {
    background: "#212121",
    color: "#fff",
  },
  loading: {
    cursor: "not-allowed",
  },

  "@media (max-width: 1600px)": {
    button: {
      height: 32,
    },
    buttonMedium: {
      width: 110,
    },
    buttonFull: {
      height: 45,
    },
  },

  "@media (max-width: 1199px)": {
    button: {
      fontSize: 14,
    },
    buttonFull: {
      height: 42,
    },
  },

  "@media (max-width: 667px)": {
    button: {
      height: 30,
      fontSize: 10,
    },
    buttonMedium: {
      width: 104,
    },
    buttonExtraLarge: {
      width: 100,
      padding: "15px 0",
      fontSize: "12px",
    },
    buttonFull: {
      height: 42,
      fontSize: 12,
    },
    buttonChat: {
      width: 70,
      height: 33,
    },
  },
};

const CommonButton = ({
  children,
  type,
  classes,
  size,
  mode,
  loading,
  disabled,
  onClick,
}) => {
  const buttonClasses = classnames({
    [classes.button]: true,
    [classes.buttonAuto]: size === "auto",
    [classes.buttonMedium]: size === "medium",
    [classes.buttonLarge]: size === "large",
    [classes.buttonExtraLarge]: size === "extraLarge",
    [classes.buttonFull]: size === "full",
    [classes.buttonChat]: size === "chat",
    [classes.buttonPlain]: mode === "plain",
    [classes.buttonWhite]: mode === "special",
    [classes.buttonBlack]: mode === "specialBlack",
    [classes.profileButton]: mode === "profile",
    [classes.followButton]: mode === "follow",
    [classes.followingButton]: mode === "following",
    [classes.reportSubmit]: mode === "reportSubmit",
    [classes.loading]: loading,
  });
  const buttonType = mode === "special" || "specialBlack" ? "inherit" : mode;
  if (loading) {
    return (
      <Button
        type="button"
        variant="contained"
        color={buttonType}
        className={buttonClasses}
      >
        <ThreeDots variant="flashing" />
      </Button>
    );
  }
  return (
    <Button
      onClick={onClick}
      type={type}
      disabled={disabled}
      variant="contained"
      color={buttonType}
      className={buttonClasses}
    >
      {children}
    </Button>
  );
};

CommonButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.oneOf(["auto", "medium", "large", "full", "extraLarge"]),
  mode: PropTypes.oneOf([
    "primary",
    "secondary",
    "special",
    "specialBlack",
    "profile",
    "follow",
    "following",
    "reportSubmit",
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  onClick: PropTypes.func,
};

CommonButton.defaultProps = {
  children: "",
  mode: "primary",
  loading: false,
  disabled: false,
  type: "button",
  size: "auto",
  onClick: () => {},
};

export default withStyles(buttonStyles)(CommonButton);
