import React, { Component } from 'react';
import { FaFacebookF,FaYoutube, FaInstagram} from "react-icons/fa";
import { IconContext } from "react-icons";
import { CONFIG } from '../../../constants';
import Styles from './Footer.module.scss';

 
export class SocialMedia extends Component {
    render() {
        const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          }
        return (
            <IconContext.Provider value={{ color: "white", className: "social-Icons"  }}>
            <div>   
                <FaFacebookF className={Styles.followSocialIcons} onClick={() => openInNewTab(CONFIG.FACEBOOK_LINK)}/>
                <FaInstagram className={Styles.followSocialIcons} onClick={() => openInNewTab(CONFIG.INSTAGRAM_LINK)}/>
                <FaYoutube  className={Styles.followSocialIcons} onClick={() => openInNewTab(CONFIG.YOUTUBE_LINK)} />
                
            </div>
            </IconContext.Provider>
            
        )
    }
}

export default SocialMedia;
