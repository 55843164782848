import Cookie from 'js-cookie';

import { USER_LOGIN, USER_LOGOUT } from '../constants/action-types';
import { CONFIG } from '../../constants';

const initState = null;

export default (state = initState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      Cookie.set(CONFIG.AUTH_TOKEN, action.payload.token, { expires: 1, path: '/' });
      return {
        ...action.payload.user
      };
    case USER_LOGOUT:
      Cookie.remove(CONFIG.AUTH_TOKEN, { path: '/' });
      return null;
    default:
      return state;
  }
};
