import React from 'react';
import Slider from 'react-slick';
import { arrayOf, any, number } from 'prop-types';

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autolay: true,
  fade: true,
  dots: false,
  arrows: false
};

const MobileBanner = ({ slides, ratio, winWidth }) => {
  const slideHeight = Math.floor(winWidth / ratio);
  const slideList = slides.map((slide, index) => (
    <div>
      <div
        key={`home-slide-mobile-${index}`}
        className="slide test"
        style={{
          background: `url("${slide.imageMain}") center center`,
          width: '100%',
          height: slideHeight,
          backgroundSize: 'cover'
        }}
      />
    </div>
  ));

  return (
    <Slider {...settings}>
      {slideList}
    </Slider>
  );
};

MobileBanner.propTypes = {
  slides: arrayOf(any).isRequired,
  ratio: number.isRequired,
  winWidth: number.isRequired
};

export default MobileBanner;
