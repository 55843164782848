import React, { Component } from 'react';
import axios from 'axios';
import { objectOf, any } from 'prop-types';

import { CONFIG } from '../../../constants';
import sitemap from '../../../routing/siteMap';
import store from '../../../redux';
import { showGlobalSnack } from '../../../redux/actions/snack.actions';
import Button from '../../Button';

class ResetPasswordSubmit extends Component {
  state = {
    password: '',
    confirmPassword: '',
    loading: false,
    errors: []
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    const { match, history } = this.props;

    if (password === '' || confirmPassword !== password) {
      store.dispatch(showGlobalSnack('error', 'Please check your password', 3000));
      return;
    }

    this.setState({ loading: true, errors: [] });
    axios.post(`${CONFIG.RESET}/${match.params.token}`, {
      password,
      'confirm-password': confirmPassword
    })
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            loading: false
          });
          store.dispatch(showGlobalSnack('normal', 'Your password has been changed', 3000));
          history.push(sitemap.login);
        } else if (response.data.status === 'not-found') {
          this.setState({
            loading: false,
            password: '',
            confirmPassword: ''
          });
          store.dispatch(showGlobalSnack('error', 'Your token is invalid or expired. Please try again', 3000));
        } else if (response.data.status === 'validate-error') {
          this.setState({
            loading: false,
            password: '',
            confirmPassword: '',
            errors: response.data.message
          });
          store.dispatch(showGlobalSnack('error', 'Validation errors occurred. Please try again', 3000));
        } else {
          this.setState({
            loading: false,
            password: '',
            confirmPassword: ''
          });
          store.dispatch(showGlobalSnack('error', 'Unexpected error. Please try again', 3000));
        }
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(showGlobalSnack('error', 'Unexpected error. Please try again', 3000));
        this.setState({
          loading: false,
          password: '',
          confirmPassword: ''
        });
      });
  }

  render() {
    const { password, confirmPassword, loading, errors } = this.state;

    return (
      <div id="login-page">
        <section className="contentSection">
          <div className="customContainer headerLayout ">
            <div className="sectionContent addFullHeight">
              <div className="customTabContent">
                <div className="title">
                  <h2>Reset Password</h2>
                </div>

                <div className="content">
                  <div className="customForm">
                    <form onSubmit={this.handleSubmit}>
                      <div className="fields">
                        <div className="formGroup">
                          <input
                            type="password"
                            placeholder="New Password"
                            name="password"
                            value={password}
                            onChange={e => this.setState({ password: e.target.value })}
                          />
                        </div>
                        <div className="formGroup">
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={e => this.setState({ confirmPassword: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="formAction">
                        <Button loading={loading} type="submit" size="full" mode="specialBlack">Submit</Button>
                      </div>
                    </form>
                    {
                      errors.length > 0 && (
                        <div className="formErrors">
                          {
                            errors.map((error, i) => (
                              <small key={`form-error-${i}`}>{error}</small>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    );
  }
}

ResetPasswordSubmit.propTypes = {
  match: objectOf(any).isRequired,
  history: objectOf(any).isRequired
};

export default ResetPasswordSubmit;
