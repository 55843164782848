import React, { Component } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Template from './Template';
import Loading from '../../../Loading';
import sitemap from '../../../../routing/siteMap';
import { CONFIG } from '../../../../constants';
import { getAuth, postAuth } from '../../../../services/generalApiServices';
import { showGlobalSnack } from '../../../../redux/actions/snack.actions';
import Image from '../../../ImageTag/Image';
import './Message.scss';
import { calculateTime } from '../../../../constants/calculateTime';

const mapStateToProps = ({ user,messageSeen,newMessage }) => ({ user });

class Inbox extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
  }
  state = {
    conversations: [],
    reciever: null,
    loadingTo: true,
  };

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.getAllConversationOfCurrentUser();
    }
    if(this.props.newMessage){
    
        this.getAllConversationOfCurrentUser();
    }
  }

  componentDidUpdate(prevProps) {
     
    if (this.props.newMessage) {
      this.getAllConversationOfCurrentUser();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  

  }

  getAllConversationOfCurrentUser = () => {
    const { user } = this.props;
    getAuth(
      `${CONFIG.GET_CONVERSATIONS}?user=${user?.id}`,
      (conversations) =>
        this.setState({
          conversations: conversations,
          loadingTo: false,
        }),
      (e) => {
        console.log(e, 'err');
        this.setState({ loadingTo: false });
      }
    );
  };

  makeConversationSeen = (seen) => {
    // postAuth(
    //   generatePath(CONFIG.MAKE_CONVERSATION_SEEN, { id }),
    //   () => {},
    //   (e) => {
    //     console.error(e);
    //   }
    // );

    this.props.messageSeen(seen)
  };

  render() {
    const { conversations, loadingTo, reciever } = this.state;

    const { user } = this.props;

    let lastMessage = [];
    var temp = conversations;
    const activeParticipants = temp?.sort((a, b) => {
      return new Date(b?.updated) - new Date(a?.updated);
    });
  

    // _.each(
    //     conversations.sort(
    //         (a, b) =>
    //             new Date(b.updated).getTime() -
    //             new Date(a.updated).getTime()
    //     ),
    //     (conversation, i) => {
    //         let unseen = false;

    //         if (!conversation?.seen?.includes(user?.id)) {
    //             unseen = true;
    //         }

    //         const activeParticipant = _.filter(
    //             conversation.participants,
    //             (participant, index) => {
    //                 if (participant._id !== user.id) {
    //                     const allMessages = conversation?.messages;
    //                     if (allMessages?.length > 0) {
    //                         lastMessage = allMessages[0];
    //                     } else {
    //                         lastMessage.message = "";
    //                     }
    //                     const messageCheck =
    //                         lastMessage?.type === "text" ? "text" : "image";
    //                     const EmptyMessage =
    //                         lastMessage?.message !== ""
    //                             ? "notEmpty"
    //                             : "Empty";

    //                     activeParticipants.push({
    //                         i,
    //                         index,
    //                         calculateTime: calculateTime(
    //                             conversation?.updated
    //                         ),
    //                         name: participant?.name,
    //                         picture: participant?.picture,
    //                         to: participant?._id,
    //                         isSeen: unseen,
    //                         isEmpty: EmptyMessage,
    //                         lastMessage,
    //                         messageCheck,
    //                         receiver: reciever?._id,
    //                         conversation,
    //                     });
    //                 }
    //                 return false;
    //             }
    //         );
    //         activeParticipants.push(...activeParticipant);
    //     }
    // );

    return (
      <div id='messagePage'>
        <section className='messageSection'>
          <div className='customContainer mediumWidthLayout'>
            <div className='sectionContent'>
              <div className='messageGridItem messageListBox'>
                <div className='title'>
                  <h5>Messages</h5>
                </div>
                <div className='content'>
                  {loadingTo ? (
                    <Loading color='red' />
                  ) : (
                    <>
                      {activeParticipants?.length > 0 ? (
                        <>
                          {activeParticipants.map((data, i) => {
                            return (
                              <>
                           
                                {data && (
                                  // ?.messages
                                  // ?.length >
                                  // 0
                                  // <Template
                                  //     index={
                                  //         data?.index
                                  //     }
                                  //     calculateTime={
                                  //         data?.calculateTime
                                  //     }
                                  //     name={
                                  //         data?.name
                                  //     }
                                  //     picture={
                                  //         data?.picture
                                  //     }
                                  //     to={
                                  //         data?.to
                                  //     }
                                  //     isSeen={
                                  //         data?.isSeen
                                  //     }
                                  //     isEmpty={
                                  //         data?.isEmpty
                                  //     }
                                  //     lastMessage={
                                  //         data?.lastMessage
                                  //     }
                                  //     messageCheck={
                                  //         data?.messageCheck
                                  //     }
                                  //     receiver={
                                  //         data?.receiver
                                  //     }
                                  //     i={
                                  //         data?.i
                                  //     }
                                  //     conversation={
                                  //         data?.conversation
                                  //     }
                                  //     user={
                                  //         user?.id
                                  //     }
                                  // />
                                  <Template
                                    data={data}
                                    user={user}
                                    i={i}
                                    refreshallConversation={
                                      this.getAllConversationOfCurrentUser
                                    }
                                    messageSeen={this.makeConversationSeen}
                                    activeParticipants={activeParticipants}
                                  />
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginTop: '10px',
                          }}
                        >
                          <p>Open a conversation to start a chat</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Inbox.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  messageSeen: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Inbox);
