import React, { Component } from "react";
import { generatePath, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import { CONFIG } from "../../../../constants";
import { getThumbnail } from "../../../../utils/mediaUtils";
import sitemap from "../../../../routing/siteMap";
import ContentCard from "../../../ContentCard";
import ProfileSidebar from "../Profile/ProfileSidebar";
import "./LikedArtwork.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getAllLikedArtworks } from "../../../../services/artworkServices";
import Image from "../../../ImageTag/Image";
import { getUserData, getYourInfo } from "../../../../services/userServices";
import Loading from "../../../Loading";
import PageHead from "../../../PageHead";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

class LikedArtwork extends Component {
  state = {
    loading: true,
    liked_artworks: [],
    userInfo: {},
  };

  componentDidMount() {
    this.getUserData();

    getAllLikedArtworks(
      (response) => {
        this.setState({
          liked_artworks: response?.liked_artworks,
          loading: false,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  }
  getUserData = () => {
    getYourInfo(
      (response) => {
        this.setState({ userInfo: response });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  render() {
    const { liked_artworks, userInfo, loading } = this.state;
    const images = [];
    liked_artworks.map((artwork) => {
      const thumbnail = getThumbnail(artwork.image, "gallery");
      images.push({ image: thumbnail.url, slug: artwork.slug });
    });

    return (
      <div id="likedartwork-page">
        {loading ? (
          <Loading color="red" />
        ) : (
          <div className="commonGidContainer typeBox">
            <PageHead imgPath={userInfo?.cover && `users/${userInfo?.cover}`} />
            {Object.keys(userInfo).length > 0 && (
              <ProfileSidebar userInfo={userInfo} />
            )}
            <div className="artworks">
              <ContentCard title="Liked Artworks">
                {images.length > 0 ? (
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry columnsCount={3} gutter="5px">
                      {images.map((image, i) => (
                        <NavLink
                          to={generatePath(sitemap.artWork, {
                            slug: image.slug,
                          })}
                        >
                          <Image
                            className="imageStyle"
                            src={image?.image}
                            alt={image?.slug}
                          />
                        </NavLink>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    You haven't liked any artworks yet!
                  </div>
                )}
              </ContentCard>
            </div>
          </div>
        )}
      </div>
    );
  }
}

LikedArtwork.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withSizes(mapSizesToProps)(LikedArtwork);
