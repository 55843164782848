import axios from 'axios';
import Cookie from 'js-cookie';
import { generatePath } from 'react-router';

import { CONFIG } from '../constants';
import store from '../redux';
import { showGlobalSnack } from '../redux/actions/snack.actions';

export const list = () => new Promise((resolve, reject) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  axios({
    url: CONFIG.LIST_EXHIBITIONS,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`
    },
  })
    .then((response) => {
      if (response.data.status === 'success') {
        resolve(response.data.message);
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      reject(e);
    });
});

export const get = id => new Promise((resolve, reject) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  axios({
    url: generatePath(CONFIG.GET_EXHIBITION, { id }),
    method: 'GET',

    headers: {
      Authorization: `JWT ${token}`
    }

  })
    .then((response) => {
      if (response.data.status === 'success') {
        resolve(response.data.message);
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      reject(e);
    });
});
