import React from "react";
import PropTypes from "prop-types";

import "./StaticContentCard.scss";

const StaticContentCard = ({ title, children }) => (
  <div className="staticContentCardItem">
    <div className="title">
      <h1>{title} </h1>
    </div>
    <div className="main">{children}</div>
  </div>
);

StaticContentCard.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
};

StaticContentCard.defaultProps = {
  title: "",
  heading: null,
};

export default StaticContentCard;
