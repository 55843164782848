import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import axios from "axios";
import Cookie from "js-cookie";

import { removeCartItem } from "../../../../redux/actions/cart.actions";
import StripeCheckout from "react-stripe-checkout";
import {
  postAuth,
  post,
  getWithAuth,
} from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import store from "../../../../redux";
import {
  showGlobalSnack,
  hideGlobalSnack,
} from "../../../../redux/actions/snack.actions";

import Loading from "../../../Loading/Loading";
import { getThumbnail } from "../../../../utils/mediaUtils";
import ContentCard from "../../../ContentCard";
import { Input } from "../../../Form";
import esewa from "../../../../assets/img/eSewa-Nepal.png";
import connectIps from "../../../../assets/img/connectIps.png";
import stripe from "../../../../assets/img/stripe.png";
import ThreeDots from "../../../ThreeDots";
import { clearCartItems } from "../../../../redux/actions/cart.actions";
import Image from "../../../ImageTag/Image";
import "./Checkout.scss";

const mapStateToProps = ({ user, cart }) => ({ user, cart });

class Checkout extends Component {
  state = {
    amount: 0,
    taxAmount: 0.13,
    serviceCharge: 0,
    deliveryCharge: 50,
    cartItem: [],
    items: [],
    isLoading: true,
    value: 0,
    localpayment: "cash",
    fullName: "",
    email: "",
    country: "",
    postalCode: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    phone: "",
    orderSuccess: false,
    stripeDisabled: true,
    reviewItems: [],
  };
  temp = [];
  formValid = false;

  formRef = React.createRef();
  stripeRef = React.createRef();

  componentDidMount() {
    this.getBasicDetail();
    this.getCartItems();
  }

  getBasicDetail() {
    getWithAuth(
      CONFIG.GET_BASIC_DETAILS,
      (res) => {
        this.setState({
          fullName: res?.name,
          email: res?.email,
          phone: res?.phoneNumber,
          country: res?.country,
          city: res?.city,
          state: res?.state,
          address1: res?.address,
          address2: res?.address2,
          postalCode: res?.postalCode,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCartItems = () => {
    getWithAuth(
      CONFIG.CART_URL,
      (cartItem) => {
        this.setState({
          cartItem: cartItem,
          isLoading: false,
          reviewItems: cartItem,
        });

        cartItem.products.map((product) =>
          this.temp.push({
            name: product?.product_id?.name,
            image: product?.product_id?.image,
            price: product?.product_id?.priceField.split(" ")[1],
            product: product?.product_id?.id,
            qty: 1,
          })
        );
      },
      (e) => {
        this.setState({ isLoading: false });
      }
    );
  };
  removeItem = (id) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    axios
      .get(`${CONFIG.CART_URL}/removeItem?productId=${id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then((response) => {
        store.dispatch(removeCartItem(id));
      })
      .catch((err) => {
        store.dispatch(
          showGlobalSnack(
            "error",
            "Something went wrong while Removing product to cart",
            3000
          )
        );
      });
  };
  handleTabs = (e, val) => {
    this.setState({ value: val });
  };

  handleTabsbtn = () => {
    const val = this.state.value + 1;
    if (val >= 4) {
      val = 3;
    }

    if (val == 1 && !this.validateShippingInfo()) {
    } else {
      this.setState({ value: val });
    }
  };

  validateShippingInfo = () => {
    const {
      fullName,
      email,
      phone,
      country,
      city,
      state,
      address1,
      postalCode,
    } = this.state;

    if (!fullName) {
      store.dispatch(showGlobalSnack("error", "Name field is required"));
      return false;
    } else if (!email) {
      store.dispatch(showGlobalSnack("error", "Email field is required"));
      return false;
    } else if (!this.validateEmail(email)) {
      store.dispatch(showGlobalSnack("error", "Email address is not valid"));
      return false;
    } else if (!phone) {
      store.dispatch(showGlobalSnack("error", "Phone field is required"));
      return false;
    } else if (!this.validatePhone(phone)) {
      store.dispatch(showGlobalSnack("error", "Phone number is not valid"));
      return false;
    } else if (!country) {
      store.dispatch(showGlobalSnack("error", "Country field is required"));
      return false;
    } else if (!city) {
      store.dispatch(showGlobalSnack("error", "City field is required"));
      return false;
    } else if (!state) {
      store.dispatch(showGlobalSnack("error", "State field is required"));
      return false;
    } else if (!postalCode) {
      store.dispatch(showGlobalSnack("error", "Postal Code is required"));
      return false;
    } else if (!this.validatePostalCode(postalCode)) {
      store.dispatch(showGlobalSnack("error", "Postal Code is invalid"));
      return false;
    } else if (!address1) {
      store.dispatch(showGlobalSnack("error", "Address field is required"));
      return false;
    }

    return true;
  };

  validateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }

    return false;
  };

  validatePhone = (number) => {
    var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (number.match(phoneno) && number.length === 10) {
      return true;
    }

    return false;
  };

  validatePostalCode = (value) => {
    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (regexp.test(value)) {
      return true;
    }

    return false;
  };

  checkShippingInfo = () => {
    const {
      fullName,
      email,
      phone,
      country,
      city,
      state,
      address1,
      postalCode,
    } = this.state;
    if (
      fullName &&
      email &&
      phone &&
      country &&
      city &&
      state &&
      postalCode &&
      address1
    ) {
      return true;
    }
    return false;
  };

  handleChange = (event) => {
    this.setState({ localpayment: event.target.value });
  };

  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value });
    let x = this.state?.phone?.length;
    if (x > 9) {
      store.dispatch(
        showGlobalSnack("error", "Phone number must be 10 Digits")
      );
    }
  };

  handleEsewaPayment = async () => {
    // const path = "https://esewa.com.np/epay/main";//for production
    const path = "https://uat.esewa.com.np/epay/main"; //for development
    const params = {
      amt: this.calculateGrandTotal(),
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: this.calculateGrandTotal(),
      pid: "ee2c3ca1-696b-4cc5-a6be-2c40d929d453", //pid merchant account bata auxa,its not a product id of cart
      scd: "EPAYTEST",
      su: "http://merchant.com.np/page/esewa_payment_success",
      fu: "http://merchant.com.np/page/esewa_payment_failed",
    };

    let form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);
    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    const response = await form.submit();

    //payment success
    //some logic to verify payment from backend
    // this.setState({ value: 3, isSaving: false, orderSuccess: true });
    // store.dispatch(hideGlobalSnack())
    // store.dispatch(clearCartItems())

    //if transaction failed
    //store.dispatch(showGlobalSnack('error', 'Something went wrong while making pyament through esewa'));
  };

  handleStripePayment = () => {
    console.log(this.stripeRef, "stripe ref");
    this.stripeRef.current.onClick();
  };

  onToken = (token) => {
    //some logic to verify token from backend
    fetch("/save-stripe-token", {
      method: "POST",
      body: JSON.stringify(token),
    })
      .then((response) => {
        response.json().then((data) => {
          alert(`We are in business, ${data.email}`);
          //payment success
          this.setState({ value: 3, isSaving: false, orderSuccess: true });
          store.dispatch(hideGlobalSnack());
          store.dispatch(clearCartItems());
        });
      })
      .catch((err) => {
        //if transaction failed
        //store.dispatch(showGlobalSnack('error', 'Something went wrong while making pyament through esewa'));
      });
  };

  handleNormalPayment = () => {
    const { cartItem } = this.state;
    if (this.state.localpayment === "cash") {
      this.setState({ value: 3, isSaving: false, orderSuccess: true });
      cartItem.products.map((prod) => this.removeItem(prod?.product_id?.id));
      store.dispatch(clearCartItems());
      store.dispatch(hideGlobalSnack());
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { orderItem } = this.state;
    const { user, cart } = this.props;
    if (this.state.localpayment === "stripe") {
      this.setState({ stripeDisabled: false });
    }

    const orderData = {
      orderItems: this.temp,
      taxPrice: this.state.taxAmount,
      shippingPrice: this.state.deliveryCharge,
      totalAmount: this.calculateTotal(),
      grandTotal: this.calculateGrandTotal(),
      paymentMethod: this.state.localpayment,
      shippingInfo: {
        name: this.state.fullName,
        email: this.state.email,
        phone: this.state.phone,
        country: this.state.country,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.postalCode,
        address: this.state.address1,
      },
    };

    // place order
    if (this.state.localpayment === "cash") {
      store.dispatch(
        showGlobalSnack("normal", "You order is processing. Please wait...")
      );
    } else {
      store.dispatch(
        showGlobalSnack(
          "normal",
          "You will shortly be redirected to payment gateway. Please wait..."
        )
      );
    }

    this.setState({ isSaving: true });
    postAuth(
      CONFIG.PLACE_ORDER,
      (order) => {
        if (!order.orderId || order.orderId == undefined) {
          store.dispatch(
            showGlobalSnack(
              "error",
              "Something went wrong while creating order"
            )
          );
          return false;
        }
        const paymentMethod = order.paymentMethod;
        switch (paymentMethod) {
          case "cash":
            return this.handleNormalPayment();
            break;
          case "esewa":
            return this.handleEsewaPayment();
            break;
          case "stripe":
            return this.handleStripePayment();
            break;

          default:
            break;
        }
      },
      (err) => {
        console.log(err);
        this.setState({ isSaving: false, orderSuccess: false });
        store.dispatch(
          showGlobalSnack(
            "error",
            "An unexpected error has occurred. Please reload and try again."
          )
        );
      },
      orderData
    );
  };

  handleBackHome = () => {
    store.dispatch(clearCartItems());
    this.props.history.push("/store/all");
  };

  calculateTotal = () => {
    const { cartItem } = this.state;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let total = 0;
    let priceList = [];
    if (cartItem?.products?.length > 0) {
      priceList = cartItem?.products.map((item) =>
        parseFloat(item?.product_id?.priceField.split(" ")[1])
      );
      total = total + priceList.reduce(reducer);
    }
    return Math.round(total);
    // return this.props.cart.items.reduce((acc, item) => acc + item.price, 0);
  };

  calculateGrandTotal = () => {
    const total = this.calculateTotal();
    const taxAmount = this.state.taxAmount * total;
    const grandTotal = this.state.deliveryCharge + taxAmount + total;
    return Math.round(grandTotal);

    // return grandTotal.toFixed(2);
  };

  render() {
    const {
      isSaving,
      isLoading,
      reviewItems,
      cartItem,
      items,
      order,
    } = this.state;
    // const { items } = this.props.cart;
    const itemsList = cartItem?.products?.map((item, index) => (
      <tbody key={`checkout-product-${item?.product_id?.id}`}>
        <tr>
          <td className="art-desc">
            <Image
              src={getThumbnail(item?.product_id?.image, "thumbnail").url}
              alt=""
            />
            <div className="desc">
              <span className="art-name">{item?.product_id?.name}</span> <br />
              <span className="artist">
                by {item?.product_id?.author?.name}
              </span>
            </div>
          </td>
          <td className="art-price"> {item?.product_id?.priceField}</td>
        </tr>
      </tbody>
    ));

    const itemListResponsive = cartItem?.products?.map((item, index) => (
      <tbody key={`checkout-product-${item?.product_id?.id}`}>
        <tr>
          <td className="art-desc">
            <Image
              src={getThumbnail(item?.product_id?.image, "thumbnail").url}
              alt=""
            />
            <div className="desc">
              <span className="art-name">{item?.product_id?.name}</span> <br />
              <span>{item.product_id?.priceField}</span> <br />
              <span className="artist">
                by {item?.product_id?.author?.name}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    ));

    const reviewItemList = reviewItems?.products?.map((item, index) => (
      <tbody key={`checkout-product-${item?.product_id?.id}`}>
        <tr>
          <td className="art-desc">
            <Image
              src={getThumbnail(item?.product_id?.image, "thumbnail").url}
              alt=""
            />
            <div className="desc">
              <span className="art-name">{item?.product_id?.name}</span> <br />
              <span className="artist">
                by {item?.product_id?.author?.name}
              </span>
            </div>
          </td>
          <td className="art-price"> {item?.product_id?.priceField}</td>
        </tr>
      </tbody>
    ));
    const reviewItemListResponsive = reviewItems?.products?.map(
      (item, index) => (
        <tbody key={`checkout-product-${item?.product_id?.id}`}>
          <tr>
            <td className="art-desc">
              <Image
                src={getThumbnail(item?.product_id?.image, "thumbnail").url}
                alt=""
              />
              <div className="desc">
                <span className="art-name">{item?.product_id?.name}</span>{" "}
                <br />
                <span> {item?.product_id?.priceField}</span> <br />
                <span className="artist">
                  by {item?.product_id?.author?.name}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      )
    );

    return (
      <div className="checkout-page">
        <div className="for-web">
          <div className="checkout-header">
            <div className="left">Checkout</div>
            <div className="center">
              <Tabs selectedIndex={this.state.value} value={this.state.value}>
                <TabList className="checkout-tab-main">
                  <Tab
                    className={
                      this.state.value === 0
                        ? "checkout-tabs tabs react-tabs__tab--selected"
                        : "checkout-tabs tabs"
                    }
                    disabled={this.state.orderSuccess ? true : false}
                  >
                    {" "}
                    Shipping{" "}
                  </Tab>
                  <Tab
                    className={
                      this.state.value === 1
                        ? "checkout-tabs tabs react-tabs__tab--selected"
                        : "checkout-tabs tabs"
                    }
                    disabled={
                      !this.checkShippingInfo() || this.state.orderSuccess
                        ? true
                        : false
                    }
                  >
                    {" "}
                    Order{" "}
                  </Tab>
                  <Tab
                    className={
                      this.state.value === 2
                        ? "checkout-tabs tabs react-tabs__tab--selected"
                        : "checkout-tabs tabs"
                    }
                    disabled={
                      !this.checkShippingInfo() || this.state.orderSuccess
                        ? true
                        : false
                    }
                  >
                    {" "}
                    Payment{" "}
                  </Tab>
                  <Tab
                    className={
                      this.state.value === 3
                        ? "checkout-tabs tabs react-tabs__tab--selected"
                        : "checkout-tabs tabs"
                    }
                    disabled={!this.state.orderSuccess ? true : false}
                  >
                    {" "}
                    Review{" "}
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="shipping-info">
                    <ContentCard title="Shipping Information">
                      <div className="typeTwoCol">
                        <Input
                          required
                          label="Full Name"
                          type="text"
                          name="fullName"
                          placeholder="Add full name"
                          value={this.state.fullName}
                          onChange={(e) =>
                            this.setState({ fullName: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="Email"
                          type="email"
                          name="email"
                          placeholder="Email address"
                          value={this.state?.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="Phone Number"
                          type="number"
                          name="phones"
                          placeholder="Add Phone No"
                          value={
                            this.state?.phone && this.state?.phone != "NaN"
                              ? this.state?.phone
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          // onChange={this.handlePhoneChange}
                        />
                        <Input
                          required
                          label="Country"
                          type="text"
                          name="country"
                          value={this.state?.country}
                          onChange={(e) =>
                            this.setState({ country: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="City"
                          type="text"
                          name="city"
                          placeholder="Add city"
                          value={this.state?.city}
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="State, Province or region "
                          type="text"
                          name="state"
                          placeholder="Add state, province or region"
                          value={this.state?.state}
                          onChange={(e) =>
                            this.setState({ state: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="Postal Code "
                          type="number"
                          name="postalCode"
                          placeholder="Add postal code"
                          value={this.state?.postalCode}
                          onChange={(e) =>
                            this.setState({ postalCode: e.target.value })
                          }
                        />
                        <Input
                          required
                          label="Address Line 1"
                          type="text"
                          name="addressline1"
                          placeholder="Add street address"
                          value={this.state?.address1}
                          onChange={(e) =>
                            this.setState({ address1: e.target.value })
                          }
                        />

                        <Input
                          label="Address Line 2 (optional)"
                          type="text"
                          name="addressline2"
                          placeholder="Add apt, floor, suite, etc"
                          value={this.state?.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                      </div>
                    </ContentCard>
                  </div>
                </TabPanel>
                <TabPanel>
                  {isLoading ? (
                    <Loading color="red" />
                  ) : (
                    <>
                      {cartItem?.products?.length > 0 ? (
                        <>
                          <div className="items">
                            <table>
                              <thead>
                                <tr>
                                  <th> Product</th>
                                  <th colSpan="2">Price</th>
                                </tr>
                              </thead>
                              {itemsList}
                            </table>
                            <div className="total-amount">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      NPR.
                                      {this.calculateTotal()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Shipping Charge</td>
                                    <td>{this.state.deliveryCharge}</td>
                                  </tr>
                                  <tr>
                                    <td className="tax">TAX</td>
                                    <td className="tax">
                                      {this.state.taxAmount * 100}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="grand-total">Grand Total</td>
                                    <td className="grand-total">
                                      NPR.
                                      {this.calculateGrandTotal()}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="no-items">
                          You don't have any items in your cart!
                        </div>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  <ContentCard>
                    <span className="payment-title">Local Payment</span>
                    <br />
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "40%" }}
                    >
                      <RadioGroup
                        aria-label="localpayment"
                        name="localpayment"
                        value={this.state.localpayment}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label={<div>Cash on delivery</div>}
                        />
                        {/* <FormControlLabel
                          value="esewa"
                          control={<Radio />}
                          disabled="true"
                          label={
                            <div>
                              <img src={esewa} style={{ height: "33px" }} />
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="connect_ips"
                          control={<Radio />}
                          disabled="true"
                          label={
                            <div>
                              <img
                                src={connectIps}
                                style={{ height: "44px" }}
                              />
                            </div>
                          }
                        /> */}
                      </RadioGroup>
                    </FormControl>
                    {/* <div className="international-payment">
                      <span className="payment-title">
                        International Payment
                      </span>
                      <br />
                      <FormControl
                        component="fieldset"
                        style={{ marginLeft: "40%" }}
                      >
                        <RadioGroup
                          aria-label="internationalPayment"
                          name="localpayment"
                          disabled="true"
                          value={this.state.localpayment}
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value="stripe"
                            disabled="true"
                            control={<Radio />}
                            label={
                              <div>
                                <StripeCheckout
                                  disabled={this.state.stripeDisabled}
                                  stripeKey="pk_test_51IPhUgGLl7aMxThQuQrg9bURJtyEwHW6tBhMR5RIhDAjPCc4ZUic7WAGwfrK8LMxs4gO0kvjmiDLNq0qyDpnOtWl005iqUg3Ev"
                                  token={this.onToken}
                                  billingAddress={false}
                                  currency="USD"
                                  amount={parseInt(this.calculateGrandTotal())}
                                  name="Tuilka"
                                  ref={this.stripeRef}
                                >
                                  <img
                                    src={stripe}
                                    style={{ height: "33px" }}
                                  />
                                </StripeCheckout>
                              </div>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                  </ContentCard>
                </TabPanel>
                <TabPanel>
                  <div className="review-section">
                    <ContentCard>
                      <CheckCircleOutlineIcon className="checkmark" />
                      <p className="review-txt">
                        Your order has been created. Your order will be
                        confirmed via Email.
                      </p>
                    </ContentCard>
                    <h4 className="order-placed">Order Placed</h4>
                    {isLoading ? (
                      <Loading color="red" />
                    ) : (
                      <>
                        {reviewItems?.products?.length > 0 ? (
                          <>
                            <div className="items">
                              <table className="review-items">
                                <thead>
                                  <tr>
                                    <th> Product</th>
                                    <th colSpan="2">Price</th>
                                  </tr>
                                </thead>
                                {reviewItemList}
                              </table>
                              <div className="total-amount">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Total</td>
                                      <td>NPR. {this.calculateTotal()} </td>
                                    </tr>
                                    <tr>
                                      <td>Shipping Charge</td>
                                      <td>{this.state.deliveryCharge}</td>
                                    </tr>
                                    <tr>
                                      <td className="tax">TAX</td>
                                      <td className="tax">
                                        {this.state.taxAmount * 100}%
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="grand-total">
                                        Grand Total
                                      </td>
                                      <td className="grand-total">
                                        NPR.{this.calculateGrandTotal()}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="no-items">
                            You don't have any items in your cart!
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
            <div className="right">
              {this.state.value === 2 && this.state.value !== 3 ? (
                <button
                  className="proceed-btn"
                  onClick={this.handleSubmit}
                  disabled={isSaving ? true : false}
                >
                  {isSaving ? <ThreeDots variant="flashing" /> : "Submit"}
                </button>
              ) : this.state.value !== 3 ? (
                <button className="proceed-btn" onClick={this.handleTabsbtn}>
                  Continue
                </button>
              ) : (
                this.state.value === 3 && (
                  <button className="proceed-btn" onClick={this.handleBackHome}>
                    Shop More
                  </button>
                )
              )}
            </div>
          </div>
        </div>

        {/*For responsive*/}

        <div className="for-responsive">
          <div className="checkout-header">
            <div className="left">Checkout</div>
            <div className="right">
              {this.state.value === 2 && this.state.value !== 3 ? (
                <button
                  className="proceed-btn"
                  onClick={this.handleSubmit}
                  disabled={isSaving ? true : false}
                >
                  {isSaving ? <ThreeDots variant="flashing" /> : "Submit"}
                </button>
              ) : this.state.value !== 3 ? (
                <button className="proceed-btn" onClick={this.handleTabsbtn}>
                  Continue
                </button>
              ) : (
                this.state.value === 3 && (
                  <button className="proceed-btn" onClick={this.handleBackHome}>
                    Shop More
                  </button>
                )
              )}
            </div>
          </div>
          <div className="center">
            <Tabs selectedIndex={this.state.value} value={this.state.value}>
              <TabList className="checkout-tab-main">
                <Tab
                  className={
                    this.state.value === 0
                      ? "checkout-tabs tabs react-tabs__tab--selected"
                      : "checkout-tabs tabs"
                  }
                  disabled={this.state.orderSuccess ? true : false}
                >
                  {" "}
                  Shipping{" "}
                </Tab>
                <Tab
                  className={
                    this.state.value === 1
                      ? "checkout-tabs tabs react-tabs__tab--selected"
                      : "checkout-tabs tabs"
                  }
                  disabled={
                    !this.checkShippingInfo() || this.state.orderSuccess
                      ? true
                      : false
                  }
                >
                  {" "}
                  Order{" "}
                </Tab>
                <Tab
                  className={
                    this.state.value === 2
                      ? "checkout-tabs tabs react-tabs__tab--selected"
                      : "checkout-tabs tabs"
                  }
                  disabled={
                    !this.checkShippingInfo() || this.state.orderSuccess
                      ? true
                      : false
                  }
                >
                  {" "}
                  Payment{" "}
                </Tab>
                <Tab
                  className={
                    this.state.value === 3
                      ? "checkout-tabs tabs react-tabs__tab--selected"
                      : "checkout-tabs tabs"
                  }
                  disabled={!this.state.orderSuccess ? true : false}
                >
                  {" "}
                  Review{" "}
                </Tab>
              </TabList>

              <TabPanel>
                <div className="shipping-info">
                  <ContentCard title="Shipping Information">
                    <div className="typeTwoCol">
                      <Input
                        required
                        label="Full Name"
                        type="text"
                        name="fullName"
                        placeholder="Add full name"
                        value={this.state?.name}
                        onChange={(e) =>
                          this.setState({ fullName: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Email address"
                        value={this.state?.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="Phone Number"
                        type="text"
                        name="phones"
                        placeholder="Add Phone No"
                        value={
                          this.state?.phone && this.state?.phone != "NaN"
                            ? this.state?.phone
                            : ""
                        }
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        // onChange={this.handlePhoneChange}
                      />
                      <Input
                        required
                        label="Country"
                        type="text"
                        name="country"
                        value={this.state?.country}
                        onChange={(e) =>
                          this.setState({ country: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="City"
                        type="text"
                        name="city"
                        placeholder="Add city"
                        value={this.state?.city}
                        onChange={(e) =>
                          this.setState({ city: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="State, Province or region "
                        type="text"
                        name="state"
                        placeholder="Add state, province or region"
                        value={this.state?.state}
                        onChange={(e) =>
                          this.setState({ state: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="Postal Code "
                        type="number"
                        name="postalCode"
                        placeholder="Add postal code"
                        value={this.state?.postalCode}
                        onChange={(e) =>
                          this.setState({ postalCode: e.target.value })
                        }
                      />
                      <Input
                        required
                        label="Address Line 1"
                        type="text"
                        name="addressline1"
                        placeholder="Add street address"
                        value={this.state?.address1}
                        onChange={(e) =>
                          this.setState({ address1: e.target.value })
                        }
                      />

                      <Input
                        label="Address Line 2 (optional)"
                        type="text"
                        name="addressline2"
                        placeholder="Add apt, floor, suite, etc"
                        value={this.state?.address2}
                        onChange={(e) =>
                          this.setState({ address2: e.target.value })
                        }
                      />
                    </div>
                  </ContentCard>
                </div>
              </TabPanel>
              <TabPanel>
                {isLoading ? (
                  <Loading color="red" />
                ) : (
                  <>
                    {cartItem?.products?.length > 0 ? (
                      <>
                        <div className="items">
                          <table>{itemListResponsive}</table>
                          <div className="total-amount">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    NPR.
                                    {this.calculateTotal()}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Shipping Charge</td>
                                  <td>{this.state.deliveryCharge}</td>
                                </tr>
                                <tr>
                                  <td className="tax">TAX</td>
                                  <td className="tax">
                                    {this.state.taxAmount}%
                                  </td>
                                </tr>
                                <tr>
                                  <td className="grand-total">Grand Total</td>
                                  <td className="grand-total">
                                    NPR.
                                    {this.calculateGrandTotal()}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="no-items">
                        You don't have any items in your cart!
                      </div>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <ContentCard>
                  <span className="payment-title">Local Payment</span>
                  <br />
                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "40%" }}
                  >
                    <RadioGroup
                      aria-label="localpayment"
                      name="localpayment"
                      value={this.state.localpayment}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="cash"
                        control={<Radio />}
                        label={<div>Cash on delivery</div>}
                      />
                      <FormControlLabel
                        value="esewa"
                        control={<Radio />}
                        label={
                          <div>
                            <img src={esewa} style={{ height: "33px" }} />
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="connect_ips"
                        control={<Radio />}
                        label={
                          <div>
                            <img src={connectIps} style={{ height: "44px" }} />
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="international-payment">
                    <span className="payment-title">International Payment</span>
                    <br />
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "40%" }}
                    >
                      <RadioGroup
                        aria-label="internationalPayment"
                        name="localpayment"
                        value={this.state.localpayment}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="stripe"
                          control={<Radio />}
                          label={
                            <div>
                              <StripeCheckout
                                disabled={this.state.stripeDisabled}
                                stripeKey="pk_test_51IPhUgGLl7aMxThQuQrg9bURJtyEwHW6tBhMR5RIhDAjPCc4ZUic7WAGwfrK8LMxs4gO0kvjmiDLNq0qyDpnOtWl005iqUg3Ev"
                                token={this.onToken}
                                billingAddress={false}
                                currency="USD"
                                amount={parseInt(this.calculateGrandTotal())}
                                name="Tuilka"
                                ref={this.stripeRef}
                              >
                                <img src={stripe} style={{ height: "33px" }} />
                              </StripeCheckout>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </ContentCard>
              </TabPanel>
              <TabPanel>
                <div className="review-section">
                  <CheckCircleOutlineIcon className="checkmark" />
                  <p className="review-txt">
                    Your order has been created. Your order will be confirmed
                    via Email.
                  </p>
                  <h4 className="order-placed">Order Placed</h4>
                  {isLoading ? (
                    <Loading color="red" />
                  ) : (
                    <>
                      {reviewItems?.products?.length > 0 ? (
                        <>
                          <div className="items">
                            <table className="review-items">
                              {reviewItemListResponsive}
                            </table>
                            <div className="total-amount">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      NPR.
                                      {this.calculateTotal()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Shipping Charge</td>
                                    <td>{this.state.deliveryCharge}</td>
                                  </tr>
                                  <tr>
                                    <td className="tax">TAX</td>
                                    <td className="tax">
                                      {this.state.taxAmount}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="grand-total">Grand Total</td>
                                    <td className="grand-total">
                                      NPR.
                                      {this.calculateGrandTotal()}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="no-items">
                          You don't have any items in your cart!
                        </div>
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

Checkout.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  cart: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(Checkout);
