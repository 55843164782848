import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import OnImagesLoaded from "react-on-images-loaded";
import { sortBy } from "lodash";
import { connect } from "react-redux";

import { get } from "../../../services/exhibition.services";
import Slider from "../../ExhibitionSlider";
import css from "./exhibition.module.scss";
import { showGlobalSnack } from "../../../redux/actions/snack.actions";
import { setLoginBack } from "../../../redux/actions/global.actions";
import siteMap from "../../../routing/siteMap";
const mapStateToProps = ({ user }) => ({ user });

class Exhibition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibition: null,
      imagesLoaded: false,
    };
    this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.history.goBack();
    }
  }

  componentDidMount() {
    const { match } = this.props;

    get(match?.params?.slug)
      .then((exhibition) => {
        const sortedItems = sortBy(exhibition?.items, ["order"]);
        delete exhibition.items;
        exhibition.items = sortedItems;
        this.setState({ exhibition });
      })
      .catch((e) => {
        console.log(e);
      });
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { exhibition, imagesLoaded } = this.state;
    const sliderOptions = {
      gutter: 60,
      parallax: exhibition && exhibition?.parallaxBackground,
    };

    return (
      <>
        {!!exhibition && (
          <>
            {imagesLoaded ? (
              <div className={css.sliderWrap}>
                <Slider
                  background={exhibition?.background}
                  options={sliderOptions}
                  exhibition={exhibition}
                />
              </div>
            ) : (
              <div style={{ opacity: 0 }}>
                <OnImagesLoaded
                  onLoaded={() => this.setState({ imagesLoaded: true })}
                >
                  {exhibition?.items?.map((item, i) => (
                    <div className={css.slideWrap} key={i}>
                      <img src={item?.thumb} alt="" />
                    </div>
                  ))}
                </OnImagesLoaded>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Exhibition));
