import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import { CONFIG } from "../../../../constants";
import { getWithParams } from "../../../../services/generalApiServices";
import { generatePath } from "react-router";
import "./UserWork.scss";
import { getThumbnail } from "../../../../utils/mediaUtils";
import ArtShowcase, { ArtShowcaseItem } from "../../../ArtShowcase";
import UserArtShowcaseItem from "./UserArtShowcaseItem";
import Loading from "../../../Loading";
import { isUserArtist } from "../../../../utils/userUtils";
import BottomScrollListener from "react-bottom-scroll-listener";
import { getYourProducts } from "../../../../services/productServices";

const mapSizesToProps = (sizes) => ({ winWidth: sizes.width });

const YourWork = (props) => {
  const [userArts, setUserArts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(99999);
  const [page, setPage] = useState(1);

  const { userInfo, winWidth } = props;

  useEffect(() => {
    getUserArts();
  }, [userInfo?.id, page]);

  const getUserArts = () => {
    const id = userInfo?._id;

    if (userInfo) {
      getYourProducts(
        id,
        { page: page },
        (response) => {
          const newProducts = response?.products;
          setTotal(response?.total);
          setUserArts([...userArts, ...newProducts]);
          setIsLoading(false);
          setLoadingMore(false);
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
          setLoadingMore(false);
        }
      );
    }
  };
  const loadMore = () => {
    setPage((page) => page + 1);
    setLoadingMore(true);
  };

  const userArtworkList = userArts?.map((userArt) => {
    const thumbnail = getThumbnail(userArt?.image, "gallery");
    if (isUserArtist(userInfo?.role)) {
      return (
        <ArtShowcaseItem
          readOnly={false}
          key={`artist-work-${userArt?._id}`}
          artist={userArt?.author?.name}
          artistId={userArt?.author?._id}
          artName={userArt?.name}
          thumbnail={thumbnail}
          price={userArt?.priceField}
          slug={userArt?.slug}
          art={userArt}
        />
      );
    } else {
      return (
        <UserArtShowcaseItem
          readOnly={false}
          key={`user-work-${userArt?._id}`}
          artist={userArt?.author?.name}
          userId={userArt?.author?._id}
          artName={userArt?.name}
          thumbnail={thumbnail}
          price={String(userArt?.priceField)}
          slug={userArt?.slug}
          art={userArt}
        />
      );
    }
  });

  let columnCount = 4;
  if (winWidth < 1440) {
    columnCount = 4;
  }
  if (winWidth < 1199) {
    columnCount = 3;
  }
  // if (winWidth < 991) {
  //   columnCount = 3;
  // }
  if (winWidth < 481) {
    columnCount = 2;
  }
  return (
    <section className="UserWorkSection">
      <div className="customContainer">
        <div className="sectionContent2">
          {isLoading ? (
            <Loading color="red" />
          ) : (
            <>
              {userArts.length > 0 ? (
                <>
                  <ArtShowcase columnCount={columnCount} gutter={17}>
                    {userArtworkList}
                  </ArtShowcase>
                  {loadingMore ? (
                    <Loading color="red" />
                  ) : (
                    <>
                      {userArts?.length < total && (
                        <>
                          <BottomScrollListener
                            onBottom={loadMore}
                            offset={300}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 20 }}>
                  No Artworks to show
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

YourWork.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
  // children: PropTypes.node.isRequired,
  columnCount: PropTypes.number,
  gutter: PropTypes.number,
  // slug: PropTypes.string.isRequired,
};
YourWork.defaultProps = {
  columnCount: 3,
  gutter: 15,
};

export default withSizes(mapSizesToProps)(YourWork);
