import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CONFIG } from "../../constants";
import AdvancedAvatarInlineStudio from "../AdvancedAvatarInline/AdvanceAvatarInlineStudio";
import ArtistGalleryInline from "../ArtistGalleryInline";
import "./artistShowcaseStudio.scss";

const ArtistShowcaseStudio = ({ artist, fullWidthGallery,setFollow }) => {
  const showcaseItemClasses = classnames("artistShowcaseItem", {
    "full-width-gallery": fullWidthGallery,
  });
  const image = "assets/profile-dummy.png";

  return (
    <div className={showcaseItemClasses}>
      <AdvancedAvatarInlineStudio
        imgPath={
          artist?.picture && artist.picture != "undefined"
            ? `users/${artist?.picture}`
            : image
        }
        followers={artist?.follows?.length}
        works={artist?.products?.length}
        name={artist?.name}
        category={artist?.info?.categories[0]?.name}
        verified={artist?.verifiedArtist}
        artist={artist}
        setFollow={setFollow}

      />
      <ArtistGalleryInline artistId={artist._id} />
    </div>
  );
};

ArtistShowcaseStudio.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidthGallery: PropTypes.bool,
};

ArtistShowcaseStudio.defaultProps = {
  fullWidthGallery: false,
};

export default ArtistShowcaseStudio;
