import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import { getProductsFromFollowed } from "../../services/productServices";
import { getThumbnail } from "../../utils/mediaUtils";
import sitemap from "../../routing/siteMap";
import Loading from "../Loading";
import HomeSection from "../HomeSection";
import ContentScroller from "../ContentScroller";
import LazyLoad from "react-lazy-load";
import LikeArt from "../LikeArt/LikeArt";
import Image from "../ImageTag/Image";
import "./ArtFromFollowed.scss";

class ArtFromFollowed extends Component {
  state = {
    artworks: [],
    isLoading: true
  };

  componentDidMount() {
    getProductsFromFollowed()
      .then(artworks => this.setState({ artworks, isLoading: false }))
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { artworks, isLoading } = this.state;

    const items = artworks.slice(0, 11).map(item => {
      const thumbnail = getThumbnail(item?.image, "productImage");
      const { slug, name } = item?.author;
      return (
        <div key={`popular-item-${item?._id}`} className="popularItem">
          <div className="imageContainer">
            <Link to={generatePath(sitemap.artWork, { slug: item?.slug })}>
              <div className="">
                {/* <LazyLoad once> */}
                <Image
                  src={`${item?.image?.set?.slug}/${thumbnail?.fileName}`}
                  alt=""
                  skeletonHeight={300}
                  skeletonWidth={300}
                />
                {/* </LazyLoad> */}
              </div>
            </Link>
          </div>
          {/* <LikeArt art={item} out={true} /> */}

          <div className="artTextContainer">
            <div className="artTextItem">
              <h5>
                {item.name.slice(0, 20)}
                {item?.name?.length > 20 && "..."}
              </h5>
              <h6>{item?.priceField.toUpperCase()}</h6>

              <span>
                by{" "}
                <Link to={generatePath(sitemap.artist, { slug })}>{name}</Link>
              </span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <HomeSection title="From studios you follow" fullWidth>
        <div className="art-from-followed">
          {isLoading ? (
            <Loading color="red" />
          ) : (
            <ContentScroller title="Popular Works" arrowPosition="around">
              {items}
            </ContentScroller>
          )}
        </div>
      </HomeSection>
    );
  }
}

export default ArtFromFollowed;
