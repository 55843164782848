import axios from 'axios';
import Cookie from 'js-cookie';
import { CONFIG } from '../constants';

export const getAllLikedArtworks = ( success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = CONFIG.GET_LIKED_ARTWORS;
  axios({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`
    }
  })
    .then((response) => {
      if (response.data.status === 'success') {
        success({ liked_artworks: response.data.message });
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};


export const getArtistsInfo = ( success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = CONFIG.GET_ARTIST_BY_SLUG;
  axios({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`
    }
  })
    .then((response) => {
      if (response.data.status === 'success') {
        success({ artists: response.data.message });
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};
