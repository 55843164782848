import React from "react";
import { NavLink } from "react-router-dom";
import not_found from "../../../assets/img/404/404.png";
import sitemap from "../../../routing/siteMap";

import styles from "./NotFound.module.scss";

const NotFound = () => (
  <div className={styles.notfound}>
    <p>PAGE NOT FOUND</p>
    <img src={not_found} alt="" />
    <NavLink to={sitemap.home}>
      <button className={styles.backHome}>Back to Home</button>
    </NavLink>
  </div>
);

export default NotFound;
