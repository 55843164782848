import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './sass/three-dots.scss';

/**
 * CSS Loading Animation
 */
const ThreeDots = ({ variant, color }) => {
  const classes = classnames(`dot-${variant}`, {
    red: color === 'red'
  });
  return (
    <div className={classes}/>
  );
};

ThreeDots.propTypes = {
  variant: PropTypes.oneOf([
    'elastic',
    'pulse',
    'flashing',
    'collision',
    'revolution',
    'carousel',
    'typing',
    'windmill',
    'bricks',
    'floating',
    'fire',
    'spin',
    'falling',
    'stretching'
  ]),
  color: PropTypes.oneOf(['red', null])
};

ThreeDots.defaultProps = {
  variant: 'floating',
  color: null
};

export default ThreeDots;
