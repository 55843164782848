import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { generatePath } from "react-router";

import {
  showDialog,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import { getWithAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import Loading from "../../../Loading";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import Image from "../../../ImageTag/Image";

class Followers extends Component {
  state = {
    loading: true,
    deleting: false,
  };

  handleDelete = (id, e) => {
    const { refresh } = this.props;
    e.preventDefault();
    store.dispatch(
      showDialog(
        "Remove Follower",
        "Are you sure you want remove this follower?",
        () => {
          this.setState({ deleting: true });
          getWithAuth(
            generatePath(CONFIG.REMOVE_FOLLOWER, { id: id }),
            () => {
              store.dispatch(
                showGlobalSnack("Normal", "Deleted follower", 3000)
              );
              store.dispatch(hideDialog());
              refresh();
            },
            () => {
              console.log("Error deleting follower.");
              store.dispatch(hideDialog());
            }
          );
        }
      )
    );
  };

  render() {
    const { loading } = this.state;
    const { followers } = this.props;
    const dummyImage = "assets/profile-dummy.png";

    return (
      <section className="followersSection">
        {followers.length > 0 ? (
          <>
            <table>
              {followers.map((follower) => {
                return (
                  <tbody key={follower?.id}>
                    <tr>
                      <td>
                        <Image
                          className="pp-img"
                          src={
                            follower?.picture &&
                            follower?.picture !== "undefined"
                              ? `users/${follower?.picture}`
                              : dummyImage
                          }
                        />
                        {follower?.name}
                      </td>
                      <td>
                        <IconButton>
                          <DeleteIcon
                            className="dlt-icon"
                            onClick={(e) => this.handleDelete(follower?.id, e)}
                          />
                        </IconButton>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            No Followers
          </div>
        )}
      </section>
    );
  }
}

Followers.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  followers: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Followers;
