import store from '../redux';
import { GLOBALS } from '../constants';

const en = require('./en.json');
const ne = require('./ne.json');
const zh = require('./zh.json');

const messages = {
  en,
  ne,
  zh
};

export const isSupportedLanguage = lang => Boolean(messages[lang]);

export const getLanguageMessageList = (requestedLanguage) => {
  const { lang } = store.getState().locale;

  return (requestedLanguage && isSupportedLanguage[requestedLanguage])
    ? messages[requestedLanguage]
    : messages[lang || GLOBALS.DEFAULT_SITE_LANGUAGE];
};
