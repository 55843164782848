import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import anime from 'animejs';
import withSizes from 'react-sizes';
import BannerNavItem from '../BannerNavItem';

import styles from './slider.module.scss';

const mapSizesToProps = (sizes => (
  {
    winWidth: sizes.width
  }
));

class ReactAnimeSlider extends Component {
  constructor() {
    super();
    this.state = {
      activeSlide: 0,
      nextSlide: null,
      nextAnim: 'fromRight'
    };
    this.autoPlayPause = false;
    this.animating = false;
    this.nextSlideRef = React.createRef();
    this.sliderWrapRef = React.createRef();
  }

  componentDidMount() {
    const { autoplay, interval } = this.props;
    if (autoplay) {
      this.autoplaySlider = setInterval(() => {
        if (!this.autoPlayPause) {
          this.slideNext();
        }
      }, interval);
    }
  }

  componentDidUpdate() {
    this.animateSlide();
  }

  componentWillUnmount() {
    clearInterval(this.autoplaySlider);
  }

  animateSlide = () => {
    const { nextAnim, nextSlide } = this.state;
    const {
      easing, duration, update, mobileBreakPoint, winWidth
    } = this.props;
    const translateParams = nextAnim === 'fromRight' ? ['100%', 0] : ['-100%', 0];
    if (nextSlide !== null) {
      anime({
        targets: this.nextSlideRef.current,
        translateX: translateParams,
        translateY: 0,
        translateZ: 0,
        backgroundPosition: mobileBreakPoint && winWidth < mobileBreakPoint ? null : ['0px center'],
        easing,
        duration,
        begin: () => {
          this.animating = true;
          if (update) {
            update(true);
          }
        },
        complete: () => {
          this.setState(prevState => ({
            activeSlide: prevState.nextSlide,
            nextSlide: null
          }));
          this.animating = false;
          if (update) {
            update(false);
          }
        }
      });
    }
  }

  slideNext = () => {
    const { children } = this.props;
    if (!this.animating && children.length > 1) {
      this.setState((prevState) => {
        let nextSlide = 0;
        if (prevState.activeSlide !== children.length - 1) {
          nextSlide = prevState.activeSlide + 1;
        }
        return {
          nextSlide,
          nextAnim: 'fromRight'
        };
      });
    }
  }

  slidePrev = () => {
    const { children } = this.props;
    if (!this.animating && children.length > 1) {
      this.setState((prevState) => {
        let nextSlide = children.length - 1;
        if (prevState.activeSlide !== 0) {
          nextSlide = prevState.activeSlide - 1;
        }
        return {
          nextSlide,
          nextAnim: 'fromLeft'
        };
      });
    }
  }

  goToSlide = (nextSlide) => {
    const { children } = this.props;
    if (!this.animating && children.length > 1) {
      this.setState((prevState) => {
        const nextAnim = nextSlide < prevState.activeSlide ? 'fromLeft' : 'fromRight';
        return {
          nextSlide,
          nextAnim
        };
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line
    const { activeSlide } = this.state;
    if (nextProps.activeSlide !== activeSlide) this.goToSlide(nextProps.activeSlide);
  }

  render() {
    const { activeSlide, nextSlide, nextAnim } = this.state;
    const {
      children, arrows, pauseOnHover, ratio, mobileBreakPoint, winWidth, nav, slideList
    } = this.props;
    const navItemsMultiple = slideList.map((slide, index) => (
      <BannerNavItem
        key={`nav-item-${index}`}
        image={slide.thumbnail}
        artist={slide.linkArtist.name}
        artistSlug={slide.linkArtist.slug}
        name={slide.title}
        onClick={() => { this.goToSlide(index); }}
        interval={3000}
      />
    ));
    const sliderWidth = this.sliderWrapRef.current ? this.sliderWrapRef.current.clientWidth : null;
    const slides = children.map((slide, index) => {
      let slideClasses = '';
      let slideStyles = {
        background: slide.props.style.background
      };
      if (index === activeSlide) {
        if (nextSlide === null) {
          slideClasses = '__a';
        } else slideClasses = '__ar';
      } else if (index === nextSlide) {
        slideClasses = '__r';
        if (nextAnim === 'fromRight') {
          slideStyles = {
            background: slide.props.style.background,
            backgroundPosition: mobileBreakPoint && winWidth < mobileBreakPoint ? 'right center' : `${-1 * sliderWidth + 150}px center`
          };
        } else {
          slideStyles = {
            background: slide.props.style.background,
            backgroundPosition: mobileBreakPoint && winWidth < mobileBreakPoint ? 'right center' : `${sliderWidth - 150}px center`
          };
        }
      }
      return (
        <div
          key={`anime-slide-${index}`}
          className={classnames(slide.props.className, slideClasses, styles.slide)}
          style={slideStyles}
          ref={index === nextSlide ? this.nextSlideRef : null}
        >
          {slide.props.children}
        </div>
      );
    });
    return (
      <div className={classnames('slider-wrap', styles.sliderWrap)} ref={this.sliderWrapRef}>
        <div
          className={classnames('slides', styles.slides)}
          onMouseOver={() => {
            if (pauseOnHover) {
              this.autoPlayPause = true;
            }
          }}
          onMouseOut={() => { this.autoPlayPause = false; }}
          style={ratio ? { paddingBottom: `${(1 / ratio) * 100}%` } : {}}
        >
          {slides}
        </div>
        <div className="buttons">
          {
            arrows
              ? (
                <React.Fragment>
                  <button type="button" className="_prev" onClick={this.slidePrev}>Prev</button>
                  <button type="button" className="_next" onClick={this.slideNext}>Next</button>
                </React.Fragment>
              )
              : ''
          }
        </div>
        {
          nav
            && (
              <div className={styles.bannerNavContainer}>
                {navItemsMultiple}
              </div>
            )
        }
      </div>
    );
  }
}

ReactAnimeSlider.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  easing: PropTypes.string,
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  arrows: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  ratio: PropTypes.number,
  update: PropTypes.func,
  mobileBreakPoint: PropTypes.number,
  winWidth: PropTypes.number.isRequired,
  nav: PropTypes.node,
  slideList: PropTypes.arrayOf(PropTypes.any).isRequired
};

ReactAnimeSlider.defaultProps = {
  duration: 750,
  easing: 'easeOutQuint',
  autoplay: false,
  arrows: true,
  pauseOnHover: true,
  interval: 300,
  ratio: null,
  update: null,
  mobileBreakPoint: null,
  nav: null
};

export default withSizes(mapSizesToProps)(ReactAnimeSlider);
