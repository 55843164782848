import React from "react";
import Cookie from "js-cookie";
import { Route, Redirect } from "react-router-dom";
import { CONFIG } from "../constants";

export const LoginRoute = ({ component: Component, ...rest }) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Redirect to="/" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
