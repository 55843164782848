import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  name,
  placeholder,
  value,
  label,
  onChange,
  onClick,
  type,
  required,
  subtitle,
  min,
  max,
  weight
}) => {
  if (type === 'textarea') {
    return (
      <div className="styled-input">
        <label htmlFor={name}>
          <span style={{ fontWeight: weight ? 600 : '' }}>{label}{required ? '*' : ''}</span>
          <textarea
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            required={required}
          />
          {
            subtitle && <small>{subtitle}</small>
          }
        </label>
      </div>
    );
  }

  return (
    <div className="styled-input">
      <label htmlFor={name}>
        <span style={{ fontWeight: weight ? 600 : '' }}>{label}{required ? '*' : ''}</span>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onClick={onClick}
          required={required}
          min={min}
          max={max}
        />
        {
          subtitle && <small>{subtitle}</small>
        }
      </label>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['text', 'email', 'password', 'tel', 'textarea', 'number']),
  required: PropTypes.bool,
  subtitle: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  weight: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: '',
  label: '',
  type: 'text',
  onClick: () => { },
  required: false,
  subtitle: null,
  min: 0,
  max: null
};

export default Input;
