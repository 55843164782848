import { SHOW_DIALOG, HIDE_DIALOG } from '../constants/action-types';

export const showDialog = (title, message, confirm, yesLabel = 'Yes', noLabel = 'No') => ({
  type: SHOW_DIALOG,
  payload: {
    title,
    message,
    confirm,
    yesLabel,
    noLabel
  }
});

export const addToCartDialog = (title, confirm, yesLabel = 'Go to Cart', noLabel = 'Continue') => ({
  type: SHOW_DIALOG,
  payload: {
    title,
    confirm,
    yesLabel,
    noLabel
  }
});

export const showDialogNotification = (title, message, confirm, yesLabel = 'Continue', noLabel = 'Cancel') => ({
  type: SHOW_DIALOG,
  payload: {
    title,
    message,
    confirm,
    yesLabel,
    noLabel
  }
});

export const showMessageDeleteDialog = (title, message, confirm, yesLabel = 'Confirm', noLabel = 'Cancel') => ({
  type: SHOW_DIALOG,
  payload: {
    title,
    message,
    confirm,
    yesLabel,
    noLabel
  }
});

export const hideDialog = () => (
  {
    type: HIDE_DIALOG
  }
);
