import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import sitemap from "../../../../routing/siteMap";
import "./CategorySelectDialog.scss";

const dialogClasses = {
  paper: {
    width: "100%",
    padding: 0,
    borderRadius: 0,
    maxWidth: 300,
  },
};

const CategorySelectDialog = ({ isOpen, onClose, classes, categories }) => {
  const categoryList = categories.map((category) => (
    <li key={`store-cat-${category._id}`}>
      <NavLink
        onClick={onClose}
        exact
        to={generatePath(sitemap.exclusive, { cat: category.slug })}
      >
        <span>{category.name}</span>
      </NavLink>
    </li>
  ));
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={classes}
    >
      <div id="category-select-dialog-title">
        <span>Categories</span>
        <span className="_close" onClick={onClose} role="button" tabIndex="-1">
          <CloseIcon />
        </span>
      </div>
      <div id="category-select-dialog-message">
        <ul>
          <li>
            <NavLink onClick={onClose} exact to="/store/all">
              <span>All</span>
            </NavLink>
          </li>
          {categoryList}
        </ul>
      </div>
    </Dialog>
  );
};

CategorySelectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(dialogClasses)(CategorySelectDialog);
