import React, { Component } from 'react';
import axios from 'axios';

import App from './App';
import { CONFIG } from './constants';

class AppContainer extends Component {
  constructor() {
    super();
    this.state = {
      maintenanceMode: false
    };
  }

  componentDidMount() {
    axios.get(`${CONFIG.API_URL}/check`)
      .catch(() => {
        this.setState({ maintenanceMode: true });
      });
  }

  render() {
    const { maintenanceMode } = this.state;
    const styles = {
      background: '#984b48',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    };
    return (
      <>
        {
          maintenanceMode
            ? (
              <div id="maintenance-mode" style={styles}>
                <span>The site is offline.<br/> Please check back again later.</span>
              </div>
            )
            : <App/>
        }
      </>
    );
  }
}

export default AppContainer;
