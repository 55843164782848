import axios from 'axios';
import Cookie from 'js-cookie';
import { CONFIG } from '../constants';

export const getAllPosts = (params, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = CONFIG.GET_POSTS_ALL;
  axios({
    url: apiUrl,
    method: 'GET',
    params,
    headers: {
      Authorization: `JWT ${token}`
    }
  })
    .then((response) => {
      if (response.data.status === 'success') {
        success({ posts: response.data.message, total: response.data.total });
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getPostsByFollowed = (params, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = CONFIG.GET_POSTS_BY_FOLLOWED;
  axios({
    url: apiUrl,
    method: 'GET',
    params,
    headers: {
      Authorization: `JWT ${token}`
    }
  })
    .then((response) => {
      if (response?.data?.status === 'success') {
        success({ posts: response?.data?.message, total: response?.data?.total });
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};

