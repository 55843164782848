import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { func, any, objectOf } from "prop-types";
import { AiOutlineShoppingCart } from "react-icons/all";

import { nl2br } from "../../utils/stringUtils";
import css from "./exhibitionSlider.module.scss";

const DetailModal = ({ item, onClose, onImageOpen, onBuy }) => (
  <div className={css.imageModal}>
    <div
      className={css.backdropDetails}
      onClick={onClose}
      role="button"
      tabIndex="-1"
    />
    <div className={css.imageContainer}>
      <div style={{ top: -60, position: "absolute", display: "flex" }}>
        <button onClick={onImageOpen} className={css.button}>
          Full Size Image
        </button>
        <button className={css.buy} onClick={onBuy}>
          <AiOutlineShoppingCart />
          <span>Buy</span>
        </button>
      </div>
      <div className={css.detailsModal}>
        <div className={css._title}>{item?.title}</div>
        {nl2br(item?.details)}
      </div>
      <CloseIcon onClick={onClose} className={css.close} />
    </div>
  </div>
);

DetailModal.propTypes = {
  item: objectOf(any).isRequired,
  onClose: func.isRequired,
};

export default DetailModal;
