import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './artShowcase.scss';

class ArtShowcase extends Component {
  constructor() {
    super();
    this.state = {
      containerWidth: 0
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      containerWidth: this.containerRef.current.clientWidth
    });
  }

  makeColumns = (columnIndex) => {
    const { children, columnCount, gutter } = this.props;
    const itemClasses = {
      paddingBottom: gutter
    };

    return children.map((listItem, index) => {
      if ((index === columnIndex) || ((index % columnCount) === columnIndex)) {
        return (
          <div
            key={`showcase-item-${index}-${columnIndex}`}
            className="showcase-item"
            style={itemClasses}
          >
            {listItem}
          </div>
        );
      }
      return '';
    });
  }

  render() {
    const { columnCount, gutter } = this.props;
    const { containerWidth } = this.state;

    const containerStyles = {
      marginLeft: (gutter / 2) * -1,
      marginRight: (gutter / 2) * -1
    };

    const columns = [];
    for (let i = 0; i < columnCount; i++) {
      const columnClasses = {
        paddingLeft: gutter / 2,
        paddingRight: gutter / 2
      };

      const items = containerWidth > 0 && this.makeColumns(i);

      columns.push(
        <div
          key={`showcase-column-${i}`}
          className="showcase-column"
          style={columnClasses}
        >
          {items}
        </div>
      );
    }
    return (
      <div
        className="art-showcase-container"
        ref={this.containerRef}
        style={containerStyles}
      >
        {columns}
      </div>
    );
  }
}

ArtShowcase.propTypes = {
  children: PropTypes.node.isRequired,
  columnCount: PropTypes.number,
  gutter: PropTypes.number
};

ArtShowcase.defaultProps = {
  columnCount: 3,
  gutter: 15
};

export default ArtShowcase;
