import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import VerifiedIcon from "../../assets/img/Verified.png";

import sitemap from "../../routing/siteMap";
import Avatar from "../Avatar";
import Loading from "../Loading";
import { get, postAuth } from "../../services/generalApiServices";
import { CONFIG } from "../../constants";
import "./Comment.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});

class Comment extends Component {
  state = {
    comment: "",
    comments: [],
    commenting: false,
    total: CONFIG.FEED_COMMENTS_LIMIT,
    loadingComments: true,
    isCommmentsTruncated: true,
  };

  componentDidMount() {
    this.getComments();
  }

  getComments = () => {
    const { item } = this.props;
    const { total } = this.state;
    get(
      generatePath(CONFIG.GET_POST_COMMENTS, { postId: item?._id, total }),
      (post) => {
        this.setState({ comments: post?.comments, loadingComments: false });
      }
    );
  };

  handleNewComment = (e) => {
    const { refresh, user } = this.props;

    e.preventDefault();
    this.props.handler();
    const { item, comment, clearComment, commentCount } = this.props;
    this.setState({ commenting: true });
    let author = {
      name: user?.name,
      author: {
        picture: user?.picture || "assets/profile-dummy.png",
      },
    };
    let newComments = [...this.state.comments];
    newComments.push({
      author,
      comment,
      _id: `comments${this.state.comments.length}`,
      created: moment(),
    });
    commentCount(newComments);
    postAuth(
      CONFIG.MAKE_POST_COMMENT,
      () => {
        this.setState({ commenting: false });
        clearComment();
        this.getComments();
      },
      (err) => {
        console.error(err);
        clearComment();
        this.setState({ commenting: false });
      },
      {
        comment,
        post_id: item?._id,
      }
    );
  };

  showAllComments = () => {
    this.setState({
      total: 0,
    });
  };

  render() {
    const { user, showComments } = this.props;
    const {
      comments,
      comment,
      commenting,
      loadingComments,
      total,
      isCommmentsTruncated,
    } = this.state;

    const commentsNewFirst = comments.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });
    const truncatedComments = [];
    commentsNewFirst.forEach((commentItem, i) => {
      if (total === 0 || i < total) {
        truncatedComments.push(commentItem);
      }
    });

    const commentsList = truncatedComments.map((commentItem) => (
      <div
        className="commonCommentItem"
        key={`post-comment-${commentItem?._id}`}
      >
        <Avatar
          imgPath={
            commentItem?.author?.picture &&
            commentItem?.author?.picture != "undefined" &&
            `users/${commentItem?.author?.picture}`
          }
        />
        <div className="commentField">
          <p>
            <span style={{ marginRight: "10px" }}>
              <b>
                {commentItem?.author?.name}
                {commentItem?.author?.verifiedArtist && (
                  <img
                    src={VerifiedIcon}
                    style={{
                      marginLeft: "5px",
                      marginBottom: "3px",
                      height: "12px",
                    }}
                  />
                )}
              </b>
            </span>
            <span>{commentItem?.comment}</span>
            {"     "}
            <span
              style={{
                color: "#BBBBBB",
                fontSize: "12px",
                marginLeft: "7px",
                fontWeight: "bold",
              }}
            >
              {moment(commentItem?.created).fromNow()}
            </span>
          </p>
          {/* <h5>{commentItem?.author?.name}</h5>
          <div className="inner" style={{ marginLeft: "10px" }}>
            {commentItem?.comment}
            <span
              style={{ marginLeft: "7px", fontSize: "12px", color: "#BBBBBB" }}
            >
              {moment(commentItem?.created).fromNow()}
            </span>
          </div> */}
        </div>
      </div>
    ));
    return (
      <div className="comments-wrap">
        {showComments && (
          <div className="comments">
            <div className="commonCommentContainer">
              {loadingComments ? <Loading color="red" /> : commentsList}
            </div>
            {isCommmentsTruncated &&
            comments?.length > truncatedComments?.length ? (
              <div
                className="commentsViewMore"
                onClick={this.showAllComments}
                role="button"
                tabIndex="-1"
              >
                <p className="coSmallText">
                  View More Comments <DownArrow />
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {user ? (
          <div className="comments newComments">
            <div className="commonCommentContainer">
              <div className="commonCommentItem">
                {/* <Avatar
                      imgPath={`users/${user.picture}`}
                    /> */}
                <div className="commentField">
                  <div className="inner">
                    <form
                      onSubmit={(e) => {
                        this.handleNewComment(e);
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Add a comment..."
                        value={this.props.comment}
                        disabled={commenting}
                        onChange={this.props.onChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="not-logged-in-comment">
            <Link to={sitemap.login}>Add a comment</Link>
          </div>
        )}
      </div>
    );
  }
}

Comment.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func,
};

Comment.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(Comment);
