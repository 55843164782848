import React from 'react';
import PropTypes from 'prop-types';

import ThreeDots from '../ThreeDots';
import styles from './Loading.module.css';

const Loading = ({ color }) => (
  <div className={styles.wrapper}>
    <ThreeDots
      variant="flashing"
      color={color}
    />
  </div>
);

Loading.propTypes = {
  color: PropTypes.oneOf(['white', 'red'])
};

Loading.defaultProps = {
  color: 'white'
};

export default Loading;
