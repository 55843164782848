import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';

import Button from '../../Button';
import store from '../../../redux';
import { showGlobalSnack } from '../../../redux/actions/snack.actions';
import { showDialog, hideDialog } from '../../../redux/actions/dialog.actions';
import { post } from '../../../services/generalApiServices';
import { CONFIG } from '../../../constants';
import sitemap from '../../../routing/siteMap';
import './Login.scss';

class VerifyEmail extends Component {
  state = {
    code: '',
    verifyInProgress: false,
    resendEmail: '',
    resendEmailInProgress: false
  };

  handleVerifyCode = (e) => {
    e.preventDefault();
    const { code } = this.state;
    const { history } = this.props;
    if (code !== '') {
      this.setState({
        verifyInProgress: true
      }, () => {
        post(
          generatePath(CONFIG.VERIFY_CODE, { code }),
          () => {
            store.dispatch(showDialog(
              'Registration Successful',
              'Thank you for registering. Would you like to login now?',
              () => {
                history.push(sitemap.login);
                store.dispatch(hideDialog());
              }
            ));
          },
          () => {
            store.dispatch(showGlobalSnack('error', 'The code you entered is invalid', 3000));
            this.setState({ verifyInProgress: false });
          }
        );
      });
    }
  }

  handleResendEmail = (e) => {
    e.preventDefault();
    const { resendEmail } = this.state;
    if (resendEmail !== '') {
      this.setState({
        resendEmailInProgress: true
      }, () => {
        post(
          CONFIG.RESEND_CODE,
          () => {
            store.dispatch(showGlobalSnack('normal', `An email has been sent to ${resendEmail}`, 3000));
            this.setState({ resendEmailInProgress: false });
          },
          () => {
            store.dispatch(showGlobalSnack('error', 'The email you entered is not associated with any account.', 3000));
            this.setState({ resendEmailInProgress: false });
          },
          {
            email: resendEmail
          }
        );
      });
    }
  }

  render() {
    const { code, verifyInProgress, resendEmail, resendEmailInProgress } = this.state;
    return (
      <div id="login-page">
        <section className="contentSection">
          <div className="customContainer headerLayout ">
            <div className="sectionContent">
              <div className="customTabContent">
                <div className="verificationContainer">
                  <div className="verificationItem">
                    <div className="title">
                      <h2>Verify Email</h2>
                      <p>Enter your verification code</p>
                    </div>
                    <div className="content">
                      <div className="customForm">
                        <form onSubmit={this.handleVerifyCode}>
                          <div className="fields">
                            <div className="formGroup">
                              <input
                                type="text"
                                placeholder="Verification Code"
                                name="verification"
                                id="verification"
                                value={code}
                                onChange={(e) => { this.setState({ code: e.target.value }); }}
                              />
                            </div>
                          </div>
                          <div className="formAction">
                            <Button loading={verifyInProgress} size="full" mode="specialBlack" type="submit">Register</Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="verificationItem">
                    <div className="title">
                      <h2>Resend Email</h2>
                      <p>Enter your email if you didn‘t get a verification email. We‘ll resend the verification mail.</p>
                    </div>
                    <div className="content">
                      <div className="customForm">
                        <form onSubmit={this.handleResendEmail}>
                          <div className="fields">
                            <div className="formGroup">
                              <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                id="email"
                                value={resendEmail}
                                onChange={(e) => { this.setState({ resendEmail: e.target.value }); }}
                              />
                            </div>
                          </div>
                          <div className="formAction">
                            <Button size="full" mode="specialBlack" type="submit" loading={resendEmailInProgress}>Send</Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VerifyEmail;
