import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { generatePath } from "react-router";
import { withRouter } from "react-router-dom";

import {
  get,
  postAuthMultipart,
} from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import Header from "../../../UserPageHeader";
import ContentCard from "../../../ContentCard";
import { Input } from "../../../Form";
import FancyNav from "../../../FancyNav";
import ImageUploader from "../../../ImageUploader";
import sitemap from "../../../../routing/siteMap";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import "./Upload.scss";
import { isUserArtist } from "../../../../utils/userUtils";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCategories: [],
      images: [],
      tags: [],
      coverImageIndex: 0,
      name: null,
      description: null,
      price: null,
      stock: null,
      width: "",
      height: "",
      breadth: "",
      weight: "",
      mainCategory: null,
      subCategory: [],
      uploading: false,
      tagLimitOver: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    get(
      CONFIG.GET_CATEGORIES_SORTED,
      (allCategories) => {
        this.setState({
          allCategories,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }
  onTagsChanged(tags) {
    if (tags.length < 10) {
      this.setState({ tags });
      this.setState({ tagLimitOver: false });
    } else {
      this.setState({ tags });
      this.setState({ tagLimitOver: true });
    }
  }

  handleCategorySelect = (categoryId) => {
    this.setState((prevState) => {
      if (prevState.mainCategory !== categoryId) {
        return {
          mainCategory: categoryId,
          subCategory: [],
        };
      }

      return {
        mainCategory: categoryId,
      };
    });
  };

  handleSubCategoryClick = (categoryId) => {
    const { subCategory } = this.state;
    const updatedCategories = [...subCategory];
    const index = updatedCategories.indexOf(categoryId);
    if (index > -1) {
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(categoryId);
    }

    this.setState({ subCategory: updatedCategories });
    // this.setState({
    //   subCategory: categoryId === subCategory ? null : categoryId
    // });
  };

  handleAddImage = (file, dataUrl, id) => {
    const { images } = this.state;
    const newImagesState = [...images];
    newImagesState.push({
      id,
      file,
      dataUrl,
    });
    this.setState({ images: newImagesState });
  };

  handleSetCoverImage = (coverImageIndex) => {
    this.setState({ coverImageIndex });
  };

  handleRemoveImage = (imageId) => {
    const { images, coverImageIndex } = this.state;
    const newImages = [...images];
    const imageIdIndex = newImages.findIndex((image) => image.id === imageId);
    const coverImageId = newImages[coverImageIndex].id;
    newImages.splice(imageIdIndex, 1);
    const newCoverImageIndex = newImages.findIndex(
      (image) => image.id === coverImageId
    );
    this.setState({
      images: newImages,
      coverImageIndex: newCoverImageIndex < 0 ? 0 : newCoverImageIndex,
    });
  };

  handleUpload = () => {
    const { user } = this.props;
    const {
      images,
      coverImageIndex,
      name,
      description,
      mainCategory,
      subCategory,
      price,
      stock,
      width,
      height,
      breadth,
      weight,
      tags,
    } = this.state;

    const { history } = this.props;

    const data = new FormData();

    // validate images
    // upload images limit
    if (images.length > CONFIG.UPLOAD_ART_IMAGES_LIMIT) {
      store.dispatch(
        showGlobalSnack(
          "error",
          `You can only upload ${CONFIG.UPLOAD_ART_IMAGES_LIMIT} images`,
          3000
        )
      );
      return;
    }

    if (images.length < 1) {
      store.dispatch(
        showGlobalSnack("error", "You have to upload at least one image", 3000)
      );
      return;
    }

    // image
    data.append("image", images[coverImageIndex].file);

    // images
    for (let i = 0; i < images.length; i++) {
      if (i !== coverImageIndex) {
        data.append("images", images[i].file);
      }
    }

    // name
    if (name) {
      data.append("name", name);
    } else {
      store.dispatch(showGlobalSnack("error", "Name is required", 3000));
      return;
    }

    // description
    if (description) {
      data.append("description", description);
    } else {
      // store.dispatch(showGlobalSnack('error', 'Description is required', 3000));
      // return;
    }

    // categories
    // if (mainCategory) {
    //   data.append('categories[]', mainCategory);
    // } else {
    //   store.dispatch(showGlobalSnack('error', 'Please select a category.', 3000));
    //   return;
    // }

    // if (subCategory) {
    //   data.append('categories[]', subCategory);
    // } else {
    //   store.dispatch(showGlobalSnack('error', 'Please select a sub category.', 3000));
    //   return;
    // }

    // categories
    if (mainCategory) {
      data.append("categories[]", mainCategory);
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please select a category.", 3000)
      );
      return;
    }

    if (subCategory.length > 0) {
      subCategory.forEach((id) => data.append("categories[]", id));
    }

    if (tags.length > 0) {
      if (tags.length < 10) {
        tags.forEach((item) => data.append("tags[]", item));
      }
    }

    // price
    if (price || price === 0) {
      data.append("price", price);
    } else {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please provide the price. Enter 0 to list as Not For Sale",
          3000
        )
      );
      return;
    }

    if (price && price > 10000000) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter price less than or equals 10000000",
          3000
        )
      );
      return;
    }

    // stock
    if (stock || stock === 0) {
      data.append("stock", stock);
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please provide the stock amount", 3000)
      );
      return;
    }
    if (stock && stock > 50) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter stock less than or equals 50",
          3000
        )
      );
      return;
    }

    if (height && height > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter height less than or equals 240",
          3000
        )
      );
      return;
    }

    if (width && width > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter width less than or equals 240",
          3000
        )
      );
      return;
    }

    if (breadth && breadth > 120) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter breadth less than or equals 120",
          3000
        )
      );
      return;
    }

    if (weight && weight > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter weight less than or equals 100kg",
          3000
        )
      );
      return;
    }

    data.append("attributes[width]", width);
    data.append("attributes[height]", height);
    data.append("attributes[breadth]", breadth);
    data.append("attributes[weight]", weight);

    // if everything ok

    if (isUserArtist(this.props?.user?.role)) {
      this.setState(
        {
          uploading: true,
        },
        () => {
          postAuthMultipart(
            CONFIG.UPLOAD_ART,
            (res) => {
              store.dispatch(
                showGlobalSnack(
                  "normal",
                  "You have successfully uploaded your artwork",
                  3000
                )
              );
              history.push(
                generatePath(sitemap.artWork, { slug: res.data.productSlug })
              );
            },
            (e) => {
              console.error(e);
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong. Please try again.",
                  3000
                )
              );
            },
            data
          );
        }
      );
    } else {
      this.setState(
        {
          uploading: true,
        },
        () => {
          postAuthMultipart(
            CONFIG.UPLOAD_ART_BY_USER,
            (res) => {
              store.dispatch(
                showGlobalSnack(
                  "normal",
                  "You have successfully uploaded your artwork",
                  3000
                )
              );
              history.push(
                generatePath(sitemap.userArt, { slug: res.data.productSlug })
              );
            },
            (e) => {
              console.error(e);
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong. Please try again.",
                  3000
                )
              );
            },
            data
          );
        }
      );
    }
  };

  cancelUpload = () => {
    const { user, history } = this.props;
    // if (user.role > 10 && user.role < 21) {
    //   history.push(generatePath(sitemap.artist, { slug: user.slug }));
    // } else {
    history.push(generatePath(sitemap.profile, { slug: user.slug }));
    // }
  };

  render() {
    const { history, user } = this.props;
    const {
      allCategories,
      images,
      coverImageIndex,
      name,
      description,
      mainCategory,
      subCategory,
      price,
      stock,
      width,
      height,
      breadth,
      weight,
      uploading,
    } = this.state;
    const topCategories = allCategories.filter((category) => !category.parent);
    const categoryNavList = topCategories.map((category) => ({
      name: category.name,
      key: category._id,
    }));
    const categoryChildren = allCategories.filter(
      (category) => category.parent && category.parent._id === mainCategory
    );

    const categoryChildrenList = categoryChildren.map((category) => {
      const buttonClasses = classnames("category-select", {
        active: subCategory.indexOf(category._id) > -1,
      });
      return (
        <button
          key={`category-select-${category._id}`}
          className={buttonClasses}
          onClick={() => {
            this.handleSubCategoryClick(category._id);
          }}
        >
          {category.name}
        </button>
      );
    });

    return (
      <div id="page-upload-art">
        <Header
          title="Upload Art"
          subTitle="Upload high quality and original content"
          cancel={this.cancelUpload}
          publish={this.handleUpload}
          loading={uploading}
        />

        <section className="commonFormSection">
          <div className="customContainer smallWidthLayout">
            <ContentCard title="Upload Images">
              <ImageUploader
                images={images}
                coverImageIndex={coverImageIndex}
                addImage={this.handleAddImage}
                setCoverImage={this.handleSetCoverImage}
                removeImage={this.handleRemoveImage}
              />
            </ContentCard>
            <ContentCard title="Basic Information">
              <Input
                required
                weight
                label="Name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
              <Input
                weight
                label="Description"
                type="textarea"
                name="description"
                value={description}
                onChange={(e) => {
                  this.setState({ description: e.target.value });
                }}
              />
            </ContentCard>
            <ContentCard title="Categories">
              {categoryNavList.length > 0 && (
                <FancyNav
                  activeItemKey={mainCategory}
                  items={categoryNavList}
                  onClick={this.handleCategorySelect}
                />
              )}
              <div className="category-list">{categoryChildrenList}</div>
            </ContentCard>

            <ContentCard title="Price and Stock" className="typeThreeCol">
              <div className="typeThreeCol">
                <Input
                  weight
                  required
                  label="Price"
                  type="number"
                  name="price"
                  value={price}
                  subtitle="Enter 0 to list as Not For Sale"
                  min={0}
                  onChange={(e) => {
                    this.setState({ price: e.target.value });
                  }}
                />
                <Input
                  weight
                  required
                  label="Stock"
                  type="number"
                  name="stock"
                  value={stock}
                  min={0}
                  onChange={(e) => {
                    this.setState({ stock: e.target.value });
                  }}
                />
              </div>
            </ContentCard>

            <ContentCard title="Dimensions (inch,kg)">
              <div className="typeThreeCol">
                <Input
                  weight
                  label="Height"
                  type="number"
                  name="height"
                  value={height}
                  onChange={(e) => {
                    this.setState({ height: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Width"
                  type="number"
                  name="width"
                  value={width}
                  onChange={(e) => {
                    this.setState({ width: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Breadth"
                  type="number"
                  name="breadth"
                  value={breadth}
                  onChange={(e) => {
                    this.setState({ breadth: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Weight"
                  type="number"
                  name="weight"
                  value={weight}
                  onChange={(e) => {
                    this.setState({ weight: e.target.value });
                  }}
                />
              </div>
            </ContentCard>

            <ContentCard
              title="Artwork Tags"
              optional="(Optional)"
              heading="Add up to 10 keyword to help people discover your project"
            >
              <div className="tagsWrapper ">
                <InputTags
                  placeholder="Enter tags and press enter"
                  values={this.state.tags}
                  onFocus={() => {
                    window.scrollTo(0, 0);
                  }}
                  onTags={(value) => this.onTagsChanged(value?.values)}
                  disabled={this.state.tagLimitOver ? true : false}
                />
              </div>
            </ContentCard>
          </div>
        </section>
      </div>
    );
  }
}

Upload.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Upload);
