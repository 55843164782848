import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { isUserArtist } from "../../../utils/userUtils";
import { get, getWithAuth } from "../../../services/generalApiServices";
import { CONFIG } from "../../../constants";
import Loading from "../../Loading";
import siteMap from "../../../routing/siteMap";
import { getThumbnail } from "../../../utils/mediaUtils";
import Avatar from "../../Avatar";
import AdvanceAvatarInlineStudio from "../../AdvancedAvatarInline/AdvanceAvatarInlineStudio";
import Image from "../../ImageTag/Image";

import "./Search.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});
class Search extends Component {
  state = {
    artists: [],
    products: [],
    isLoading: true,
  };

  componentDidMount() {
    const { match, user } = this.props;
    if (user) {
      getWithAuth(
        generatePath(CONFIG.SEARCH, { term: match?.params?.term }),
        (data) =>
          this.setState({
            products: data.products,
            artists: data.artists,
            isLoading: false,
          }),
        () => this.setState({ isLoading: false })
      );
    } else {
      get(
        generatePath(CONFIG.SEARCH, { term: match?.params?.term }),
        (data) =>
          this.setState({
            products: data.products,
            artists: data.artists,
            isLoading: false,
          }),
        () => this.setState({ isLoading: false })
      );
    }
  }

  render() {
    const { isLoading, artists, products } = this.state;
    const { match } = this.props;
    const dummyImage = "assets/profile-dummy.png";
    const artistList = artists.map((artist) => {
      return (
        <div
          className="search-result artist"
          key={`search-page-result-${artist?._id}`}
        >
          {isUserArtist(artist.role) ? (
            <Link to={generatePath(siteMap.artist, { slug: artist?.slug })}>
              <Avatar
                imgPath={
                  artist?.picture &&
                  artist?.picture != "undefined" &&
                  `users/${artist?.picture}`
                }
              />
              <div className="info">
                <div className="artist-name">{artist?.name}</div>
              </div>
            </Link>
          ) : (
            <Link to={generatePath(siteMap.normalUser, { slug: artist?.slug })}>
              <Avatar
                imgPath={
                  artist?.picture &&
                  artist?.picture != "undefined" &&
                  `users/${artist?.picture}`
                }
              />
              <div className="info">
                <div className="artist-name">{artist?.name}</div>
              </div>
            </Link>
          )}
        </div>
      );
    });

    const productList = products.map((product) => {
      const thumbnail = getThumbnail(product?.image, "thumbnail");
      return (
        <div
          className="search-result product"
          key={`search-page-result-${product?._id}`}
        >
          {isUserArtist(product?.author?.role) ? (
            <Link to={generatePath(siteMap.artWork, { slug: product?.slug })}>
              <Image src={thumbnail?.url} alt="" />
              <div className="info">
                <div className="name">{product?.name}</div>
                <div className="author">By: {product?.author?.name}</div>
              </div>
            </Link>
          ) : (
            <Link to={generatePath(siteMap.userArt, { slug: product?.slug })}>
              <Image src={thumbnail?.url} alt="" />
              <div className="info">
                <div className="name">{product?.name}</div>
                <div className="author">By: {product?.author?.name}</div>
              </div>
            </Link>
          )}
        </div>
      );
    });
    return (
      <div id="search-page">
        {isLoading ? (
          <Loading color="red" />
        ) : (
          <div className="search-results">
            <div className="customContainer mediumWidthLayout">
              <div className="_title">
                Showing results for: <b>{match?.params?.term}</b>
              </div>
              {products?.length === 0 && artists?.length === 0 ? (
                <div className="no-results">No results found</div>
              ) : (
                <>
                  {artistList}
                  {productList}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};
Search.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(Search);
