import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import ThumbIcon from "@material-ui/icons/ThumbUp";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { CONFIG } from "../../constants";
import store from "../../redux";
import { showGlobalSnack } from "../../redux/actions/snack.actions";
import Styles from "./ButtonLike.module.scss";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const mapStateToProps = (state) => ({
  user: state.user,
});

class ButtonLike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like: false,
      item: props.item,
    };
  }

  componentDidMount() {
    const { user, item } = this.props;
    if (user !== null) {
      const userLikes = user && item.likes.find((userId) => userId === user.id);
      if (userLikes) {
        this.setLike();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user, item } = this.props;
    if (prevProps.user !== user) {
      if (user !== null) {
        const userLikes =
          user && item.likes.find((userId) => userId === user.id);
        if (userLikes) {
          this.setLike();
        }
      }
    }
  }

  setLike = () => {
    this.setState({ like: true });
  };

  handleClick = () => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    const { history } = this.props;
    if (token) {
      this.handleLike();
    } else {
      store.dispatch(
        showGlobalSnack("normal", "You need to login first!", 3000)
      );
      history.push("/login");
    }
  };

  handleLike = () => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    const { user } = this.props;

    const newItem = { ...this.state.item };
    if (newItem.likes.includes(user?.id)) {
      let lk = [...newItem.likes];
      let likes = lk.filter((obj) => obj != user?.id);
      newItem.likes = likes;
      this.setState({ like: false });
    } else {
      newItem.likes.push(this.props.user?.id);
      this.setState({ like: true });
    }
    this.setState({ item: newItem });
    axios({
      url: `${CONFIG.API_URL}/post/like`,
      method: "POST",
      data: { post: this.state.item._id },
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          // refresh();
        } else throw new Error(response.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { like, item } = this.state;
    const buttonClasses = classnames({
      [Styles.commonLikeButton]: true,
      [Styles.isLiked]: like,
    });
    const iconStyles = {
      color: like ? "#fff" : "#000",
    };
    return (
      <>
        {like ? (
          <FaHeart className="heartIcon" onClick={this.handleClick} />
        ) : (
          <FaRegHeart className="heartIconUnlike" onClick={this.handleClick} />
        )}
        <span style={{ padding: "0 5px" }}>{item?.likes?.length}</span>
      </>
    );
  }
}

ButtonLike.propTypes = {
  item: PropTypes.shape({
    likes: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.func,
  user: PropTypes.objectOf(PropTypes.any),
};

ButtonLike.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(withRouter(ButtonLike));
