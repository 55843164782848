import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { generatePath, Redirect } from "react-router";

import CONFIG from "../../../constants/config.constants";
import { getWithAuth } from "../../../services/generalApiServices";
import Loading from "../../Loading";
import FeedItem from "../../FeedItem";
import "./feedDetails.scss";
import siteMap from "../../../routing/siteMap";

const FeedDetails = (props) => {
  const [postDetail, setPostDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(CONFIG.FEED_LIMIT);
  const { match } = props;

  useEffect(() => {
    getFeed();
  }, [match?.params?.id]);

  const getFeed = () => {
    getWithAuth(
      generatePath(CONFIG.GET_SINGLE_POST, { id: match?.params?.id }),
      (postDetail) => {
        setPostDetail(postDetail);
        setLoading(false);
      },
      (e) => {
        console.log(e);
        setLoading(false);
      }
    );
  };

  return (
    <div className="feedDetails customContainer smallWidthLayout">
      {loading ? (
        <Loading color="red" />
      ) : (
        <>
          {postDetail ? (
            <div className="commonFeedContainer">
              <FeedItem
                key={`feed-item-${postDetail?._id}`}
                post={postDetail}
                preview={true}
              />
            </div>
          ) : (
            <Redirect to={siteMap.notFound} />
          )}
        </>
      )}
    </div>
  );
};

FeedDetails.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  total: PropTypes.number.isRequired,
};

export default FeedDetails;
