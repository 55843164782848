import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { connect } from "react-redux";

import sitemap from "../../routing/siteMap";
import FollowArtist from "../FollowArtist";
import Avatar from "../Avatar";
import CommonButton from "../Button";

import VerifiedIcon from "../../assets/img/Verified.png";

import "./advanceAvatarInline.scss";

/**
 * Display Avatar with more info
 * @visibleName Advanced Avatar
 */
const mapStateToProps = (state) => ({
  user: state.user,
});
const AdvancedAvatarInline = ({
  artist,
  name,
  imgPath,
  followers,
  works,
  category,
  verified,
  intl,
  user,
}) => {
  const detailsArray = [];
  if (followers)
    detailsArray.push(
      intl.formatMessage({ id: "ui.artist.followers" }, { count: followers })
    );
  if (works)
    detailsArray.push(
      intl.formatMessage({ id: "ui.artist.works" }, { count: works })
    );
  const detailsString = detailsArray.join(" / ");
  return (
    <div className="advanceAvatarInline">
      {imgPath && <Avatar imgPath={imgPath} alt={name} />}
      <div className="infoContainer bsb-container">
        <div>
          <div className="name">
            <h4>
              {name}{" "}
              {verified && (
                <img
                  src={VerifiedIcon}
                  style={{
                    marginBottom: "3px",
                    height: "12px",
                  }}
                />
              )}
            </h4>
          </div>
          <div className="category">
            <h6>{category}</h6>
          </div>
          <div className="details">
            <h6>{detailsString}</h6>
          </div>
        </div>
        <div className="action">
          <ul>
            {user?.id != artist?._id && (
              <li>
                <FollowArtist artist={artist} />
              </li>
            )}
            <li>
              <Link to={generatePath(sitemap.artist, { slug: artist?.slug })}>
                <CommonButton size="medium" mode="profile">
                  Profile
                </CommonButton>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

AdvancedAvatarInline.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
  imgPath: PropTypes.string,
  followers: PropTypes.number,
  works: PropTypes.number,
  name: PropTypes.string.isRequired,
  category: PropTypes.string,
  verified: PropTypes.bool,

  intl: intlShape.isRequired,
};

AdvancedAvatarInline.defaultProps = {
  imgPath: null,
  followers: null,
  works: null,
  category: null,
};

export default connect(mapStateToProps)(injectIntl(AdvancedAvatarInline));
