import React from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Brush";
import Image from "../../ImageTag/Image";
import sitemap from "../../../routing/siteMap";
import LikeArt from "../../LikeArt";

const mapStateToProps = ({ user }) => ({ user });

const UserArtShowcaseItem = ({
  artist,
  thumbnail,
  artName,
  price,
  slug,
  readOnly,
  user,
  userId,
  artistSlug,
  art,
}) => {
  const thumbnailRatio = thumbnail.width / thumbnail.height;
  const paddingBottom = (1 / thumbnailRatio) * 100;
  return (
    <div className="artItem">
      <div className="inner">
        {!readOnly && user && user.id === userId && (
          <Link to={generatePath(sitemap.artWorkEdit, { slug })}>
            <button type="button" className="edit-art">
              <EditIcon style={{ fontSize: 10 }} />
              <span>Edit</span>
            </button>
          </Link>
        )}
        <div className="imageContainer">
          <Link to={generatePath(sitemap.userArt, { slug })}>
            <div
              className="lazy-placeholder"
              style={{ paddingBottom: `${paddingBottom}%` }}
            >
              <LazyLoad>
                <Image src={thumbnail.url} alt="" />
              </LazyLoad>
            </div>
          </Link>
        </div>
        <div className="like">
          <LikeArt art={art} out={true} />
        </div>
        <div className="textContainer">
          <div className="textItem">
            <h5>{artName}</h5>
            <h6>{price.toUpperCase()}</h6>
            <span style={{ color: "#A8A8A8" }}>by {artist}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

UserArtShowcaseItem.propTypes = {
  artist: PropTypes.string.isRequired,
  artName: PropTypes.string.isRequired,
  thumbnail: PropTypes.objectOf(PropTypes.any).isRequired,
  price: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
  artistSlug: PropTypes.string,
};

UserArtShowcaseItem.defaultProps = {
  readOnly: true,
  user: null,
  artistSlug: null,
};

export default connect(mapStateToProps)(UserArtShowcaseItem);
