import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import PropTypes from 'prop-types';
import MessageIcon from '@material-ui/icons/Message';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import SocketContext from '../../../../../socket';
import sitemap from '../../../../../routing/siteMap';
import {
  getAuth,
  getWithAuth,
} from '../../../../../services/generalApiServices';
import { CONFIG } from '../../../../../constants';
import './Messages.scss';

const mapStateToProps = ({ user }) => ({ user });

class Messages extends Component {
  state = {
    newConversations: [],
    seen:false
  };
  componentDidMount() {

    this.getAllConversationOfCurrentUser();
    this.connect();
    if(this.props.messageSeen){
            this.getAllConversationOfCurrentUser();
    }
  }
  componentDidUpdate(prevProps) {
    const { messageSeen } = this.props;
       
    if (prevProps.messageSeen !== messageSeen) {

       this.getAllConversationOfCurrentUser();
    }
  }
  connect = () => {
    const { socket, user } = this.props;
    socket.on('receive-message', (data) => {
      this.getAllConversationOfCurrentUser();
    });
    socket.on('refreshHeaderMessage', () => {
      this.getAllConversationOfCurrentUser();
    });

      // socket.on('message-seen', (data) => {
      //       console.log(data,"data seen")
         
      //      })
     
        
  };


  //   getNewConversations = () => {
  //     getWithAuth(
  //       CONFIG.GET_ACTIVE_MESSAGES,
  //       (newConversations) => this.setState({ newConversations }),
  //       (e) => console.error(e)
  //     );
  //   };
  getAllConversationOfCurrentUser = () => {
    const { user,messageSeen } = this.props;
    
    getAuth(
      `${CONFIG.GET_CONVERSATIONS}?user=${user?.id}`,
      (conversations) =>
       {
        
         this.setState({
          newConversations: conversations,
        })},
      (e) => {
        console.log(e, 'err');
        this.setState({ loadingTo: false });
      }
    );
  };

  

  render() {
    const { newConversations } = this.state;


    const { user } = this.props;
    let unseenMessages = false;
    
    const newConversationsList = _.isEmpty(newConversations)
      ? false
      : newConversations.map((conversation, i) => {
          let messageRead = true;
      
          if (!conversation.seen.includes(user.id)) {
            
            unseenMessages = true;
            // this.setState({seen:true})
            messageRead = false;
          }
          if (!messageRead) {
            const from = _.filter(conversation.participants, (participant) => {
              return participant.id !== user.id;
            });

            return (
              <div
                key={`new-conversation-${i}`}
                className={`dropDownItem ${messageRead ? '' : '_new'}`}
              >
                <NavLink
                  to={generatePath(sitemap.messages, {
                    to: from[0]?._id,
                  })}
                >
                  {from[0].name} sent you a message
                  <p>{moment(conversation.updated).fromNow()}</p>
                </NavLink>
              </div>
            );
          }
          return false;
        });
        
    return (
      <>
        <NavLink to={sitemap.inbox} activeClassName='active'>
          {unseenMessages &&!this.props.messageSeen? (
            <MessageIcon style={{ fill: '#984B48' }} />
          ) : (
            <MessageIcon />
          )}
        </NavLink>
        {/* <div className='dropDownContainer messages-dropdown'>
          <div className='inner'>{newConversationsList}</div>
          <div className='seeMoreMessage'>
            <NavLink to='/inbox' activeClassName='active'>
              See More
            </NavLink>
          </div>
        </div> */}
      </>
    );
  }
}

Messages.propTypes = {
  socket: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const SocketedHeaderMessages = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Messages {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default connect(mapStateToProps)(SocketedHeaderMessages);
