import { createTheme } from "@material-ui/core/styles";

const palette = {
  primary: {
    main: "#984B48",
  },
  secondary: {
    main: "#212121",
  },
  special: {
    main: "#ffffff",
  },
};

export default createTheme({
  palette,
  shadows: Array(25).fill("none"),
  typography: {
    useNextVariants: true,
  },
});
