import axios from "axios";
import Cookie from "js-cookie";

import { CONFIG } from "../constants";
import store from "../redux";
import { showGlobalSnack } from "../redux/actions/snack.actions";

export const get = (api, success, fail = () => {}, params = null) => {
    axios({
        url: api,
        method: "GET",
        params,
    })
        .then((response) => {
            if (response.data.status === "success") {
                success(response.data.message);
            } else throw new Error(response.data.message);
        })
        .catch((e) => {
            fail(e);
        });
};

export const getWithAuth = (api, success, fail = () => {}, params = null) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "GET",
            headers: {
                Authorization: `JWT ${token}`,
            },
            params,
        })
            .then((response) => {
                if (response.data.status === "success") {
                    success(response.data.message);
                } else throw new Error(response.data.message);
            })
            .catch((e) => {
                fail(e);
            });
    } else {
        store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
};

export const getAuth = (api, success, fail = () => {}, params = null) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "GET",
            headers: {
                Authorization: `JWT ${token}`,
            },
            params,
        })
            .then((response) => {
                // if (response.data.status === "success") {
                success(response.data);
                // } else throw new Error(response.data.message);
            })
            .catch((e) => {
                fail(e);
            });
    } else {
        store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
};

export const getWithParams = (api, success, fail, params) => {
    axios({
        url: api,
        method: "GET",
        params,
    })
        .then((response) => {
            if (response.data.status === "success") {
                success(response.data.message);
            } else throw new Error(response.data.message);
        })
        .catch((e) => {
            fail(e);
        });
};

export const postAuth = (api, success, fail, data = {}) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "POST",
            data,
            headers: {
                Authorization: `JWT ${token}`,
            },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    success(response.data.message);
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch((e) => {
                fail(e.message);
            });
    }
};
export const postData = (api, success, fail, data = {}) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "POST",
            data,
            headers: {
                Authorization: `JWT ${token}`,
            },
        })
            .then((response) => {
                success(response.data);
            })
            .catch((e) => {
                fail(e.message);
            });
    }
};

export const postAuthMultipart = (api, success, fail, data = {}) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${token}`,
            },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    success(response);
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch((e) => {
                fail(e.message);
            });
    }
};

export const post = (api, success, fail, data = {}) => {
    axios({
        url: api,
        method: "POST",
        data,
    })
        .then((response) => {
            if (response.data.status === "success") {
                success(response.data.message);
            } else {
                throw new Error(response.data.message);
            }
        })
        .catch((e) => {
            fail(e.message);
        });
};

export const getWithParamsRaw = (api, success, fail, params) => {
    axios({
        url: api,
        method: "GET",
        params,
    })
        .then((response) => {
            if (response.data.status === "success") {
                success(response.data);
            } else throw new Error(response.data.message);
        })
        .catch((e) => {
            fail(e);
        });
};
export const getWithParamsRawAuth = (api, success, fail, params) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "GET",
            headers: {
                Authorization: `JWT ${token}`,
            },
            params,
        })
            .then((response) => {
                if (response.data.status === "success") {
                    success(response.data);
                } else throw new Error(response.data.message);
            })
            .catch((e) => {
                fail(e);
            });
    } else {
        store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
};

export const patchAuth = (api, success, fail, data = {}) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
        axios({
            url: api,
            method: "PATCH",
            data,
            headers: {
                Authorization: `JWT ${token}`,
            },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    success(response);
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch((e) => {
                fail(e.message);
            });
    }
};


export const postPromise = (endpoint, data) =>
    new Promise((resolve, reject) => {
        const token = Cookie.get(CONFIG.AUTH_TOKEN);
        if (token) {
            axios({
                url: endpoint,
                method: "POST",
                data,
                headers: {
                    Authorization: `JWT ${token}`,
                },
            })
                .then((response) => {
                    if (response.data.status === "success") {
                        resolve(response.data.message);
                    } else {
                        throw new Error(response.data.message);
                    }
                })
                .catch((e) => {
                    reject(e.message);
                });
        } else {
            reject(new Error("NO_AUTH"));
        }
    });

    
