import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/img/tulikaa_logo.svg';
import Styles from './Logo.module.scss';

const Logo = () => {
  return (
    <div className={Styles.logo}>
      <NavLink to="/">
        <img className={Styles.logoSvg} src={logo} alt=""/>
      </NavLink>
    </div>
  );
};

export default Logo;
