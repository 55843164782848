import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Cookie from "js-cookie";
import axios from "axios";

import { CONFIG } from "../../../../constants";
import { getWithAuth } from "../../../../services/generalApiServices";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import {
  showDialog,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import { removeCartItem } from "../../../../redux/actions/cart.actions";
import Loading from "../../../Loading/Loading";
import { getThumbnail } from "../../../../utils/mediaUtils";
import sitemap from "../../../../routing/siteMap";
import Image from "../../../ImageTag/Image";
import "./Cart.scss";

const mapStateToProps = ({ user, cart }) => ({ user, cart });

class Cart extends Component {
  state = {
    isSaving: false,
    amount: 0,
    taxAmount: 0,
    serviceCharge: 0,
    deliveryCharge: 0,
    merchantId: null,
    gatewayUrl: null,
    items: [],
    total: 0,
    isLoading: true,
  };

  formValid = false;

  componentDidMount() {
    // this.populateItems();
    this.getCartItems();
  }

  getCartItems = () => {
    getWithAuth(
      CONFIG.CART_URL,
      (items) => {
        this.setState({ items: items, isLoading: false });
      },
      (e) => {
        this.setState({ isLoading: false });
      }
    );
  };
  // populateItems = () => {
  //   const cart = this.props.cart;
  //   this.setState({ items: cart.items, isLoading: false });
  // };

  removeItem = (id) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    store.dispatch(
      showDialog(
        "Remove Item",
        "Are you sure you want to remove this item?",
        () => {
          axios
            .get(`${CONFIG.CART_URL}/removeItem?productId=${id}`, {
              headers: {
                Authorization: `JWT ${token}`,
              },
            })
            .then((response) => {
              store.dispatch(removeCartItem(id));
              store.dispatch(hideDialog());
              this.getCartItems();
            })
            .catch((err) => {
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong while Removing product to cart",
                  3000
                )
              );
            });
        }
      )
    );
  };

  proceedCheckout = () => {
    this.props.history.push("/checkout");
  };

  render() {
    const { isLoading, items } = this.state;
    // const { items } = this.props.cart;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = () => {
      let total = 0;
      let priceList = [];
      if (items?.products?.length > 0) {
        priceList = items?.products.map((item) =>
          parseFloat(item?.product_id?.priceField.split(" ")[1])
        );
        total = total + priceList.reduce(reducer);
      }
      return Math.round(total);
    };

    return (
      <div className="cart-page">
        <div className="cart-header">
          <div className="left">Cart</div>
          <div className="right">
            <span className="total-price">
              Total: NPR.
              {totalPrice()}
            </span>
            {items?.products?.length > 0 && (
              <button className="proceed-btn" onClick={this.proceedCheckout}>
                Proceed To Checkout
              </button>
            )}
          </div>
        </div>
        <span className="responsive-total-price">
          Total: NPR.
          {totalPrice()}
        </span>
        {isLoading ? (
          <Loading color="red" />
        ) : (
          <>
            {items?.products?.length > 0 ? (
              <>
                <div className="items">
                  <table>
                    <thead>
                      <tr>
                        <th> Product</th>
                        <th colSpan="2">Price</th>
                      </tr>
                    </thead>
                    {items?.products.map((item, i) => {
                      const thumbnail = getThumbnail(
                        item?.product_id?.image,
                        "thumbnail"
                      );

                      return (
                        <tbody key={i}>
                          <tr>
                            <td className="art-desc">
                              <Image src={thumbnail.url} alt="" />
                              <div className="desc">
                                <span className="art-name">
                                  {item?.product_id?.name}
                                </span>{" "}
                                <br />
                                <span className="artist">
                                  by {item?.product_id?.author?.name}
                                </span>
                                <br />
                                <span className="responsive-price">
                                  {item?.product_id?.priceField}
                                </span>
                              </div>
                            </td>

                            <td className="art-price">
                              {/* <span>NPR.</span> */}
                              {item?.product_id?.priceField}
                            </td>
                            <td>
                              <IconButton
                                onClick={(id) =>
                                  this.removeItem(item?.product_id?.id)
                                }
                              >
                                <DeleteIcon className="dlt-icon" />
                              </IconButton>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : (
              <div className="no-items">
                You don't have any items in your cart!
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

Cart.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  cart: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(Cart);
