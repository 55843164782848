import React from 'react';
import { node, bool } from 'prop-types';
import classnames from 'classnames';

import './HomeSection.scss';

const HomeSectionTab = ({ children, grey, fullWidth, fullHeight }) => {
    const classes = classnames('home-section', {
        grey,
        fullHeight
    });
    const containerClasses = classnames('customContainer', {
        mediumWidthLayout: !fullWidth
    });
    return (
        <div className={classes}>
            <div className={containerClasses}>
                {children}
            </div>
        </div>
    );
};

HomeSectionTab.propTypes = {
    children: node.isRequired,
    grey: bool,
    fullWidth: bool,
    fullHeight: bool
};

HomeSectionTab.defaultProps = {
    grey: false,
    fullWidth: false,
    fullHeight: false
};

export default HomeSectionTab;
