import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Image from "../ImageTag/Image";
import "./PageHead.scss";

const PageHead = ({ title, imgPath }) => {
  const headClasses = classnames("commonPageHead", {
    withBackgroundImage: imgPath,
  });
  return (
    <div
      className={headClasses}
      // style={
      //   imgPath && {
      //     backgroundImage: `url(https://storage.googleapis.com/tulikaabucketdev/${imgPath})`,
      //   }
      // }
    >
      {imgPath && <Image src={imgPath} />}
      {title && <h1>{title}</h1>}
    </div>
  );
};

PageHead.propTypes = {
  title: PropTypes.string,
  imgPath: PropTypes.string,
};

PageHead.defaultProps = {
  title: null,
  imgPath: null,
};

export default PageHead;
