import React, { Component } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";

import ThumbsUpIcon from "@material-ui/icons/ThumbUp";
import { connect } from "react-redux";
import { objectOf, any, bool } from "prop-types";
import classnames from "classnames";
import { generatePath } from "react-router";
import { withRouter } from "react-router-dom";

import sitemap from "../../routing/siteMap";
import { showGlobalSnack } from "../../redux/actions/snack.actions";
import { setLoginBack } from "../../redux/actions/global.actions";
import { postAuth } from "../../services/generalApiServices";
import { CONFIG } from "../../constants";
import "./LikeArt.scss";

const mapStateToProps = ({ user }) => ({ user });

class LikeArt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      liked:
        props?.user &&
        props?.art?.likes?.findIndex((like) => like === props?.user?.id) > -1,
    };
  }

  handleLikeArt = () => {
    const { art, history, dispatch, user, match } = this.props;
    const { loading } = this.state;

    if (!user) {
      dispatch(showGlobalSnack("normal", "Please login first", 3000));
      dispatch(setLoginBack(match.url));
      history.push(sitemap.login);
      return false;
    }

    if (!loading) {
      this.setState({ loading: true });
      postAuth(
        generatePath(CONFIG.LIKE_PRODUCT, { productId: art?._id }),
        () =>
          this.setState((prevState) => ({
            loading: false,
            liked: !prevState.liked,
          })),
        () =>
          this.setState((prevState) => ({
            loading: false,
            liked: !prevState.liked,
          }))
      );
    }
  };

  render() {
    const { loading, liked } = this.state;
    const { out } = this.props;
    const buttonClasses = classnames("like-art-button", {
      liked,
      loading,
    });
    return (
      <span
        className={buttonClasses}
        role="button"
        tabIndex="-1"
        onClick={this.handleLikeArt}
      >
        {out ? (
          <FaHeart className="heartIcon" style={{ color: "#fff" }} />
        ) : (
          <FaHeart style={{ color: liked ? "#fff" : "#000" }} />
        )}
      </span>
    );
  }
}

LikeArt.propTypes = {
  art: objectOf(any).isRequired,
  user: objectOf(any),
  out: bool,
};

LikeArt.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(withRouter(LikeArt));
