import { combineReducers } from "redux";

import localeReducer from "./locale.reducer";
import userReducer from "./user.reducer";
import snackReducer from "./snack.reducer";
import dialogReducer from "./dialog.reducer";
import cartReducer from "./cart.reducer";
import globalReducer from "./global.reducer";
import userDetails from "./alluser.reducer";

const reducers = combineReducers({
  locale: localeReducer,
  user: userReducer,
  snack: snackReducer,
  dialog: dialogReducer,
  cart: cartReducer,
  global: globalReducer,
  userDetails,
});

export default reducers;
