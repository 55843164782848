import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { GoogleLogin } from "react-google-login";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import {
  FaFacebookF,
  IoLogoGoogle,
  MdMail,
  FaChevronLeft,
} from "react-icons/all";
import { connect } from "react-redux";

import { CONFIG, KEYS } from "../../../constants";
import { userLogin } from "../../../redux/actions/user.actions";
import { showGlobalSnack } from "../../../redux/actions/snack.actions";
import sitemap from "../../../routing/siteMap";
import store from "../../../redux";
import Button from "../../Button";
import "./Login.scss";

const mapStateToProps = ({ global }) => ({ global });

class Login extends Component {
  state = {
    loginPage: true,
    username: "",
    password: "",
    fullName: "",
    email: "",
    registerPassword: "",
    passwordConfirm: "",
    loading: false,
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { history, global } = this.props;
    this.setState({ loading: true });
    axios({
      method: "post",
      url: `${CONFIG.AUTH_URL}/login`,
      data: {
        email: username,
        password,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          const { user } = response.data;
          if (user.emailVerified === "false") {
            store.dispatch(
              showGlobalSnack("normal", "Please verify your email", 3000)
            );
          }
          store.dispatch(userLogin(user, response.data.token));

          if (global.loginBack) {
            history.push(global.loginBack);
          } else {
            history.push("/");
          }
        } else throw new Error(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(showGlobalSnack("error", error.message, 3000));
        this.setState({
          loading: false,
          username: "",
          password: "",
        });
      });
  };

  handleRegister = (e) => {
    e.preventDefault();
    // validate
    const { fullName, email, registerPassword, passwordConfirm } = this.state;

    if (fullName === "") {
      store.dispatch(
        showGlobalSnack("error", "Full Name cannot be empty", 3000)
      );
      return;
    }

    if (email === "") {
      store.dispatch(showGlobalSnack("error", "Email cannot be empty", 3000));
      return;
    }

    if (registerPassword === "") {
      store.dispatch(
        showGlobalSnack("error", "Password cannot be empty", 3000)
      );
      return;
    }

    if (passwordConfirm === "") {
      store.dispatch(
        showGlobalSnack("error", "Password Confirmation cannot be empty", 3000)
      );
      return;
    }

    if (registerPassword !== passwordConfirm) {
      store.dispatch(showGlobalSnack("error", "Passwords do not match", 3000));
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios
          .post(`${CONFIG.AUTH_URL}/register`, {
            name: fullName,
            email,
            password: registerPassword,
            "confirm-password": passwordConfirm,
          })
          .then((response) => {
            this.setState({ loading: false });
            if (response.data.status === "success") {
              this.setState({
                fullName: "",
                email: "",
                registerPassword: "",
                passwordConfirm: "",
                loginPage: true,
              });
              store.dispatch(
                showGlobalSnack(
                  "normal",
                  "Thank you for registering! Please, verify your email(Verification code sent)",
                  7000
                )
              );
            } else if (isArray(response.data.message)) {
              store.dispatch(
                showGlobalSnack("error", response.data.message[0], 5000)
              );
            } else {
              store.dispatch(
                showGlobalSnack("error", response.data.message, 5000)
              );
            }
          })
          .catch((err) => {
            console.error(err);
            store.dispatch(
              showGlobalSnack(
                "error",
                "Something went wrong. Please try again.",
                3000
              )
            );
            this.setState({ loading: false });
          });
      }
    );
  };

  handleFacebookLoginCallback = (userData) => {
    const { history, global } = this.props;
    if (userData?.accessToken) {
      store.dispatch(
        showGlobalSnack(
          "normal",
          "Please wait while we process your request",
          999999999
        )
      );
      axios({
        method: "POST",
        url: CONFIG.FB_VERIFY_TOKEN,
        data: userData,
      })
        .then((response) => {
          if (response?.data?.status === "success") {
            const { user } = response.data;
            if (user?.emailVerified === "false") {
              store.dispatch(
                showGlobalSnack("normal", "Please verify your email", 3000)
              );
            }
            store.dispatch(userLogin(user, response?.data?.token));
            if (global.loginBack) {
              history.push(global.loginBack);
            } else {
              history.push("/");
            }
            store.dispatch(showGlobalSnack("normal", "", 1));
          } else throw new Error(response.data.message);
        })
        .catch((e) => {
          console.error(e);
          store.dispatch(
            showGlobalSnack(
              "error",
              "Something went wrong. Please try again",
              3000
            )
          );
        });
    }
  };

  handleGoogleLoginCallback = (response) => {
    console.log("google res", response);
    const { history, global } = this.props;
    // const token = response?.getAuthResponse()?.id_token;
    const token = response?.access_token;
    // const token = response?.tokenObj.id_token;

    const userData = {
      accessToken: token,
    };

    if (token) {
      store.dispatch(
        showGlobalSnack(
          "normal",
          "Please wait while we process your request",
          999999999
        )
      );
      axios({
        method: "POST",
        url: CONFIG.GOOGLE_VERIFY_TOKEN,
        data: userData,
      })
        .then((res) => {
          console.log("first", res);
          if (res.data.status === "success") {
            const { user } = res.data;
            if (user.emailVerified === "false") {
              store.dispatch(
                showGlobalSnack("normal", "Please verify your email", 3000)
              );
            }
            store.dispatch(userLogin(user, res.data.token));
            if (global.loginBack) {
              history.push(global.loginBack);
            } else {
              history.push("/");
            }
            store.dispatch(showGlobalSnack("normal", "", 1));
          } else throw new Error(res.data.message);
        })
        .catch((e) => {
          console.error(e);
          store.dispatch(
            showGlobalSnack(
              "error",
              "Something went wrong. Please try again",
              3000
            )
          );
        });
    }
  };

  render() {
    const {
      username,
      password,
      loginPage,
      fullName,
      email,
      registerPassword,
      passwordConfirm,
      loading,
    } = this.state;
    return (
      <div id="login-page">
        <section className="contentSection">
          <div className="customContainer headerLayout ">
            <div className="sectionContent addFullHeight">
              <div className="customTabContent">
                <div
                  id="login"
                  className={
                    loginPage ? "customTabItem active" : "customTabItem"
                  }
                >
                  <div className="title">
                    <div className="customTabHeader">
                      <h2>Welcome To Tulikaa</h2>
                      <p>
                        For new users you can directly register and login with
                        your Google or Facebook account. You can also create a
                        new account via Email.
                      </p>
                      <div className="social-logins">
                        <div className="google-login">
                          <GoogleOAuthProvider clientId={KEYS.GOOGLE_CLIENT_ID}>
                            <GoogleButton
                              onSuccess={this.handleGoogleLoginCallback}
                              onFailure={this.handleGoogleLoginCallback}
                            />
                          </GoogleOAuthProvider>
                          {/* <GoogleOAuthProvider clientId={KEYS.GOOGLE_CLIENT_ID}>
                            <GoogleLogin
                              // clientId={KEYS.GOOGLE_CLIENT_ID}
                              onSuccess={this.handleGoogleLoginCallback}
                              onFailure={this.handleGoogleLoginCallback}
                              useOneTap
                              // cookiePolicy="single_host_origin"
                              // render={(renderProps) => (
                              //   <button
                              //     type="button"
                              //     onClick={renderProps.onClick}
                              //     // disabled={renderProps.disabled}
                              //   >
                              //     <IoLogoGoogle />
                              //     <span>Google</span>
                              //   </button>
                              // )}
                            />
                          </GoogleOAuthProvider> */}
                        </div>
                        <div className="facebook-login">
                          <FacebookLogin
                            disableMobileRedirect
                            // appId="547724009141212"
                            appId="645526270696051" //goto public /index.html and change appid chenge here doesn't work
                            fields="name,email,picture"
                            callback={this.handleFacebookLoginCallback}
                            render={(renderProps) => (
                              <button
                                type="button"
                                onClick={renderProps.onClick}
                              >
                                <FaFacebookF />
                                <span>Facebook</span>
                              </button>
                            )}
                          />
                        </div>
                        <button
                          className="email-register"
                          onClick={() => {
                            this.setState({ loginPage: false });
                          }}
                        >
                          <MdMail />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="content-divider">
                    <span>OR</span>
                  </div>
                  <div className="content">
                    <div className="customForm typeLogin">
                      <form onSubmit={this.handleFormSubmit}>
                        <div className="fields">
                          <div className="formGroup">
                            <input
                              placeholder="Email Address"
                              id="email"
                              name="email"
                              autoComplete="email"
                              value={username}
                              onChange={(e) => {
                                this.setState({ username: e.target.value });
                              }}
                            />
                          </div>
                          <div className="formGroup">
                            <input
                              type="password"
                              placeholder="Password"
                              name="password"
                              id="password"
                              autoComplete="current-password"
                              value={password}
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                            />
                          </div>
                          <div className="formGroup typeForgot">
                            <NavLink
                              to={sitemap.resetPassword}
                              activeClassName="active"
                            >
                              Forgot Password ?
                            </NavLink>
                          </div>
                        </div>
                        <div className="formAction">
                          <Button loading={loading} size="full" type="submit">
                            Login
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div
                  id="register"
                  className={
                    loginPage ? "customTabItem" : "customTabItem active"
                  }
                >
                  <div className="title">
                    <div className="customTabHeader">
                      <div
                        className="go-back"
                        role="button"
                        tabIndex="-1"
                        onClick={() => this.setState({ loginPage: true })}
                      >
                        <FaChevronLeft />
                        <span>Go Back</span>
                      </div>
                      <h2>Register Via Email</h2>
                      <p>Register a new account using your Email.</p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="customForm typeLogin">
                      <form onSubmit={this.handleRegister}>
                        <div className="fields">
                          <div className="formGroup">
                            <input
                              value={fullName}
                              type="text"
                              placeholder="Full Name"
                              id="registerName"
                              name="registerName"
                              autoComplete="registerName"
                              onChange={(e) => {
                                this.setState({ fullName: e.target.value });
                              }}
                            />
                          </div>
                          <div className="formGroup">
                            <input
                              type="email"
                              placeholder="Email Address"
                              id="registerEmail"
                              name="registerEmail"
                              autoComplete="registerEmail"
                              value={email}
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                          <div className="formGroup">
                            <input
                              type="password"
                              placeholder="Password"
                              id="registerPassword"
                              name="registerPassword"
                              autoComplete="registerPassword"
                              value={registerPassword}
                              onChange={(e) => {
                                this.setState({
                                  registerPassword: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="formGroup">
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              id="registerConfirmPassword"
                              name="registerConfirmPassword"
                              autoComplete="registerConfirmPassword"
                              value={passwordConfirm}
                              onChange={(e) => {
                                this.setState({
                                  passwordConfirm: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="formGroup">
                            <small>
                              Password must be at least 6 characters long and
                              must contain at least one number
                            </small>
                          </div>
                        </div>
                        <div className="formAction">
                          <Button
                            loading={loading}
                            size="full"
                            mode="specialBlack"
                            type="submit"
                          >
                            Register
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(Login);

function GoogleButton({ onSuccess, onError }) {
  const login = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError,
  });
  return (
    <button type="button" onClick={() => login()}>
      <IoLogoGoogle />
      <span>Google</span>
    </button>
  );
}
