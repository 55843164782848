import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ChatIcon from "@material-ui/icons/Chat";
import Dialog from "@material-ui/core/Dialog";
import { MdReport } from "react-icons/md";
import { generatePath } from "react-router";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { report } from "../../../services/reportServices";
import Button from "../../Button";
import Cookie from "js-cookie";
import { addToCart } from "../../../redux/actions/cart.actions";
import store from "../../../redux";
import { get, postAuth } from "../../../services/generalApiServices";
import { CONFIG } from "../../../constants";
import { showGlobalSnack } from "../../../redux/actions/snack.actions";
import axios from "axios";
import ThreeDots from "../../ThreeDots";
import sitemap from "../../../routing/siteMap";

const mapStateToProps = ({ user, dialog }) => ({
  user,
  dialog,
});
const dialogClasses = {
  paper: {
    width: "100%",
    maxWidth: 280,
    padding: 20,
  },
};

const ArtDetailBreakdown = ({ art, user, classes }) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const history = useHistory();
  const [reportValue, setReportValue] = useState("");
  const [show, setShow] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showRights, setShowRights] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportOpt, setReportOpt] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [otherText, setOtherText] = useState("");
  const categoryList = art?.categories?.map((category) => {
    return (
      <li key={`category-item-${category?._id}`}>
        <NavLink to="#" className="active">
          {category?.name}
        </NavLink>
      </li>
    );
  });
  React.useEffect(() => {
    getReportOptions();
  }, []);

  const addItemToCart = () => {
    if (!token) {
      store.dispatch(
        showGlobalSnack("error", "You must logged in to buy an art", 3000)
      );
      return false;
    }
    setIsLoading(true);
    axios
      .post(
        `${CONFIG.CART_URL}/addItem`,
        { product_id: art._id, quantity: 1 },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        setShow(true);
        setIsLoading(false);
        const products = response?.data?.data?.products;
        const item = products.find((x) => x?._id === art?._id);
        const cartData = {
          product_id: item?._id,
          quantity: 1,
          price: item?.price,
          name: item?.name,
          image: item?.image,
          author: item?.author?.name,
        };
        store.dispatch(addToCart(cartData));
      })
      .catch((err) => {
        setShow(false);
        setIsLoading(false);
        store.dispatch(
          showGlobalSnack(
            "error",
            "Something went wrong while adding product to cart",
            3000
          )
        );
      });
  };

  const getReportOptions = () => {
    get(
      CONFIG.GET_REPORT_OPTIONS,
      (options) => {
        setReportOpt(options);
      },
      (err) => {}
    );
  };
  const goToCart = () => {
    history.push("/cart");
  };
  const closeDialog = () => {
    setShow(false);
    setShowReport(false);
    setShowRights(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setReportValue(e.target.value);
    if (e.target.value === "61cd60b2d9cf90805f719a2c") {
      setShowInput(true);
    }
  };
  const handleSubmit = () => {
    if (reportValue) {
      report(
        generatePath(CONFIG.REPORT_PRODUCT, { id: art?._id }),
        (res) => {
          store.dispatch(
            showGlobalSnack("success", "Product reported successfully", 3000)
          );
          setShowReport(false);
        },
        (err) => {
          store.dispatch(showGlobalSnack("error", err, 3000));
          setShowReport(false);
        },
        {
          category: reportValue,
          content: otherText,
        }
      );
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please Select option to continue", 3000)
      );
    }
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
      >
        <div id="responsive-dialog-title">Artwork has been added to cart</div>
        <div id="responsive-dialog-actions" style={{ marginTop: "14px" }}>
          <span
            role="button"
            tabIndex="-1"
            onClick={closeDialog}
            mode="special"
          >
            Continue
          </span>
          <Button size="auto" onClick={goToCart} mode="specialBlack">
            Go To Cart
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={showReport}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
      >
        <div id="responsive-dialog-title">Report Artwork</div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="report"
            name="report"
            disabled="true"
            value={reportValue}
            onChange={handleChange}
          >
            {reportOpt &&
              reportOpt.map((opt) => (
                <FormControlLabel
                  value={opt?._id}
                  control={<Radio />}
                  label={<div>{opt?.name}</div>}
                />
              ))}
          </RadioGroup>
          {showInput && (
            <input
              placeholder="write reason"
              className="report-input"
              onChange={(e) => setOtherText(e.target.value)}
            />
          )}
        </FormControl>
        <div className="report-btns">
          <Button mode="reportSubmit" onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            mode="special"
            onClick={() => {
              setShowReport(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Dialog>

      <Dialog
        open={showRights}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
      >
        <p>
          All rights reserved to the owner of artwork. No use is allowed without
          permission from owner.
        </p>
      </Dialog>
      <div className="artworkButtonWrapper">
        {art?.inStore && art?.author?._id != user?.id && (
          <>
            <button className="cartButton" onClick={addItemToCart}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? <ThreeDots variant="flashing" /> : "Buy Artwork"}
              </div>
            </button>

            <Link
              to={generatePath(sitemap.messages, { to: art?.author?.slug })}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title={`Message ${art?.author?.name}`}
              >
                <button className="messageButton">
                  <ChatIcon className="icon" />
                  Send Message
                </button>
              </Tooltip>
            </Link>
          </>
        )}
      </div>
      <div className="artDetailBreakdown">
        <div className="breakdownRow">
          <div className="title">
            <h6>Categories</h6>
          </div>
          <div className="content categories">
            <ul>{categoryList}</ul>
          </div>
        </div>

        {/* {
          art.attributes.height || art.attributes.width || art.attributes.breadth
            ? (
              <div className="breakdownRow">
                <div className="title">
                  <h6>Dimension</h6>
                </div>
                <div className="content dimensions">
                  <ul>
                    {
                      art.attributes.height && <li>H: {art.attributes.height} in</li>
                    }
                    {
                      art.attributes.width && <li>W:{art.attributes.width} in</li>
                    }
                    {
                      art.attributes.breadth && <li>B: {art.attributes.breadth} in</li>
                    }
                  </ul>
                </div>
              </div>
            )
            : ''
        } */}
      </div>
      <div className="privacy">
        <span
          // onMouseOver={() => setShowRights(true)}
          onClick={() => setShowRights(true)}
        >
          &copy; All Rights Reserved
        </span>
        {art?.author?._id !== user?.id && (
          <span onClick={() => setShowReport(true)} className="report">
            <MdReport
              style={{
                fontSize: "15px",
                marginRight: "3px",
                marginBottom: "3px",
              }}
            />
            Report
          </span>
        )}
      </div>
    </>
  );
};

ArtDetailBreakdown.propTypes = {
  art: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  dialog: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(
  withStyles(dialogClasses)(ArtDetailBreakdown)
);
