import axios from "axios";
import { generatePath } from "react-router";
import Cookie from "js-cookie";

import { CONFIG } from "../constants";
import store from "../redux";
import { showGlobalSnack } from "../redux/actions/snack.actions";

export const getCategories = (success, fail) => {
  axios({
    url: `${CONFIG.API_URL}/categories`,
    method: "GET",
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success(response?.data?.message);
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getPopularProductsByCategory = (categoryId, success, fail) => {
  axios({
    url: `${CONFIG.API_URL}/products/popular/${categoryId}`,
    method: "GET",
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success(response?.data?.message);
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getPopularProductsByCategoryAuth = (categoryId, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  if (token) {
    axios({
      url: `${CONFIG.API_URL}/products/popular/${categoryId}`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (response?.data?.status === "success") {
          success(response?.data?.message);
        } else throw new Error(response?.data?.message);
      })
      .catch((e) => {
        fail(e);
      });
  }
};

export const getProductsAuth = (catId, params, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = generatePath(CONFIG.GET_PRODUCTS_BY_CAT_AUTH, { cat: catId });
  if (token) {
    axios({
      url: apiUrl,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
      params,
    })
      .then((response) => {
        if (response?.data?.status === "success") {
          success({
            products: response?.data?.message,
            total: response?.data?.total,
          });
        } else throw new Error(response?.data?.message);
      })
      .catch((e) => {
        fail(e);
      });
  } else {
    store.dispatch(showGlobalSnack("error", "Please, login first!", 3000));
  }
};

export const getProducts = (catId, params, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_PRODUCTS_BY_CAT, { catID: catId });
  axios({
    url: apiUrl,
    method: "GET",
    params,
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success({
          products: response?.data?.message,
          total: response?.data?.total,
        });
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getStoreProducts = (catId, params, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_STORE_BY_CAT, { catID: catId });
  axios({
    url: apiUrl,
    method: "GET",
    params,
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success({
          products: response?.data?.message,
          total: response?.data?.total,
        });
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getStoreProductsAuth = (catId, params, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = generatePath(CONFIG.GET_STORE_BY_CAT_AUTH, { catID: catId });
  if (token) {
    axios({
      url: apiUrl,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
      params,
    })
      .then((response) => {
        if (response?.data?.status === "success") {
          success({
            products: response?.data?.message,
            total: response?.data?.total,
          });
        } else throw new Error(response?.data?.message);
      })
      .catch((e) => {
        fail(e);
      });
  } else {
    store.dispatch(showGlobalSnack("error", "Please, login first!", 3000));
  }
};

export const getProductsFromFollowed = () => {
  return new Promise((resolve, reject) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      axios({
        url: CONFIG.GET_PRODUCTS_BY_FOLLOWED,
        method: "GET",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response?.data?.success) {
            resolve(response?.data?.result);
          } else throw new Error(response?.data?.error);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
  });
};

export const getFeatured = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: CONFIG.GET_FEATURED_PRODUCTS,
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.success) {
          resolve(response?.data?.result);
        } else throw new Error(response?.data?.error);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getFeaturedAuth = () => {
  return new Promise((resolve, reject) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      axios({
        url: CONFIG.GET_FEATURED_PRODUCTS,
        method: "GET",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response?.data?.success) {
            resolve(response?.data?.result);
          } else throw new Error(response?.data?.error);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};
export const getSimilarProducts = (slug, params, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_ARTWORK_BY_SIMILAR, { slug: slug });
  axios({
    url: apiUrl,
    method: "POST",
    params,
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success({
          products: response?.data?.message,
          total: response?.data?.total,
        });
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getProfileProducts = (id, params, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_PRODUCTS_BY_USERID, { id: id });
  axios({
    url: apiUrl,
    method: "GET",
    params,
  })
    .then((response) => {
      if (response?.data?.status === "success") {
        success({
          products: response?.data?.message,
          total: response?.data?.total,
        });
      } else throw new Error(response?.data?.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getYourProducts = (id, params, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  if (token) {
    const apiUrl = CONFIG.GET_YOUR_ARTWORK;
    axios({
      url: apiUrl,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
      params,
    })
      .then((response) => {
        if (response?.data?.status === "success") {
          success({
            products: response?.data?.products,
            total: response?.data?.totalProducts,
          });
        } else throw new Error(response?.data?.message);
      })
      .catch((e) => {
        fail(e);
      });
  }
};
