import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import cn from 'classnames';

import './FancyNav.scss';

class FancyNav extends Component {
  constructor() {
    super();
    this.itemsRef = React.createRef();
    this.indicatorRef = React.createRef();
  }

  componentDidMount() {
    this.animateIndicator();
  }

  componentDidUpdate(prevProps) {
    const { activeItemKey } = this.props;
    if (prevProps.activeItemKey !== activeItemKey) {
      this.animateIndicator();
    }
  }

  handleClick = (index, key) => {
    const { onClick } = this.props;
    onClick(key);
  }

  animateIndicator = () => {
    const { activeItemKey, items } = this.props;
    if (activeItemKey) {
      const activeItemIndex = items.findIndex(item => item.key === activeItemKey);
      const activeItem = this.itemsRef.current.children[activeItemIndex];
      let leftPosition = 0;
      for (let i = 0; i <= activeItemIndex; i++) {
        if (i !== 0) {
          leftPosition += this.itemsRef.current.children[i - 1].clientWidth;
        }
      }
      anime({
        targets: this.indicatorRef.current,
        width: activeItem.clientWidth,
        left: leftPosition,
        easing: 'easeOutQuint',
        duration: 500,
        begin: () => { },
        complete: () => { }
      });
    }
  }

  render() {
    const { items, fancy, activeItemKey } = this.props;
    const itemList = items.map((item, i) => (
      <li key={`fancy-nav-item-${item.key}`} className={cn({ active: item.key === activeItemKey })}>
        <span
          onClick={() => { this.handleClick(i, item.key); }}
          role="button"
          tabIndex="-1"
        >
          {item.name}
        </span>
      </li>
    ));
    return (
      <>
        <ul className={cn('fancy-nav', { _off: !fancy })} ref={this.itemsRef}>
          {itemList}
        </ul>
        {
          fancy && (
            <div className="nav-indicator-rail">
              <div className="nav-indicator" ref={this.indicatorRef} />
            </div>
          )
        }
      </>
    );
  }
}

FancyNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  activeItemKey: PropTypes.string
};

FancyNav.defaultProps = {
  activeItemKey: null
};

export default FancyNav;
