import React, { Component, useState } from "react";
import AddImageIcon from "@material-ui/icons/AddPhotoAlternate";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Button from "../Button";
import CropImage from "../CropImage";
import "./ImageUploader.scss";
import CropCoverImage from "../CropImage/CropCoverImage";

const styles = {
  iconStyles: {
    color: "#984B48",
  },
  closeIconStyles: {
    color: "#fff",
  },
};

class CoverUploader extends Component {
  state = {
    zoom: 1.5,
  };
  inputFileRef = React.createRef();

  handleUploadClick = () => {
    if (this.inputFileRef) {
      this.inputFileRef.current.click();
    }
  };
  cropImage = () => {
    const { setCroppedImage } = this.props;
    if (this.editor) {
      const canvas = this?.editor?.getImage();
      setCroppedImage(canvas?.toDataURL());
    }
  };
  zoomIn = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom + 0.1,
    }));
  };

  zoomOut = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom - 0.1,
    }));
  };

  render() {
    const {
      classes,
      imageFile,
      width,
      height,
      setCroppedImage,
      onImageChange,
      url,
    } = this.props;
    return (
      <div className="image-uploader-wrap">
        <input
          type="file"
          style={{ display: "none" }}
          ref={this.inputFileRef}
          onChange={onImageChange}
          accept="image/*"
        />
        {imageFile ? (
          <>
            <CropCoverImage
              setCroppedImage={setCroppedImage}
              image={imageFile}
              width={width}
              height={height}
              zoomValue={this.state.zoom}
              zoomIn={this.zoomIn}
              zoomOut={this.zoomOut}
              cropImage={this.cropImage}
            />
            <div style={{ textAlign: "center" }}>
              <Button
                mode="secondary"
                size="auto"
                onClick={this.handleUploadClick}
              >
                Change Image
              </Button>
            </div>
          </>
        ) : (
          <div
            role="button"
            tabIndex="-1"
            className="image-uploader"
            onClick={this.handleUploadClick}
          >
            <AddImageIcon className={classes.iconStyles} />
            <span>Click to add image</span>
          </div>
        )}
      </div>
    );
  }
}

CoverUploader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  setCroppedImage: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  imageFile: PropTypes.instanceOf(new FileReader()),
};

CoverUploader.defaultProps = {
  imageFile: null,
};

export default withStyles(styles)(CoverUploader);
