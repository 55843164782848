import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { injectIntl, intlShape } from "react-intl";
import { Link, NavLink, useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { connect } from "react-redux";

import VerifiedIcon from "../../assets/img/Verified.png";
import sitemap from "../../routing/siteMap";
import styles from "./AdvancedAvatar.scss";
import Avatar from "../Avatar";

/**
 * Display Avatar with more info
 * @visibleName Advanced Avatar
 */
const mapStateToProps = (state) => ({
  user: state.user,
});
const AdvancedAvatar = ({
  className,
  width,
  imgPath,
  followers,
  works,
  name,
  intl,
  user,
  match,
  category,
  verified,
  id,
}) => {
  const history = useHistory();
  const detailsArray = [];
  if (followers)
    detailsArray.push(
      intl.formatMessage({ id: "ui.artist.followers" }, { count: followers })
    );
  if (works)
    detailsArray.push(
      intl.formatMessage({ id: "ui.artist.works" }, { count: works })
    );
  const detailsString = detailsArray.join(" / ");
  return (
    <div className={(classnames(styles.advancedAvatar, className), "avatar")}>
      <Avatar width={width} imgPath={imgPath} alt={name} />
      <div className="info">
        <div className="name">
          <h4>
            {name}{" "}
            {verified && (
              <img
                src={VerifiedIcon}
                style={{
                  marginBottom: "3px",
                  height: "12px",
                }}
              />
            )}
          </h4>
        </div>
        <div className="description">
          <h6>{category}</h6>
        </div>
        <div className="details">
          {user?.id === id ? (
            <NavLink
              to={generatePath(sitemap.followingFollowers, {
                slug: user?.slug,
              })}
            >
              <h6>{detailsString}</h6>
            </NavLink>
          ) : (
            <h6>{detailsString}</h6>
          )}
        </div>
      </div>
    </div>
  );
};

AdvancedAvatar.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  width: PropTypes.number,
  imgPath: PropTypes.string,
  followers: PropTypes.string,
  works: PropTypes.string,
  name: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  verified: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any),
  category: PropTypes.string,
  id: PropTypes.string,
};

AdvancedAvatar.defaultProps = {
  className: "",
  width: 150,
  imgPath: null,
  followers: null,
  works: null,
};

export default injectIntl(connect(mapStateToProps)(AdvancedAvatar));
