import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breakpoint from "react-socks";
import moment from "moment";
import axios from "axios";
import sitemap from "../../../routing/siteMap";
import Banner1 from "../../../assets/img/divya-desktop.jpg";
import Banner2 from "../../../assets/img/divya-mobile.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { get } from "../../../services/generalApiServices";
import Loading from "../../Loading";
import { CONFIG } from "../../../constants";
import { generatePath } from "react-router";
import Image from "../../ImageTag/Image";
const Carousel = require("react-responsive-carousel").Carousel;

const urls = [
  "/exhibitions/5ede188af30c3727337562ce",
  "/exhibitions/5ede188af30c3727337562ce",
  "/exhibitions/5ee4d935a299707f4faf4a41",
  "/exhibitions/5ee1a942f30c3727337564e1",
  "/exhibitions/5ede15e3f30c3727337562c6",
  "/exhibitions/5edf5c7df30c3727337563b4",
  "/exhibitions/5ee72ff1a299707f4faf4aa7",
  "/exhibitions/5ee2fcae3648de6a2a42e19d",
  "/exhibitions/5eece4ed0b1ead43984620c3",
  "/exhibitions/5eeb965e0b1ead4398462051",
  "/exhibitions/5ede845ef30c3727337562df",
  "/exhibitions/5ee19afef30c3727337564b4",
  "/exhibitions/5eeba4550b1ead4398462072",
  "/exhibitions/5ee1a514f30c3727337564cf",
  "/exhibitions/5ee234683648de6a2a42e190",
  "/exhibitions/5ee1a082f30c3727337564c4",
  "/exhibitions/5eeb9d4a0b1ead4398462058",
  "/exhibitions/5ee39a615816e271dd79be7c",
  "/exhibitions/5ee393d45816e271dd79be76",
  "/exhibitions/5ee4ed86a299707f4faf4a71",
  "/exhibitions/5ee10fd0f30c3727337564a5",
  "/exhibitions/5ee366051c16e470f680b37ax",
];

const startDate = moment("07-02-2020", "MM-DD-YYYY");
const dayNumber = moment().diff(startDate, "d");

const BhittaBanner = () => {
  const [index, setIndex] = useState(0);
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  const onClickThumb = (index) => {
    setIndex(index);
  };

  const getSliders = () => {
    setLoading(true);
    get(
      CONFIG.GET_SLIDERS,
      (response) => {
        setSliders(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error.response);
      }
    );
  };

  useEffect(() => {
    getSliders();
  }, []);

  return (
    <div id="bhitta-banner">
      <Breakpoint s>
        <Carousel
          onChange={onClickThumb}
          showArrows={true}
          showThumbs={false}
          selectedItem={index}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
        >
          {sliders[0] &&
            sliders[0].slides.map((slider, index) => (
              <div key={index} className="slide-wrapper">
                <img src={slider.imageMain} alt={slider.title} />
              </div>
            ))}
        </Carousel>
      </Breakpoint>
      <Breakpoint m>
        <Carousel
          onChange={onClickThumb}
          showArrows={true}
          showThumbs={false}
          selectedItem={index}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
        >
          {sliders[0] &&
            sliders[0].slides.map((slider, index) => (
              <div key={index} className="slide-wrapper">
                <img src={slider.imageMain} alt={slider.title} />
              </div>
            ))}
        </Carousel>

        {/* <div className="carouselThumbnailWrapper">
          {sliders[0] &&
            sliders[0].slides.map((slider, i) => (
              <div
                className={`thumbCard ${index === i ? "selectedThumb" : ""}`}
                onClick={() => onClickThumb(i)}
                key={i}
              >
                <div className="imageHolder">
                  <img
                    src={slider.thumbnail}
                    alt={slider.title}
                    title="Click here to view"
                  />
                </div>
                <div className="detailHolder">
                  <Link
                    to={generatePath(sitemap.artist, {
                      slug: slider.linkArtist.slug,
                    })}
                  >
                    <h6>{slider.linkArtist.name}</h6>
                  </Link>
                  <div style={{ fontWeight: "bold" }}>----</div>
                  <h5>{slider.title}</h5>
                </div>
              </div>
            ))}
        </div> */}
      </Breakpoint>
    </div>
  );
};

// const BhittaBanner = () => (

// <div id="bhitta-banner" >
//   <Breakpoint s>
//     <Carousel showArrows={true} onClickThumb={onClickThumb} autoPlay={true}>
//       <div>
//         <img src={Banner2} alt="" />

//       </div>
//       <div>
//         <img src={Banner2} alt="" />

//       </div>

//     </Carousel>
//   </Breakpoint>
//   <Breakpoint m>
//     <Carousel showArrows={true} showThumbs={false} selectedItem={1} autoPlay={true} showStatus={false}>
//       <div>
//         <img src={Banner1} alt="" />

//       </div>
//       <div>
//         <img src={Banner1} alt="" />

//       </div>
//       <div>
//         <img src={Banner1} alt="" />

//       </div>

//     </Carousel>
//     <div className="carouselThumbnailWrapper">
//       <div className="thumbCard">
//         <div className="imageHolder">
//           <img src={Banner2} alt="" />
//         </div>
//         <div className="detailHolder">
//           <h6>Madam Curie</h6>
//           <div style={{ fontWeight: 'bold' }}>----</div>
//           <h5>Beautiful and colorful imagination</h5>
//         </div>
//       </div>
//       <div className="thumbCard">
//         <div className="imageHolder">
//           <img src={Banner2} alt="" />
//         </div>
//         <div className="detailHolder">
//           <h6>Rasana Bajracharya</h6>
//           <div style={{ fontWeight: 'bold' }}>----</div>
//           <h5>Matina</h5>
//         </div>
//       </div>

//       <div className="thumbCard">
//         <div className="imageHolder">
//           <img src={Banner2} alt="" />
//         </div>
//         <div className="detailHolder">
//           <h6>Madam Curie</h6>
//           <div style={{ fontWeight: 'bold' }}>----</div>
//           <h5>Beautiful and colorful imagination</h5>
//         </div>
//       </div>

//     </div>
//   </Breakpoint>

//     {/* <div className="customContainer">
//       <Link to="/exhibitions/5f6d8eee5585452ea361b614">
//         <Breakpoint s>
//           <img src={Banner2} alt="" />
//         </Breakpoint>
//         <Breakpoint m>
//           <img src={Banner1} alt="" />
//         </Breakpoint>
//       </Link>
//     </div> */}
//   </div >
// );

export default BhittaBanner;
