import React from "react";
import { NavLink } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";

import "./DesktopHeader.scss";

const Navigation = ({ intl }) => {
  return (
    <div className="navigationContainer">
      <ul>
        <li>
          <NavLink to="/exhibitions">
            {intl.messages["ui.pages.exhibitions.title"]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/gallery/all">
            {intl.messages["ui.pages.gallery.title"]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/store/all">
            {intl.messages["ui.pages.store.title"]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/studios">
            {intl.messages["ui.pages.studios.title"]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/feed">{intl.messages["ui.pages.feed.title"]}</NavLink>
        </li>
      </ul>
    </div>
  );
};

Navigation.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Navigation);
