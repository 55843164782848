import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { addLocaleData } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';

import en from 'react-intl/locale-data/en';
import ne from 'react-intl/locale-data/ne';
import zh from 'react-intl/locale-data/zh';

import AppContainer from './AppContainer';
import store from './redux';
import Theme from './styles/Theme';
import { showDialog } from './redux/actions/dialog.actions';

import * as serviceWorker from './serviceWorker';

import '@babel/polyfill';

global.Intl = require('intl');
window.Intl = require('intl');

addLocaleData([...en, ...ne, ...zh]);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={Theme}>
      <AppContainer/>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    store.dispatch(showDialog(
      'Update Available',
      'Update now to see latest changes. You may have to log in again.',
      () => {
        caches.keys().then((names) => {
          Promise.all(names.map(cacheName => caches.delete(cacheName)))
            .then(() => {
              window.location.reload();
            });
        });
      },
      'Update',
      'Later'
    ));
  }
});
