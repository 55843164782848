import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BottomScrollListener from "react-bottom-scroll-listener";

import ArtistShowcaseStudio from "../../ArtistShowcase/ArtistShowcaseStudio";
import Loading from "../../Loading";

const ArtistCollection = ({
  artists,
  isLoadingMore,
  loadMore,
  loadNoMore,
  viewClass,
  setFollow
}) => {
  const [allArtist, setAllArtist] = useState([]);
  useEffect(() => {
    if (artists?.length > 0) {
      const tempArray = artists.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );
      setAllArtist(tempArray);
    }
  }, [artists]);
  const artistShowcaseItemList = allArtist.map((artist) => {
    return (
      <ArtistShowcaseStudio key={`artist-list-${artist._id}`} artist={artist} setFollow={setFollow} />
    );
  });

  return (
    <section className={viewClass}>
      <div className="customContainer">
        <div className="sectionContent">
          {artistShowcaseItemList}
          {loadNoMore ? (
            <div style={{ textAlign: "center", fontSize: 12 }}>
              No more studios to show
            </div>
          ) : (
            <>
              {isLoadingMore ? (
                <Loading color="red" loading={isLoadingMore} />
              ) : (
                <BottomScrollListener onBottom={loadMore} offset={300} />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

ArtistCollection.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  loadNoMore: PropTypes.bool.isRequired,
  viewClass: PropTypes.string,
};

export default ArtistCollection;
