import React, { Component } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { each } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Loading from "../Loading";
import sitemap from "../../routing/siteMap";
import { CONFIG } from "../../constants";
import { get, getWithAuth } from "../../services/generalApiServices";
import { getThumbnail } from "../../utils/mediaUtils";
import { isUserArtist } from "../../utils/userUtils";
import Image from "../ImageTag/Image";
import "./QuickSearch.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});
class QuickSearch extends Component {
  state = {
    searchValue: "",
    showingResults: false,
    products: [],
    artists: [],
    searching: false,
  };

  handleInputChange = (e) => {
    this.setState({ searchValue: e.target.value });
    // this.handleSearchSubmit(e);
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.search();
  };

  search = () => {
    const { searchValue } = this.state;
    const { mobile, user } = this.props;
    if (searchValue !== "") {
      if (mobile) {
        window.location.href = generatePath(sitemap.searchPage, {
          term: searchValue,
        });
      } else {
        this.setState({ searching: true, showingResults: true });
        // event listener
        document.addEventListener("click", this.deactivateSearch);
        if (user) {
          getWithAuth(
            generatePath(CONFIG.SEARCH, { term: searchValue }),
            (data) =>
              this.setState({
                products: data.products,
                artists: data.artists,
                searching: false,
              }),
            () =>
              this.setState({
                searching: false,
                showingResults: false,
              })
          );
        } else {
          get(
            generatePath(CONFIG.SEARCH, { term: searchValue }),
            (data) =>
              this.setState({
                products: data.products,
                artists: data.artists,
                searching: false,
              }),
            () =>
              this.setState({
                searching: false,
                showingResults: false,
              })
          );
        }
      }
    }
  };

  deactivateSearch = (e) => {
    let externalClick = true;
    each(e.path, (elem) => {
      if (elem.id === "search") {
        externalClick = false;
      }
    });
    if (externalClick) {
      document.removeEventListener("click", this.deactivateSearch);
      this.setState({
        showingResults: false,
        searching: false,
        products: [],
        artists: [],
        searchValue: "",
      });
    }
  };

  render() {
    const {
      searchValue,
      products,
      artists,
      showingResults,
      searching,
    } = this.state;
    const { user } = this.props;

    const dummyImage = "assets/profile-dummy.png";
    const productsList = products.map((product) => {
      const thumbnail = getThumbnail(product.image, "thumbnail");
      return (
        <div
          className="search-result product"
          key={`search-result-${product?._id}`}
        >
          {isUserArtist(product?.author?.role) ? (
            <Link to={generatePath(sitemap.artWork, { slug: product?.slug })}>
              <Image src={thumbnail?.url} alt="" />
              <div className="info">
                <div className="name">{product?.name}</div>
                <div className="author">{product?.author?.name}</div>
              </div>
            </Link>
          ) : (
            <Link to={generatePath(sitemap.userArt, { slug: product?.slug })}>
              <Image src={thumbnail?.url} alt="" />
              <div className="info">
                <div className="name">{product?.name}</div>
                <div className="author">{product?.author?.name}</div>
              </div>
            </Link>
          )}
        </div>
      );
    });
    const artistsList = artists.map((artist) => {
      return (
        <div
          className="search-result artist"
          key={`search-result-${artist?._id}`}
        >
          {user && user?.id === artist?._id ? (
            <Link to={generatePath(sitemap.profile, { slug: artist?.slug })}>
              <Image
                src={
                  artist?.picture && artist?.picture != "undefined"
                    ? `users/${artist?.picture}`
                    : dummyImage
                }
                alt={artist?.slug}
              />
              <div className="info">
                <div className="name">{artist?.name}</div>
              </div>
            </Link>
          ) : (
            <>
              {!isUserArtist(artist.role) ? (
                <Link
                  to={generatePath(sitemap.normalUser, { slug: artist?.slug })}
                >
                  <Image
                    src={
                      artist?.picture && artist?.picture != "undefined"
                        ? `users/${artist?.picture}`
                        : dummyImage
                    }
                    alt={artist?.slug}
                  />
                  <div className="info">
                    <div className="name">{artist?.name}</div>
                  </div>
                </Link>
              ) : (
                <Link to={generatePath(sitemap.artist, { slug: artist?.slug })}>
                  <Image
                    src={
                      artist?.picture && artist?.picture != "undefined"
                        ? `users/${artist?.picture}`
                        : dummyImage
                    }
                    alt={artist?.slug}
                  />
                  <div className="info">
                    <div className="name">{artist?.name}</div>
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      );
    });

    return (
      <div className="searchContainer1">
        <form onSubmit={this.handleSearchSubmit}>
          <input
            type="text"
            placeholder="Type to search"
            value={searchValue}
            onChange={this.handleInputChange}
          />
          <button type="submit" style={{ display: "none" }} />
        </form>
        {showingResults && (
          <div className="results">
            {searching ? (
              <Loading />
            ) : (
              <>
                {products?.length < 1 && artists < 1 && (
                  <div className="none">No results found</div>
                )}
                {productsList}
                {artistsList}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

QuickSearch.propTypes = {
  mobile: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
};

QuickSearch.defaultProps = {
  mobile: false,
  user: null,
};

export default connect(mapStateToProps)(QuickSearch);
