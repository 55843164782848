import Cookies from 'js-cookie';

import { SET_LOCALE } from '../constants/action-types';
import { CONFIG } from '../../constants';

const initState = {
  lang: 'en'
};

export default (state, action) => {
  if (!state) {
    return initState;
  }

  switch (action.type) {
    case SET_LOCALE:
      Cookies.set(CONFIG.LANG_COOKIE_NAME, action.lang, { expires: 7, path: '/' });
      return {
        ...state,
        lang: action.lang
      };
    default:
      return state;
  }
};
