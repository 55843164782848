import Cookies from 'js-cookie';

import { CONFIG } from '../../constants';
import {
     ADD_TO_CART,
     REMOVE_CART_ITEM,
     GET_CART_ITEMS,
     CLEAR_CART
} from '../constants/action-types';

const cartCookie = Cookies.get(CONFIG.CART_COOKIE_NAME);

const initialState = {
     items: cartCookie ? JSON.parse(cartCookie) : [],


};

export default (state = initialState, action) => {
     switch (action.type) {
          case ADD_TO_CART: {
               const existItem = state.items.find((obj) => obj.product === action.payload.product);
               if (existItem) {
                    const oldItem = state.items.map((obj) =>
                         obj.product === action.payload.product
                              ? { ...obj, qty: obj.qty + 1 }
                              : obj
                    );
                    Cookies.set(CONFIG.CART_COOKIE_NAME, JSON.stringify(oldItem), { expires: 7, path: '/' });
                    return {
                         ...state,
                         items: oldItem,

                    };


               } else {
                    const newItem = [...state.items, { ...action.payload }];
                    Cookies.set(CONFIG.CART_COOKIE_NAME, JSON.stringify(newItem), { expires: 7, path: '/' });
                    return {
                         ...state,
                         items: newItem,

                    };

               }


          }

          case REMOVE_CART_ITEM: {
               const removeItems = state.items.filter((x) => x.product !== action.payload.product);
               Cookies.set(CONFIG.CART_COOKIE_NAME, JSON.stringify(removeItems), { expires: 7, path: '/' });
               return {
                    ...state,
                    items: removeItems
               }
          }

          case CLEAR_CART: {
               Cookies.remove(CONFIG.CART_COOKIE_NAME);
               return {
                    ...state,
                    items: []
               }
          }


          default:
               return state;
     }
};
