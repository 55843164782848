import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import { generatePath } from "react-router";
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/core/styles";

import { CONFIG } from "../../../../constants";
import { getWithAuth } from "../../../../services/generalApiServices";
import Image from "../../../ImageTag/Image";
import Loading from "../../../Loading";

import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import Button from "../../../Button";
import "./PrivacySetting.scss";

const dialogClasses = {
  paper: {
    width: "100%",
    maxWidth: 280,
    padding: 20,
  },
};
const PrivacySetting = ({ classes }) => {
  const [blockList, setBlockList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showUnblock, setShowUnblock] = useState(false);
  const [id, setId] = useState("");
  useEffect(() => {
    getBlockList();
  }, []);
  const getBlockList = () => {
    getWithAuth(
      CONFIG.GET_BLOCK_LIST,
      (list) => {
        setBlockList(list);
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  };
  const unblockUser = () => {
    const token = Cookies.get(CONFIG.AUTH_TOKEN);
    const apiUrl = generatePath(CONFIG.UNBLOCK_USER, { id });
    if (token) {
      axios({
        url: apiUrl,
        method: "PATCH",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            setShowUnblock(false);
            store.dispatch(
              showGlobalSnack("success", "Unblocked successfully", 3000)
            );
            getBlockList();
          } else throw new Error(response.data.message);
        })
        .catch((e) => {
          setShowUnblock(false);
          store.dispatch(
            showGlobalSnack(
              "error",
              "Something went wrong while unblocking",
              3000
            )
          );
        });
    }
  };
  const closeDialog = () => {
    setShowUnblock(false);
  };
  const onUnblockClick = (id) => {
    setShowUnblock(true);
    setId(id);
  };
  const dummyImage = "assets/profile-dummy.png";
  return (
    <div className="privacy-setting">
      <Dialog
        open={showUnblock}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
      >
        <div id="responsive-dialog-title">Unblock User</div>
        <div id="responsive-dialog-actions" style={{ marginTop: "14px" }}>
          <span
            role="button"
            tabIndex="-1"
            onClick={() => unblockUser(blockList?._id)}
            mode="special"
          >
            Confirm
          </span>
          <Button size="medium" onClick={closeDialog} mode="specialBlack">
            Cancel
          </Button>
        </div>
      </Dialog>{" "}
      <div className="customContainer smallWidthLayout">
        <div className="sectionContent addFullHeight">
          {" "}
          <div className="commonUserContainer">
            <h5>BLOCKED USERS</h5>
            <p>
              Blocked users will no longer be allowed to follow you, check your
              work, comment on your work and message you.
            </p>
            {blockList?.length > 0 ? (
              <h6>You are currently blocking</h6>
            ) : (
              <h6>You haven't blocked any users</h6>
            )}
            <table>
              <tbody>
                {isLoading ? (
                  <Loading color="red" />
                ) : (
                  <>
                    {blockList?.length > 0 && (
                      <>
                        {blockList?.map((people, i) => (
                          <tr key={`block-list-${i}`}>
                            <td className="image">
                              <Image
                                className="pp-img"
                                src={
                                  people?.picture &&
                                  people?.picture !== "undefined"
                                    ? `users/${people?.picture}`
                                    : dummyImage
                                }
                              />
                              <div className="info">
                                <span className="name">{people?.name}</span>
                                {people?.categories?.length > 0 && (
                                  <span className="category">
                                    {people?.categories[0]?.name}
                                  </span>
                                )}
                                <span className="followers">
                                  {people?.followsCount} Followers /{" "}
                                  {people?.productsCount} Works
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="unblock-btn">
                                <button
                                  onClick={() => onUnblockClick(people?._id)}
                                >
                                  Unblock
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(dialogClasses)(PrivacySetting);
