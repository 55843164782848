import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";

class CropImage extends Component {
  cropImage = () => {
    const { setCroppedImage } = this.props;
    if (this.editor) {
      const canvas = this?.editor?.getImage();
      setCroppedImage(canvas?.toDataURL());
    }
  };

  render() {
    const { image, width, height } = this.props;
    return (
      <AvatarEditor
        ref={(c) => {
          this.editor = c;
        }}
        onMouseUp={this.cropImage}
        onLoadSuccess={this.cropImage}
        image={image}
        width={500}
        height={120}
        border={0}
        color={[0, 0, 0, 0.8]} // RGBA
        scale={1}
      />
    );
  }
}

CropImage.propTypes = {
  setCroppedImage: PropTypes.func.isRequired,
  image: PropTypes.instanceOf(new FileReader()).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default CropImage;
