import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import HomeSectionTab from "../HomeSection/HomeSectionTab";

import ContentScroller from "../ContentScroller";
import Loading from "../Loading";
import ImageContainer from "../ImageContainer";
import FollowArtistHomePage from "../FollowArtist/FollowArtistHomePage";
import sitemap from "../../routing/siteMap";
import {
  getStudiosToFollow,
  getRecommendedStudios,
} from "../../services/studioServices";
import { CONFIG } from "../../constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import VerifiedIcon from "../../assets/img/Verified.png";
import "react-tabs/style/react-tabs.scss";
import "./StudiosToFollow.scss";
import "./Tabs.scss";

class StudiosToFollow extends Component {
  state = {
    isLoading: true,
    studios: [],
    recommended: [],
  };

  componentDidMount() {
    this.getStudiosToFollow();
    this.getRecommended();
  }

  getStudiosToFollow = () => {
    getStudiosToFollow()
      .then((studios) => {
        this.setState({ isLoading: false, studios });
      })
      .catch((e) => {
        console.log("Error while trying to get studios to follow", e);
        this.setState({ isLoading: false });
      });
  };
  getRecommended = () => {
    getRecommendedStudios()
      .then((recommended) => {
        this.setState({ isLoading: false, recommended });
      })
      .catch((e) => {
        console.log("Error while trying to get studios to follow", e);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { studios, isLoading, recommended } = this.state;
    const { intl } = this.props;
    const image = "assets/profile-dummy.png";
    const recommendedStudiosList = recommended.map((studio) => {
      const detailsArray = [];
      if (studio?.followsCount >= 0)
        detailsArray.push(
          intl.formatMessage(
            { id: "ui.artist.followers" },
            { count: studio?.followsCount }
          )
        );
      if (studio?.productsCount >= 0)
        detailsArray.push(
          intl.formatMessage(
            { id: "ui.artist.works" },
            { count: studio?.productsCount }
          )
        );
      const detailsString = detailsArray.join(" / ");
      return (
        <div key={`studio-follow-${studio?.id}`} className="studio">
          <Link to={generatePath(sitemap.artist, { slug: studio?.slug })}>
            <ImageContainer
              imgPath={
                studio?.picture && studio.picture != "undefined"
                  ? `users/${studio?.picture}`
                  : image
              }
              width={300}
              height={250}
              size="cover"
              borderRadius={5}
            />
          </Link>
          <div className="details">
            <div className="name">
              {studio?.name}
              {studio?.verifiedArtist ? (
                <img
                  src={VerifiedIcon}
                  style={{
                    marginLeft: "3px",
                    marginBottom: "5px",
                    height: "12px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div className="more-details">
              <div>{studio?.categories[0]?.name}</div>
              <div>{detailsString}</div>
            </div>
            <div className="followButtonContainer">
              <FollowArtistHomePage artist={studio} />
            </div>
          </div>
        </div>
      );
    });
    const studiosList = studios.map((studio) => {
      const detailsArray = [];
      if (studio?.followsCount >= 0)
        detailsArray.push(
          intl.formatMessage(
            { id: "ui.artist.followers" },
            { count: studio?.followsCount }
          )
        );
      if (studio?.productsCount >= 0)
        detailsArray.push(
          intl.formatMessage(
            { id: "ui.artist.works" },
            { count: studio?.productsCount }
          )
        );
      const detailsString = detailsArray.join(" / ");
      return (
        <div key={`studio-follow-${studio?.id}`} className="studio">
          <Link to={generatePath(sitemap.artist, { slug: studio?.slug })}>
            <ImageContainer
              imgPath={
                studio?.picture && studio.picture != "undefined"
                  ? `users/${studio?.picture}`
                  : image
              }
              width={300}
              height={250}
              size="cover"
              borderRadius={5}
            />
          </Link>
          <div className="details">
            <div className="name">
              {studio?.name}
              {studio?.verifiedArtist ? (
                <img
                  src={VerifiedIcon}
                  style={{
                    marginLeft: "3px",
                    marginBottom: "5px",
                    height: "12px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div className="more-details">
              <div>{studio?.info?.categories[0]?.name}</div>
              <div>{detailsString}</div>
            </div>
            <div className="followButtonContainer">
              <FollowArtistHomePage artist={studio} />
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="studios-to-follow">
        <HomeSectionTab title="Studios to follow" fullWidth isTabs={true}>
          <Tabs>
            <div className="carousel-tabss">
              <div className="top">
                <div className="title">Studios to follow</div>
              </div>
              <TabList>
                <Tab>Recommended</Tab>
                <Tab>Trending</Tab>
              </TabList>
            </div>

            <TabPanel>
              {isLoading ? (
                <Loading color="red" />
              ) : (
                <ContentScroller arrowPosition="around" studiosToFollow>
                  {recommendedStudiosList}
                </ContentScroller>
              )}
            </TabPanel>

            <TabPanel>
              {isLoading ? (
                <Loading color="red" />
              ) : (
                <ContentScroller arrowPosition="around" studiosToFollow>
                  {studiosList}
                </ContentScroller>
              )}
            </TabPanel>
          </Tabs>
        </HomeSectionTab>
      </div>
    );
  }
}

StudiosToFollow.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(StudiosToFollow);
