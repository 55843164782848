import moment from "moment";

const calculateTime = (created) => {
    const currentTime = moment(new Date());
    const timeDiff = moment.duration(currentTime.diff(created));
    if (timeDiff.asSeconds() < 60) {
        return Math.round(timeDiff.asSeconds()) + "s";
    }
    if (timeDiff.asMinutes() < 60) {
        return Math.round(timeDiff.asMinutes()) + "m";
    }
    if (timeDiff.asHours() < 24) {
        return Math.round(timeDiff.asHours()) + "h";
    }
    if (timeDiff.asDays() < 365) {
        return Math.round(timeDiff.asDays()) + "d";
    }

    return Math.round(timeDiff.asYears()) + "y";
};

export default calculateTime;
