import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import MessageIcon from "@material-ui/icons/MessageRounded";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import siteMap from "../../../routing/siteMap";
import LikeArt from "../../LikeArt";
import { getThumbnail } from "../../../utils/mediaUtils";
import "slick-carousel/slick/slick.css";
import Image from "../../ImageTag/Image";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true
};

const ArtBanner = ({ art }) => {
  const slideList = [art?.image, ...art?.images].map((image, i) => {
    const thumbnail = getThumbnail(image, "large");
    return (
      <div key={`art-slide-${i}`} className="art-slide">
        <Image
          src={thumbnail?.url}
          alt=""
          skeletonWidth={400}
          skeletonHeight={400}
        />
      </div>
    );
  });
  return (
    <div className="commonProductSlider">
      <Slider {...settings}>{slideList}</Slider>
      <div className="art-buttons">
        {art && <LikeArt art={art} />}
        {/* <Link to={generatePath(siteMap.message, { to: art.author.slug })}>
          <Tooltip
            TransitionComponent={Zoom}
            title={`Message ${art.author.name}`}
          >
            <span className="like-art-button">
              <MessageIcon />
            </span>
          </Tooltip>
        </Link> */}
      </div>
    </div>
  );
};

ArtBanner.propTypes = {
  art: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ArtBanner;
