import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { connect } from "react-redux";

import { CONFIG } from "../../../constants";
import Loading from "../../Loading";
import FeedCollection from "../Feed/FeedCollection";
import { getWithParams } from "../../../services/generalApiServices";
import "./NormalUser.scss";

const mapStateToProps = (state) => ({
    user: state.user,
});

class UserFeed extends Component {
    state = {
        posts: [],
        isLoading: true,
        loadingMore: false,
    };

    total = CONFIG.FEED_LIMIT;

    componentDidMount() {
        this.getPosts();
    }

    getPosts = () => {
        const { user, userInfo } = this.props;
        getWithParams(
            generatePath(CONFIG.GET_POSTS_BY_ARTIST, {
                id: userInfo?._id,
                total: this.total,
            }),
            (response) => {
                this.setState({
                    posts: response,
                    isLoading: false,
                    loadingMore: false,
                });
            },
            (err) => {
                console.log(err);
                this.setState({ isLoading: false });
            }
        );
    };
    getMorePosts = () => {
        this.total = this.total + CONFIG.FEED_LIMIT;
        this.setState({ loadingMore: true }, () => {
            this.getPosts();
        });
    };

    render() {
        const { posts, isLoading, loadingMore } = this.state;
        const { user, userInfo } = this.props;

        return (
            <section>
                <div className="customContainer smallWidthLayout">
                    {isLoading ? (
                        <Loading color="red" />
                    ) : (
                        <FeedCollection
                            posts={posts}
                            refresh={this.getPosts}
                            loadingMore={loadingMore}
                            loadMore={this.getMorePosts}
                            showOption={true}
                        />
                    )}
                </div>
            </section>
        );
    }
}

UserFeed.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(UserFeed);
