import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import { connect } from "react-redux";
import SideFilterCloseIcon from "@material-ui/icons/ChevronLeftRounded";
import SideFilterOpenIcon from "@material-ui/icons/ChevronRightRounded";

import VerifiedIcon from "../../../assets/img/Verified.png";
import Avatar from "../../Avatar";
import NewFeed from "./NewFeed";
import FeedCollection from "./FeedCollection";
import Loading from "../../Loading";
import { CONFIG } from "../../../constants";
import { get, getWithAuth } from "../../../services/generalApiServices";
import {
  getAllPosts,
  getPostsByFollowed
} from "../../../services/feedServices";
import "./feed.scss";

const mapStateToProps = state => ({
  user: state.user
});

class Feed extends Component {
  state = {
    posts: [],
    loading: true,
    topStudios: [],
    loadingMore: false,
    sideFilterVisible: false
  };

  sort = null;

  show = "all";

  studioId = null;

  page = 1;

  total = CONFIG.FEED_LIMIT;

  totalPosts = 999999;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPosts();
    this.getTopStudios();
  }

  getRecommendedPost = () => {
    getWithAuth(
      CONFIG.GET_RECOMMENDED_POST,
      posts => {
        this.setState({ posts, loading: false });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  getTopStudios = () => {
    get(
      CONFIG.GET_TOP_ARTISTS,
      topStudios => this.setState({ topStudios }),
      e => console.log(e),
      {
        total: 25
      }
    );
  };

  getPostsByFollowedUsers = () => {
    const params = {
      sort: this.sort,
      show: this.show,
      artistId: null,
      total: this.total
    };
    getPostsByFollowed(
      params,
      response => {
        this.totalPosts = response.total;
        this.setState({
          posts: response?.posts,
          loading: false,
          loadingMore: false
        });
      },
      err => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  };

  getPosts = (loading = true) => {
    loading && this.setState({ loading: true });
    this.setState({ showUpload: true });

    const params = {
      sort: this.sort,
      show: this.show,
      artistId: this.studioId,
      total: this.total
    };
    getAllPosts(
      params,
      response => {
        this.totalPosts = response.total;
        this.setState({
          posts: response.posts,
          loading: false,
          loadingMore: false,
          showUpload: false
        });
      },
      err => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  };

  getMorePosts = () => {
    this.total = this.total + CONFIG.FEED_LIMIT;
    this.setState(
      {
        loadingMore: true
      },
      () => {
        if (this.show === "following" || this.show === "recommended") {
          this.getPostsByFollowedUsers();
        }
        this.getPosts(false);
      }
    );
  };

  handleSort = sort => {
    if (sort === "popular") {
      this.show = null;
    }
    this.setState({ posts: [], loading: true });
    this.sort = sort;
    this.studioId = null;
    this.getPosts();
  };

  handleShow = show => {
    this.setState({ posts: [], loading: true });
    this.show = show;
    this.sort = null;
    this.studioId = null;
    this.getPosts();
  };

  handleFollowedShow = show => {
    this.setState({
      posts: [],
      loading: true
    });
    this.show = show;
    this.studioId = null;
    this.sort = "recent";
    this.getPostsByFollowedUsers();
  };

  handleStudioSelect = id => {
    this.setState({
      posts: [],
      loading: true
    });
    this.show = null;
    this.sort = null;
    this.studioId = id;
    this.getPosts();
  };
  handleRefresh = () => {
    if (this.studioId === null && this.show != "recommended") {
      this.getPostsByFollowedUsers();
    } else if (this.show === "recommended") {
      this.getRecommendedPost();
    } else {
      this.getPosts();
    }
  };
  render() {
    const {
      posts,
      loading,
      topStudios,
      loadingMore,
      sideFilterVisible
    } = this.state;
    const { user } = this.props;
    const topStudiosList = topStudios.map(studio => (
      <li key={`top-studio-filter-${studio?._id}`}>
        <button
          className={this.studioId === studio?._id ? "active" : ""}
          onClick={() => this.handleStudioSelect(studio?._id)}
        >
          <span>
            <Avatar
              imgPath={
                studio?.picture &&
                studio?.picture != "undefined" &&
                `users/${studio?.picture}`
              }
            />
          </span>
          <span>
            {studio?.name}
            {/* {studio?.name.slice(0, 17)}
            {studio?.name.length > 17 && "..."} */}
            {studio?.verifiedArtist && (
              <img
                src={VerifiedIcon}
                style={{
                  marginLeft: "3px",
                  height: "12px"
                }}
              />
            )}{" "}
          </span>
          <RightIcon />
        </button>
      </li>
    ));
    const sidebarClasses = classnames("feedBlock", "feedFilters", {
      visible: sideFilterVisible
    });
    return (
      <div id="feedPage">
        <div className="feedWrapper">
          <div className={sidebarClasses}>
            <div className="sideFilterButton">
              {sideFilterVisible ? (
                <SideFilterCloseIcon
                  style={{ fontSize: 30 }}
                  onClick={() =>
                    this.setState({
                      sideFilterVisible: false
                    })
                  }
                />
              ) : (
                <SideFilterOpenIcon
                  style={{ fontSize: 30 }}
                  onClick={() =>
                    this.setState({
                      sideFilterVisible: true
                    })
                  }
                />
              )}
            </div>
            <div className="inner">
              <div className="filterItem">
                <div className="title">
                  <h4>Sort By</h4>
                </div>
                <div className="content">
                  <ul>
                    <li>
                      <button
                        className={this.show === "all" ? "active" : ""}
                        onClick={() => {
                          this.handleShow("all");
                        }}
                      >
                        All Feed <RightIcon />
                      </button>
                    </li>
                    {user && (
                      <>
                        {" "}
                        <li>
                          <button
                            className={
                              this.show === "recommended" ? "active" : ""
                            }
                            onClick={() => {
                              this.handleFollowedShow("recommended");
                            }}
                          >
                            Recommended <RightIcon />
                          </button>
                        </li>
                        <li>
                          <button
                            className={
                              this.show === "following" ? "active " : ""
                            }
                            onClick={() => {
                              this.handleFollowedShow("following");
                            }}
                          >
                            Following <RightIcon />
                          </button>
                        </li>
                        <li>
                          <button
                            className={this.sort === "popular" ? "active" : ""}
                            onClick={() => {
                              this.handleSort("popular");
                            }}
                          >
                            Popular <RightIcon />
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className="filterItem filterStudio">
                <div className="title">
                  <h6>Top Studios</h6>
                </div>
                <div className="content">
                  <ul>{topStudiosList}</ul>
                </div>
              </div>
            </div>
          </div>

          <div className="feedBlock feedContent">
            <section className="feedSection">
              <div className="customContainer smallWidthLayout">
                <div className="sectionContent">
                  {user && <NewFeed refresh={this.getPosts} />}

                  {loading ? (
                    <Loading color="red" />
                  ) : (
                    <FeedCollection
                      total={this.totalPosts}
                      posts={posts}
                      // refresh={this.handleRefresh}
                      loadingMore={loadingMore}
                      loadMore={this.getMorePosts}
                      showOption={false}
                      user={user}
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Feed.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps)(Feed);
