import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import sitemap from '../../routing/siteMap';
import Styles from './BannerNavItem.module.scss';

const BannerNavItem = ({ image, artist, name, artistSlug, onClick }) => {
  return (
    <div className={Styles.bannerNavItem}>
      <div
        className={Styles.imageContainer}
        onClick={onClick}
        role="button"
        tabIndex="-1"
      >
        <img src={image} alt=""/>
      </div>
      <div className={Styles.textContainer}>
        <p><Link to={generatePath(sitemap.artist, { slug: artistSlug })}>{artist}</Link></p>
        <h4>{name}</h4>
      </div>
    </div>
  );
};

BannerNavItem.propTypes = {
  image: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  artistSlug: PropTypes.string.isRequired
};

export default BannerNavItem;
