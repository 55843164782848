import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";

import Image from "../../../ImageTag/Image";
import { CONFIG } from "../../../../constants";
import { userLogout } from "../../../../redux/actions/user.actions";
import sitemap from "../../../../routing/siteMap";
import store from "../../../../redux";
import Notifications from "./Notifications";
import Messages from "./Messages";
import Navigation from "./Navigation";
import Logo from "../../../Logo/Logo";
import LanguageSwitcher from "../../../LanguageSwitcher";
import QuickSearch from "../../../QuickSearch";
import getStudioBackground from "../../../../assets/img/pattern/get_a_studio.png";
import SocketContext from "../../../../socket";

const mapStateToProps = (state) => ({
  user: state.user,
});

class DesktopHeader extends Component {
  logOut = () => {
    const { history, socket } = this.props;
    store.dispatch(userLogout());
    socket.disconnect();
    history.push("/");
  };

  render() {
    const { user, isUserRefreshInProgress,messageSeen } = this.props;
    const isUserAvailable = !isEmpty(user);
    

    return (
      <header id="headerWrapper" className="desktopHeader mui-fixed">
        <div className="customContainer headerLayout noPaddingTopBottom">
          <div className="logoNavWrapper">
            <div className="logoLanguageContainer">
              <Logo />
              {/* <LanguageSwitcher/> */}
              <span
                className="__separator"
                style={{
                  width: "1px",
                  height: "22px",
                  background: "#525252",
                  margin: "0 15px",
                  display: "inline-block",
                }}
              />
              <QuickSearch />
            </div>
            <div className="navigationProfileContainer">
              <Navigation />
              <div className="profileContainer">
                {isUserRefreshInProgress ? (
                  <CircularProgress style={{ width: 24, height: 24 }} />
                ) : (
                  <>
                    {isUserAvailable ? (
                      <>
                        <ul className="loggedInMenu">
                          <li className="userMenu commonDropdown">
                            {user?.role > 10 && user?.role < 21 ? (
                              <NavLink
                                to={generatePath(sitemap.yourWork, {
                                  slug: user?.slug,
                                })}
                                activeClassName="active"
                              >
                                <span>
                                  {user.picture &&
                                  user.picture !== "undefined" ? (
                                    <Image
                                      src={`users/${user?.picture}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={
                                        "https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png"
                                      }
                                      alt=""
                                    />
                                  )}
                                </span>
                                <span>{user?.name?.slice(0, 18)}</span>
                                <DownIcon />
                              </NavLink>
                            ) : (
                              <NavLink
                                to={generatePath(sitemap.yourWork, {
                                  slug: user?.slug,
                                })}
                                activeClassName="active"
                              >
                                <span>
                                  {user?.picture &&
                                  user?.picture !== "undefined" ? (
                                    <Image
                                      src={`users/${user?.picture}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png"
                                      alt=""
                                    />
                                  )}
                                </span>
                                <span>{user?.name.slice(0, 18)}</span>
                                <DownIcon />
                              </NavLink>
                            )}
                            <div className="dropDownContainer typeLargeDrop">
                              <div className="inner">
                                {user.role > 10 && user.role < 21 ? (
                                  <>
                                    <div className="dropDownItem ">
                                      <NavLink
                                        to={generatePath(sitemap.yourWork, {
                                          slug: user.slug,
                                        })}
                                        activeClassName="active"
                                      >
                                        Profile
                                      </NavLink>
                                    </div>
                                    <div className="dropDownItem ">
                                      <NavLink
                                        to={sitemap.inbox}
                                        activeClassName="active"
                                      >
                                        Inbox
                                      </NavLink>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="dropDownItem ">
                                      <NavLink
                                        to={generatePath(sitemap.yourWork, {
                                          slug: user?.slug,
                                        })}
                                        activeClassName="active"
                                      >
                                        Profile
                                      </NavLink>
                                    </div>
                                    <div className="dropDownItem ">
                                      <NavLink
                                        to={sitemap.inbox}
                                        activeClassName="active"
                                      >
                                        Inbox
                                      </NavLink>
                                    </div>
                                  </>
                                )}
                                <div className="dropDownItem ">
                                  <NavLink
                                    to={sitemap.uploadArt}
                                    activeClassName="active"
                                  >
                                    Upload Artwork
                                  </NavLink>
                                </div>
                                <div className="dropDownItem ">
                                  <NavLink
                                    to={sitemap.editInformation}
                                    activeClassName="active"
                                  >
                                    {user.updated === "false"
                                      ? "Update Profile"
                                      : "Edit Information"}
                                  </NavLink>
                                </div>
                                <div className="dropDownItem ">
                                  <NavLink
                                    to={sitemap.profilePrivacy}
                                    activeClassName="active"
                                  >
                                    Privacy
                                  </NavLink>
                                </div>
                                {user.role > 20 && (
                                  <div className="dropDownItem get-studio">
                                    <NavLink
                                      to={generatePath(sitemap.getStudio, {
                                        slug: user.slug,
                                      })}
                                      activeClassName="active"
                                    >
                                      Get into Studio
                                    </NavLink>
                                  </div>
                                )}
                                <div className="dropDownItem ">
                                  <NavLink
                                    to={sitemap.order}
                                    activeClassName="active"
                                  >
                                    My Orders
                                  </NavLink>
                                </div>
                                <div className="dropDownItem ">
                                  <button
                                    onClick={this.logOut}
                                    className="logout-btn"
                                  >
                                    Log Out
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="commonDropdown">
                            <NavLink
                              to={sitemap.uploadArt}
                              activeClassName="active"
                            >
                              <UploadIcon />
                            </NavLink>
                          </li>
                          <li className="commonDropdown typeLargeDrop">
                            <Messages messageSeen={messageSeen} />
                          </li>

                          {user ? (
                            <li className="commonDropdown typeLargeDrop">
                              <Notifications />
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </>
                    ) : (
                      // )}
                      // </>
                      <ul>
                        <li>
                          <NavLink to={sitemap.login} activeClassName="active">
                            Login
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

DesktopHeader.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  isUserRefreshInProgress: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

DesktopHeader.defaultProps = {
  user: null,
};

const SocketedHeaderMessages = (props) => (
  <SocketContext.Consumer>
    {(socket) => <DesktopHeader {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default withRouter(connect(mapStateToProps)(SocketedHeaderMessages));
