import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import Cookie from "js-cookie";

import store from "../../../../redux";
import sitemap from "../../../../routing/siteMap";
import ContentCard from "../../../ContentCard";
import ImageUploader from "../../../ImageUploader";
import { Input } from "../../../Form";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import { CONFIG } from "../../../../constants";
import ProfileSidebar from "../Profile/ProfileSidebar";
import { getUserData, getYourInfo } from "../../../../services/userServices";
import { generatePath } from "react-router";
import "./GetStudio.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});

class GetStudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      aboutYourself: "",
      submitting: false,
      submitClicked: false,
      userInfo: {},
    };
  }

  componentDidMount() {
    getYourInfo(
      (response) => {
        this.setState({ userInfo: response });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleAddImage = (file, dataUrl, id) => {
    const { images } = this.state;
    const newImagesState = [...images];
    newImagesState.push({
      id,
      file,
      dataUrl,
    });
    this.setState({ images: newImagesState });
  };

  handleRemoveImage = (imageId) => {
    const { images } = this.state;
    const newImages = [...images];
    const imageIdIndex = newImages.findIndex((image) => image.id === imageId);
    newImages.splice(imageIdIndex, 1);
    this.setState({
      images: newImages,
    });
  };

  handleSubmit = () => {
    const { history } = this.props;
    const { images, aboutYourself } = this.state;

    this.setState({ submitting: true, submitClicked: true });

    const token = Cookie.get(CONFIG.AUTH_TOKEN);

    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append("images", images[i].file);
    }
    data.append("message", aboutYourself);

    if (token) {
      axios({
        url: CONFIG.GET_STUDIO,
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            store.dispatch(
              showGlobalSnack(
                "normal",
                "Your application has been received. We will contact you as soon as possible.",
                3000
              )
            );
            history.push(
              generatePath(sitemap.profile, { slug: this.props?.user?.slug })
            );
          } else if (response.data.status === "already-exist") {
            this.setState({
              submitting: false,
            });
            store.dispatch(
              showGlobalSnack(
                "normal",
                "You have already submitted your application. We will respond you soon!",
                3000
              )
            );
            history.push(
              generatePath(sitemap.profile, { slug: this.props?.user?.slug })
            );
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((e) => {
          console.error(e);
          this.setState({ submitClicked: false });
          store.dispatch(
            showGlobalSnack(
              "error",
              "An unexpected error has occurred. Please try again",
              3000
            )
          );
        });
    }
  };
  handleCancel = () => {
    const { user, history } = this.props;
    history.push(generatePath(sitemap.profile, { slug: user?.slug }));
  };

  render() {
    // const {
    //   artistCode,
    //   description
    // } = this.state;
    const { images, aboutYourself, submitting, userInfo } = this.state;
    const { user } = this.props;
    return (
      <div id="getStudio-page">
        <div className="commonGidContainer typeBox">
          {Object.keys(userInfo).length > 0 && (
            <ProfileSidebar userInfo={userInfo} />
          )}
          <div className="gridItem mainContent">
            <section className="getStudioSection removeMarginBottom">
              <div className="customContainer smallestWidthLayout">
                <div className="sectionContent addFullHeight">
                  <div className="sectionTitle">
                    <h2>Request to get into Studio</h2>
                    <p>
                      Upload and Send us your artwork or pictures to get a
                      studio. Upload images in .jpg and .png format each images
                      less than 2 MB.
                    </p>
                  </div>

                  <div className="formContainer">
                    <div className="fields">
                      <ContentCard>
                        <ImageUploader
                          images={images}
                          addImage={this.handleAddImage}
                          removeImage={this.handleRemoveImage}
                          coverImage={false}
                        />
                      </ContentCard>
                      <ContentCard>
                        <h5 className="about-title">About Yourself</h5>
                        <Input
                          required
                          label="Description"
                          type="textarea"
                          name="description"
                          value={aboutYourself}
                          placeholder="Write a short description about yourself. You can also add link to your portfolio."
                          onChange={(e) => {
                            this.setState({ aboutYourself: e.target.value });
                          }}
                        />
                      </ContentCard>
                    </div>

                    <div className="formAction">
                      <div className="actionItem">
                        <NavLink
                          to={generatePath(sitemap.artistCode, {
                            slug: user.slug,
                          })}
                          activeClassName="active"
                        >
                          Have a Studio Code?
                        </NavLink>
                      </div>
                      <div className="actionItem">
                        {/*<CommonButton size="buttonAuto" mode="plain">Cancel</CommonButton>*/}
                        <button
                          className="cancel-btn"
                          onClick={this.handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className={
                            !this.state.submitClicked
                              ? "send-reqst"
                              : " send-reqst send-reqst-disabled"
                          }
                          onLoad={submitting}
                          onClick={
                            !this.state.submitClicked ? this.handleSubmit : ""
                          }
                        >
                          Send a Request
                        </button>
                        {/*<CommonButton loading={submitting} size="buttonAuto" onClick={this.handleSubmit} className="send-reqst">Send a Request</CommonButton>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

GetStudio.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GetStudio;
