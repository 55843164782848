import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { generatePath } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  get,
  postAuthMultipart,
  postAuth,
  getWithAuth,
} from "../../../../services/generalApiServices";
import {
  showDialog,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import { getThumbnail } from "../../../../utils/mediaUtils";
import { CONFIG } from "../../../../constants";
import Header from "../../../UserPageHeader";
import ContentCard from "../../../ContentCard";
import { Input } from "../../../Form";
import FancyNav from "../../../FancyNav";
import ImageUploader from "../../../ImageUploader";
import sitemap from "../../../../routing/siteMap";
import store from "../../../../redux";
import CommonButton from "../../../Button";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import "../Upload/Upload.scss";
import { isUserArtist } from "../../../../utils/userUtils";

const mapStateToProps = ({ user }) => ({ user });
class EditArtworkForm extends Component {
  constructor(props) {
    super(props);
    const { art } = props;
    const allImages = [art.image, ...art.images].map((image) => {
      const thumbnail = getThumbnail(image, "thumbnail");
      return {
        dataUrl: thumbnail.url,
        id: image._id,
      };
    });
    this.state = {
      allCategories: [],
      tags: art.tags ? art.tags : [],
      images: allImages,
      coverImageIndex: 0,
      name: art.name,
      description: art.description,
      price: art.price,
      stock: art.stock,

      width: art?.attributes ? art?.attributes?.width : null,
      height: art?.attributes ? art.attributes.height : null,
      breadth: art?.attributes?.breadth,
      weight: art?.attributes?.weight,
      mainCategory: art.categories.find(
        (category) => category.parent === undefined
      )?._id,
      subCategory: art.categories.find(
        (category) => category.parent !== undefined
      )
        ? art.categories
            .filter((category) => category.parent !== undefined)
            .map((category) => category?._id)
        : [],
      uploading: false,
      deleting: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    get(
      CONFIG.GET_CATEGORIES_SORTED,
      (allCategories) => {
        this.setState({
          allCategories,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onTagsChanged(tags) {
    this.setState({ tags });
  }

  handleCategorySelect = (categoryId) => {
    this.setState((prevState) => {
      if (prevState.mainCategory !== categoryId) {
        return {
          mainCategory: categoryId,
          subCategory: [],
        };
      }

      return {
        mainCategory: categoryId,
      };
    });
  };

  handleSubCategoryClick = (categoryId) => {
    const { subCategory } = this.state;
    const updatedCategories = [...subCategory];
    const index = updatedCategories.indexOf(categoryId);
    if (index > -1) {
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(categoryId);
    }
    this.setState({ subCategory: updatedCategories });
  };

  handleAddImage = (file, dataUrl, id) => {
    const { images } = this.state;
    const newImagesState = [...images];
    newImagesState.push({
      uuid: id,
      file,
      dataUrl,
    });
    this.setState({ images: newImagesState });
  };

  handleSetCoverImage = (coverImageIndex) => {
    this.setState({ coverImageIndex });
  };

  handleRemoveImage = (imageId) => {
    const { images, coverImageIndex } = this.state;
    const newImages = [...images];
    const imageIdIndex = newImages.findIndex((image) => image.id === imageId);
    const coverImageId = newImages[coverImageIndex].id;
    newImages.splice(imageIdIndex, 1);
    const newCoverImageIndex = newImages.findIndex(
      (image) => image.id === coverImageId
    );
    this.setState({
      images: newImages,
      coverImageIndex: newCoverImageIndex < 0 ? 0 : newCoverImageIndex,
    });
  };

  handleUpload = () => {
    const {
      images,
      coverImageIndex,
      name,
      description,
      mainCategory,
      subCategory,
      price,
      stock,
      width,
      height,
      breadth,
      weight,
      tags,
    } = this.state;
    const { history, art, user } = this.props;

    const data = new FormData();

    // validate images
    // upload images limit
    if (images.length > CONFIG.UPLOAD_ART_IMAGES_LIMIT) {
      store.dispatch(
        showGlobalSnack(
          "error",
          `You can only upload ${CONFIG.UPLOAD_ART_IMAGES_LIMIT} images`,
          3000
        )
      );
      return;
    }

    if (images.length < 1) {
      store.dispatch(
        showGlobalSnack("error", "You have to upload at least one image", 3000)
      );
      return;
    }

    // image
    const coverImage = images[coverImageIndex];
    if (coverImage.uuid) {
      data.append("image", images[coverImageIndex].file);
    } else if (coverImage.id) {
      data.append("image", images[coverImageIndex].id);
    }

    // images
    for (let i = 0; i < images.length; i++) {
      if (i !== coverImageIndex) {
        if (images[i].uuid) {
          data.append("images", images[i].file);
        } else if (images[i].id) {
          data.append("oldImages", images[i].id);
        }
      }
    }

    // name
    if (name) {
      data.append("name", name);
    } else {
      store.dispatch(showGlobalSnack("error", "Name is required", 3000));
      return;
    }

    // description
    if (description) {
      data.append("description", description);
    } else {
      // store.dispatch(showGlobalSnack('error', 'Description is required', 3000));
      // return;
    }

    // categories
    if (mainCategory) {
      data.append("categories[]", mainCategory);
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please select a category.", 3000)
      );
      return;
    }

    if (subCategory.length > 0) {
      subCategory.forEach((id) => data.append("categories[]", id));
    }

    if (tags.length > 0) {
      tags.forEach((item) => data.append("tags[]", item));
    }

    // price
    if (price || price === 0) {
      data.append("price", price);
    } else {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please provide the price. Enter 0 to list as Not For Sale",
          3000
        )
      );
      return;
    }
    if (price && price > 10000000) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter price less than or equals 10000000",
          3000
        )
      );
      return;
    }

    // stock
    if (stock || stock === 0) {
      data.append("stock", stock);
    } else {
      store.dispatch(
        showGlobalSnack("error", "Please provide the stock amount", 3000)
      );
      return;
    }
    if (stock && stock > 50) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter stock less than or equals 50",
          3000
        )
      );
      return;
    }
    if (height && height > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter height less than or equals 240",
          3000
        )
      );
      return;
    }

    if (width && width > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter width less than or equals 240",
          3000
        )
      );
      return;
    }

    if (breadth && breadth > 120) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter breadth less than or equals 120",
          3000
        )
      );
      return;
    }

    if (weight && weight > 240) {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please enter weight less than or equals 100kg",
          3000
        )
      );
      return;
    }

    if (width) {
      data.append("attributes[width]", width);
    }

    if (height) {
      data.append("attributes[height]", height);
    }

    if (breadth) {
      data.append("attributes[breadth]", breadth);
    }

    if (weight) {
      data.append("attributes[weight]", weight);
    }

    // if everything ok
    if (isUserArtist(this.props?.user?.role)) {
      this.setState(
        {
          uploading: true,
        },
        () => {
          postAuthMultipart(
            generatePath(CONFIG.UPDATE_ART, { id: art?._id }),
            (res) => {
              store.dispatch(
                showGlobalSnack(
                  "normal",
                  "You have successfully updated your artwork",
                  3000
                )
              );
              history.push(
                generatePath(sitemap.artWork, { slug: res?.data?.productSlug })
              );
            },
            (e) => {
              console.error(e);
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong. Please try again.",
                  3000
                )
              );
            },
            data
          );
        }
      );
    } else if (!isUserArtist(this.props?.user?.role)) {
      this.setState(
        {
          uploading: true,
        },
        () => {
          postAuthMultipart(
            generatePath(CONFIG.UPDATE_USER_ART, { id: art?._id }),
            (res) => {
              store.dispatch(
                showGlobalSnack(
                  "normal",
                  "You have successfully updated your artwork",
                  3000
                )
              );

              history.push(
                generatePath(sitemap.userArt, { slug: res?.data?.productSlug })
              );
            },
            (e) => {
              console.error(e);
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong. Please try again.",
                  3000
                )
              );
            },
            data
          );
        }
      );
    } else {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Something went wrong. Please try again.",
          3000
        )
      );
    }
  };

  handleDelete = () => {
    const { history, art } = this.props;
    store.dispatch(
      showDialog(
        "Delete Art",
        "Are you sure you want to delete this art?",
        () => {
          this.setState({ deleting: true });
          getWithAuth(
            generatePath(CONFIG.DELETE_ART, { id: art.id }),
            () => {
              store.dispatch(hideDialog());
              history.goBack();
              store.dispatch(showGlobalSnack("normal", "Deleted art", 3000));
            },
            () => {
              store.dispatch(hideDialog());
              console.log("Error deleting art.");
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong while deleting the artwork",
                  3000
                )
              );

              // window.location.reload();
            }
          );
        }
      )
    );
  };

  handleUserArtDelete = () => {
    const { history, art } = this.props;
    store.dispatch(
      showDialog(
        "Delete Art",
        "Are you sure you want to delete this art?",
        () => {
          this.setState({ deleting: true });
          getWithAuth(
            generatePath(CONFIG.DELETE_USER_ART, { id: art.id }),
            () => {
              store.dispatch(hideDialog());
              history.goBack();
              store.dispatch(showGlobalSnack("normal", "Deleted art", 3000));
            },
            () => {
              console.log("Error deleting art.");
              store.dispatch(hideDialog());
              store.dispatch(
                showGlobalSnack(
                  "error",
                  "Something went wrong while deleting the artwork",
                  3000
                )
              );
              // window.location.reload();
            }
          );
        }
      )
    );
  };

  handleCancel = () => {
    const { art, user, history } = this.props;
    history.push(generatePath(sitemap.profile, { slug: art?.author?.slug }));
  };

  render() {
    const { history, art } = this.props;
    const {
      allCategories,
      images,
      coverImageIndex,
      name,
      description,
      mainCategory,
      subCategory,
      price,
      stock,
      width,
      height,
      breadth,
      weight,
      uploading,
      deleting,
    } = this.state;
    const { user } = this.props;
    const topCategories = allCategories.filter((category) => !category.parent);
    const categoryNavList = topCategories.map((category) => ({
      name: category.name,
      key: category._id,
    }));
    const categoryChildren = allCategories.filter(
      (category) => category.parent && category.parent._id === mainCategory
    );
    const categoryChildrenList = categoryChildren.map((category) => {
      const buttonClasses = classnames("category-select", {
        active: subCategory.indexOf(category._id) > -1,
      });
      return (
        <button
          key={`category-select-${category._id}`}
          className={buttonClasses}
          onClick={() => {
            this.handleSubCategoryClick(category._id);
          }}
        >
          {category.name}
        </button>
      );
    });

    return (
      <div id="page-upload-art">
        <Header
          title="Edit Art"
          subTitle="Upload high quality and original content"
          cancel={this.handleCancel}
          publish={this.handleUpload}
          loading={uploading}
        />

        <section className="commonFormSection">
          <div className="customContainer smallWidthLayout">
            <ContentCard title="Upload Images">
              <ImageUploader
                images={images}
                coverImageIndex={coverImageIndex}
                addImage={this.handleAddImage}
                setCoverImage={this.handleSetCoverImage}
                removeImage={this.handleRemoveImage}
              />
            </ContentCard>
            <ContentCard title="Basic Information">
              <Input
                weight
                required
                label="Name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
              <Input
                weight
                label="Description"
                type="textarea"
                name="description"
                value={description}
                onChange={(e) => {
                  this.setState({ description: e.target.value });
                }}
              />
            </ContentCard>
            <ContentCard title="Categories">
              {categoryNavList.length > 0 && (
                <FancyNav
                  fancy={false}
                  activeItemKey={mainCategory}
                  items={categoryNavList}
                  onClick={this.handleCategorySelect}
                />
              )}
              <div className="category-list">{categoryChildrenList}</div>
            </ContentCard>

            <ContentCard title="Price and Stock" className="typeThreeCol">
              <div className="typeThreeCol">
                <Input
                  weight
                  required
                  label="Price"
                  type="number"
                  name="price"
                  value={price}
                  subtitle="Enter 0 to list as Not For Sale"
                  onChange={(e) => {
                    this.setState({ price: e.target.value });
                  }}
                />
                <Input
                  weight
                  required
                  label="Stock"
                  type="number"
                  name="stock"
                  value={stock}
                  onChange={(e) => {
                    this.setState({ stock: e.target.value });
                  }}
                />
              </div>
            </ContentCard>

            <ContentCard title="Dimensions(inch,kg)">
              <div className="typeThreeCol">
                <Input
                  weight
                  label="Height"
                  type="number"
                  name="height"
                  value={height}
                  onChange={(e) => {
                    this.setState({ height: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Width"
                  type="number"
                  name="width"
                  value={width}
                  onChange={(e) => {
                    this.setState({ width: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Breadth"
                  type="number"
                  name="breadth"
                  value={breadth}
                  onChange={(e) => {
                    this.setState({ breadth: e.target.value });
                  }}
                />
                <Input
                  weight
                  label="Weight"
                  type="number"
                  name="weight"
                  value={weight}
                  onChange={(e) => {
                    this.setState({ weight: e.target.value });
                  }}
                />
              </div>
            </ContentCard>
            <ContentCard
              title="Artwork Tags"
              optional="(Optional)"
              heading="Add up to 10 keyword to help people discover your project"
            >
              <div className="tagsWrapper">
                <InputTags
                  placeholder="Enter tags.."
                  values={this.state.tags}
                  onTags={(value) => this.onTagsChanged(value.values)}
                />
              </div>
            </ContentCard>

            <ContentCard title="Delete Art">
              <div className="delete-art-card">
                <small>This cannot be undone.</small>
                {user.role > 10 && user.role < 21 && (
                  <CommonButton
                    onClick={this.handleDelete}
                    loading={deleting}
                    size="auto"
                  >
                    Delete
                  </CommonButton>
                )}
                {user.role >= 21 && user.role <= 30 && (
                  <CommonButton
                    onClick={this.handleUserArtDelete}
                    loading={deleting}
                    size="auto"
                  >
                    Delete
                  </CommonButton>
                )}
              </div>
            </ContentCard>
          </div>
        </section>
      </div>
    );
  }
}

EditArtworkForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  art: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(connect(mapStateToProps)(EditArtworkForm));
