import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import { generatePath } from "react-router";
import _ from "lodash";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Cookie from "js-cookie";
import axios from "axios";

import sitemap from "../../../../routing/siteMap";
import Avatar from "../../../Avatar";
import Loading from "../../../Loading";
import store from "../../../../redux";
import {
  showDialogNotification,
  hideDialog,
} from "../../../../redux/actions/dialog.actions";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import { getWithAuth, postAuth } from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import CommonButton from "../../../Button";
import FollowArtist from "../../../FollowArtist";
import DropdownMenu from "../../../DropdownMenu";
import "./notification.scss";

const mapStateToProps = ({ user }) => ({
  user,
});

class Notification extends Component {
  state = {
    notifications: [],
    loading: true,
    following: [],
    artist: {},
  };

  componentDidMount() {
    this.getNotifications();
    this.getFollowing();
  }

  getNotifications = () => {
    getWithAuth(
      CONFIG.GET_NOTIFICATIONS,
      (notifications) => {
        // TODO: LImit from BACKEND
        notifications.splice(100);
        this.setState({
          notifications,
          loading: false,
        });
      },
      (e) => {
        console.log(e);
        this.setState({ loading: false });
      }
    );
  };

  makeallNotificationsViewed = () => {
    const { notifications } = this.state;
    let needToUpdate = false;
    _.each(notifications, (notification) => {
      if (notification.read === "no" || notification.read === "seen")
        needToUpdate = true;
    });
    if (needToUpdate) {
      postAuth(
        CONFIG.MAKE_ALL_NOTIFICATIONS_VIEWED,
        () => {
          this.getNotifications();
          // this.setState({ newNotification: false });
        },
        (e) => {
          console.error(e);
        }
      );
    }
  };
  makeNotificationViewed = (id) => {
    postAuth(
      CONFIG.MAKE_NOTIFICATION_VIEWED,
      () => {
        this.getNotifications();
      },
      (e) => {
        console.error(e);
      },
      {
        notificationID: id,
      }
    );
  };
  getFollowing = () => {
    getWithAuth(
      CONFIG.GET_FOLLOWING,
      (following) => this.setState({ following }),
      (e) => console.log(e)
    );
  };
  approveFollowRequest = (notification) => {
    postAuth(
      CONFIG.APPROVE_FOLLOW,
      () => {
        this.setState({ notifications: [], loading: true });
        this.getNotifications();
      },
      (error) => console.log(error),
      {
        notification,
      }
    );
  };

  clearNotifications = () => {
    postAuth(
      CONFIG.CLEAR_NOTIFICATIONS,
      () => {
        store.dispatch(hideDialog());
        store.dispatch(
          showGlobalSnack(
            "normal",
            "Your notifications have been cleared",
            3000
          )
        );
        this.getNotifications();
      },
      (e) => {
        console.error(e);
        store.dispatch(
          showGlobalSnack(
            "error",
            "Something went wrong. Please reload and try again",
            3000
          )
        );
      }
    );
  };
  clearNotificationsDialog = () => {
    store.dispatch(
      showDialogNotification("Clear Notifications", "", this.clearNotifications)
    );
  };

  followArtist = (id) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      // this.setState({ loading: true });
      axios({
        url: CONFIG.FOLLOW_ARTIST,
        method: "POST",
        headers: {
          Authorization: `JWT ${token}`,
        },
        data: {
          artist: id,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            this.getFollowing();
          } else if (response.data.status === "followed") {
            showGlobalSnack(
              "normal",
              "You already follow this artist. Please refresh the page again.",
              2000
            );
          } else if (response.data.status === "not-found") {
            showGlobalSnack(
              "normal",
              "An error occurred. Please refresh the page and try again.",
              2000
            );
          } else {
            showGlobalSnack(
              "normal",
              "An unexpected error occurred. Please refresh the page and try again.",
              2000
            );
          }
          this.setState({ loading: false });
        })
        .catch((e) => {
          showGlobalSnack(
            "normal",
            "An unexpected error occurred. Please refresh the page and try again.",
            2000
          );
          this.setState({ loading: false });
          console.log(e.message || e);
        });
    }
  };
  unFollowArtist = (id) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      // this.setState({ loading: true });
      axios({
        url: `${CONFIG.API_URL}/secure/unfollowartist`,
        method: "POST",
        headers: {
          Authorization: `JWT ${token}`,
        },
        data: {
          artist: id,
        },
      })
        .then((res) => {
          if (res.data.status === "success") {
            this.getFollowing();
          } else {
            showGlobalSnack(
              "normal",
              "An unexpected error occurred. Please refresh the page and try again.",
              2000
            );
          }
          this.setState({ loading: false });
        })
        .catch((e) => {
          showGlobalSnack(
            "normal",
            "An unexpected error occurred. Please refresh the page and try again.",
            2000
          );
          console.log(e.message || e);
          this.setState({ loading: false });
        });
    }
  };
  render() {
    const { notifications, loading, following, artist } = this.state;
    const image = "assets/profile-dummy.png";
    // const dropdownActions = [
    //   {
    //     id: `notification-mark-read-action`,
    //     name: "Mark all as read",
    //     onClick: this.makeallNotificationsViewed,
    //   },
    //   {
    //     id: `notification-delete-action`,
    //     name: "Clear all notifications",
    //     onClick: this.clearNotificationsDialog,
    //   },
    // ];
    const notificationList = notifications.map((notification) => {
      let isFollowing = false;
      _.each(following, (list) => {
        if (list?._id === notification?.sender?._id) isFollowing = true;
      });

      let status = notification.read;
      if (notification.type === "follow") {
        return (
          <div
            className={`commonUserItem follow-request ${status}`}
            key={`notification-${notification?._id}`}
            onClick={() => this.makeNotificationViewed(notification?._id)}
          >
            <div className="innerItem">
              {notification?.link ? (
                <NavLink to={notification?.link}>
                  <Avatar
                    className="notification"
                    imgPath={
                      notification?.sender?.picture &&
                      notification?.sender?.picture != "undefined"
                        ? `users/${notification?.sender?.picture}`
                        : image
                    }
                  />
                </NavLink>
              ) : (
                <Avatar
                  className="notification"
                  imgPath={
                    notification?.sender?.picture &&
                    notification?.sender?.picture != "undefined"
                      ? `users/${notification?.sender?.picture}`
                      : image
                  }
                />
              )}
            </div>
            <div className="innerItem _info">
              <ul>
                <li>
                  <span>
                    {notification?.sender && notification?.sender?.name}
                  </span>
                  {notification?.message}
                </li>
                <li className="noti-time">
                  {moment(notification?.created).fromNow()}
                </li>
              </ul>
            </div>
            {isFollowing ? (
              <CommonButton
                mode="secondary"
                loading={this.state.loading}
                onClick={() => {
                  this.unFollowArtist(notification?.sender?._id);
                }}
              >
                Following
              </CommonButton>
            ) : (
              <CommonButton
                onClick={() => {
                  this.followArtist(notification?.sender?._id);
                }}
                mode="specialBlack"
                loading={this.state.loading}
              >
                Follow
              </CommonButton>
            )}
          </div>
        );
      } else if (notification.type == "post") {
        return (
          <div
            className={`commonUserItem ${status}`}
            key={`notification-${notification?._id}`}
          >
            <Link
              to={generatePath(sitemap.feedDetails, { id: notification?.link })}
              onClick={() => this.makeNotificationViewed(notification?._id)}
            >
              <div className="innerItem">
                <Avatar
                  className="notification"
                  imgPath={
                    notification?.sender?.picture &&
                    notification?.sender?.picture != "undefined"
                      ? `users/${notification?.sender?.picture}`
                      : image
                  }
                />
              </div>
              <div className="innerItem">
                <ul>
                  <li>
                    <span>
                      {notification?.sender && notification?.sender?.name}
                    </span>
                    {notification?.message}
                  </li>
                  <li className="noti-time">
                    {moment(notification?.created).fromNow()}
                  </li>
                </ul>
              </div>
              {notification?.image && (
                <div className="innerItem3">
                  <div className="img-wrapper">
                    <img src={notification?.image} />
                  </div>
                </div>
              )}
            </Link>
          </div>
        );
      }
      return (
        <div
          className={`commonUserItem ${status}`}
          key={`notification-${notification?._id}`}
        >
          <Link
            to={notification?.link}
            onClick={() => this.makeNotificationViewed(notification?._id)}
          >
            <div className="innerItem">
              <Avatar
                className="notification"
                imgPath={
                  notification?.sender?.picture &&
                  notification?.sender?.picture != "undefined"
                    ? `users/${notification?.sender?.picture}`
                    : image
                }
              />
            </div>
            <div className="innerItem">
              <ul>
                <li>
                  <span>
                    {notification?.sender && notification?.sender?.name}
                  </span>
                  {notification?.message}
                </li>
                <li className="noti-time">
                  {moment(notification?.created).fromNow()}
                </li>
              </ul>
            </div>
            {notification?.image && (
              <div className="innerItem3">
                <div className="img-wrapper">
                  <img src={notification?.image} />
                </div>
              </div>
            )}
          </Link>
        </div>
      );
    });
    return (
      <div id="notificationPage">
        <section className="notificationSection">
          <div className="customContainer smallWidthLayout">
            <div className="sectionContent addFullHeight">
              <div className="title">
                <div className="titleItem">
                  <h5>Your Notifications</h5>
                </div>
                {notifications?.length > 0 && (
                  // <DropdownMenu actions={dropdownActions} />
                  <div className="titleItem">
                    <button onClick={this.makeallNotificationsViewed}>
                      Mark all as read
                    </button>
                    <DeleteIcon
                      onClick={this.clearNotificationsDialog}
                      className="delete-noti"
                    />
                  </div>
                )}
              </div>
              <div className="content">
                {loading ? (
                  <Loading color="red" />
                ) : (
                  <div className="commonUserContainer">
                    {notifications?.length > 0 ? (
                      notificationList
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                      >
                        You don't have any Notification!
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Notification);
