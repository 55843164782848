import React, { useEffect, useState } from "react";
import { Route, Redirect, NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import sitemap from "../../../routing/siteMap";
import { getArtistData } from "../../../services/artistServices";
import ArtistWork from "./ArtistWork";
import ArtistFeed from "./ArtistFeed";
import PageHead from "../../PageHead";
import ArtistSideBar from "./ArtistSideBar";
import "./artist.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});
const Artist = (props) => {
  const [artist, setArtist] = useState({});
  const [loading, setLoading] = useState(true);
  const { match, history, user } = props;

  useEffect(() => {
    getArtistInfo();
  }, [match?.params?.slug]);

  const getArtistInfo = () => {
    const slug = match?.params?.slug;
    getArtistData(
      slug,
      (response) => {
        setArtist(response);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  const setFollow=()=>{
    getArtistInfo()
  }

  return (
    <div id="artistPage">
      {loading ? (
        ""
      ) : (
        <div className="commonGidContainer typeHeaderUp">
          <PageHead imgPath={artist?.cover && `users/${artist?.cover}`} />
          <ArtistSideBar artist={artist} history={history} setFollow={setFollow} />
          <div className="gridItem mainContent">
            <div className="artist-bar">
              <div className="sectionContent">
                <ul>
                  <li>
                    <NavLink
                      to={generatePath(sitemap.artistWork, {
                        slug: match?.params?.slug,
                      })}
                    >
                      Artworks
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={generatePath(sitemap.artistFeed, {
                        slug: match?.params?.slug,
                      })}
                    >
                      Feed
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <Route
              exact
              path={sitemap.artistWork}
              render={(props) => <ArtistWork {...props} artist={artist} />}
            />
            <Route
              exact
              path={sitemap.artistFeed}
              render={(props) => <ArtistFeed {...props} artist={artist} />}
            />
            <Redirect
              to={generatePath(sitemap.artistWork, {
                slug: match?.params?.slug,
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Artist.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};
Artist.defaultProps = {
  user: null,
};
export default connect(mapStateToProps)(Artist);
