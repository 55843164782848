import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import HomeSection from "../HomeSection";
import Loading from "../Loading";
import ImageContainer from "../ImageContainer";
import { getFeatured, getFeaturedAuth } from "../../services/productServices";
import { getThumbnail } from "../../utils/mediaUtils";
import siteMap from "../../routing/siteMap";
import LikeArt from "../LikeArt";
import "./FeaturedArtworks.scss";

class FeaturedArtworks extends Component {
  state = {
    items: [],
    isLoading: false,
    showArts: 3,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      getFeaturedAuth()
        .then((items) => {
          this.setState({ items, isLoading: false });
        })
        .catch((error) => {
          console.log(
            "Error occurred while trying to get featured products.",
            error
          );
          this.setState({ isLoading: false });
        });
    } else {
      getFeatured()
        .then((items) => {
          this.setState({ items, isLoading: false });
        })
        .catch((error) => {
          console.log(
            "Error occurred while trying to get featured products.",
            error
          );
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { isLoading, items } = this.state;
    const featuredList = items?.map((item) => {
      const thumbnail = getThumbnail(item?.image, "gallery");
      return (
        <div key={`popular-item-${item?._id}`} className="featured-art">
          <Link to={generatePath(siteMap.artWork, { slug: item?.slug })}>
            <ImageContainer
              imgPath={`${item?.image?.set?.slug}/${thumbnail?.fileName}`}
              width="100%"
              height={300}
              size="cover"
              borderRadius={5}
            />
          </Link>
          {/* <LikeArt art={item} /> */}
          <div className="details">
            <div className="left">
              <div className="name">{item?.name}</div>
              <div> {item?.priceField.toUpperCase()}</div>
              <span>
                by{" "}
                <Link
                  to={generatePath(siteMap.artist, {
                    slug: item?.author?.slug,
                  })}
                >
                  {item?.author?.name}
                </Link>
              </span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <>
        {featuredList?.length > 0 && (
          <HomeSection title="Featured Artworks" grey fullHeight fullWidth>
            {isLoading ? (
              <Loading color="red" />
            ) : (
              <>
                <div className="featured-art-list web-view">{featuredList}</div>
                <div className="featured-art-list mobile-view">
                  {featuredList?.slice(0, this.state.showArts)}
                  <div className="load-featured">
                    <button
                      onClick={() => {
                        this.setState({
                          showArts:
                            this.state.showArts < featuredList?.length
                              ? featuredList?.length
                              : 3,
                        });
                      }}
                      className="load-btn"
                    >
                      {this.state.showArts < featuredList?.length
                        ? "Show More"
                        : "Show Less"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </HomeSection>
        )}
      </>
    );
  }
}

export default FeaturedArtworks;
