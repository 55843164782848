import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import classnames from "classnames";
import { connect } from "react-redux";
import { MdReport } from "react-icons/md";
import { Dialog } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Cookie from "js-cookie";
import axios from "axios";

import { report } from "../../../services/reportServices";
import Button from "../../Button";
import sitemap from "../../../routing/siteMap";
import AdvancedAvatar from "../../AdvancedAvatar/AdvancedAvatar";
import CommonButton from "../../Button";
import FollowArtist from "../../FollowArtist";
import { CONFIG } from "../../../constants";
import store from "../../../redux";
import { showGlobalSnack } from "../../../redux/actions/snack.actions";
import {
    get,
    getWithAuth,
    postData,
} from "../../../services/generalApiServices";
import "./NormalUser.scss";

const mapStateToProps = (state) => ({
    user: state.user,
});
const dialogClasses = {
    paper: {
        width: "100%",
        maxWidth: 280,
        padding: 20,
    },
};
class ProfileSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBioOpen: false,
            showAllText: false,
            showAllAchiev: false,
            styles: [],
            showReport: false,
            showBlock: false,
            reportValue: "",
            reportOpt: "",
            showInput: false,
            otherText: "",
        };
    }

    handelBioShow = () => {
        this.setState((prevState) => ({
            isBioOpen: !prevState.isBioOpen,
        }));
    };
    componentDidMount() {
        const { userInfo } = this.props;
        if (userInfo?.info?.categories?.length > 0) {
            const tempArray = userInfo?.info?.categories?.filter(
                (v, i, a) => a.findIndex((t) => t._id === v._id) === i
            );
            this.setState({ styles: tempArray });
        }
        this.getReportOptions();
    }
    handleChange = (e) => {
        this.setState({ reportValue: e.target.value });
        if (e.target.value === "61cd60b2d9cf90805f719a2c") {
            this.setState({ showInput: true });
        }
    };
    closeDialog = () => {
        this.setState({ showReport: false, showBlock: false });
    };
    getReportOptions = () => {
        get(
            CONFIG.GET_REPORT_OPTIONS,
            (options) => {
                this.setState({ reportOpt: options });
            },
            (err) => {}
        );
    };
    initChat = () => {
        const { user, userInfo } = this.props;
        postData(
            CONFIG.CREATE_ROOM,
            (res) => {
            
                this.props.history.push(
                    `${
                        user
                            ? generatePath(sitemap.messages, {
                                  to: res?.room?._id,
                              })
                            : sitemap.login
                    }`
                );
            },
            (e) => console.log(e, "error creating room"),
            { sender: user.id, receiver: userInfo._id }
        );
    };

    handleSubmit = () => {
        const { reportValue, otherText } = this.state;
        if (reportValue) {
            report(
                generatePath(CONFIG.REPORT_USER, {
                    id: this.props.userInfo?._id,
                }),
                (res) => {
                    store.dispatch(
                        showGlobalSnack(
                            "success",
                            "Profile reported successfully",
                            3000
                        )
                    );
                    this.setState({ showReport: false });
                },
                (err) => {
                    store.dispatch(showGlobalSnack("error", err, 3000));
                    this.setState({ showReport: false });
                },
                {
                    category: reportValue,
                    content: otherText,
                }
            );
        } else {
            store.dispatch(
                showGlobalSnack(
                    "error",
                    "Please Select option to continue",
                    3000
                )
            );
        }
    };
    blockUser = (id) => {
        const { history } = this.props;
        const token = Cookie.get(CONFIG.AUTH_TOKEN);
        const apiUrl = generatePath(CONFIG.BLOCK_USER, { id });
        if (token) {
            axios({
                url: apiUrl,
                method: "PATCH",
                headers: {
                    Authorization: `JWT ${token}`,
                },
            })
                .then((response) => {
                    if (response.data.status === "succes") {
                        this.setState({ showBlock: false });
                        store.dispatch(
                            showGlobalSnack(
                                "success",
                                "User blocked successfully",
                                3000
                            )
                        );
                        history.push("/privacy");
                    } else throw new Error(response.data.message);
                })
                .catch((e) => {
                    this.setState({ showBlock: false });
                    store.dispatch(
                        showGlobalSnack(
                            "error",
                            "Something went wrong while blocking user",
                            3000
                        )
                    );
                });
        }
    };
    render() {
        const {
            isBioOpen,
            styles,
            showReport,
            reportOpt,
            reportValue,
            showInput,
            showBlock,
        } = this.state;
        const { user, userInfo } = this.props;
        const bioClass = classnames("sideBarGroup bioSideBarGroup", {
            "bio-showing": isBioOpen,
        });
        const noOffollowers = userInfo?.follows?.length;
        const noOfWorks = userInfo?.totalArtworks;
        const slug = user?.slug;
        const image = "assets/profile-dummy.png";
        const categoryList = styles.map((category) => {
            return (
                <li key={`category-item-${category._id}`}>
                    <NavLink to="#" className="active">
                        {category.name}
                    </NavLink>
                </li>
            );
        });
        return (
            <div className="gridItem artistSideBar">
                <Dialog
                    open={showReport}
                    onClose={this.closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={this.props.classes}
                >
                    <div id="responsive-dialog-title">Report Profile</div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="report"
                            name="report"
                            disabled="true"
                            value={reportValue}
                            onChange={this.handleChange}
                        >
                            {reportOpt &&
                                reportOpt.map((opt) => (
                                    <FormControlLabel
                                        value={opt?._id}
                                        control={<Radio />}
                                        label={<div>{opt?.name}</div>}
                                    />
                                ))}
                        </RadioGroup>
                        {showInput && (
                            <input
                                placeholder="write reason"
                                className="report-input"
                                onChange={(e) =>
                                    this.setState({ otherText: e.target.value })
                                }
                            />
                        )}
                    </FormControl>
                    <div className="report-btns">
                        <Button mode="reportSubmit" onClick={this.handleSubmit}>
                            Submit
                        </Button>
                        <Button mode="special" onClick={this.closeDialog}>
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <Dialog
                    open={showBlock}
                    onClose={this.closeDialog}
                    aria-labelledby="responsive-dialog-title"
                    classes={this.props.classes}
                >
                    <div id="responsive-dialog-title">Block Messages</div>
                    <div
                        id="responsive-dialog-actions"
                        style={{ marginTop: "14px" }}
                    >
                        <span
                            role="button"
                            tabIndex="-1"
                            onClick={() => this.blockUser(userInfo?._id)}
                            mode="special"
                        >
                            Confirm
                        </span>
                        <Button
                            size="medium"
                            onClick={this.closeDialog}
                            mode="specialBlack"
                        >
                            Cancel
                        </Button>
                    </div>
                </Dialog>
                <div className="inner">
                    <div className="sideBarGroup profileSideBarGroup">
                        <div className="sideGroupContent">
                            <div className="sideBarItem artistContainer">
                                <AdvancedAvatar
                                    name={userInfo?.name}
                                    imgPath={
                                        userInfo?.picture &&
                                        userInfo.picture != "undefined"
                                            ? `users/${userInfo?.picture}`
                                            : image
                                    }
                                    followers={String(noOffollowers)}
                                    works={String(noOfWorks)}
                                    id={userInfo?._id}
                                    category={
                                        userInfo?.info?.categories[0]?.name
                                    }
                                />

                                <div className="action">
                                    {userInfo &&
                                    user &&
                                    userInfo?._id === user?.id ? (
                                        <ul>
                                            <li>
                                                <NavLink
                                                    to={sitemap.cart}
                                                    activeClassName="active"
                                                >
                                                    <button
                                                        className="cart-btn"
                                                        onClick={sitemap.cart}
                                                    >
                                                        Cart
                                                    </button>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={generatePath(
                                                        sitemap.userlikedArtwork,
                                                        {
                                                            slug: slug,
                                                        }
                                                    )}
                                                    activeClassName="active"
                                                >
                                                    <button className="saved-btn">
                                                        Liked
                                                    </button>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            <li>
                                                <FollowArtist
                                                    artist={userInfo}
                                                    setFollow={this.props.setFollow}
                                                />
                                            </li>
                                            <li>
                                                {userInfo?.slug && (
                                                    <div
                                                        // to={
                                                        //     user
                                                        //         ? generatePath(
                                                        //               sitemap.messages,
                                                        //               {
                                                        //                   to:
                                                        //                       userInfo?.slug,
                                                        //               }
                                                        //           )
                                                        //         : sitemap.login
                                                        // }
                                                        onClick={() =>
                                                            this.initChat()
                                                        }
                                                    >
                                                        <CommonButton
                                                            size="medium"
                                                            mode="special"
                                                        >
                                                            Message
                                                        </CommonButton>
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                    )}
                                    <ul></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={bioClass} id="bio">
                        <div className="sideGroupContent">
                            {userInfo?.info?.categories.length <= 0 ? (
                                ""
                            ) : (
                                <div className="sideBarItem">
                                    <div className="title">
                                        <h6>Styles</h6>
                                    </div>

                                    <div className="styles">
                                        <ul>{categoryList}</ul>
                                    </div>
                                </div>
                            )}
                            {userInfo?.info?.biography &&
                                userInfo?.info?.biography !== "undefined" && (
                                    <div className="sideBarItem bioContainer">
                                        <div className="title">
                                            <h6>Biography</h6>
                                        </div>
                                        <div className="content">
                                            <p
                                                className={`bio ${this.state
                                                    .showAllText &&
                                                    "view-all"}`}
                                            >
                                                {userInfo?.info?.biography.slice(
                                                    0,
                                                    132
                                                )}
                                                {this.state.showAllText &&
                                                    userInfo?.info?.biography.slice(
                                                        132
                                                    )}
                                                {userInfo?.info?.biography
                                                    .length > 132 && (
                                                    <>
                                                        <span
                                                            onClick={() =>
                                                                this.setState({
                                                                    showAllText: !this
                                                                        .state
                                                                        .showAllText,
                                                                })
                                                            }
                                                        >
                                                            {!this.state
                                                                .showAllText
                                                                ? " .... Read More"
                                                                : " Read Less"}
                                                        </span>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            {userInfo?.info?.achievements &&
                                userInfo?.info?.achievements !==
                                    "undefined" && (
                                    <div className="sideBarItem">
                                        <div className="title">
                                            <h6>Achievements</h6>
                                        </div>
                                        <div className="content">
                                            <p
                                                className={`bio ${this.state
                                                    .showAllAchiev &&
                                                    "view-all"}`}
                                            >
                                                {userInfo?.info?.achievements.slice(
                                                    0,
                                                    132
                                                )}
                                                {this.state.showAllAchiev &&
                                                    userInfo?.info?.achievements.slice(
                                                        132
                                                    )}
                                                {userInfo?.info?.achievements
                                                    .length > 132 && (
                                                    <>
                                                        <span
                                                            onClick={() =>
                                                                this.setState({
                                                                    showAllAchiev: !this
                                                                        .state
                                                                        .showAllAchiev,
                                                                })
                                                            }
                                                        >
                                                            {!this.state
                                                                .showAllAchiev
                                                                ? " .... Read More"
                                                                : " Read Less"}
                                                        </span>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="sideGroupAction">
                        <DownIcon onClick={this.handelBioShow} />
                    </div>
                    {user?.id !== userInfo?._id && (
                        <div className="report-profile">
                            <span
                                className="pro-report"
                                onClick={() =>
                                    this.setState({ showReport: true })
                                }
                            >
                                Report
                            </span>
                            <span
                                className="pro-block"
                                onClick={() =>
                                    this.setState({ showBlock: true })
                                }
                            >
                                Block
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ProfileSidebar.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default connect(mapStateToProps)(
    withStyles(dialogClasses)(ProfileSidebar)
);
