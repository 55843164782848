import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { generatePath } from "react-router";
import YouTube from "react-youtube";

import Button from "../Button";
import Avatar from "../Avatar";
import { ImageUploaderSingle } from "../ImageUploader";
import { CONFIG } from "../../constants";
import { postAuthMultipart } from "../../services/generalApiServices";
import store from "../../redux";
import { showGlobalSnack } from "../../redux/actions/snack.actions";

const dialogClasses = {
  paper: {
    width: "100%",
    padding: 12,
    borderRadius: 5,
  },
};

class EditFeed extends Component {
  constructor(props) {
    super(props);
    const imageObj = props?.post?.picture
      ? { dataUrl: props?.post?.picture }
      : null;
    const videoId = props?.post?.youtube_url ? props?.post?.youtube_url : null;
    this.state = {
      postContent: props?.post?.post,
      image: imageObj && imageObj,
      loading: false,
      videoId: videoId,
      changeVideoUrl: false,
      youtubeUrl: "",
      // youtubeUrl:`www.youtube.com/watch?v=${props.post.youtube_url}`
    };
  }

  handleAddImage = (file, dataUrl, uuid) => {
    this.setState({
      image: {
        uuid,
        file,
        dataUrl,
      },
    });
  };

  handleRemoveImage = () => {
    this.setState({
      image: null,
    });
  };

  handleChangeVideoUrl = () => {
    this.setState({
      changeVideoUrl: true,
    });
  };

  onYoutubeLinkPaste = (event) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    let url = (event.clipboardData || window.clipboardData).getData("text");
    var match = url.match(regExp);
    if (match && match[7].length == 11) {
      this.setState({ videoId: match[7] });
    } else {
      const message = `Invalid Youtube Url`;
      store.dispatch(showGlobalSnack("error", message, 3000));
    }
  };

  updatePost = () => {
    const { post, handleClose, refresh } = this.props;
    const { postContent, image, videoId } = this.state;
    this.setState({ loading: true });
    const data = new FormData();
    data.append("post", postContent);
    if (image) {
      if (image.file) {
        data.append("picture", image.file);
      } else {
        data.append("picture", image.dataUrl);
      }
    }
    if (videoId) {
      data.append("youtube_url", videoId);
    }
    postAuthMultipart(
      generatePath(CONFIG.UPDATE_POST, { id: post?._id }),
      () => {
        this.setState({ loading: false });
        refresh();
        handleClose();
        store.dispatch(
          showGlobalSnack("normal", "Post has been updated", 3000)
        );
        window.location.reload();
      },
      () => {
        this.setState({ loading: false });
        handleClose();
        store.dispatch(
          showGlobalSnack(
            "error",
            "Something went wrong. Please try again",
            3000
          )
        );
      },
      data
    );
  };

  render() {
    const { classes, handleClose, post, refresh } = this.props;
    const {
      postContent,
      image,
      loading,
      videoId,
      changeVideoUrl,
      youtubeUrl,
    } = this.state;

    const opts = {
      height: "235",
      width: "235",
      playerVars: {
        autoplay: 0,
      },
    };

    return (
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={classes}
        className="edit-post-dialog"
      >
        <div className="edit-post-body">
          <div className="feedItem">
            <div className="feedTitle">
              <Avatar
                imgPath={
                  post?.author?.picture &&
                  post?.author?.picture != "undefined" &&
                  `users/${post?.author?.picture}`
                }
              />
              <div className="authorName">
                <h3>{post?.author?.name}</h3>
                <p className="coSmallText">{moment(post?.created).fromNow()}</p>
              </div>
            </div>
            <div className="editFeedContent">
              {/* {image ? ( */}
              {videoId ? (
                ""
              ) : (
                <div className="imageContainer">
                  <ImageUploaderSingle
                    image={image}
                    addImage={this.handleAddImage}
                    removeImage={this.handleRemoveImage}
                  />
                </div>
              )}
              {/* ) : (
                ""
              )} */}
              {videoId ? (
                <YouTube
                  id="post-video"
                  videoId={videoId}
                  opts={opts}
                  onReady={this._onReady}
                />
              ) : (
                ""
              )}
              {videoId ? (
                <>`https://www.youtube.com/watch?v=${videoId}`</>
              ) : (
                <></>
              )}
              {changeVideoUrl ? (
                <input
                  id="youtubeUrl"
                  value={youtubeUrl}
                  onPaste={this.onYoutubeLinkPaste}
                  onChange={(e) => {
                    this.setState({ youtubeUrl: e.target.value });
                  }}
                  placeholder="Paste your new youtube video URL"
                />
              ) : (
                ""
              )}
              {videoId && (
                <button id="changeVideoBtn" onClick={this.handleChangeVideoUrl}>
                  Change Video URL
                </button>
              )}
              <div className="editTextContainer">
                <textarea
                  value={postContent}
                  onChange={(e) => {
                    this.setState({ postContent: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="edit-post-dialog-actions">
          <span
            role="button"
            tabIndex="-1"
            onClick={handleClose}
            mode="special"
          >
            Cancel
          </span>
          <Button
            size="auto"
            onClick={this.updatePost}
            mode="primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

EditFeed.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  post: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default withStyles(dialogClasses)(EditFeed);
