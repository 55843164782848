import React, { Component } from "react";
import ListViewIcon from "@material-ui/icons/ViewAgenda";
import GridViewIcon from "@material-ui/icons/Dashboard";
import classnames from "classnames";
import Breakpoint from "react-socks";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import Cookie from "js-cookie";

import {
  get,
  getWithAuth,
  getWithParams,
  getWithParamsRaw,
  getWithParamsRawAuth,
} from "../../../services/generalApiServices";
import { CONFIG } from "../../../constants";
import ArtistCollection from "./ArtistCollection";
import Loading from "../../Loading";
import PageHead from "../../PageHead";
import "./studios.scss";
import { generatePath, NavLink, Link } from "react-router-dom";
import siteMap from "../../../routing/siteMap";
import CategorySelectDialog from "./CategorySelectDialog/CategorySelectDialog";

const mapStateToProps = (state) => ({
  user: state.user,
});
class Studios extends Component {
  state = {
    artists: [],
    isLoading: true,
    sortBy: "all",
    loadingMore: false,
    page: 0,
    isGridView: false,
    category_id: "all",
    categorySelectDialogOpen: false,
    categories: [],
  };

  total = 999999;

  componentDidMount() {
    const { sortBy } = this.state;
    this.getInitialArtists(sortBy);
    this.getCategories();
    // if(event){
    //   this.getInitialArtists(sortBy);
    // }
  }
  getCategories = () => {
    get(
      CONFIG.GET_CATEGORIES,
      (categories) => {
        this.setState({
          categories,
        });
      },
      (e) => {
        console.log(e);
      }
    );
  };
  getInitialArtists = (term) => {
    const { page } = this.state;
    const { user } = this.props;
    this.setState({
      artists: [],
      isLoading: true,
    });
    {
      user
        ? getWithParamsRawAuth(
            CONFIG.GET_ARTISTS_SORTED,
            (res) => {
              this.total = res.total;
              this.setState({
                artists: res.message,
                isLoading: false,
              });
            },
            (error) => {
              console.error(error);
            },
            {
              sortby: term,
              limit: CONFIG.ARTISTS_PAGE_LIMIT,
              skip: page * CONFIG.ARTISTS_PAGE_LIMIT,
            }
          )
        : getWithParamsRaw(
            CONFIG.GET_ARTISTS_SORTED,
            (res) => {
              this.total = res.total;
              this.setState({
                artists: res.message,
                isLoading: false,
              });
            },
            (error) => {
              console.error(error);
            },
            {
              sortby: term,
              limit: CONFIG.ARTISTS_PAGE_LIMIT,
              skip: page * CONFIG.ARTISTS_PAGE_LIMIT,
            }
          );
    }
  };

  handleSort = (sortBy) => {
    this.setState({ sortBy });
    this.getInitialArtists(sortBy);
  };

  handleShowGrid = () => {
    this.setState({
      isGridView: true,
    });
  };

  handleHideGrid = () => {
    this.setState({
      isGridView: false,
    });
  };

  loadMore = () => {
    const { page, sortBy, artists } = this.state;
    const { user } = this.props;
    const newPage = page + 1;
    this.setState(
      {
        page: newPage,
        loadingMore: true,
      },
      () => {
        {
          user
            ? getWithAuth(
                CONFIG.GET_ARTISTS_SORTED,
                (moreArtists) => {
                  const newArtists = [...artists, ...moreArtists];
                  this.setState({
                    artists: newArtists,
                    loadingMore: false,
                  });
                },
                (error) => {
                  console.error(error);
                },
                {
                  sortby: sortBy,
                  limit: CONFIG.ARTISTS_PAGE_LIMIT,
                  skip: newPage * CONFIG.ARTISTS_PAGE_LIMIT,
                }
              )
            : getWithParams(
                CONFIG.GET_ARTISTS_SORTED,
                (moreArtists) => {
                  const newArtists = [...artists, ...moreArtists];
                  this.setState({
                    artists: newArtists,
                    loadingMore: false,
                  });
                },
                (error) => {
                  console.error(error);
                },
                {
                  sortby: sortBy,
                  limit: CONFIG.ARTISTS_PAGE_LIMIT,
                  skip: newPage * CONFIG.ARTISTS_PAGE_LIMIT,
                }
              );
        }
      }
    );
  };
  selectCategory = (_id) => {
    this.setState({ category_id: _id });
    this.handleSort(_id);
  };
  setFollow = () => {
    
     const { sortBy } = this.state;
    this.getInitialArtists(sortBy);
    this.getCategories();
  }

  render() {
    const {
      artists,
      isLoading,
      sortBy,
      loadingMore,
      isGridView,
      categories,
      categorySelectDialogOpen,
      category_id,
    } = this.state;
    const { match } = this.props;
    const categoryList = categories.map((category) => (
      <li
        key={`studios-cat-${category._id}`}
        className={sortBy === category?._id ? "active" : ""}
      >
        <span
          role="button"
          tabIndex="-1"
          onClick={() => {
            this.handleSort(category?._id);
          }}
        >
          {category?.name}
        </span>
      </li>
    ));
    let activeCategoryName = null;
    if (category_id === "all") {
      activeCategoryName = "All";
    } else {
      activeCategoryName = categories.find((cat) => cat._id === category_id);
      activeCategoryName = activeCategoryName && activeCategoryName.name;
    }
    const viewClass = classnames("artistSection", {
      "grid-showing": isGridView,
    });
    return (
      <div id="studiosPage">
        <CategorySelectDialog
          isOpen={categorySelectDialogOpen}
          onClose={() => {
            this.setState({ categorySelectDialogOpen: false });
          }}
          categories={categories}
          selectCategory={this.selectCategory}
          activeCat={activeCategoryName}
        />
        <PageHead title="Studios" />
        <Breakpoint m>
          <section className="commonSubNavSection addHalfMargin typeCategories">
            <div className="customContainer">
              <div className="sectionContent">
                <div className="subNavItem">
                  <ul className="cat-nav">
                    <li>
                      <span style={{ fontWeight: 600, color: "#343434" }}>
                        Categories
                      </span>
                    </li>
                    <li className={sortBy === "all" ? "active" : ""}>
                      <span
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                          this.handleSort("all");
                        }}
                      >
                        All
                      </span>
                    </li>
                    {categoryList}
                  </ul>
                </div>
                <div className="subNavItem">
                  <ul className="artist-nav">
                    <li className={sortBy === "follows" ? "active" : ""}>
                      <span
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                          this.handleSort("follows");
                        }}
                      >
                        Most Followers
                      </span>
                    </li>
                    <li className={sortBy === "works" ? "active" : ""}>
                      <span
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                          this.handleSort("works");
                        }}
                      >
                        Most Works
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </Breakpoint>

        <Breakpoint s>
          <section className="mobileSelectSection commonSubNavSection addHalfMargin">
            <div className="customContainer">
              <div className="sectionContent">
                <div className="filterContainer">
                  <div
                    role="button"
                    tabIndex="-1"
                    className="filterItem"
                    onClick={() => {
                      this.setState({ categorySelectDialogOpen: true });
                    }}
                  >
                    <ul>
                      <li>Categories</li>
                      <li>{activeCategoryName}</li>
                    </ul>
                    <DownIcon />
                  </div>
                </div>
                <ul className="artist-nav">
                  <li className={sortBy === "follows" ? "active" : ""}>
                    <span
                      role="button"
                      tabIndex="-1"
                      onClick={() => {
                        this.handleSort("follows");
                      }}
                    >
                      Most Followers
                    </span>
                  </li>
                  <li className={sortBy === "works" ? "active" : ""}>
                    <span
                      role="button"
                      tabIndex="-1"
                      onClick={() => {
                        this.handleSort("works");
                      }}
                    >
                      Most Works
                    </span>
                  </li>
                </ul>

                <ul className="artistView">
                  <li className={!isGridView ? "active" : ""}>
                    <ListViewIcon onClick={this.handleHideGrid} />
                  </li>
                  <li className={isGridView ? "active" : ""}>
                    <GridViewIcon onClick={this.handleShowGrid} />
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </Breakpoint>

        {/* {isLoading ? (
          <Loading color="red" />
        ) : ( */}
          <ArtistCollection
            artists={artists}
            loadMore={this.loadMore}
            isLoadingMore={loadingMore}
            viewClass={viewClass}
            loadNoMore={artists.length >= this.total}
            setFollow={this.setFollow}
          />
         {/* )} */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Studios);
