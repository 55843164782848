import axios from "axios";
import { generatePath } from "react-router";
import { CONFIG } from "../constants";

export const getArtistsByCat = (catId, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_ARTIST_BY_CATEGORY, { cat: catId });
  axios({
    url: apiUrl,
    method: "GET",
  })
    .then((response) => {
      if (response.data.status === "success") {
        success(response.data.message);
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getArtistData = (slug, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_ARTIST_BY_SLUG, { slug });
  axios({
    url: apiUrl,
    method: "GET",
  })
    .then((response) => {
      if (response.data.status === "success") {
        success(response.data.message);
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};
