import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import { Breakpoint } from "react-socks";
import { connect } from "react-redux";
import { objectOf, any } from "prop-types";

import sitemap from "../../routing/siteMap";
import Notifications from "../__subpages/Header/DesktopHeader/Notifications";

import FeedIcon from "../../assets/icons/Feed.svg";
import Gallery from "../../assets/icons/Gallery.svg";
import Home from "../../assets/icons/Home.svg";
import Studios from "../../assets/icons/Studios.svg";

import ExhibitionOutline from "../../assets/icons/Exhibition.Stroke.png";
import ExhibitionFill from "../../assets/icons/Exhibition.Fill.png";
import GalleryOutline from "../../assets/icons/Gallery.Stroke.png";
import GalleryFill from "../../assets/icons/Gallery.Fill.png";
import HomeOutline from "../../assets/icons/Home.Stroke.png";
import HomeFill from "../../assets/icons/Home.Fill.png";
import NotificationOutline from "../../assets/icons/Notification.Stroke.png";
import NotificationFill from "../../assets/icons/Notification.Fill.png";
import Add from "../../assets/icons/Add.png";
import Feed from "../../assets/icons/Feed.png";
import Upload from "../../assets/icons/Upload.png";
import FeedSelected from "../../assets/icons/Feed Selected.png";

import "./FooterNavigation.scss";

const mapStateToProps = ({ user }) => ({ user });

const FooterNavigation = ({ user }) => {
  const [newActiveLink, setNewActiveLink] = useState(null);

  return (
    <Breakpoint s>
      <div id="footer-navigation">
        <div className="nav-icon">
          <NavLink
            exact
            to="/"
            isActive={(match, location) => {
              match && setNewActiveLink(1);
              return match;
            }}
          >
            <img
              src={newActiveLink === 1 ? HomeFill : Home}
              alt=""
              style={{ height: 24 }}
            />
          </NavLink>
        </div>

        <div className="nav-icon">
          <NavLink
            to={sitemap.exhibitions}
            isActive={(match, location) => {
              match && setNewActiveLink(2);
              return match;
            }}
          >
            <img
              src={newActiveLink === 2 ? ExhibitionFill : Gallery}
              alt=""
              style={{ height: 20 }}
            />
          </NavLink>
        </div>

        <div className="nav-icon">
          <NavLink
            to={generatePath(sitemap.gallery, { cat: "all" })}
            isActive={(match, location) => {
              match && setNewActiveLink(3);
              return match;
            }}
          >
            <img
              src={newActiveLink === 3 ? GalleryFill : Studios}
              alt=""
              style={{ height: 24 }}
            />
          </NavLink>
        </div>
        <div className="nav-icon">
          <NavLink
            to={sitemap.feed}
            isActive={(match, location) => {
              match && setNewActiveLink(4);
              return match;
            }}
          >
            <img
              src={newActiveLink === 4 ? FeedSelected : Feed}
              alt=""
              style={{ height: 24 }}
            />
          </NavLink>
        </div>
        {user && (
          <div onClick={() => setNewActiveLink(5)}>
            {" "}
            <Notifications footer fill={newActiveLink} />
          </div>
        )}
      </div>
    </Breakpoint>
  );
};

FooterNavigation.propTypes = {
  user: objectOf(any),
};

FooterNavigation.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(FooterNavigation);
