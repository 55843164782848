import { USER_LOGIN, USER_LOGOUT } from '../constants/action-types';

export const userLogin = (user, token) => ({
  type: USER_LOGIN,
  payload: {
    user,
    token
  }
});

export const userLogout = () => ({
  type: USER_LOGOUT
});

