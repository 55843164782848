export const SET_LOCALE = "SET_LOCALE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const SHOW_GLOBAL_SNACK = "SHOW_GLOBAL_SNACK";
export const HIDE_GLOBAL_SNACK = "HIDE_GLOBAL_SNACK";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const SET_LOGIN_BACK = "SET_LOGIN_BACK";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_FOLLOWER = "REMOVE_FOLLOWER";
export const GET_ALL_USER_DETAILS = "GET_ALL_USER_DETAILS";
