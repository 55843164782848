import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { generatePath } from "react-router";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import Header from "../../../UserPageHeader";
import ContentCard from "../../../ContentCard";
import { Input } from "../../../Form";
import {
  get,
  postAuthMultipart,
} from "../../../../services/generalApiServices";
import { CONFIG } from "../../../../constants";
import store from "../../../../redux";
import { showGlobalSnack } from "../../../../redux/actions/snack.actions";
import Loading from "../../../Loading";
import { CoverUploader } from "../../../ImageUploader";
import { dataURItoBlob } from "../../../../utils/mediaUtils";
import sitemap from "../../../../routing/siteMap";
import ProfileUploader from "../../../ImageUploader/profileUploader";
import "./Edit.scss";

const styles = {
  root: {
    width: "100%",
    margin: 0,
  },
  label: {
    flexGrow: 1,
    fontFamily: '"Roboto"',
  },
};

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      allCategories: [],
      mainCategory: null,
      categories: [],
      errors: {},
      picture: {
        url: null,
        dataUrl: null,
        file: null,
      },
      cover: {
        url: null,
        dataUrl: null,
        file: null,
      },
      fullName: "",
      dob: moment(),
      email: "",
      address: "",
      bio: "",
      achievements: "",
      loadingUser: true,
      saving: false,
      privateProfile: null,
      country: null,
      postalCode: null,
      address1: null,
      address2: null,
      city: null,
      stateRegion: null,
      phoneNumber: null,
      editProfileBtn: false,
      editCoverPictureBtn: false,
    };
    this.pickerDialogRef = React.createRef();
  }

  componentDidMount() {
    this.getUserInfo();
    get(
      CONFIG.GET_CATEGORIES,
      (allCategories) => {
        this.setState({
          allCategories,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  handleCategorySelect = (categoryId) => {
    const { categories } = this.state;
    if (categories.includes(categoryId)) {
      const filterCategory = categories.filter((id) => id !== categoryId);
      this.setState({ categories: filterCategory });
    } else {
      this.setState({ categories: [...categories, categoryId] });
    }
    // this.setState({ mainCategory: categoryId });
  };

  handleSubCategoryClick = (categoryId) => {
    const { categories } = this.state;
    const newCategories = [...categories];
    if (categories.indexOf(categoryId) < 0) {
      newCategories.push(categoryId);
    } else {
      newCategories.splice(newCategories.indexOf(categoryId), 1);
    }
    this.setState({ categories: newCategories });
  };

  handlePrivateProfile = (e, toggle) => {
    this.setState({ privateProfile: toggle });
  };

  openPickerDialog = () => {
    this.pickerDialogRef.current.open();
  };

  handleDateChange = (date) => {
    this.setState({ dob: date });
  };

  openPickerDialog = () => {
    this.pickerDialogRef.current.open();
  };

  isUserArtist = () => {
    const { user } = this.props;
    return user.role > 10 && user.role < 21;
  };

  getUserInfo = () => {
    const { user } = this.props;
    this.setState({ loadingUser: true }, () => {
      get(
        generatePath(CONFIG.GET_USER_BY_ID, { id: user.id }),
        (res) => {
          this.setState({
            loadingUser: false,
            picture: {
              url: res?.picture,
            },
            cover: {
              url: res?.cover,
            },
            fullName: res?.name,
            dob: moment(res?.info?.dob),
            email: res?.email,
            address:
              res?.info?.address !== "undefined" ? res?.info?.address : "",
            bio:
              res?.info?.biography !== "undefined" ? res?.info?.biography : "",
            achievements:
              res?.info?.achievements !== "undefined"
                ? res?.info?.achievements
                : "",
            // categories: res.info.categories,
            privateProfile: res?.settings && res?.settings?.private,
            country: res?.shippingInfo?.country && res?.shippingInfo?.country,
            stateRegion: res?.shippingInfo?.state && res?.shippingInfo?.state,
            postalCode:
              res?.shippingInfo?.postalCode && res?.shippingInfo?.postalCode,
            address1: res?.shippingInfo?.address && res?.shippingInfo?.address,
            address2:
              res?.shippingInfo?.address2 && res?.shippingInfo?.address2,
            city: res?.shippingInfo?.city && res?.shippingInfo?.city,
            phoneNumber: res?.shippingInfo?.phoneNumber
              ? res?.shippingInfo?.phoneNumber
              : "",
          });
          if (res?.info?.categories) {
            const cat = res?.info?.categories.map((item) => {
              return item._id;
            });
            this.setState({ categories: [...cat] });
          }
        },
        (e) => {
          console.log(e);
          store.dispatch(
            showGlobalSnack(
              "error",
              "Something went wrong. Please try again.",
              3000
            )
          );
        }
      );
    });
  };

  handleCroppedCoverImage = (dataUrl) => {
    const { cover } = this.state;
    const newCover = { ...cover };
    newCover.dataUrl = dataUrl;
    this.setState({
      cover: newCover,
    });
  };

  handleCoverImageChange = (e) => {
    const { cover } = this.state;
    const newCover = { ...cover };
    newCover.file = e.target.files[0]; // eslint-disable-line
    if (newCover.file.size >= 2e6) {
      this.setState({
        cover: newCover,
      });
    } else {
      store.dispatch(
        showGlobalSnack(
          "error",
          "Please select cover image greater than 2MB",
          3000
        )
      );
    }
  };

  handleCroppedPP = (dataUrl) => {
    const { picture } = this.state;
    const newPicture = { ...picture };
    newPicture.dataUrl = dataUrl;
    this.setState({
      picture: newPicture,
    });
  };

  handleCoverPPChange = (e) => {
    const { picture } = this.state;
    const newPicture = { ...picture };
    newPicture.file = e.target.files[0]; // eslint-disable-line
    this.setState({
      picture: newPicture,
    });
  };

  handleRemovePP = () => {
    this.setState({
      picture: {
        url: null,
        dataUrl: null,
        file: null,
      },
    });
  };

  handleCancel = () => {
    const { history, user } = this.props;

    // if (user.role > 10 && user.role < 21) {
    //   history.push(generatePath(sitemap.artist, { slug: user.slug }));
    // } else {
    history.push(generatePath(sitemap.profile, { slug: user?.slug }));
    // }
  };
  handleProfileEditBtn = () => {
    this.setState({ editProfileBtn: true });
  };
  handleCoverPictureEditBtn = () => {
    this.setState({ editCoverPictureBtn: true });
  };
  handleProfileEditCancelBtn = () => {
    this.setState({ editProfileBtn: false });
  };

  handleSubmit = () => {
    const { user, history } = this.props;
    const data = new FormData();
    const {
      cover,
      fullName,
      dob,
      email,
      address,
      bio,
      achievements,
      allCategories,
      categories,
      picture,
      privateProfile,
      stateRegion,
      postalCode,
      address1,
      address2,
      city,
      phoneNumber,
      country,
    } = this.state;

    if (cover.dataUrl) {
      data.append("cover", dataURItoBlob(cover.dataUrl));
    } else if (cover.url) {
      data.append("cover", cover.url);
    }

    if (picture.dataUrl) {
      data.append("picture", dataURItoBlob(picture.dataUrl));
    } else if (picture.url) {
      data.append("picture", picture.url);
    }

    data.append("name", fullName);
    data.append("email", email);
    data.append("info.address", address);
    data.append("info.dob", dob.format("MM/DD/YYYY"));
    data.append("settings.private", privateProfile);
    data.append("shippingInfo.country", country);
    data.append("shippingInfo.city", city);
    data.append("shippingInfo.state", stateRegion);
    data.append("shippingInfo.address", address1);
    data.append("shippingInfo.address2", address2);
    if (phoneNumber && phoneNumber !== "NaN") {
      data.append("shippingInfo.phoneNumber", parseInt(phoneNumber));
    }
    if (postalCode && postalCode !== "NaN") {
      data.append("shippingInfo.postalCode", parseInt(postalCode));
    }
    data.append("info.achievements", achievements);
    data.append("info.biography", bio);

    // categories

    // const allCategoriesTemp = [];
    // categories.forEach((categoryId) => {
    //   const categoryObj = allCategories.find(
    //     (category) => category._id === categoryId
    //   );
    //   if (
    //     categoryObj.parent &&
    //     allCategoriesTemp.indexOf(categoryObj.parent._id) < 0
    //   ) {
    //     allCategoriesTemp.push(categoryObj.parent._id);
    //   }
    // });

    // const allCategoriesTemp = [];
    // allCategoriesTemp.push(...categories);
    // for (let i = 0; i < allCategoriesTemp.length; i++) {
    //   data.append("info.categories[]", allCategoriesTemp[i]);
    // }
    categories.forEach((item) => data.append("info.categories", item));

    this.setState({ saving: true });
    postAuthMultipart(
      CONFIG.UPDATE_USER_INFO,
      () => {
        this.setState({ saving: false });
        store.dispatch(
          showGlobalSnack("success", "Profile Updated Successfully", 3000)
        );

        // if (user?.role > 10 && user?.role < 21) {
        //   history.push(generatePath(sitemap.artist, { slug: user?.slug }));
        // } else {
        history.push(generatePath(sitemap.profile, { slug: user?.slug }));
        // }
      },
      (e) => {
        console.log(e);
        store.dispatch(
          showGlobalSnack(
            "error",
            "Something went wrong. Please try again.",
            3000
          )
        );
      },
      data
    );
  };

  render() {
    const {
      allCategories,
      categories,
      picture,
      cover,
      errors,
      loadingUser,
      fullName,
      dob,
      email,
      address,
      bio,
      achievements,
      saving,
      privateProfile,
      country,
      stateRegion,
      postalCode,
      address1,
      address2,
      city,
      phoneNumber,
    } = this.state;

    let { mainCategory } = this.state;
    const profileImg =
      "https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png";
    const { classes } = this.props;

    // first paint main category
    if (!mainCategory && categories.length > 0 && allCategories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        const categoryId = categories[i];
        const catObj = allCategories.find(
          (category) => category._id === categoryId
        );
        if (catObj && !catObj.parent) {
          mainCategory = categoryId;
          break;
        }
      }
    }

    const topCategories = allCategories.filter((category) => !category.parent);
    const categoryNavList = topCategories.map((category) => ({
      name: category.name,
      key: category._id,
    }));
    const categoryChildren = allCategories.filter(
      (category) => category.parent && category.parent._id === mainCategory
    );
    const categoryChildrenList = categoryChildren.map((category) => {
      const buttonClasses = classnames("category-select", {
        selected: categories.indexOf(category._id) > -1,
      });
      return (
        <button
          key={`category-select-${category._id}`}
          className={buttonClasses}
          onClick={() => {
            this.handleSubCategoryClick(category._id);
          }}
        >
          {category.name}
        </button>
      );
    });
    let imgUrl = "";
    if (picture?.url === "undefined" || picture?.url === null) {
      imgUrl = profileImg;
    } else {
      imgUrl = `${CONFIG.BUCKET_URL}/users/${picture?.url}`;
    }
    return (
      <div id="edit-info-page">
        <Header
          title="Edit Information"
          subTitle="Add your information to be more visible"
          loading={saving}
          cancel={this.handleCancel}
          publish={this.handleSubmit}
        />

        <section className="commonFormSection">
          {loadingUser ? (
            <Loading color="red" />
          ) : (
            <div className="customContainer smallWidthLayout">
              <ContentCard
                title="Profile Picture"
                handleEdit={this.handleProfileEditBtn}
                editBtn
              >
                <div className="profile-picture">
                  {this.state.editProfileBtn ? (
                    <ProfileUploader
                      setCroppedImage={this.handleCroppedPP}
                      onImageChange={this.handleCoverPPChange}
                      imageFile={picture.file}
                      width={200}
                      height={200}
                      remove={this.handleRemovePP}
                      url={imgUrl}
                      // url={`users/${picture.url}`}
                    />
                  ) : (
                    <div className="pp">
                      {picture?.url ? (
                        <img
                          src={`${CONFIG.BUCKET_URL}/users/${picture.url}`}
                          alt=""
                        />
                      ) : (
                        <img src={profileImg} alt="profile-img" />
                      )}
                    </div>
                  )}
                </div>
              </ContentCard>

              <ContentCard
                title="Cover Image"
                subtitle={
                  this.state.editCoverPictureBtn &&
                  "Please upload image greater than 2MB"
                }
                handleEdit={this.handleCoverPictureEditBtn}
                editBtn
              >
                <div className="cover-image">
                  {this.state.editCoverPictureBtn ? (
                    <CoverUploader
                      setCroppedImage={this.handleCroppedCoverImage}
                      onImageChange={this.handleCoverImageChange}
                      imageFile={cover.file}
                      width={480}
                      height={148}
                      url={`${CONFIG.BUCKET_URL}/users/${cover.url}`}
                    />
                  ) : (
                    <>
                      {cover?.url ? (
                        <img
                          style={{ height: "120px" }}
                          src={`${CONFIG.BUCKET_URL}/users/${cover.url}`}
                          alt=""
                        />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          Please Update Cover Image
                        </div>
                      )}
                    </>
                  )}
                  {/* <Button
                    mode="special"
                    size="auto"
                    onClick={() => {
                      this.setState({
                        cover: { url: null, dataUrl: null, file: null },
                      });
                    }}
                  >
                    Change Image
                  </Button> */}
                </div>
              </ContentCard>

              <ContentCard title="Basic Information">
                <div className="typeTwoCol">
                  <Input
                    required
                    label="Full Name"
                    type="text"
                    name="fullName"
                    value={fullName}
                    onChange={(e) =>
                      this.setState({ fullName: e.target.value })
                    }
                  />
                  <Input
                    required
                    label="Date of Birth"
                    type="text"
                    name="dob"
                    errors={errors}
                    value={dob.format("Do MMM, YYYY")}
                    onClick={this.openPickerDialog}
                  />
                  <div className="datePickerHide">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        ref={this.pickerDialogRef}
                        margin="normal"
                        label="Date Of Birth"
                        value={dob.format("Do MMM, YYYY")}
                        onChange={this.handleDateChange}
                        style={{
                          width: "100%",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Input
                    required
                    readonly="readonly"
                    label="Email Address"
                    type="email"
                    name="Email"
                    value={email}
                    // onChange={(e) => this.setState({ email: e.target.value })}
                    // disabled
                  />
                  <Input
                    required
                    label="Address"
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => this.setState({ address: e.target.value })}
                  />
                </div>
                <p id="shippingInfo">Shipping Information (optional)</p>
                <div className="typeTwoCol">
                  <Input
                    label="Country"
                    type="text"
                    name="country"
                    value={country}
                    onChange={(e) => this.setState({ country: e.target.value })}
                  />

                  <Input
                    label="Postal Code"
                    type="text"
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) =>
                      this.setState({ postalCode: e.target.value })
                    }
                  />
                  <Input
                    label="Address Line 1"
                    type="text"
                    name="address"
                    value={address1}
                    onChange={(e) =>
                      this.setState({ address1: e.target.value })
                    }
                  />
                  <Input
                    label="Address Line 2"
                    type="text"
                    name="address2"
                    value={address2}
                    onChange={(e) =>
                      this.setState({ address2: e.target.value })
                    }
                  />
                  <Input
                    label="City"
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                  />
                  <Input
                    label="State, Province or region"
                    type="text"
                    name="state"
                    value={stateRegion}
                    onChange={(e) =>
                      this.setState({ stateRegion: e.target.value })
                    }
                  />
                  <Input
                    label="Phone"
                    type="text"
                    name="phone"
                    value={phoneNumber}
                    onChange={(e) =>
                      this.setState({ phoneNumber: e.target.value })
                    }
                  />
                </div>
              </ContentCard>
              <ContentCard title="Choose your styles">
                {categoryNavList.length > 0 && (
                  // <FancyNav
                  //   activeItemKey={categories}
                  //   items={categoryNavList}
                  //   onClick={this.handleCategorySelect}
                  // />
                  <div className="your-styles">
                    {categoryNavList.map((item) => {
                      return (
                        <div
                          key={`fancy-nav-item-${item.key}`}
                          className={
                            categories.includes(item.key)
                              ? "activeCat cat"
                              : "cat"
                          }
                        >
                          <span
                            onClick={() => this.handleCategorySelect(item.key)}
                            role="button"
                            tabIndex="-1"
                          >
                            {item.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* <div className="common-category-list">
                  {categoryChildrenList}
                </div> */}
              </ContentCard>

              <ContentCard title="Biography">
                <textarea
                  className="textarea"
                  name="biography"
                  value={bio}
                  placeholder="Biography"
                  onChange={(e) => this.setState({ bio: e.target.value })}
                />
              </ContentCard>

              <ContentCard title="Achievements">
                <textarea
                  name="achievements"
                  value={achievements}
                  placeholder="Achievements"
                  onChange={(e) =>
                    this.setState({ achievements: e.target.value })
                  }
                />
              </ContentCard>

              {/* {
                    this.isUserArtist()
                    && (
                      <ContentCard title="Private Profile">
                        <p>You will have to approve requests for followers if your profile is private</p>
                        <FormControlLabel
                          labelPlacement="start"
                          classes={classes}
                          control={
                            (
                              <Switch
                                checked={privateProfile}
                                onChange={this.handlePrivateProfile}
                                value="privateProfile"
                                color="primary"
                              />
                            )
                          }
                          label="Make my profile private"
                        />
                      </ContentCard>
                    )
                  } */}
            </div>
          )}
        </section>
      </div>
    );
  }
}

Edit.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Edit);
