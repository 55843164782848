import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import "react-perfect-scrollbar/dist/css/styles.css";

import Image from "../ImageTag/Image";
import AddImageIcon from "@material-ui/icons/AddPhotoAlternate";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

import "./ImageUploader.scss";

const styles = {
  iconStyles: {
    color: "#984B48",
  },
  closeIconStyles: {
    color: "#fff",
    // fontSize: 16,
  },
};

class ImageUploaderSingle extends Component {
  inputFileRef = React.createRef();

  handleUploadClick = () => {
    if (this.inputFileRef) {
      this.inputFileRef.current.click();
    }
  };

  onImageChange = (event) => {
    event.persist();
    const { addImage } = this.props;
    if (event.target.files) {
      const { files } = event.target;
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        addImage(event.target.files[0], e.target.result, uuid.v4());
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    const { image, classes, removeImage } = this.props;
    return (
      <div className="image-uploader-wrap single">
        <input
          multiple
          type="file"
          style={{ display: "none" }}
          ref={this.inputFileRef}
          onChange={this.onImageChange}
          accept="image/*"
        />
        {image ? (
          <>
            <div className="single-preview">
              {image.file ? (
                <img src={image.dataUrl} alt="" />
              ) : (
                <Image src={`posts/${image.dataUrl}`} alt="" />
              )}
              {/* <span
                role="button"
                tabIndex="-1"
                onClick={() => {
                  removeImage(image.id);
                }}
                className="remove-image"
              >
                <CloseIcon className={classes.closeIconStyles} />
              </span> */}
            </div>
            <button
              onClick={() => {
                removeImage(image.id);
              }}
              className="change-img-btn"
            >
              Change Image
            </button>
          </>
        ) : (
          <div
            role="button"
            tabIndex="-1"
            className="image-uploader"
            onClick={this.handleUploadClick}
          >
            <AddImageIcon className={classes.iconStyles} />
            <span>Click to add image</span>
          </div>
        )}
      </div>
    );
  }
}

ImageUploaderSingle.propTypes = {
  addImage: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  removeImage: PropTypes.func.isRequired,
  image: PropTypes.objectOf(PropTypes.any),
};

ImageUploaderSingle.defaultProps = {
  image: null,
};

export default withStyles(styles)(ImageUploaderSingle);
