import Cookie from "js-cookie";
import axios from "axios";
import { generatePath } from "react-router";
import { CONFIG } from "../constants";
import store from "../redux";
import { showGlobalSnack } from "../redux/actions/snack.actions";

export const refreshUser = (begin, success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  if (token) {
    begin();
    axios({
      url: `${CONFIG.AUTH_URL}/authenticate`,
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          success(response.data.user, token);
        } else throw new Error(response.data.message);
      })
      .catch((e) => {
        fail(e);
      });
  } else {
    fail("no token");
  }
};

export const getUserData = (slug, success, fail) => {
  const apiUrl = generatePath(CONFIG.GET_USER_SLUG, { slug });
  axios({
    url: apiUrl,
    method: "GET",
  })
    .then((response) => {
      if (response.data.status === "success") {
        success(response.data.message);
      } else throw new Error(response.data.message);
    })
    .catch((e) => {
      fail(e);
    });
};

export const getYourInfo = (success, fail) => {
  const token = Cookie.get(CONFIG.AUTH_TOKEN);
  const apiUrl = CONFIG.GET_YOUR_INFO;
  if (token) {
    axios({
      url: apiUrl,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          success(response.data.message);
        } else throw new Error(response.data.message);
      })
      .catch((e) => {
        fail(e);
      });
  } else {
    store.dispatch(showGlobalSnack("error", "Please login first", 3000));
  }
};
