import React from 'react';
import { node, string, bool } from 'prop-types';
import classnames from 'classnames';

import './HomeSection.scss';

const HomeSection = ({ children, title, primary, grey, fullWidth, fullHeight }) => {
  const classes = classnames('home-section', {
    grey,
    fullHeight
  });
  const containerClasses = classnames('customContainer', {
    mediumWidthLayout: !fullWidth
  });
  return (
    <div className={classes}>
      <div className={containerClasses}>
        {
          title !== '' && (
            <div className="top">
              <div className="title">
                {title}
              </div>
              <div className="primary">
                {primary}
              </div>
            </div>
          )
        }
        {children}
      </div>
    </div>
  );
};

HomeSection.propTypes = {
  children: node.isRequired,
  title: string,
  primary: node,
  grey: bool,
  fullWidth: bool,
  fullHeight: bool
};

HomeSection.defaultProps = {
  title: '',
  primary: '',
  grey: false,
  fullWidth: false,
  fullHeight: false
};

export default HomeSection;
