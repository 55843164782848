import React, { Component } from "react";
import { string } from "prop-types";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { objectOf, any } from "prop-types";

import {
  getPopularProductsByCategory,
  getPopularProductsByCategoryAuth
} from "../../services/productServices";
import Loading from "../Loading";
import HomeSection from "../HomeSection";
import ContentScroller from "../ContentScroller";
import { getThumbnail } from "../../utils/mediaUtils";
import siteMap from "../../routing/siteMap";
import "./ArtList.scss";
import Image from "../ImageTag/Image";

class ArtList extends Component {
  state = {
    isLoading: false,
    items: []
  };

  componentDidMount() {
    const { categoryId, name, user } = this.props;
    if (user) {
      getPopularProductsByCategoryAuth(
        categoryId,
        items => {
          this.setState({ items, isLoading: false });
        },
        err => {
          console.log(`Error occurred while trying to fetch ${name}`, err);
        }
      );
    } else {
      getPopularProductsByCategory(
        categoryId,
        items => {
          this.setState({ items, isLoading: false });
        },
        err => {
          console.log(`Error occurred while trying to fetch ${name}`, err);
        }
      );
    }
  }

  render() {
    const { name } = this.props;
    const { items, isLoading } = this.state;
    const artList = items.slice(0, 11).map(item => {
      const thumbnail = getThumbnail(item?.image, "productImage");
      return (
        <div key={`${name}-item-${item?._id}`} className="popularItem">
          <div className="imageContainer">
            <Link to={generatePath(siteMap.artWork, { slug: item?.slug })}>
              <div>
                <Image
                  src={`${item?.image?.set?.slug}/${thumbnail?.fileName}`}
                  alt=""
                  skeletonHeight={300}
                  skeletonWidth={300}
                />
              </div>
            </Link>
          </div>

          <div className="artDetails">
            <div className="artLeft">
              <div className="artName">
                {item.name.slice(0, 20)}
                {item?.name?.length > 20 && "..."}
              </div>
              <div>{item?.priceField.toUpperCase()}</div>
              <span>
                by{" "}
                <Link
                  to={generatePath(siteMap.artist, {
                    slug: item?.author?.slug
                  })}
                >
                  {item?.author?.name}
                </Link>
              </span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="art-list">
        <HomeSection title={name} fullWidth>
          {isLoading ? (
            <Loading color="red" />
          ) : (
            <ContentScroller title={name} arrowPosition="around">
              {artList}
            </ContentScroller>
          )}
        </HomeSection>
      </div>
    );
  }
}

ArtList.propTypes = {
  categoryId: string.isRequired,
  name: string.isRequired,
  user: objectOf(any)
};

ArtList.defaultProps = {
  user: null
};
export default ArtList;
