import axios from "axios";
import Cookie from "js-cookie";

import { CONFIG } from "../constants";
import store from "../redux";
import { showGlobalSnack } from "../redux/actions/snack.actions";

export const getStudiosToFollow = () => {
  return new Promise((resolve, reject) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      axios({
        url: CONFIG.GET_STUDIOS_TO_FOLLOW,
        method: "GET",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.result);
          } else throw new Error(response.data.error);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
  });
};
export const getRecommendedStudios = () => {
  return new Promise((resolve, reject) => {
    const token = Cookie.get(CONFIG.AUTH_TOKEN);
    if (token) {
      axios({
        url: CONFIG.GET_RECOMMENDED_STUDIOS,
        method: "GET",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response.data.studios);
          } else throw new Error(response.data.error);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      store.dispatch(showGlobalSnack("error", "Please login first", 3000));
    }
  });
};

export const getFeatured = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: CONFIG.GET_FEATURED_STUDIOS,
      method: "GET",
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.result);
        } else throw new Error(response.data.error);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
