import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";

import { getWithAuth } from "../../services/generalApiServices";
import { getThumbnail } from "../../utils/mediaUtils";
import { CONFIG } from "../../constants";
import sitemap from "../../routing/siteMap";
import Loading from "../Loading";
import HomeSection from "../HomeSection";
import ContentScroller from "../ContentScroller";
import LazyLoad from "react-lazy-load";
import Image from "../ImageTag/Image";
import "./RecommendedProducts.scss";

class RecommendedProducts extends Component {
  state = {
    artworks: [],
    isLoading: true
  };

  componentDidMount() {
    getWithAuth(
      CONFIG.GET_RECOMMENDED,
      artworks => {
        this.setState({ artworks, isLoading: false });
      },
      e => {
        console.log(e);
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    const { artworks, isLoading } = this.state;

    const items = artworks.slice(0, 11).map(item => {
      const thumbnail = getThumbnail(item.image, "productImage");
      const { slug, name } = item?.author;
      return (
        <div key={`popular-item-${item._id}`} className="popularItem">
          <div className="imageContainer">
            <Link to={generatePath(sitemap.artWork, { slug: item?.slug })}>
              <div>
                {/* <LazyLoad once> */}
                <Image
                  src={`${item?.image?.set?.slug}/${thumbnail?.fileName}`}
                  alt=""
                  skeletonHeight={300}
                  skeletonWidth={300}
                />
                {/* </LazyLoad> */}
              </div>
            </Link>
          </div>
          <div className="artTextContainer">
            <div className="artTextItem">
              <h5>
                {item.name.slice(0, 20)}
                {item?.name?.length > 20 && "..."}
              </h5>
              <h6>
                {item?.priceField.toUpperCase()}
                {/* {item?.price && item?.price != 0 ? "NPR. " + item.price : "NFS"} */}
              </h6>

              <span>
                by{" "}
                <Link to={generatePath(sitemap.artist, { slug })}>{name}</Link>
              </span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <>
        {items.length > 0 ? (
          <HomeSection title="Recommended for you" fullWidth>
            <div className="art-from-followed">
              {isLoading ? (
                <Loading color="red" />
              ) : (
                <ContentScroller title="Popular Works" arrowPosition="around">
                  {items}
                </ContentScroller>
              )}
            </div>
          </HomeSection>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default RecommendedProducts;
