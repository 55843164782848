import React from "react";
import { NavLink } from "react-router-dom";

import HomeSection from "../../HomeSection";
import "./Home.scss";

const ArtCategory = () => {
  return (
    <div className="art-categories">
      <HomeSection fullWidth>
        <section className="categorySection">
          <h3 className="cat">Categories</h3>
          <div className="sectionContent">
            <ul className="categoryContainer">
              <li>
                <NavLink to="/gallery/painting" className="cat1">
                  Painting
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/sculpture" className="cat2">
                  Sculpture
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/photography" className="cat3">
                  Photography
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/new-media-art" className="cat4">
                  New Media Art
                </NavLink>
              </li>
            </ul>
            <ul className="categoryContainer">
              <li>
                <NavLink to="/gallery/printmaking" className="cat5">
                  Printmaking
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/drawing" className="cat6">
                  Drawing
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/design" className="cat7">
                  Design
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery/handicrafts" className="cat8">
                  Handicraft
                </NavLink>
              </li>
            </ul>
          </div>
        </section>
      </HomeSection>
    </div>
  );
};

export default ArtCategory;
