import React, { Component } from "react";
import { generatePath } from "react-router";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import Breakpoint from "react-socks";

import ArtShowcase, { ArtShowcaseItem } from "../../ArtShowcase";
import { getThumbnail } from "../../../utils/mediaUtils";
import { nl2br } from "../../../utils/stringUtils";
import Loading from "../../Loading";
import { get, post } from "../../../services/generalApiServices";
import ArtBanner from "./ArtBanner";
import { CONFIG } from "../../../constants";
import UserShowcase from "../../ArtistShowcase/UserShowcase";
import ArtWorkBreakdwon from "./ArtWorkBreakdown";
import UserArtShowcaseItem from "../User/Profile/UserArtShowcaseItem";
import CommentOnUserArt from "../../Comment/CommentOnUserArt";
import "./ArtWork.scss";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});

class UserArtwork extends Component {
  constructor() {
    super();
    this.state = {
      art: {},
      similarArts: [],
      userInfo: null,
      isLoading: true,
      readMore: false,
    };
  }

  componentDidMount() {
    this.loadArt();
    this.getSimilarArts();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.slug !== match.params.slug) {
      this.setState(
        {
          // eslint-disable-line
          art: {},
          similarArts: [],
          userInfo: null,
          isLoading: true,
          readMore: false,
        },
        () => {
          this.loadArt();
          this.getSimilarArts();
        }
      );
    }
  }

  getSimilarArts() {
    const { match } = this.props;
    post(
      generatePath(CONFIG.GET_ARTWORK_BY_SIMILAR, { slug: match.params.slug }),
      (similarArts) => {
        this.setState({
          similarArts,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadArt() {
    const { match } = this.props;
    post(
      generatePath(CONFIG.GET_ARTWORK_BY_SLUG, { slug: match.params.slug }),
      (art) => {
        get(
          generatePath(CONFIG.GET_USER_SLUG, { slug: art.author.slug }),
          (userInfo) => {
            this.setState({
              userInfo,
              art,
              isLoading: false,
            });
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  toggleReadMore = () => {
    this.setState({ readMore: !this.state.readMore });
  };

  setFollow=()=>{
        this.loadArt();
    this.getSimilarArts();
  }

  render() {
    const { art, similarArts, isLoading, userInfo } = this.state;
    const { winWidth } = this.props;

    const productsList = similarArts.map((product) => {
      const thumbnail = getThumbnail(product?.image, "gallery");
      return (
        <UserArtShowcaseItem
          readOnly={true}
          key={`user-work-${product._id}`}
          artist={product?.author?.name}
          userId={product?.author?._id}
          artName={product?.name}
          thumbnail={thumbnail}
          price={product?.priceField}
          // price={String(product?.price)}
          slug={product?.slug}
          art={product}
          artistSlug={product?.author?.slug}
        />
      );
    });

    let columnCount = 3;
    let gutterWidth = 14;

    if (winWidth < 991) {
      columnCount = 2;
      gutterWidth = 10;
    }
    return (
      <div id="artWorkPage">
        {isLoading ? (
          <Loading color="red" />
        ) : (
          <>
            <div className="mobilePriceContainer">
              <Breakpoint s>
                <section className="mobilePriceSection">
                  <div className="priceContainer">
                    <h2>{art?.name}</h2>
                    <h2>{art?.priceField.toUpperCase()}</h2>
                    {/* <h2>{art?.price !== 0 ? "NPR. " + art.price : "NFS"}</h2> */}
                  </div>
                </section>
              </Breakpoint>
            </div>

            <section className="artGallerySection">
              <ArtBanner art={art} />
            </section>

            <section className="detialSection">
              <div className="customContainer mediumWidthLayout">
                <div className="sectionContent">
                  <article className="arDetailArticle">
                    <div className="artDetailContainer">
                      <div className="artDetailItem">
                        <Breakpoint m>
                          <article
                            className="artNameArticle"
                            style={{
                              paddingBottom: "20px",
                              paddingTop: "0",
                              paddingLeft: "0",
                              borderBottom: "1px solid #F2F2F2",
                            }}
                          >
                            <h2>{art.name}</h2>
                            <p style={{ marginTop: "5px" }}>
                              {art?.priceField.toUpperCase()}
                              {/* {art.price === 0 ? "NFS" : "NPR. " + art.price} */}
                            </p>
                            {art.attributes &&
                            (art.attributes.height || art.attributes.width) ? (
                              <div className="dimension">
                                <span>
                                  <b>H:</b> {"  "}{" "}
                                  <span>
                                    {" "}
                                    {art.attributes.height
                                      ? art.attributes.height
                                      : ""}{" "}
                                    in
                                  </span>
                                </span>
                                <span>
                                  <b>W:</b> {"  "}
                                  <span>
                                    {" "}
                                    {art.attributes.width
                                      ? art.attributes.width
                                      : ""}{" "}
                                    in
                                  </span>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </article>
                        </Breakpoint>
                        <div className="descriptionWrapper">
                          <h6>Description</h6>

                          {art.description && art.description !== undefined && (
                            <>
                              <p>
                                {" "}
                                {!this.state.readMore ? (
                                  <>
                                    {" "}
                                    {art?.description?.slice(0, 150)}{" "}
                                    {art?.description?.length > 150 && "..."}
                                  </>
                                ) : (
                                  nl2br(art?.description)
                                )}{" "}
                                <label
                                  style={{ fontWeight: "bolder" }}
                                  onClick={this.toggleReadMore}
                                  htmlFor="expanded"
                                  role="button"
                                >
                                  {" "}
                                  {art?.description?.length > 150 && (
                                    <>
                                      {" "}
                                      {!this.state.readMore
                                        ? "Read More"
                                        : "Show Less"}
                                    </>
                                  )}
                                </label>{" "}
                              </p>
                            </>
                          )}
                        </div>

                        <div className="commentWrapper">
                          <CommentOnUserArt item={art} />
                        </div>
                      </div>
                      <div className="artDetailItem">
                        <ArtWorkBreakdwon art={art} />
                      </div>
                    </div>
                  </article>

                  <article className="artistInfoArticle">
                    <UserShowcase fullWidthGallery userInfo={userInfo} setFollow={this.setFollow} />
                  </article>

                  <article className="similarArtArticle">
                    <div className="sectionTitle addTypeCenter coAddMargin coMarginSmall">
                      <h4>Similar Artworks</h4>
                    </div>
                    {isLoading ? (
                      <Loading color="red" />
                    ) : (
                      <ArtShowcase
                        columnCount={columnCount}
                        gutter={gutterWidth}
                      >
                        {productsList}
                      </ArtShowcase>
                    )}
                  </article>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}

UserArtwork.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
};

export default withSizes(mapSizesToProps)(UserArtwork);
