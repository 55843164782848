import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordSubmit from './ResetPasswordSubmit';
import VerifyEmail from './VerifyEmail';

export default Login;

export {
  ResetPassword,
  ResetPasswordSubmit,
  VerifyEmail
};
