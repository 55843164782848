import React from 'react';
import { withRouter } from 'react-router-dom';
import { isRegExp } from 'lodash';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Wrapper = ({ hideFooterOnPages, children, location, hideMobileFooter }) => {
  let isFooterHidden = false;
  let isFooterMobileHidden = false;

  hideFooterOnPages.forEach((exp) => {
    if (isRegExp(exp) && location.pathname.match(exp)) {
      isFooterHidden = true;
    } else if (exp === location.pathname) {
      isFooterHidden = true;
    }
  });

  hideMobileFooter.forEach((exp) => {
    if (isRegExp(exp) && location.pathname.match(exp)) {
      isFooterMobileHidden = true;
    } else if (exp === location.pathname) {
      isFooterMobileHidden = true;
    }
  });

  return (
    <div id="wrapper" className={cn({ noFooter: isFooterHidden, noMobileFooter: isFooterMobileHidden })}>
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  hideFooterOnPages: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Wrapper);
