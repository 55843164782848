import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";

import { get } from "../../../services/generalApiServices";
import { CONFIG } from "../../../constants";
import FeedItem from "../../FeedItem";

class ArtistFeed extends Component {
  state = {
    posts: [],
    loading: true,
    totalPosts: CONFIG.FEED_LIMIT,
  };

  componentDidMount() {
    this.getPosts();
  }

  getPosts = () => {
    const { artist } = this.props;
    const { totalPosts } = this.state;
    get(
      generatePath(CONFIG.GET_POSTS_BY_ARTIST, {
        id: artist._id,
        total: totalPosts,
      }),
      (response) => {
        this.setState({
          posts: response,
          loading: false,
        });
      },
      (error) => {
        console.error(error);
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { posts, loading } = this.state;
    const feedList =
      posts.length > 0 &&
      posts.map((post) => (
        <FeedItem
          key={`artist-feed-${post._id}`}
          refresh={this.getPosts}
          post={post}
          showOption={true}
        />
      ));
    return (
      <section>
        <div className="customContainer smallWidthLayout">
          {loading ? (
            ""
          ) : (
            <>
              {feedList.length > 0 ? (
                feedList
              ) : (
                <div style={{ textAlign: "center" }}>No posts found</div>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
}

ArtistFeed.propTypes = {
  artist: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArtistFeed;
