import React from "react";
import PropTypes from "prop-types";
import { Range } from "rc-slider";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import "./FilterDialog.scss";

const dialogClasses = {
  paper: {
    width: "100%",
    padding: 0,
    borderRadius: 0,
    maxWidth: 300,
  },
};

const FilterDialog = ({
  isOpen,
  onClose,
  classes,
  onPriceChange,
  afterPriceChange,
  priceMin,
  priceMax,
  priceRange,
  handleSort,
  orderBy,
  user,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={classes}
    >
      <div id="filter-dialog-title">
        <span>Filter</span>
        <span className="_close" onClick={onClose} role="button" tabIndex="-1">
          <CloseIcon />
        </span>
      </div>
      <div id="filter-dialog-message">
        <div className="range-wrap">
          <Range
            onChange={onPriceChange}
            onAfterChange={afterPriceChange}
            min={priceMin}
            max={priceMax}
            step={500}
            value={priceRange}
          />
          <div className="range-display">
            {`NPR${priceRange[0]} - NPR${priceRange[1]}`}
          </div>
        </div>
        <div className="price-sort">
          <span className="_title">Sort By</span>
          <ul id="sort-options">
            {user && (
              <li className={orderBy === "recommended" ? "active" : ""}>
                <span
                  onClick={() => handleSort("recommended")}
                  role="button"
                  tabIndex="-1"
                >
                  Recommended
                </span>
              </li>
            )}
            <li className={orderBy === "new" ? "active" : ""}>
              <span
                onClick={() => handleSort("new")}
                role="button"
                tabIndex="-1"
              >
                Newest First
              </span>
            </li>
            <li className={orderBy === "pricehigh" ? "active" : ""}>
              <span
                onClick={() => handleSort("pricehigh")}
                role="button"
                tabIndex="-1"
              >
                Price: High to Low
              </span>
            </li>
            <li className={orderBy === "pricelow" ? "active" : ""}>
              <span
                onClick={() => handleSort("pricelow")}
                role="button"
                tabIndex="-1"
              >
                Price: Low to High
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

FilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  onPriceChange: PropTypes.func.isRequired,
  afterPriceChange: PropTypes.func.isRequired,
  priceMin: PropTypes.number.isRequired,
  priceMax: PropTypes.number.isRequired,
  priceRange: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(dialogClasses)(FilterDialog);
