import React, { Component } from "react";
import PropTypes from "prop-types";
import Breakpoint from "react-socks";
import { NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Range } from "rc-slider";
import withSizes from "react-sizes";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";

import sitemap from "../../../routing/siteMap";
import ArtShowcase, { ArtShowcaseItem } from "../../ArtShowcase";
import { getThumbnail } from "../../../utils/mediaUtils";
import PageHead from "../../PageHead/PageHead";
import Loading from "../../Loading";
import CategorySelectDialog from "./CategorySelectDialog";
import FilterDialog from "./FilterDialog";
import { get } from "../../../services/generalApiServices";
import {
  getProductsAuth,
  getProducts,
} from "../../../services/productServices";
import { CONFIG } from "../../../constants";
import { FaAngleDown } from "react-icons/fa";
import "rc-slider/assets/index.css";
import "./Gallery.scss";

const mapSizesToProps = (sizes) => ({
  winWidth: sizes.width,
});
const mapStateToProps = ({ user }) => ({ user });

class Gallery extends Component {
  state = {
    products: [],
    categories: [],
    loading: true,
    loadingMore: false,
    priceRange: [0, 5000000],
    categorySelectDialogOpen: false,
    filterDialogOpen: false,
  };

  orderBy = "new";

  total = 999999;

  skip = 0;

  limit = CONFIG.GALLERY_PAGE_LIMIT;

  priceMin = 0;

  priceMax = 5000000;

  componentDidMount() {
    const { match } = this.props;
    if (match.params.cat !== "all") {
      this.getCategories();
    } else {
      this.getCategories();
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { categories } = this.state;
    if (prevProps.match.url !== match.url) {
      this.skip = 0;
      this.getProducts(categories);
    }
  }

  getCategories = () => {
    get(
      CONFIG.GET_CATEGORIES,
      (categories) => {
        this.setState({
          categories,
        });
        this.getProducts(categories);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  getProducts = (categories, concat = false) => {
    const { match, user } = this.props;
    const { priceRange, products } = this.state;
    const activeCat = match.params.cat;
    const { _id } =
      activeCat === "all"
        ? { _id: "all" }
        : categories.find((category) => category.slug === activeCat);

    if (!concat) {
      this.setState({ loading: true, products: [] });
    }
    if (user) {
      getProductsAuth(
        _id,
        {
          orderby: this.orderBy,
          price: `${priceRange[0]},${priceRange[1]}`,
          skip: this.skip,
          limit: this.limit,
        },
        (response) => {
          const newProducts = response.products;
          this.total = response.total;
          if (concat) {
            this.setState({
              products: [...products, ...newProducts],
              loadingMore: false,
            });
            if (
              this.state.products?.length <= 0 &&
              this.orderBy === "recommended"
            ) {
              this.orderBy = "new";
              this.getProducts(categories, (concat = false));
            }
          } else {
            this.setState({
              products: newProducts,
              loading: false,
            });
          }
        },
        (error) => {
          console.error(error);
          this.setState({
            loading: false,
          });
        }
      );
    } else {
      getProducts(
        _id,
        {
          orderby: this.orderBy,
          price: `${priceRange[0]},${priceRange[1]}`,
          skip: this.skip,
          limit: this.limit,
        },
        (response) => {
          const newProducts = response.products;
          this.total = response.total;
          if (concat) {
            this.setState({
              products: [...products, ...newProducts],
              loadingMore: false,
            });
          } else {
            this.setState({
              products: newProducts,
              loading: false,
            });
          }
        },
        (error) => {
          console.error(error);
          this.setState({
            loading: false,
          });
        }
      );
    }
  };

  loadMore = () => {
    const { categories } = this.state;
    this.skip += this.limit;
    this.setState({ loadingMore: true });
    this.getProducts(categories, true);
  };

  handleSort = (orderBy) => {
    const { categories } = this.state;
    this.orderBy = orderBy;
    this.skip = 0;
    this.getProducts(categories);
  };

  handlePriceChange = (range) => {
    this.setState({ priceRange: range });
  };

  updatePriceChange = () => {
    this.skip = 0;
    const { categories } = this.state;
    this.getProducts(categories);
  };

  render() {
    const {
      products,
      loading,
      categories,
      loadingMore,
      priceRange,
      categorySelectDialogOpen,
      filterDialogOpen,
    } = this.state;
    const { winWidth, match, user } = this.props;
    const categoryList = categories.map((category) => (
      <li key={`gallery-cat-${category._id}`}>
        <NavLink
          exact
          to={generatePath(sitemap.gallery, { cat: category?.slug })}
        >
          <span>{category?.name}</span>
        </NavLink>
      </li>
    ));
    let activeCategoryName = null;
    if (match.params.cat === "all") {
      activeCategoryName = "All";
    } else {
      activeCategoryName = categories.find(
        (cat) => cat?.slug === match?.params?.cat
      );
      activeCategoryName = activeCategoryName && activeCategoryName?.name;
    }
    const productsList = products.map((product) => {
      const thumbnail = getThumbnail(product?.image, "gallery");
      return (
        <>
          {product?.author && (
            <ArtShowcaseItem
              key={`artist-work-${product?._id}`}
              artist={product?.author?.name}
              artName={product?.name}
              thumbnail={thumbnail}
              price={product?.priceField}
              slug={product?.slug}
              artistSlug={product?.author?.slug}
              art={product}
            />
          )}
        </>
      );
    });

    let columnCount = 5;
    if (winWidth < 1199) {
      columnCount = 4;
    }
    if (winWidth < 667) {
      columnCount = 3;
    }
    if (winWidth < 481) {
      columnCount = 2;
    }

    return (
      <div id="galleryPage">
        <CategorySelectDialog
          isOpen={categorySelectDialogOpen}
          onClose={() => {
            this.setState({ categorySelectDialogOpen: false });
          }}
          categories={categories}
        />
        <FilterDialog
          isOpen={filterDialogOpen}
          onClose={() => {
            this.setState({ filterDialogOpen: false });
          }}
          onPriceChange={this.handlePriceChange}
          afterPriceChange={this.updatePriceChange}
          priceMin={this.priceMin}
          priceMax={this.priceMax}
          priceRange={priceRange}
          handleSort={this.handleSort}
          orderBy={this.orderBy}
          user={user}
        />
        <PageHead title="Gallery" />

        {/* <Breakpoint m> */}
        <section className="commonSubNavSection addHalfMargin typeCategories">
          <div className="customContainer">
            <div className="sectionContent">
              <div className="subNavItem">
                <ul>
                  <li>
                    <span style={{ fontWeight: 600, color: "#343434" }}>
                      Categories
                    </span>
                  </li>
                  <li>
                    <NavLink exact to="/gallery/all">
                      <span>All</span>
                    </NavLink>
                  </li>
                  {categoryList}
                </ul>
              </div>

              <div className="subNavItem">
                <ul>
                  {/* <li className="commonDropdown typeLargeSpace">
                    <NavLink to="/studios">
                      <span>
                        Price{" "}
                        <FaAngleDown
                          style={{
                            fontSize: "15px",
                            position: "relative",
                            top: "-1px",
                            left: "8px",
                          }}
                        />
                      </span>
                    </NavLink>
                  </li> */}
                  <li className="commonDropdown typeLargeSpace">
                    <span style={{ cursor: "pointer", color: "#212121" }}>
                      Price{" "}
                      <FaAngleDown
                        style={{
                          fontSize: "15px",
                          position: "relative",
                          top: "-1px",
                          left: "8px",
                        }}
                      />
                    </span>
                    <ul id="price-options">
                      <li>
                        <div className="range-wrap">
                          <Range
                            onChange={(e) => {
                              this.handlePriceChange(e);
                            }}
                            onAfterChange={this.updatePriceChange}
                            min={this.priceMin}
                            max={this.priceMax}
                            step={500}
                            value={priceRange}
                          />
                          <div className="range-display">
                            {`NPR${priceRange[0]} - NPR${priceRange[1]}`}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="commonDropdown typeLargeSpace">
                    <span style={{ cursor: "pointer", color: "#212121" }}>
                      Sort By{" "}
                      <FaAngleDown
                        style={{
                          fontSize: "15px",
                          position: "relative",
                          top: "-1px",
                        }}
                      />
                    </span>

                    <ul id="sort-options">
                      {user && (
                        <li>
                          <span
                            onClick={() => this.handleSort("recommended")}
                            role="button"
                            tabIndex="-1"
                          >
                            Recommended
                          </span>
                        </li>
                      )}
                      <li>
                        <span
                          onClick={() => this.handleSort("new")}
                          role="button"
                          tabIndex="-1"
                        >
                          Newest First
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => this.handleSort("pricehigh")}
                          role="button"
                          tabIndex="-1"
                        >
                          Price: High to Low
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => this.handleSort("pricelow")}
                          role="button"
                          tabIndex="-1"
                        >
                          Price: Low to High
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* </Breakpoint>

        <Breakpoint s> */}
        <section className="mobileSelectSection">
          <div className="filterContainer">
            <div
              role="button"
              tabIndex="-1"
              className="filterItem"
              onClick={() => {
                this.setState({ categorySelectDialogOpen: true });
              }}
            >
              <ul>
                <li>Categories</li>
                <li>{activeCategoryName}</li>
              </ul>
              <DownIcon />
            </div>
            <div
              role="button"
              tabIndex="-1"
              onClick={() => {
                this.setState({ filterDialogOpen: true });
              }}
              className="filterItem"
            >
              <ul>
                <li>Filter</li>
              </ul>
              <DownIcon />
            </div>
          </div>
        </section>
        {/* </Breakpoint> */}

        <section className="gallerySection">
          <div className="customContainer">
            <div className="sectionContent">
              {loading ? (
                <Loading color="red" />
              ) : (
                <ArtShowcase columnCount={columnCount} gutter={17}>
                  {productsList}
                </ArtShowcase>
              )}
            </div>
          </div>
        </section>
        {!loading && (
          <>
            {loadingMore ? (
              <Loading color="red" />
            ) : (
              <>
                {products.length < this.total && (
                  <BottomScrollListener onBottom={this.loadMore} offset={300} />
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

Gallery.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  winWidth: PropTypes.number.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(Gallery));
