import React from "react";
import classnames from "classnames";
import { oneOf, string, oneOfType, number } from "prop-types";
import LazyLoad from "react-lazy-load";

import "./ImageContainer.scss";
import Image from "../ImageTag/Image";

const ImageContainer = ({
  size,
  imgPath,
  alt,
  width,
  height,
  borderRadius
}) => {
  const imgClasses = classnames({
    fill: size === "fill",
    contain: size === "contain",
    cover: size === "cover",
    "scale-down": size === "scale-down"
  });
  const styles = {
    width,
    height,
    borderRadius
  };

  return (
    <div className="image-container" style={styles}>
      <Image
        src={imgPath}
        alt={alt}
        className={imgClasses}
        skeletonHeight={300}
        skeletonWidth={300}
      />
    </div>
  );
};

ImageContainer.propTypes = {
  imgPath: string.isRequired,
  size: oneOf(["cover", "contain", "fill", "none", "scale-down"]),
  alt: string,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  borderRadius: number
};

ImageContainer.defaultProps = {
  size: "none",
  alt: "",
  width: "100%",
  height: "auto",
  borderRadius: 0
};

export default ImageContainer;
